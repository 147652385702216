import { Component, OnInit } from '@angular/core';


@Component({
	selector: 'app-cost-center',
	templateUrl: './cost-center.component.html',
	styleUrls: ['./cost-center.component.scss']
})
export class CostCenterComponent implements OnInit {


	constructor() {	}

	ngOnInit() {}
}
