import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CostCenterRoutingModule } from './cost-center-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { CostCenterComponent } from './cost-center.component';
import { UpdateCostCenterComponent } from './update-cost-center/update-cost-center.component';
import { CreateCostCenterComponent } from './create-cost-center/create-cost-center.component';
import { ViewCostCenterComponent } from './view-cost-center/view-cost-center.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';


@NgModule({
  declarations: [ CostCenterComponent , UpdateCostCenterComponent, CreateCostCenterComponent, ViewCostCenterComponent],
  imports: [
    CommonModule,
    SharedModule,
    CostCenterRoutingModule,
    NgxPaginationModule,
    KeyboardShortcutsModule.forRoot(),
    FormsModule,
    ReactiveFormsModule
  ]
})
export class CostCenterModule { }
