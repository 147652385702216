
 <div *ngIf="showMessage" class="status-modal-box  transition delay scale-1" [ngClass]="{'show-modal-box': showMessage, 'hide-modal-box': !showMessage}">
  <div class="status-card transition w-100per position-fixed top-10 bg-white-color modal-box-card d-flex flex-y-center w-100per justify-space-between p-10-20 bdr-radius-10 mb-10">
    <div class="d-flex flex-y-center">
      <img
        src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/{{alertType=='error-box' ? 'red-circle-error.svg' :
          alertType=='success-box' ? 'green-sucess.svg' : 
          alertType=='warning-box' ? 'red-warning.svg' : 
          null }}"
        width="20px"
        height="20px"
        alt="alert-logo"
        class="mr-20"
      />
      <div class="ml-10">
        <h4 class="m-0 text-size-18 text-bold"
        [ngClass]="{'text-red': alertType=='error-box', 'text-orange-color': alertType=='warning-box', 'text-green' :  alertType=='success-box' }">
          {{alertType === "error-box"
          ? "Error"
          : alertType === "warning-box"
          ? "Warning"
          : alertType === "success-box"
          ? "Success"
          : null}}
        </h4>
        <small className="text-dark-grey-color text-size-12 mt-5 mb-0">
         {{message}}
        </small>
      </div>
    </div>
    <img
      src="{{commonService.aws_asset_url}}/_assets/icons/red-close.svg"
      alt="close-btn"
      (click)="hideError()"
      className=" close-btn"
    />
  </div>
</div>

<button type="button" hidden  #openSuccessModal data-toggle="modal" data-target="#successModal"></button>
<!-- Modal -->
<div id="successModal" class="modal fade" role="dialog" data-backdrop="false">
    <div class="modal-dialog transaction-creation-modal">
        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <span class="messageFont">
                    <span>
                        {{message}} 
                    </span>
                    <a 
                        *ngIf="createdSupplierCode != ''"
                         href="javascript:void(0)"
                        (click)="viewSupplier(createdSupplierId)">
                            {{createdSupplierCode}}
                    </a>
                </span>
            <button type="button" class="close" (click)="closeSuccesModal()">&times;</button>
            </div>

            <div class="modal-body">
                <p>Do you want to create new Supplier or view newly created Supplier ?</p>
            </div>
          
            <div class="modal-footer justify-content-center" >
                <button tabIndex="1" tabindex="1" type="button" class="btn-style bg-blue" (click)="viewSupplier(createdSupplierId)">
                    View
                </button>
                <button tabIndex="3" tabindex="3" type="button" class="btn-style bg-green" (click)="closeSuccesModal()">
                    Create New
                </button>
            </div>
        </div>
    </div>
</div>

<div class="ledger-container  ">
  <div class="row tab-nav pl-0 pr-0">
    <!-- <div class="col-sm-4 pl-0">
        <ul class="list-inline list-unstyled p-0 m-0">
            <li class="active page-title text-uppercase cursor"> View Client</li>
        </ul>
    </div> -->
    <div class="col-sm-4 ">
      <ul class="page-nav d-flex align-item-center list-inline list-unstyled p-0 m-0">
          <li [routerLink]="['/']"
              class="text-grey text-capitalize cursor  hover-text-primary transition"> Master</li>
          <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
          <li [routerLink]="['/']"
              class="text-grey text-capitalize cursor  hover-text-primary transition"> Supplier</li>
          <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
          <li class="text-grey text-capitalize cursor text-primary transition"> Create Supplier</li>
      </ul>
  </div>
</div>
 

    <form [formGroup]="supplierForm" [ngClass]="{'d-none': !newSupplier}">
      <div class="custom-form form-container mt-4 pl-15 pr-15" style="    width: 600px;
      margin: 20px 0;">
      <div class="text-input-group d-flex justify-content-between">

        <div class="select-field text-input-field mr-2">
          <select class="input-field" oninput="this.value = this.value.toUpperCase()" tabIndex="1"
            formControlName="parent_id" [ngClass]="{'error-field': (!supplierForm.controls.parent_id.valid && submitted),
            'success-field' : supplierForm.controls.parent_id.valid && supplierForm.controls.parent_id.dirty}">
            <option selected disabled>SELECT</option>
            <!-- <ng-container *ngIf="groupList.length > 0"> -->
            <option *ngFor="let item of groupList" value="{{item.id}}">{{item.name}}</option>
            <!-- </ng-container> -->
          </select>
          <label
            [ngClass]="{'error-label': supplierForm.controls.parent_id.invalid && supplierForm.controls.parent_id.dirty || (!supplierForm.controls.parent_id.valid && submitted)}">Group
            <ng-container
              *ngIf="supplierForm.controls.parent_id.invalid && supplierForm.controls.parent_id.dirty || (!supplierForm.controls.parent_id.valid && submitted)">
              <i class="fa fa-info-circle ml-2" title="Group Required" aria-hidden="true"></i>
            </ng-container>
          </label>
        </div>

        <div style="width: 150px;" class="select-field text-input-field">
          <select class="input-field" tabIndex="1" formControlName="is_card_account">
            <option value="true">YES</option>
            <option value="false">NO</option>
          </select>
          <label>CARD</label>
        </div>

      </div>

      <div class="text-input-group d-flex justify-content-between">
        <div class="text-input-field" [ngClass]="{'mr-2': isHQBranch}">
          <input class="input-field" oninput="this.value = this.value.toUpperCase()" tabIndex="2" formControlName="name"
            type="text" [ngClass]="{'error-field': supplierForm.controls.name.invalid && supplierForm.controls.name.dirty || (!supplierForm.controls.name.valid && submitted) ,
      'success-field' : supplierForm.controls.name.valid && supplierForm.controls.name.dirty}" />
          <label
            [ngClass]="{'error-label': supplierForm.controls.name.invalid && supplierForm.controls.name.dirty || (!supplierForm.controls.name.valid && submitted)}">Supplier Name
            <span class="text-danger">*</span>

            <ng-container
              *ngIf="supplierForm.controls.name.invalid && supplierForm.controls.name.dirty || (!supplierForm.controls.name.valid && submitted)">
              <i class="fa fa-info-circle ml-2" title="Invalid Account Number" aria-hidden="true"></i>
            </ng-container>
          </label>
        </div>

        


    </div>

      <div class="text-input-field">
        <input class="input-field" oninput="this.value = this.value.toUpperCase()" name="address_1" tabIndex="3"
          formControlName="address_1" type="text" [ngClass]="{'error-field': supplierForm.controls.address_1.invalid && supplierForm.controls.address_1.dirty,
            'success-field' : supplierForm.controls.address_1.valid && supplierForm.controls.address_1.dirty}" />
        <label
          [ngClass]="{'error-label': supplierForm.controls.address_1.invalid && supplierForm.controls.address_1.dirty}">Address

          <ng-container *ngIf="supplierForm.controls.address_1.invalid && supplierForm.controls.address_1.dirty">
            <i class="fa fa-info-circle ml-2" title="Address Required" aria-hidden="true"></i>
          </ng-container>
        </label>
      </div>

      <div class="text-input-group d-flex justify-content-between">
        <div class="text-input-field mr-2">
          <select class="input-field" oninput="this.value = this.value.toUpperCase()" tabIndex="4"
            formControlName="country_id" (change)="getStates($event.target.value)">
            <option *ngFor="let country of countryArr" value="{{country.location_id}}">{{country.name}}</option>
          </select>
          <label>Country</label>
        </div>

        <div class="text-input-field mr-2">
          <select class="input-field" oninput="this.value = this.value.toUpperCase()" tabIndex="5"
            formControlName="state_id" (change)="getCities($event.target.value)">
            <option *ngFor="let state of stateArr" value="{{state.location_id}}">{{state.name}}</option>
          </select>
          <label>State</label>
        </div>


        <div class="text-input-field">
          <select class="input-field" oninput="this.value = this.value.toUpperCase()" tabIndex="6"
            formControlName="city_id" (change)="getCityName($event.target.value)">
            <option *ngFor="let city of cityArr" value="{{city.location_id}}">{{city.name}}</option>
          </select>
          <label>City</label>
        </div>
      </div>


      <div class="text-input-group d-flex justify-content-between">
        <div class="text-input-field mr-2" *ngIf="isTenantIndian">
          <input class="input-field" oninput="this.value = this.value.toUpperCase()" name="gst_number" tabIndex="7"
            formControlName="gst_number" type="text" maxlength="15"
            pattern="^([0][1-9]|[1-2][0-9]|[3][0-7])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$"
            [ngClass]="{'error-field': supplierForm.controls.gst_number.invalid && supplierForm.controls.gst_number.dirty,
                'success-field' : supplierForm.controls.gst_number.valid && supplierForm.controls.gst_number.dirty}" />
          <label
            [ngClass]="{'error-label': supplierForm.controls.gst_number.invalid && supplierForm.controls.gst_number.dirty}">
            GST NUMBER
            <ng-container *ngIf="supplierForm.controls.gst_number.invalid && supplierForm.controls.gst_number.dirty">
              <i class="fa fa-info-circle ml-2" title="Invalid GST Number ( ex: 12ABCDE1234F1Z3)"
                aria-hidden="true"></i>
            </ng-container>
          </label>
        </div>

        <div class="text-input-field" *ngIf="isTenantIndian">
          <input class="input-field" oninput="this.value = this.value.toUpperCase()" name="gst_expiry_date" tabIndex="8"
            formControlName="gst_expiry_date" type="date"
            [ngClass]="{'error-field': supplierForm.controls.gst_expiry_date.invalid && supplierForm.controls.gst_expiry_date.dirty,
                    'success-field' : supplierForm.controls.gst_expiry_date.valid && supplierForm.controls.gst_expiry_date.dirty}" />
          <label
            [ngClass]="{'error-label': supplierForm.controls.gst_expiry_date.invalid && supplierForm.controls.gst_expiry_date.dirty}">Expiry
            Date
            <ng-container
              *ngIf="supplierForm.controls.gst_expiry_date.invalid && supplierForm.controls.gst_expiry_date.dirty">
              <i class="fa fa-info-circle ml-2" title="required expiry date" aria-hidden="true"></i>
            </ng-container>
          </label>
        </div>

        <div class="text-input-field" *ngIf="!isTenantIndian">
          <input class="input-field" oninput="this.value = this.value.toUpperCase()" name="vat_number" tabIndex="7"
            formControlName="vat_number" type="text" maxlength="20"
            [ngClass]="{'error-field': supplierForm.controls.vat_number.invalid && supplierForm.controls.vat_number.dirty,
                'success-field' : supplierForm.controls.vat_number.valid && supplierForm.controls.vat_number.dirty}" />
          <label
            [ngClass]="{'error-label': supplierForm.controls.vat_number.invalid && supplierForm.controls.vat_number.dirty}">
            {{vatAlternetname}} NUMBER
          </label>
        </div>

         
      </div>
      <div class="text-input-field">
        <input class="input-field" oninput="this.value = this.value.toUpperCase()" name="telephone" tabIndex="9"
          formControlName="telephone" type="text" [ngClass]="{'error-field': supplierForm.controls.telephone.invalid && supplierForm.controls.telephone.dirty,
              'success-field' : supplierForm.controls.telephone.valid && supplierForm.controls.telephone.dirty}" />
        <label
          [ngClass]="{'error-label': supplierForm.controls.telephone.invalid && supplierForm.controls.telephone.dirty}">telephone
          <ng-container *ngIf="supplierForm.controls.telephone.invalid && supplierForm.controls.telephone.dirty">
            <i class="fa fa-info-circle ml-2" title="Invalid telephone Number" aria-hidden="true"></i>
          </ng-container>
        </label>
      </div>

      <div class="text-input-field">
        <input class="input-field" oninput="this.value = this.value.toUpperCase()" name="mobile_no" minlength="10"
          maxlength="15" tabIndex="10" formControlName="mobile_no" type="text" pattern="([0-9]+)" [ngClass]="{'error-field': supplierForm.controls.mobile_no.invalid && supplierForm.controls.mobile_no.dirty || (!supplierForm.controls.mobile_no.valid && submitted),
                'success-field' : supplierForm.controls.mobile_no.valid && supplierForm.controls.mobile_no.dirty}" />
        <label
          [ngClass]="{'error-label': supplierForm.controls.mobile_no.invalid && supplierForm.controls.mobile_no.dirty  || (!supplierForm.controls.mobile_no.valid && submitted)}">Mobile
          No.
          <ng-container
            *ngIf="supplierForm.controls.mobile_no.invalid && supplierForm.controls.mobile_no.dirty || (!supplierForm.controls.mobile_no.valid && submitted)">
            <i class="fa fa-info-circle ml-2" title="Invalid Mobile Number" aria-hidden="true"></i>
          </ng-container>
        </label>
      </div>

      <div class="text-input-group d-flex justify-content-between">
        <div class="text-input-field mr-2">
          <input class="input-field" oninput="this.value = this.value.toUpperCase()" name="primary_email" tabIndex="11"
            formControlName="primary_email" type="text" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,100})+$"
            [ngClass]="{'error-field': supplierForm.controls.primary_email.invalid && supplierForm.controls.primary_email.dirty || (!supplierForm.controls.primary_email.valid && submitted),
      'success-field' : supplierForm.controls.primary_email.valid && supplierForm.controls.primary_email.dirty}" />
          <label
            [ngClass]="{'error-label': supplierForm.controls.primary_email.invalid && supplierForm.controls.primary_email.dirty || (!supplierForm.controls.primary_email.valid && submitted)}">Email
            #1 <ng-container
              *ngIf="supplierForm.controls.primary_email.invalid && supplierForm.controls.primary_email.dirty || (!supplierForm.controls.primary_email.valid && submitted)">
              <i class="fa fa-info-circle ml-2" title="Invalid Email" aria-hidden="true"></i>
            </ng-container>
          </label>
        </div>

        <div class="text-input-field">
          <input class="input-field" oninput="this.value = this.value.toUpperCase()" name="secondary_email"
            tabIndex="12" formControlName="secondary_email" type="text"
            [ngClass]="{'error-field': supplierForm.controls.secondary_email.invalid && supplierForm.controls.secondary_email.dirty,
        'success-field' : supplierForm.controls.secondary_email.valid && supplierForm.controls.secondary_email.dirty}" />
          <label
            [ngClass]="{'error-label': supplierForm.controls.secondary_email.invalid && supplierForm.controls.secondary_email.dirty}">Email
            #2 <ng-container
              *ngIf="supplierForm.controls.secondary_email.invalid && supplierForm.controls.secondary_email.dirty">
              <i class="fa fa-info-circle ml-2" title="Invalid Email" aria-hidden="true"></i>
            </ng-container>
          </label>

        </div>
      </div>

      <ng-container *ngIf="!isTenantIndian">
        <div class="text-input-group d-flex justify-content-between">
        
          <div class="select-field text-input-field mr-3">
            <select id="selectscroll" class="input-field" tabIndex="1" formControlName="currency" (change)="currencyChange($event.target.value)" >
              <option value="INR">INR</option>
              <option value="CAD">CAD</option>
                <option value="USD">USD</option>
                <option value="AED">AED</option>
                <option value="EURO">EURO</option>
            </select>
            <label>CURRENCY</label>
          </div>
          <div class="text-input-field mr-3">
            <input class="input-field" style="    width: 185px;" oninput="this.value = this.value.toUpperCase()"
              name="currency" tabIndex="13" formControlName="currency_rate" type="text" maxlength="10"
              [ngClass]="{'error-field': supplierForm.controls.currency_rate.invalid && supplierForm.controls.currency_rate.dirty,
          'success-field' : supplierForm.controls.currency_rate.valid && supplierForm.controls.currency_rate.dirty}" />
            <label
              [ngClass]="{'error-label': supplierForm.controls.currency_rate.invalid && supplierForm.controls.currency_rate.dirty}">CURRENCY RATE<ng-container *ngIf="supplierForm.controls.currency_rate.invalid && supplierForm.controls.currency_rate.dirty">
                <i class="fa fa-info-circle ml-2" title="Invalid pan number" aria-hidden="true"></i>
              </ng-container>
            </label>
          </div>
          <div class="text-input-group d-flex justify-content-between">
            <div class="text-input-field" style="    width: 185px;">
              <input class="input-field" oninput="this.value = this.value.toUpperCase()" name="local_balance" tabIndex="13"
            formControlName="local_balance"  type="text" [ngClass]="{'error-field': supplierForm.controls.local_balance.invalid && supplierForm.controls.local_balance.dirty,
          'success-field' : supplierForm.controls.local_balance.valid && supplierForm.controls.local_balance.dirty}" (input)="changeCurrentAmount($event)" />
          <label
            [ngClass]="{'error-label': supplierForm.controls.local_balance.invalid && supplierForm.controls.local_balance.dirty}">AMOUNT
            <ng-container *ngIf="supplierForm.controls.local_balance.invalid && supplierForm.controls.local_balance.dirty">
              <i class="fa fa-info-circle ml-2" title="Invalid amount" aria-hidden="true"></i>
            </ng-container>
          </label>
            </div>
          </div>
        </div>
      </ng-container>

      <div class="text-input-group d-flex justify-content-between">

        <div class="w-50 text-input-field mr-2">
          <input class="input-field" oninput="this.value = this.value.toUpperCase()" name="pan_number" tabIndex="13"
            formControlName="pan_number" type="text" maxlength="10" pattern="([a-zA-Z]{5})(\d{4})([a-zA-Z]{1})$"
            [ngClass]="{'error-field': supplierForm.controls.pan_number.invalid && supplierForm.controls.pan_number.dirty,
            'success-field' : supplierForm.controls.pan_number.valid && supplierForm.controls.pan_number.dirty}" />
          <label
            [ngClass]="{'error-label': supplierForm.controls.pan_number.invalid && supplierForm.controls.pan_number.dirty}">PAN
            NO. <ng-container
              *ngIf="supplierForm.controls.pan_number.invalid && supplierForm.controls.pan_number.dirty">
              <i class="fa fa-info-circle ml-2" title="Invalid pan number" aria-hidden="true"></i>
            </ng-container>
          </label>
        </div>
        <div class="w-50 d-flex">
          <div class="text-input-field mr-2">
            <input class="input-field" oninput="this.value = this.value.toUpperCase()" name="balance" tabIndex="14" [readonly]="!isTenantIndian"
              formControlName="balance" pattern="^\d*(\.\d{0,8})?$" type="text" [ngClass]="{'error-field': supplierForm.controls.balance.invalid && supplierForm.controls.balance.dirty,
                    'success-field' : supplierForm.controls.balance.valid && supplierForm.controls.balance.dirty}" />
            <label
              [ngClass]="{'error-label': supplierForm.controls.balance.invalid && supplierForm.controls.balance.dirty}">Opening
              Balance
              <ng-container *ngIf="supplierForm.controls.balance.invalid && supplierForm.controls.balance.dirty">
                <i class="fa fa-info-circle ml-2" title="Invalid opening balance" aria-hidden="true"></i>
              </ng-container>
            </label>
          </div>
  
          <div style="width: 60px;" class="select-field text-input-field">
            <select class="input-field" tabIndex="15" formControlName="balance_type">
              <option value="debit">DR</option>
              <option value="credit">CR</option>
            </select>
          </div>
        </div>
        

      </div>

      <div class="text-input-field">
        <textarea tabIndex="16" class="input-field" formControlName="remarks"
          oninput="this.value = this.value.toUpperCase()" rows="2"></textarea>
        <!-- <input tabIndex="9" class="input-field" oninput="this.value = this.value.toUpperCase()" formControlName="remarks"
          type="text" /> -->
        <label>Remarks</label>
      </div>
      <div class="form-check">
        <input class="radio-ui mr-2" type="checkbox" formControlName="is_branch" id="isBranch">
        <label class="radio-label" style="transform: none;font-size: 12px;margin-bottom: 0;" for="isBranch">
          Is Company
        </label>
      </div>

      <!-- <span class="info alert-warning"
        *ngIf="showMessage && alertType == 'success-box' && createdSupplierId != ''">Supplier created. <a
          [routerLink]="['/master/policy/default-supplier-policy', createdSupplierId]">Click here to update
          policy.</a></span> -->
          <div  class="select-field text-input-field" *ngIf="isHQBranch">
            <label for="selectedBranch" style="font-size: 13px; font-weight: 500!important;">Branch</label>
            <ng-container *ngFor="let item of branches">
                <div *ngIf="item?.branch_type=='BRANCH'" class="input-fiel" style="display: flex;align-items: center;">
                  <input class="radio-ui mr-2" type="checkbox"  (change)="handleValueChange($event,item?.branch_name)"  />
                  <label class="radio-label"  style="transform: none;font-size: 12px;margin-bottom: 0;">{{item?.branch_name}}</label>
                </div>
          </ng-container>
          </div>

        </div>

<div class="btn-field bottom-btns-container mt-30 d-flex align-item-center justify-content-end ml-0 mr-0"> 
        <div>
            <button type="button" class="btn-style bg-green mr-3" (click)="reset()">Reset</button>
            <button type="button" class="btn-style bg-purple" tabIndex="17" #confirmModal
              (keyup.enter)="openConfirmModal()" data-toggle="modal" data-target="#confirmationModal">Save</button>
        </div>
      </div>
    </form>
</div>


<div class="modal fade" id="confirmationModal" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
  <div class="modal-dialog modal-md" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4>Confirmation</h4>

        <button type="button" class="close" #closeModal data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Create {{supplierForm?.value?.name}} ?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn-style mr-3" data-dismiss="modal">Close</button>
        <button type="button" class="btn-style bg-green" (click)="checkBalance()" (keyup.enter)="checkBalance()" tabIndex="18">Yes</button>
      </div>
    </div>
  </div>
</div>

<button type="button" hidden #confirmUpdateModal data-toggle="modal" data-target="#updateconfirmationModal"></button>
<div id="updateconfirmationModal" class="modal fade" role="dialog" data-backdrop="false">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Confirmation</h4>
        <button type="button" #closeUpdateModal class="close" data-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        There is a mismatch of amount {{remainingAmount}} in your opening balance. It will be adjusted in the Opening Balance Adjustment account. Would you like to proceed?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn-style mr-3" 
        data-dismiss="modal">Close</button>
        <button type="button" class="btn-style bg-green" (click)="updateOpeningBalancecombine()" >CREATE</button>
      </div>
    </div>
  </div>
</div>