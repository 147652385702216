import { Injectable } from '@angular/core';
import { SectorRefundService } from './sector-refund.service';
import * as moment from 'moment'

@Injectable({
  providedIn: 'root'
})
export class InvoiceRequestBuilderRefundService {

  constructor(
		public sectorService: SectorRefundService,
	) { }
	createInvoiceProduct(travellerFormValue,sectorFromValue,invoiceFromValue) {
		let travellers = this.createTravellers(travellerFormValue)
		
		let sectors = this.createSectorsObj(sectorFromValue,invoiceFromValue)
		let requestObj = {
			"booking_ref_no":  "AT" + Math.floor((Math.random() * 10000000) + 1),
            "crs_id": "",
            "boooking_supplier": "",
			"travellers" : travellers,
			"sectors": sectors,
			"employee": invoiceFromValue.employee,
			"employee_name": invoiceFromValue.employee_name,
			"last_updated_by_user_id": JSON.parse(localStorage.okountUserInfo).id,
			"last_updated_by_user_name": JSON.parse(localStorage.okountUserInfo).name,
			"last_updated_by_user_account_code": JSON.parse(localStorage.okountUserInfo).accountcode,
			"sectorComb1" : sectorFromValue.txtSector1?sectorFromValue.txtSector1:'',
			"sectorComb2" : sectorFromValue.txtSector2?sectorFromValue.txtSector2:'',
			"sub_ledger_code_1": invoiceFromValue.sub_ledger_code_1,
			"sub_ledger_code_2": invoiceFromValue.sub_ledger_code_2,
			"sub_ledger_code_3": invoiceFromValue.sub_ledger_code_3,
			"sub_ledger_code_4": invoiceFromValue.sub_ledger_code_4,
			"sub_ledger_code_5": invoiceFromValue.sub_ledger_code_5,
			"sub_ledger_code_1_type": invoiceFromValue.sub_ledger_code_1_type,
			"sub_ledger_code_2_type": invoiceFromValue.sub_ledger_code_2_type,
			"sub_ledger_code_3_type": invoiceFromValue.sub_ledger_code_3_type,
			"sub_ledger_code_4_type": invoiceFromValue.sub_ledger_code_4_type,
			"sub_ledger_code_5_type": invoiceFromValue.sub_ledger_code_5_type
		}
		return requestObj;
	}
	getCRSID(invoiceFormValue, selectedRetID) {
		let selCRS = invoiceFormValue.crsId
		let selectCRS = "";
		if (selCRS == '1A') {
			selectCRS = "AM"
		}
		if (selCRS == '1B') {
			selectCRS = "SB"
		}
		if (selCRS == '1G') {
			selectCRS = "1G" //fix me
		}
		if ((selCRS == 'website' || selCRS == 'WEBSITE') && selectedRetID == "6E670") {
			selectCRS = "6E"
		}
		if ((selCRS == 'website' || selCRS == 'WEBSITE') && (selectedRetID == "SG050" || selectedRetID == "SG051" || selectedRetID == "SG052")) {
			selectCRS = "SG"
		}
		if ((selCRS == 'website' || selCRS == 'WEBSITE') && (selectedRetID == "GO008" || selectedRetID == "G8008")) {
			selectCRS = "GO"
		}
		if ((selCRS == 'website' || selCRS == 'WEBSITE') && (selectedRetID == "G9514")) {
			selectCRS = "AA" //FIX ME
		}
		if ((selCRS == 'website' || selCRS == 'WEBSITE') && (selectedRetID == "Other")) {
			selectCRS = "OT" //FIX ME
		}
		else{
			'OT'
		}
		return selectCRS;
	} 

	createTravellers(travellerFormValue){
		let travellers = []
		for (let i = 0; i < travellerFormValue.travellerList.length; i++) {
			const traveller = travellerFormValue.travellerList[i];
			if (traveller['isCancel'] == true) {
				let parsedTraveller = 	this.createTravellerObj(traveller)
				travellers.push(parsedTraveller)
			}
		}
		return travellers
	}

	createTravellerObj(traveller) {
		let travellerObj = {
			"title": traveller.selTitle,
			"type": traveller.selType,
			"name": traveller.txtPassengerName,
			"employee":traveller.txtEmployeeName ? (traveller.txtEmployeeName.account_name?traveller.txtEmployeeName.account_name:traveller.txtEmployeeName):'',
			"e_code":traveller.txtEmployeeCode,
			"cost_center":traveller.txtEmployeeCostCenter,
			"payable_transaction_code":traveller.txtSupplierCode,
			"payable_date":traveller.supplierDate,
			"ticket_no": traveller.txtTicketNumber,
			"isConjuction": traveller.isConjuction,
			"booking_supplier": traveller.txtbookingSupplier,
			"pay_type": traveller.type_of_pay ? traveller.type_of_pay : '',
			"ledger_card_no": traveller.ledger_card_no ? traveller.ledger_card_no : '',
			"fare": {
				"total": traveller.txtAmount,
				"base": traveller.txtBaseFare,
				"YQ": traveller.txtYQ,
				"YR": traveller.txtYR,
				"K3": traveller.txtK3,
				"OT": traveller.txtOT,
				"OC": traveller.txtOC,
				"addons": traveller.txtAddons,
				"markup": 0,
				"service_charge": traveller.txtSupplierSC1Amount,
				"gst_on_service_charge": 0
			},
			"local_fare":{
				"total": traveller.localAmount?traveller.localAmount:0,
				"base": traveller.localBaseFare? traveller.localBaseFare:0,
				"YQ": traveller.localYQ?traveller.localYQ:0,
				"YR": traveller.localYR?traveller.localYR:0,
				"K3": traveller.localK3?traveller.localK3:0,
				"OT": traveller.localOT?traveller.localOT:0,
				"OC": traveller.localOC?traveller.localOC:0,
				"addons": traveller.localAddons?traveller.localAddons:0,
				"markup": 0,
				"service_charge": traveller.localSupplierSC1Amount?traveller.localSupplierSC1Amount:0,
				"gst_on_service_charge": 0
			},
			"cancel_sectors": this.getCancelledSector(traveller.sectorList),
			"prev_ticket_no": traveller.prev_ticket_no ? traveller.prev_ticket_no : traveller.txtTicketNumber,
			"new_pnr": traveller.newPNRNo,
			"new_ticket_no": traveller.newTicketNo,
			"ssr": this.getSSRs(traveller.sectorList),
			"commission": {
				"customer": this.getClientComission(traveller),
				"supplier": this.getSupplierComission(traveller)
			},
			"service_charge": {
				"customer": this.getClientServiceCharge(traveller),
				"supplier": this.getSupplierServiceCharge(traveller)
			},
			"dis_recall":{
				"customer": this.getClientDiscountRecall(traveller),
				"supplier": this.getSupplierDiscountRecall(traveller)
			}
		}
		return travellerObj

	}
	
	getClientDiscountRecall(traveller) {
		let clientDiscRecallObj = {
			"dis_recall_type": traveller.disClientRecallType,
			"dis_recall_value": traveller.disClientRecallValue,
			"dis_recall_amount": traveller.disClientRecallAmount,
		}
		return clientDiscRecallObj
	}
	getSupplierDiscountRecall(traveller) {
		let supplierDiscRecallObj = {
			"dis_recall_type": traveller.disSupplierRecallType,
			"dis_recall_value": traveller.disSupplierRecallValue,
			"dis_recall_amount": traveller.disSupplierRecallAmount,
		}
		return supplierDiscRecallObj
	}
	getCancelledSector(sectorList: any) {
		let sectorArr = []
		for (const key in sectorList) {
			if (sectorList.hasOwnProperty(key)) {
				const element = sectorList[key];
				if (element.isCancelRequest == true) {
					sectorArr.push(element.sector)
				}
			}
		}
		return sectorArr
	}
	getSSRs(sectorList: any) {
		let ssrList = []
		for (let i = 0; i < sectorList.length; i++) {
			const sector = sectorList[i];
			let seat = {
				code : sector.txtSeatCode,
				amount : sector.txtSeatCharges
			}
			let meal = {
				code : sector.txtMealCode,
				amount : sector.txtMealCharges
			}
			let baggage = {
				code : sector.txtBagCode,
				amount : sector.txtBagCharges
			}
			let ancillary = {
				code : sector.txtAncillaryCode,
				amount : sector.txtAncillaryCharges
			}
			let additionalBaggage = {
				code : sector.txtAdditionalBaggageCode,
				amount : sector.txtAdditionalBaggageCharges
			}
			let ssr = {seat,meal,baggage,ancillary,additionalBaggage}
			
			ssrList.push(ssr)
		}
		return ssrList
	}
	getClientComission(traveller) {
		let clientComissionObj = {
			"iata_type": traveller.selClientIataType,
			"iata_value": traveller.selClientIataValue,
			"iata_amount": traveller.selClientIataAmount,
			"plb_type": traveller.selClientPlbType,
			"plb_value": traveller.selClientPlbValue,
			"plb_amount": traveller.selClientPlbAmount,
			"cashback_type": traveller.selClientCbType,
			"cashback_value": traveller.selClientCbValue,
			"cashback_amount": traveller.selClientCbAmount,
			"discount_type": traveller.selClientDsType?traveller.selClientDsType:'VL',
			"discount_value": traveller.selClientDsValue? traveller.selClientDsValue:'0',
			"discount_amount": traveller.selClientDsAmount?traveller.selClientDsAmount:'0',
		}
		return clientComissionObj
	}
	getSupplierComission(traveller) {
		let supplierComissionObj = {
			"iata_type": traveller.selSupplierIataType,
			"iata_value": traveller.selSupplierIataValue,
			"iata_amount": traveller.selSupplierIataAmount,
			"plb_type": traveller.selSupplierPlbType,
			"plb_value": traveller.selSupplierPlbValue,
			"plb_amount": traveller.selSupplierPlbAmount,
			"cashback_type": traveller.selSupplierCbType,
			"cashback_value": traveller.selSupplierCbValue,
			"cashback_amount": traveller.selSupplierCbAmount,
			"discount_type": traveller.selSupplierDsType?traveller.selSupplierDsType:'VL',
			"discount_value": traveller.selSupplierDsValue?traveller.selSupplierDsValue:'0',
			"discount_amount": traveller.selSupplierDsAmount?traveller.selSupplierDsAmount:'0'
		}
		return supplierComissionObj
	}
	getClientServiceCharge(traveller) {
		let clientComissionObj = {
			"sc1_type": traveller.txtClientSC1Type,
			"sc1_value": traveller.txtClientSC1Value,
			"sc1_amount": traveller.txtClientSC1Amount,
			"sc2_type": traveller.txtClientSC2Type,
			"sc2_taxable": traveller.txtClientSC2Taxable,
			"sc2_value": traveller.txtClientSC2Value,
			"sc2_amount": traveller.txtClientSC2Amount,
			"sc3_type": traveller.txtClientSC3Type,
			"sc3_taxable": traveller.txtClientSC3Taxable,
			"sc3_value": traveller.txtClientSC3Value,
			"sc3_amount": traveller.txtClientSC3Amount,
			"raf": traveller.txtClientRAFAmount,
            "xxl": traveller.txtClientXXLAmount,
            "gst_on_raf": traveller.txtClientRAFAmount*0.18
		}
		return clientComissionObj
	}
	getSupplierServiceCharge(traveller) {
		let supplierComissionObj = {
			"sc1_type": traveller.txtSupplierSC1Type,
			"sc1_value": traveller.txtSupplierSC1Value,
			"sc1_amount": traveller.txtSupplierSC1Amount,
			"sc2_type": traveller.txtSupplierSC2Type,
			"sc2_value": traveller.txtSupplierSC2Value,
			"sc2_amount": traveller.txtSupplierSC2Amount,
			"sc3_type": traveller.txtSupplierSC3Type,
			"sc3_value": traveller.txtSupplierSC3Value,
			"sc3_amount": traveller.txtSupplierSC3Amount,
			"raf": traveller.txtSupplierRAFAmount,
            "xxl": traveller.txtSupplierXXLAmount,
            "gst_on_raf": traveller.txtSupplierRAFAmount*0.18
		}
		return supplierComissionObj
	}
	createSectorsObj(sectorForm,invoiceForm) {
		try {
			let sectors = []
			for (let i = 0; i < sectorForm.sectorlist.length; i++) {
				const sector = sectorForm.sectorlist[i];
				let depart_arrival_info = this.getAirlineInfo(sector)
				var duration = ""+moment.duration(moment(sector.dateAirlineArrivalInfo).diff(sector.dateAirlineDepartInfo, 'minutes'));
				let sectorObj = {
					"pnr": invoiceForm.pnr,
					"duration": duration,
					"departure_airport_details": depart_arrival_info['departure_airport_details'],
					"arrival_airport_details": depart_arrival_info['arrival_airport_details'],
					"legs": this.createLegs(sector, depart_arrival_info)
				}
				sectors.push(sectorObj)
			}
			return sectors
		} catch (error) {
			console.log('Error occured while creating sectors obj : ',error)
		}
	}

	getAirlineInfo(sector){
		let sectorCombination = sector.txtSectorComb ? sector.txtSectorComb : "";
		let legArr = sectorCombination.split("/");
		let departure_station_code = legArr[0] ? legArr[0].trim() : "";
		let arrival_station_code = legArr[1] ? legArr[1].trim() : "";
		let departureDetails = sector.txtAirlineDepartInfo ? sector.txtAirlineDepartInfo : ""
		let departurInfoArr = departureDetails.split(",");
		let departure_station_city = departurInfoArr[0] ? departurInfoArr[0].trim() : "";
		let departure_station_name = departurInfoArr[1] ? departurInfoArr[1].trim() : "";
		if (departurInfoArr[1] != undefined) {
			if (departurInfoArr[1].includes("(")) {
				departure_station_name = departure_station_city
			}
		}
		let departure_terminal = departurInfoArr[2] ? departurInfoArr[2].trim() : undefined;
		if (departure_terminal != undefined) {
			if (departurInfoArr[2].includes("(")) {
				departure_terminal = ""
			}
		} else {
			departure_terminal = ""
		}
		let arrivalDetails = sector.txtAirlineArrivalInfo ? sector.txtAirlineArrivalInfo : "";
		let arrivalInfoArr = arrivalDetails.split(",");
		let arrival_station_city = arrivalInfoArr[0] ? arrivalInfoArr[0].trim() : "";
		let arrival_station_name = arrivalInfoArr[1] ? arrivalInfoArr[1].trim() : "";
		if (arrivalInfoArr[1] != undefined) {
			if (arrivalInfoArr[1].includes("(")) {
				arrival_station_name = arrival_station_city
			}
		}
		let arrival_terminal = arrivalInfoArr[2] ? arrivalInfoArr[2].trim() : undefined;
		if (arrival_terminal != undefined) {
			if (arrivalInfoArr[2].includes("(")) {
				arrival_station_name = "";
			}
		} else {
			arrival_terminal = ""
		}
		let departArrivalInfo = {
			"departure_airport_details": {
				"code": departure_station_code,
				"city": departure_station_city,
				"name": departure_station_name,
				"terminal": departure_terminal,
				"date_time": moment(sector.dateAirlineDepartInfo).format('YYYY-MM-DDTHH:mm'),
				"remark": ""
			},
			"arrival_airport_details": {
				"code": arrival_station_code,
				"city": arrival_station_city,
				"name": arrival_station_name,
				"terminal": arrival_terminal,
				"date_time": moment(sector.dateAirlineArrivalInfo).format('YYYY-MM-DDTHH:mm'),
				"remark": ""
			}
		}
		return departArrivalInfo
	}
	createLegs(sector,depart_arrival_info) {
		let selectedAirlineName = this.sectorService.airlineArr.filter(option => {
			return ((option['2_ltr_code'] == sector.txtAirlinePrefix)||(option['Airline Name']== sector.txtAirlinePrefix));
		});
		let airline_name = ''
		let airline_xl_code = ''
		if (selectedAirlineName.length > 0) {
			airline_name = selectedAirlineName[0]['account_name']||selectedAirlineName[0]['Airline Name']
			airline_xl_code = selectedAirlineName[0]['account_code']||selectedAirlineName[0]['xl code']
		}
		var duration = ""+moment.duration(moment(sector.dateAirlineArrivalInfo).diff(sector.dateAirlineDepartInfo, 'minutes'));
		let legObj  = {
				"airline_code": sector.txtAirlinePrefix,
				"airline_name": airline_name,
				"airline_xl_code": airline_xl_code,
				"flight_no": sector.txtFlightNo,
				"service_class": sector.txtClass,
				"airline_pnr": sector.txtAirlinePnrX,
				"duration": duration,
				"departure_airport_details": depart_arrival_info['departure_airport_details'],
				"arrival_airport_details": depart_arrival_info['arrival_airport_details'],
				"baggage_info": {
					"cabin_baggage": "",
					"checkin_baggabe": sector.txtBaggage
				}
			}
		
		return legObj;
	}


	createFareObj(travellers,invoiceForm,account_code,date,invoice_date,ref_no,rcm_applicable=false){
		
		let fareObjArr = []
		for (const key in travellers.travellerList) {
			if (travellers.travellerList.hasOwnProperty(key)) {
				const traveller = travellers.travellerList[key];
				if (traveller['isCancel'] == true) {
					let fareObj = {
						"base" : traveller.txtBaseFare,
						"yq" : traveller.txtYQ,
						"yr" : traveller.txtYR,
						"k3" : traveller.txtK3,
						"oc" : traveller.txtOC,
						"ot" : traveller.txtOT,
						"ssr" : traveller.txtAddons,
						"localbase" : traveller.localBaseFare,
						"localyq" : traveller.localYQ,
						"localyr" : traveller.localYR,
						"localk3" : traveller.localK3,
						"localoc" : traveller.localOC,
						"localot" : traveller.localOT,
						"localssr" : traveller.localAddons,
						"airline_account_code": invoiceForm.airline,
						"client_account_code": account_code,
						"date": date,
						"invoice_date": invoice_date,
						"ref_no": ref_no,
						"client_accounting" : {
							"iata_type": traveller.selClientIataType,
							"iata_value": traveller.selClientIataValue,
							"plb_type": traveller.selClientPlbType,
							"plb_value": traveller.selClientPlbValue,
							"cashback_type": traveller.selClientCbType,
							"cashback_value": traveller.selClientCbValue,
							"discount_type": traveller.selClientDsType? traveller.selClientDsType:'VL',
							"discount_value": traveller.selClientDsValue?traveller.selClientDsValue:'0',
							"service_charge_1_type" : traveller.txtClientSC1Type,
							"service_charge_1_value" : traveller.txtClientSC1Value,
							"service_charge_2_type" : traveller.txtClientSC2Type,
							"service_charge_2_taxable" : traveller.txtClientSC2Taxable,
							"service_charge_2_value" : traveller.txtClientSC2Value,
							"service_charge_3_type" : traveller.txtClientSC3Type,
							"service_charge_3_taxable" : traveller.txtClientSC3Taxable,
							"service_charge_3_value" : traveller.txtClientSC3Value,
							"tds" : 0,
							"disc_recall" : traveller.disClientRecallValue,
							"gst_on_service_charge" : 0,
							"xxl_charge" : traveller.txtClientXXLAmount,
							"raf_charge" : traveller.txtClientRAFAmount 
						},
						"supplier_accounting" : {
							"iata_type": traveller.selSupplierIataType,
							"iata_value": traveller.selSupplierIataValue,
							"plb_type": traveller.selSupplierPlbType,
							"plb_value": traveller.selSupplierPlbValue,
							"cashback_type": traveller.selSupplierCbType,
							"cashback_value": traveller.selSupplierCbValue,
							"discount_type": traveller.selSupplierDsType?traveller.selSupplierDsType:'VL',
							"discount_value": traveller.selSupplierDsValue?traveller.selSupplierDsValue:'0',
							"service_charge_1_type" : traveller.txtSupplierSC1Type,
							"service_charge_1_value" : traveller.txtSupplierSC1Value,
							"service_charge_2_type" : traveller.txtSupplierSC2Type,
							"service_charge_2_value" : traveller.txtSupplierSC2Value,
							"service_charge_3_type" : traveller.txtSupplierSC3Type,
							"service_charge_3_value" : traveller.txtSupplierSC3Value,
							"tds" : 0,
							"disc_recall" : traveller.disSupplierRecallValue,
							"gst_on_service_charge" : 0 ,
							"raf_charge" : traveller.txtSupplierRAFAmount,
							"xxl_charge" : traveller.txtSupplierXXLAmount 
						},
						"traveller_detail" : traveller,
						"rcm_applicable" : rcm_applicable,
						// 'reverse_tds' : reverse_tds
					}
	
					fareObjArr.push(fareObj)
				}
			}
		}
		return fareObjArr
	}

	async createProductObj(product_json,totalFareModel,narration){
		let total = await  this.getTotalAmount(totalFareModel)
		if(product_json.hasOwnProperty('total_fare')) {
			total = product_json['total_fare'].net_paid

		}
		let amount =  await  this.getAmountwithoutGst(totalFareModel)
		// let remark = product_json.travellers[0].title + " " +product_json.travellers[0].name;
		// 		if(product_json.travellers.length > 1) {
		// 			remark += " + " + Number(product_json.travellers.length - 1)
		// 		}
		let productObj = {
			"json_doc": product_json,
			"quantity":1,
			"rate": total,
			"amount": total,
			"tax": 0, //FIX ME
			"total":  total,
			"product" : "Flight",
			"narration" : narration
		  }
		  return productObj
	}
	getTotalAmount(totalFareModel) {
		let total  = 0
		if (totalFareModel != undefined) {
			total =  totalFareModel.base + totalFareModel.ssr + totalFareModel.yq + totalFareModel.yr + totalFareModel.k3 + totalFareModel.oc + totalFareModel.ot - totalFareModel.client_xxl - totalFareModel.client_raf - totalFareModel.client_raf_gst
			return total
		}
	}
	getAmountwithoutGst(totalFareModel) {
		let amount  = 0
		if (totalFareModel != undefined) {
			amount =  totalFareModel.base + totalFareModel.ssr + totalFareModel.yq + totalFareModel.yr  + totalFareModel.oc + totalFareModel.ot
			return amount
		}
	}


	removeGSTAndServiceCharges(data) {
		let newData = JSON.parse(JSON.stringify(data));
	
		let serviceChargeTotal = 0;
		let	gstOnScTotal = 0
		let rafChargesTotal = 0;
		let gstOnRafTotal = 0;
		newData.products.forEach(product => {
			product.json_doc.travellers.forEach(traveller => {
				serviceChargeTotal += parseFloat(traveller.service_charge.customer.sc1_amount);
				gstOnScTotal += (parseFloat(traveller.service_charge.customer.sc1_amount) * 18/100)
				rafChargesTotal += parseFloat(traveller.service_charge.customer.raf);
				gstOnRafTotal += parseFloat(traveller.service_charge.customer.gst_on_raf)
			});
		});
	
		let amountToSubtract = gstOnScTotal + serviceChargeTotal + rafChargesTotal + gstOnRafTotal;
		newData.total -= amountToSubtract;
		newData.products[0].json_doc.total_fare.net_paid -= amountToSubtract;
		newData.products.forEach(product => {
			product.rate -= amountToSubtract;
			product.amount -= amountToSubtract;
			product.total -= amountToSubtract;
			product.json_doc.total_fare.client_raf -= rafChargesTotal;
			product.json_doc.total_fare.client_raf_gst -= gstOnRafTotal;
			product.json_doc.total_fare.gst_recieved -= gstOnScTotal;
			product.json_doc.total_fare.client_service_charges -= serviceChargeTotal;
			product.json_doc.travellers.forEach(traveller => {
				traveller.fare.gst_on_service_charge = 0;
				traveller.service_charge.customer.sc1_value = 0;
				traveller.service_charge.customer.sc1_amount = 0;
				traveller.service_charge.customer.raf = 0;
				traveller.service_charge.customer.gst_on_raf = 0;
			});
		});
	
		return newData;
	}
	
	extractGSTAndServiceCharges(data) {
		let newData = JSON.parse(JSON.stringify(data));
		let serviceChargeTotal = 0;
		let	gstOnScTotal = 0
		let rafChargesTotal = 0;
		let gstOnRafTotal = 0;
		newData.products.forEach(product => {
			product.json_doc.travellers.forEach(traveller => {
				serviceChargeTotal += parseFloat(traveller.service_charge.customer.sc1_amount);
				gstOnScTotal += (parseFloat(traveller.service_charge.customer.sc1_amount) * 18/100)
				rafChargesTotal += parseFloat(traveller.service_charge.customer.raf);
				gstOnRafTotal += parseFloat(traveller.service_charge.customer.gst_on_raf)
			});
		});
	
		let amountToAdd = gstOnScTotal + serviceChargeTotal + rafChargesTotal + gstOnRafTotal;
	
		newData.total = amountToAdd;
		newData.products.forEach(product => {
			product.rate = amountToAdd;
			product.amount = amountToAdd;
			product.total = amountToAdd;
	
			product.json_doc.travellers.forEach(traveller => {
				traveller.fare = {
					"total": "0",
					"base": "0",
					"YQ": "0",
					"YR": "0",
					"K3": "0",
					"OT": "0",
					"OC": "0",
					"addons": 0,
					"markup": 0,
					"service_charge": "0",
					"gst_on_service_charge": 0
				}
	
				traveller.commission = {
					"customer": {
						"iata_type": "RB",
						"iata_value": "0",
						"iata_amount": "0.00",
						"plb_type": "RB",
						"plb_value": "0",
						"plb_amount": "0.00",
						"cashback_type": "VL",
						"cashback_value": "0",
						"cashback_amount": "0.00"
					},
					"supplier": {
						"iata_type": "RB",
						"iata_value": "0",
						"iata_amount": "0.00",
						"plb_type": "RB",
						"plb_value": "0",
						"plb_amount": "0.00",
						"cashback_type": "VL",
						"cashback_value": "0",
						"cashback_amount": "0.00"
					}
				}
	
				traveller.service_charge = {
					"customer": {
						"sc1_type": "T",
						"sc1_value": traveller.service_charge.customer.sc1_value,
						"sc1_amount": traveller.service_charge.customer.sc1_amount,
						"sc2_type": "OT",
						"sc2_value": "0",
						"sc2_amount": "0",
						"sc3_type": "OT",
						"sc3_value": 0,
						"sc3_amount": 0,
						"raf": traveller.service_charge.customer.raf,
						"xxl": "0.00",
						"gst_on_raf": traveller.service_charge.customer.gst_on_raf
					},
					"supplier": {
						"sc1_type": "T",
						"sc1_value": 0,
						"sc1_amount": 0,
						"sc2_type": "N",
						"sc2_value": 0,
						"sc2_amount": 0,
						"sc3_type": "N",
						"sc3_value": 0,
						"sc3_amount": 0,
						"raf": "0.00",
						"xxl": "0.00",
						"gst_on_raf": 0
					}
				}
	
				traveller.ssr = [
					{
						"seat": {
							"code": "",
							"amount": ""
						},
						"meal": {
							"code": "",
							"amount": ""
						},
						"baggage": {
							"code": "",
							"amount": ""
						},
						"ancillary": {
							"code": "",
							"amount": ""
						},
						"additionalBaggage": {
							"code": "",
							"amount": ""
						}
					}
				]
		
				traveller.dis_recall = {
					"customer": {
						"dis_recall_type": "",
						"dis_recall_value": 18,
						"dis_recall_amount": "0"
					},
					"supplier": {
						"dis_recall_type": "",
						"dis_recall_value": 18,
						"dis_recall_amount": "0"
					}
				}
			});
	
			product.json_doc.total_fare = {
				"base": "0",
				"yq": "0",
				"yr": "0",
				"k3": "0",
				"oc": "0",
				"ot": "0",
				"ssr": "0",
				"tds_paid": "0",
				"tds_recieved": "0",
				"gst_paid": "0",
				"gst_recieved": gstOnScTotal,
				"commission_paid": "0",
				"commission_received": "0",
				"net_paid": amountToAdd,
				"net_received": "0",
				"client_xxl": "0",
				"client_raf": rafChargesTotal,
				"client_raf_gst": gstOnRafTotal,
				"client_raf_vat": "0",
				"supplier_xxl": "0",
				"supplier_raf": "0",
				"supplier_raf_gst": "0",
				"supplier_raf_vat": "0",
				"round_off": "0",
				"client_normal_service_charges": "0",
				"supplier_normal_service_charges": "0",
				"vat_paid": "0",
				"vat_received": "0",
				"supplier_service_charges": "0",
				"client_service_charges": serviceChargeTotal
			}
		});
	
		newData.invoice_uuid = null;
		newData.bill_of_supply = false;
		return newData;
	}
	

	
	
}
