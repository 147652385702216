export class TourFareSummaryModel {
  "supplier_non_account" = 0;
  "supplier_basic" = 0;
  "supplier_taxes" = 0;
  "supplier_commission_amount" = 0;
  "supplier_discount_amount" = 0;
  "supplier_tds" = 0;
  "supplier_tds_amount" = 0;
  "supplier_sc1_amount" = 0;
  "supplier_sc2_amount" = 0;
  "supplier_sc3_amount" = 0;
  "supplier_gst" = 0;
  "supplier_vat_amount"=0;
  "supplier_net_amount" = 0;

  "client_non_account" = 0;
  "client_basic" = 0;
  "client_taxes" = 0;
  "client_commission_amount" = 0;
  "client_discount_amount" = 0
  "client_tds" = 0;
  "client_tds_amount" = 0;
  "client_sc1_amount" = 0;
  "client_sc2_amount" = 0;
  "client_sc3_amount" = 0;
  "client_gst" = 0;
  "client_vat_amount"=0;
  "client_net_amount" = 0;

  constructor(fare) {
    {
      if (fare != null) {
        this.supplier_non_account = fare.supplier_non_account || 0;
        this.supplier_basic = fare.supplier_basic || 0;
        this.supplier_taxes = fare.supplier_taxes || 0;
        this.supplier_commission_amount = fare.supplier_commission_amount || 0;
        this.supplier_discount_amount = fare.supplier_discount_amount || 0;
        this.supplier_tds = fare.supplier_tds || 0;
        this.supplier_tds_amount = fare.supplier_tds_amount || 0;
        this.supplier_sc1_amount = fare.supplier_sc1_amount || 0;
        this.supplier_sc2_amount = fare.supplier_sc2_amount || 0;
        this.supplier_sc3_amount = fare.supplier_sc3_amount || 0;
        this.supplier_gst = (fare.supplier_cgst || 0) + (fare.supplier_sgst || 0) + (fare.supplier_igst || 0) + (fare.supplier_cgst_3 || 0) + (fare.supplier_sgst_3 || 0) + (fare.supplier_igst_3 || 0) + (fare.supplier_cgst_2 || 0) + (fare.supplier_sgst_2 || 0) + (fare.supplier_igst_2 || 0);
        this.supplier_vat_amount = fare.supplier_vat_amount||0
        this.supplier_net_amount = fare.supplier_net_amount || 0;

        this.client_non_account = fare.client_non_account || 0;
        this.client_basic = fare.client_basic || 0;
        this.client_taxes = fare.client_taxes || 0;
        this.client_commission_amount = fare.client_commission_amount || 0;
        this.client_discount_amount = fare.client_discount_amount || 0;
        this.client_tds = fare.client_tds || 0;
        this.client_tds_amount = fare.client_tds_amount || 0;
        this.client_sc1_amount = fare.client_sc1_amount || 0;
        this.client_sc2_amount = fare.client_sc2_amount || 0;
        this.client_sc3_amount = fare.client_sc3_amount || 0;
        this.client_gst = (fare.client_cgst || 0) + (fare.client_sgst || 0) + (fare.client_igst || 0) + (fare.client_cgst_3 || 0) + (fare.client_sgst_3 || 0) + (fare.client_igst_3 || 0) + (fare.client_cgst_2 || 0) + (fare.client_sgst_2 || 0) + (fare.client_igst_2 || 0);
        this.client_vat_amount = fare.client_vat_amount || 0;
        this.client_net_amount = fare.client_net_amount || 0;
      } else {
        this.supplier_non_account = 0;
        this.supplier_basic = 0;
        this.supplier_taxes = 0;
        this.supplier_commission_amount = 0;
        this.supplier_discount_amount = 0;
        this.supplier_tds = 0;
        this.supplier_sc1_amount = 0;
        this.supplier_sc2_amount = 0;
        this.supplier_sc3_amount = 0;
        this.supplier_gst = 0;
        this.supplier_vat_amount = 0;
        this.supplier_net_amount = 0;

        this.client_non_account = 0;
        this.client_basic = 0;
        this.client_taxes = 0;
        this.client_commission_amount = 0;
        this.client_discount_amount =  0;
        this.client_tds = 0;
        this.client_sc1_amount = 0;
        this.client_sc2_amount = 0;
        this.client_sc3_amount = 0;
        this.client_gst = 0;
        this.client_vat_amount = 0;
        this.client_net_amount = 0;
      }
    }
  }
}
