<header class="app-header bg-white " >
    <div class="row">
        <div class="align-items-center col-md-2 col-sm-2 d-flex">
            <img src="{{commonService.aws_asset_url}}/_assets/icons/{{darkModeStatus == true ? 'dark' : 'light'}}-mode/full-logo.svg" (click)="openAccountPage('')" alt="" width="105px" class="cursor logo " />
            <div class="border-right ml-2 px-2 switch-2-web-btn">
                <small class="text-grey" >Financial Year</small>
                <div class="d-flex justify-content-between align-item-center">
                    <select style="border: 0!important;"                           
                    class="d-block text-primary text-bold transition" (change)="setFinancialYear($event)">
                    <ng-container *ngFor="let fy of financialYears">
                        <option  value="{{fy.value}}" [selected]="financialYear == fy.value">{{fy.key}}</option>
                    </ng-container>
                </select>
                <img src="{{commonService.aws_asset_url}}/_assets/icons/white-angle-down.svg" alt="" class="ml-10">
                </div>
            </div>
        </div>

        <div class="col-sm-7 tab-group">
            <!-- <ng-container *ngFor="let tab of internalTabs; let i = index;">
                <div class="tab-item" title="{{tab.name}}" [ngClass]="{'active' : activeTabIndex == i}" >
                    <div class="tab-header" (click)="setActiveTab(i)">{{tab.name}}</div> 
                    <span *ngIf="i != 0" class="close-icon" (click)="closeTab(i)"> x </span>
                </div>
            </ng-container> -->
            <div class="tab-item" title="{{appTitle}}" [ngClass]="{'active' : !showTabs}" >
                <div class="tab-header" (click)="setSelectedTab(-1)">{{appTitle}}</div> 
            </div>
            <ng-container *ngFor="let tab of tabs; let i = index;">
                <div class="tab-item" title="{{tab.title_hover}}" [ngClass]="{'active' : showTabs && selectedTab == i}" >
                    <div class="tab-header" (click)="setSelectedTab(i)">{{tab.title}}</div> 
                    <span class="cross-icon" (click)="removeTab(i)"><img src="{{commonService.aws_asset_url}}/_assets/icons/close.svg" width="8px" height="8px" alt=""></span>
                </div>
            </ng-container>
            <!-- <nav mat-tab-nav-bar  [tabPanel]="tabPanel">
                <a mat-tab-link *ngFor="let tab of tabs; let i = index;"
                    (click)="setSelectedTab(i)"
                   [active]="selectedTab == i"> {{tab.title}} 
                </a>
              </nav>
            <mat-tab-nav-panel #tabPanel></mat-tab-nav-panel> -->
        </div>
        <div class="col-sm-3 col-md-3 pl-0 justify-content-end  d-flex  align-item-center" [ngClass]="{'d-none': !hasAccessToken}">
            <ul class="d-flex align-items-center list-inline list-unstyled mb-0"  >
                <!-- <li>
                    <div class='company-info'>
                        <h5 title='SOUVIK TEST - HQ' class='company Name'>SOUVIK TEST  - HQ</h5>
                        <p title='SOUVIK JHA - SUPERADMIN' class='company Name'>SOUVIK JHA -  SUPERADMIN</p>
                    </div>
                </li> -->
                <li>
                    <div class="company-info">
                      <h5 [title]="currentCompany + ' - ' + currentBranch" class="company-name">
                        {{ currentCompany }} - {{ currentBranch }}
                      </h5>
                      <p [title]="currentUser + ' - ' + currentRole" class="company-name">
                        {{ currentUser }} - {{ currentRole }}
                      </p>
                    </div>
                  </li>
                <li >
                    <Button *ngIf="branchSwitchAccess==true" title="Switch Branch" type="primary" class='branch-switch mr-3' (click)="setSwitchMOdalStatus(true)">
                        <i class="fas fa-exchange-alt refresh-ico"></i>
                    </Button>                         
                      <!-- <Modal title="Switch Company and Branch" visible={showModal} class='switch-modal'
                    >
                        <div style='margin-bottom:20px'>
                            <Select placeholder="COMPANY" class="w-100">
                                <option>COMPANY</option>
                            </Select>
                        </div>
                        <div >
                            <Select placeholder="BRANCH"  class="w-100" >
                                <option>BRANCH</option>
                            </Select>
                        </div>
                    </Modal> -->
                </li>
                <!-- <li *ngIf="branches && branches.length>0">
                    <div className='branchesSelect'>
                    <select class="w-100 accountNotifySelect  custom-select" (change)="onBranchChange($event, null)" title='Switch to branch'>
                        <option value="">{{currentCompany}}</option>
                        <option *ngFor="let item of branches; let i = index;" value="{{item.id}}">{{item.branch_name}}</option>
                    </select>
                     </div>
                </li> -->

                <!-- <li *ngIf="parentAccount && parentAccount.length>0 && branchSwitchAccess==true">
                    <select class="w-100 accountNotifySelect custom-select" (change)="onBranchChange(null, $event)" title='Switch to Parent Account'>
                        <option value="">{{currentCompany}}</option>
                        <option *ngFor="let item of parentAccount; let i = index;" value="{{item.tenant_id}}">{{item.tenant_company_name}}</option>
                    </select>
                </li> -->

                <li (mouseover)="darkModeStatus == true ? notificationImgSrc = commonService.aws_asset_url+'/_assets/icons/dark-mode/menues/active-notification.svg' : notificationImgSrc = commonService.aws_asset_url+'/_assets/icons/light-mode/menues/active-notification.svg'"
                (mouseout)="darkModeStatus == true ? notificationImgSrc = commonService.aws_asset_url+'/_assets/icons/dark-mode/menues/inactive-notification.svg' : notificationImgSrc = commonService.aws_asset_url+'/_assets/icons/light-mode/menues/inactive-notification.svg'"
                 class=" cursor noDrag text-size-12 noDrag position-relative" (click)="showNotificationArea()" title="Notification">
                 <img [src]="notificationImgSrc" alt="" style="width: 17px;">
                        <span class="notificationCount" >
                            <!-- <i>{{notificationCount}}</i> -->
                        </span>
                        </li>
                <li (mouseover)="darkModeStatus == true ? settingImgSrc = commonService.aws_asset_url+'/_assets/icons/dark-mode/active-dev-tool.svg' : settingImgSrc = commonService.aws_asset_url+'/_assets/icons/light-mode/active-dev-tool.svg'"
                (mouseout)="darkModeStatus == true ? settingImgSrc = commonService.aws_asset_url+'/_assets/icons/dark-mode/inactive-dev-tool.svg' : settingImgSrc = commonService.aws_asset_url+'/_assets/icons/light-mode/inactive-dev-tool.svg'" 
                 class=" cursor noDrag text-size-12 noDrag ml-3" (click)="openDevtools()" title="Dev Tools" [ngClass]="{'d-none': hideWinActionBtn}">
                 <img [src]="settingImgSrc" alt="" style="width: 20px;">
                </li>
            </ul>
            <ul class="d-flex align-items-center list-unstyled mb-0" [ngClass]="{'d-none': hideWinActionBtn}">
                <li (mouseover)="darkModeStatus == true ? minimizeImgSrc = commonService.aws_asset_url+'/_assets/icons/dark-mode/active-minimize.svg' : minimizeImgSrc = commonService.aws_asset_url+'/_assets/icons/light-mode/active-minimize.svg'"
                (mouseout)="darkModeStatus == true ? minimizeImgSrc = commonService.aws_asset_url+'/_assets/icons/dark-mode/inactive-minimize.svg' : minimizeImgSrc = commonService.aws_asset_url+'/_assets/icons/light-mode/inactive-minimize.svg'"
                 title="Minimize" id="minimizeApp" (click)="mimimizeWin()" class="ml-4 cursor noDrag">
                    <img [src]="minimizeImgSrc" alt="" style="width: 15px;">
                </li>
                <li (mouseover)="darkModeStatus == true ? maximizeImgSrc = commonService.aws_asset_url+'/_assets/icons/dark-mode/active-maximize.svg' : maximizeImgSrc = commonService.aws_asset_url+'/_assets/icons/light-mode/active-maximize.svg'"
                (mouseout)="maximizeImgSrc = commonService.aws_asset_url+'/_assets/icons/light-mode/inactive-maximize.svg'"
                 title="Maximize" id="maximizeApp" (click)="maximizeWin()" class="ml-3 cursor noDrag">
                    <img [src]="maximizeImgSrc" alt="" style="width: 15px;">
                </li>
                <li (mouseover)="closeImgSrc = commonService.aws_asset_url+'/_assets/icons/light-mode/active-close.svg'"
                (mouseout)="closeImgSrc = commonService.aws_asset_url+'/_assets/icons/light-mode/inactive-close.svg'"
                 title="Close" id="closeApp" (click)="closeWin()" class="ml-3 cursor noDrag">
                    <img [src]="closeImgSrc" alt="" style="width: 15px;">
                </li>
            </ul>
            <div class="userProfilewrapper ml-20">
                <div class="user-dropdown">
                    <a class="userIco" href="javascript:void(0)" (click)="openAccountPage('account/profile')">
                    <img src="{{commonService.aws_asset_url}}/_assets/icons/profile.svg" width="22px" height="22px" alt="active Dashboard" class="user-icon">
                </a>
                <div class="user-dropdown-content">
                    <a href="javascript:void(0)" (click)="openAccountPage('account/profile')">Profile</a>
                    <a href="javascript:void(0)" (click)="openAccountPage('account/teams')">Manage Team</a>
                    <!-- <a href="javascript:void(0)" (click)="openAccountPage('billing')">Billing &amp; Subscriptions</a> -->
                    <!-- <a href="javascript:void(0)" (click)="openAccountPage('upgrade')">Upgrade</a> -->
                    <a href="https://help.systacc.com/">Help Center</a>
                    <a href="javascript:void(0)" (click)="openAccountPage('contact-us')">Contact Us</a>
                    <a href="javascript:void(0)" (click)="setLogoutMOdalStatus(true)">Logout</a>
                </div>
            </div>  
            </div>
            <button type="button" class="upgrade-btn ml-20">Upgrade</button>
        </div>
        <!-- <div class="col-sm-2 text-right" >
            <i id="closeApp" class="cursor noDrag mr-2" [ngClass]="{'d-none': hideWinActionBtn}" (click)="openDevtools()" title="Close"><img style="width: 15px;"
                    src="{{commonService.aws_asset_url}}/_assets/icons/dark-mode/menues/active-settings.svg" alt=""></i>
            <i id="closeApp" class="cursor noDrag" (click)="showNotificationArea()" title="Close">
                <img style="width: 15px;"
                    src="{{commonService.aws_asset_url}}/_assets/icons/dark-mode/menues/active-notification.svg" alt="">{{notificationCount}}</i>
        </div>
        <div class="col-sm-1 text-right" [ngClass]="{'d-none': hideWinActionBtn}">
            <i id="minimizeApp" (click)="mimimizeWin()" class="fa fa-window-minimize  cursor noDrag" title="Minimize"
                aria-hidden="true"></i>
            <i id="maximizeApp" (click)="maximizeWin()" class="fa fa-clone mx-4 cursor noDrag" title="Maximize"
                aria-hidden="true"></i>
            <i id="closeApp" class="cursor noDrag" (click)="closeWin()" title="Close"><img style="width: 15px;"
                    src="{{commonService.aws_asset_url}}/_assets/icons/close_black.svg" alt=""></i>
        </div> -->
    </div>
    <div class="pl-0 d-flex justify-content-center align-items-center pr-0">           
        <div class=" position-relative noDrag"   [ngClass]="{'d-none': !hasAccessToken}"> 
            <div class="d-none">
                <div class="position-absolute adv-search d-flex flex-x-center flex-y-center dropdown-toggle dropdown-toggle-split"
                    #openDropDown data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <img src="{{commonService.aws_asset_url}}/_assets/icons/{{darkModeStatus == true ? 'dark' : 'light'}}-mode/purple-magnifying-glass.svg" alt="">
                    <img src="{{commonService.aws_asset_url}}/_assets/icons/{{darkModeStatus == true ? 'dark' : 'light'}}-mode/purple-down-angle.svg" class="ml-15" alt="">
                </div>
                <div class="dropdown-menu" *ngIf="menuLoaded">
                    <a class="dropdown-item text-capitalize"
                        [ngClass]="{'selectedGroup': selectedSearchType == 'invoice'}"
                        (click)="selectSearchType('invoice')" *hasAccess="{'key' : 'F0002012'}">Invoice</a>
                    <a class="dropdown-item text-capitalize"
                        [ngClass]="{'selectedGroup': selectedSearchType == 'credit-note'}"
                        (click)="selectSearchType('credit-note')" *hasAccess="{'key' : 'F000301'}">Credit Note</a>
                    <a class="dropdown-item text-capitalize"
                        [ngClass]="{'selectedGroup': selectedSearchType == 'payment'}"
                        (click)="selectSearchType('payment')" *hasAccess="{'key' : 'F0004022'}">Payment</a>
                    <a class="dropdown-item text-capitalize"
                        [ngClass]="{'selectedGroup': selectedSearchType == 'receipt'}"
                        (click)="selectSearchType('receipt')" *hasAccess="{'key' : 'F0004012'}">Receipt</a>
                    <a class="dropdown-item text-capitalize" [ngClass]="{'selectedGroup': selectedSearchType == 'jv'}"
                        (click)="selectSearchType('jv')" *hasAccess="{'key' : 'F0002072'}">JV</a>
                    <a class="dropdown-item text-capitalize"
                        [ngClass]="{'selectedGroup': selectedSearchType == 'adm_acm'}"
                        (click)="selectSearchType('adm_acm') " *hasAccess="{'key' : 'F0002062'}">ACM/ADM</a>
                        <a class="dropdown-item text-capitalize"
                        [ngClass]="{'selectedGroup': selectedSearchType == 'adm_acm'}"
                        (click)="selectSearchType('adm_acm') " *hasAccess="{'key' : 'F0002062'}">ADM</a>
                    <a class="dropdown-item text-capitalize"
                        [ngClass]="{'selectedGroup': selectedSearchType == 'client'}"
                        (click)="selectSearchType('client')" *hasAccess="{'key' : 'F000101'}">Client</a>
                    <a class="dropdown-item text-capitalize"
                        [ngClass]="{'selectedGroup': selectedSearchType == 'airline'}"
                        (click)="selectSearchType('airline')" *hasAccess="{'key' : 'F000102'}">Airline</a>
                    <a class="dropdown-item text-capitalize"
                        [ngClass]="{'selectedGroup': selectedSearchType == 'supplier'}"
                        (click)="selectSearchType('supplier')" *hasAccess="{'key' : 'F000103'}">Supplier</a>
                    <a class="dropdown-item text-capitalize"
                        [ngClass]="{'selectedGroup': selectedSearchType == 'general'}"
                        (click)="selectSearchType('general')" *hasAccess="{'key' : 'F000105'}">General</a>
                    <!-- <a class="dropdown-item text-capitalize"
                        [ngClass]="{'selectedGroup': selectedSearchType == 'employee'}"
                        (click)="selectSearchType('employee')" *hasAccess="{'key' : 'F000104'}">Employee</a> -->

                    <a class="dropdown-item text-capitalize"
                        [ngClass]="{'selectedGroup': selectedSearchType == 'ledger'}"
                        (click)="selectSearchType('ledger')" *hasAccess="{'key' : 'F000501'}">Ledger Statement</a>
                    <a class="dropdown-item text-capitalize"
                        [ngClass]="{'selectedGroup': selectedSearchType == 'outstanding'}"
                        (click)="selectSearchType('outstanding')" *hasAccess="{'key' : 'F000502'}">Client
                        Outstanding</a>
                    <a class="dropdown-item text-capitalize"
                        [ngClass]="{'selectedGroup': selectedSearchType == 'reconciliation'}"
                        (click)="selectSearchType('reconciliation')" *hasAccess="{'key' : 'F000405'}">Reconciliation</a>


                    <a class="dropdown-item text-capitalize"
                        [ngClass]="{'selectedGroup': selectedSearchType == 'banking'}"
                        (click)="selectSearchType('banking')" *hasAccess="{'key' : 'F000405'}">Bank Reconciliation</a>
                    <a class="dropdown-item text-capitalize"
                        [ngClass]="{'selectedGroup': selectedSearchType == 'e-invoicing'}"
                        (click)="selectSearchType('banking')" >E-invoicing</a>
                        <a class="dropdown-item text-capitalize"
                        [ngClass]="{'selectedGroup': selectedSearchType == 'e-inv-list'}"
                        (click)="selectSearchType('banking')" >E-invoice Listing</a>
                    <a class="dropdown-item text-capitalize"
                        [ngClass]="{'selectedGroup': selectedSearchType == 'history-ledger'}"
                        (click)="selectSearchType('history-ledger')" >History Ledger</a>    
                    <a class="dropdown-item text-capitalize" [ngClass]="{'selectedGroup': selectedSearchType == 'role'}"
                        *ngIf="userInfo && userInfo?.roles && userInfo?.roles.length > 0 && userInfo?.roles[0].rolename == 'SUPERADMIN'" (click)="selectSearchType('role')">Role</a>
                    <a class="dropdown-item text-capitalize"
                        [ngClass]="{'selectedGroup': selectedSearchType == 'account'}"
                        *ngIf="userInfo && userInfo?.roles && userInfo?.roles.length > 0 && userInfo?.roles[0].rolename == 'SUPERADMIN'" (click)="selectSearchType('account')">Account</a>


                    <div role="separator" class="dropdown-divider"></div>
                    <li class="text-center adv-search dropdown-item" (click)="openModal()">
                        <div><span class="text-blue2 cursor-pointer">
                                Advanced Search</span></div>
                    </li>
                </div>
                <input type="text" class="w-100per" placeholder="Search {{selectedSearchType | titlecase}}..." />
            </div>
           <div class="modal fade" id="exampleModal" role="dialog" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div class="modal-dialog customModal" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title pull-left d-flex">
                                <div>Search</div>
                                <div class="ml-3 text-field-group">
                                    <div class="select-field text-input-field mr-3" *ngIf="menuLoaded">
                                        <select searchtabindex="0" class="input-field"
                                            (change)="selectSearchType($event.target.value)">
                                            <option value="">Select</option>
                                            <option value="account" [selected]="selectedSearchType == 'account'"
                                                *ngIf="userInfo && userInfo?.roles && userInfo?.roles.length > 0 && userInfo?.roles[0].rolename == 'SUPERADMIN'">Account</option>
                                                <option value="adm_acm" [selected]="selectedSearchType == 'adm_acm'" *hasAccess="{'key' : 'F0002062'}">
                                                    ACM </option>
                                                    <option value="adm_acm" [selected]="selectedSearchType == 'adm_acm'" *hasAccess="{'key' : 'F0002062'}">
                                                        ADM </option>
                                            <option value="aging-report"
                                            [selected]="selectedSearchType == 'aging-report'">Aging Report
                                        </option>
                                            <option value="airline" [selected]="selectedSearchType == 'airline'"
                                                *hasAccess="{'key' : 'F0001022'}">Airline</option>
                                            <option value="plb-report"
                                                [selected]="selectedSearchType == 'plb-report'">Airline PLB Report
                                            </option>
                                                <option value="balance-sheet"
                                                [selected]="selectedSearchType == 'balance-sheet'">Balance Sheet
                                                </option>
                                                <option value="bill-wise-profit-report"
                                                [selected]="selectedSearchType == 'bill-wise-profit-report'">Bill Wise Profitability
                                            </option>
                                                <option value="client" [selected]="selectedSearchType == 'client'"
                                                *hasAccess="{'key' : 'F0001012'}">Client</option>
                                                <option value="outstanding"
                                                [selected]="selectedSearchType == 'outstanding'"
                                                >Client Outstanding </option>
                                                <option value="client-sales-report"
                                                [selected]="selectedSearchType == 'client-sales-report'">CLIENT SALES REPORT
                                            </option>
                                                <option value="create_credit_note"
                                                [selected]="selectedSearchType == 'create_credit_note'"
                                                *hasAccess="{'key' : 'F000301'}">Create Credit Note</option>
                                                <option value="credit-note"
                                                [selected]="selectedSearchType == 'credit-note'"
                                                *hasAccess="{'key' : 'F000301'}">Credit Note</option>
                                                <option value="daybook" [selected]="selectedSearchType == 'daybook'"
                                                *hasAccess="{'key' : 'F000503'}">Day Book</option>
                                                <option value="e-invoicing"
                                                 [selected]="selectedSearchType == 'e-invoicing'">E-Invoicing
                                            </option>
                                            <option value="e-inv-list"
                                                 [selected]="selectedSearchType == 'e-inv-list'">E-Invoice List
                                            </option>
                                            <option value="email-status"
                                                [selected]="selectedSearchType == 'email-status'">Email Status
                                            </option>
                                            <option value="flown-report"
                                                [selected]="selectedSearchType == 'flown-report'">Flown
                                                Report</option>
                                                <option value="general" [selected]="selectedSearchType == 'general'"
                                                *hasAccess="{'key' : 'F0001052'}">General</option>
                                                <option value="b2c-adjustment"
                                                 [selected]="selectedSearchType == 'b2c-adjustment'">GST-B2CS Adjustment
                                            </option>
                                            <option value="gstreport" [selected]="selectedSearchType == 'gstreport'"
                                                *hasAccess="{'key' : 'F000505'}">Gst Statement</option>
                                                <option value="history-ledger"
                                                [selected]="selectedSearchType == 'history-ledger'">History Ledger
                                            </option>
                                            <option value="invoice" [selected]="selectedSearchType == 'invoice'"
                                                *hasAccess="{'key' : 'F0002012'}">Invoice</option>
                                                <option value="jv" [selected]="selectedSearchType == 'jv'"
                                                *hasAccess="{'key' : 'F0002072'}">JV </option>
                                                <option value="ledger" [selected]="selectedSearchType == 'ledger'"
                                                *hasAccess="{'key' : 'F000501'}">Ledger Statement</option>
                                                <option value="missing-transactions"
                                            [selected]="selectedSearchType == 'missing-transactions'">Mismatch Transactions
                                        </option>
                                                <option value="mis-report"
                                                [selected]="selectedSearchType == 'mis-report'">MIS REPORT - SERVICE WISE
                                            </option>
                                            <option value="mis-report"
                                            [selected]="selectedSearchType == 'mis-report-employee'">MIS REPORT - EMPLOYEE WISE
                                        </option>
                                        <option value="mis-report"
                                        [selected]="selectedSearchType == 'mis-report-sales-person'">MIS REPORT - SALES PERSON WISE
                                    </option>
                                    <option value="mis-report"
                                    [selected]="selectedSearchType == 'mis-report-cost-center'">MIS REPORT - COST CENTER WISE
                                </option>
                                            
                                            <option value="payment" [selected]="selectedSearchType == 'payment'"
                                                *hasAccess="{'key' : 'F0004022'}">Payment</option>
                                                <option value="profit-loss"
                                                [selected]="selectedSearchType == 'profit-loss'">Profit & Loss
                                                </option>
                                                <option value="psr-report"
                                                [selected]="selectedSearchType == 'psr-report'">PSR Report
                                            </option>
                                            <option value="receipt" [selected]="selectedSearchType == 'receipt'"
                                                *hasAccess="{'key' : 'F0004012'}">Receipt</option>
                                                <option value="reconciliation"
                                                [selected]="selectedSearchType == 'reconciliation'"
                                                *hasAccess="{'key' : 'F0059'}">Reconciliation</option>
                                                <option value="role" [selected]="selectedSearchType == 'role'"
                                                *ngIf="userInfo && userInfo?.roles && userInfo?.roles.length > 0 && userInfo?.roles[0].rolename == 'SUPERADMIN' ">Role</option>

                                                <option value="sales-report"
                                                [selected]="selectedSearchType == 'sales-report'">SALES REPORT
                                            </option>

                                            <option value="supplier" [selected]="selectedSearchType == 'supplier'"
                                                *hasAccess="{'key' : 'F0001032'}">Supplier</option>
                                                <option value="supplier-wise-ticket"
                                                [selected]="selectedSearchType == 'supplier-wise-ticket'">Supplier Ticket Statement
                                              </option>
                                             <!-- <option value="employee" [selected]="selectedSearchType == 'employee'"
                                                *hasAccess="{'key' : 'F0001042'}">Employee</option>  -->
                                                <option value="tcsreport" [selected]="selectedSearchType == 'tcsreport'"
                                                *hasAccess="{'key' : 'F000504'}">Tcs Statement</option>
                                                
                                            <option value="tdsreport" [selected]="selectedSearchType == 'tdsreport'"
                                                *hasAccess="{'key' : 'F000504'}">Tds Statement</option>
                                                <option value="trial-balance"
                                                [selected]="selectedSearchType == 'trial-balance'">Trial Balance
                                                </option>
                                            
                                            <!-- <option value="banking" [selected]="selectedSearchType == 'banking'"
                                                *hasAccess="{'key' : 'F000405'}">Bank Reconciliation</option> -->
                                            <option value="unpaid-report"
                                                [selected]="selectedSearchType == 'unpaid-report'">Un-paid
                                                Report</option>

                                                <option value="vertical-pnl"
                                                [selected]="selectedSearchType == 'vertical-pnl'">vertical PNL
                                                </option>
                                            
                                        </select>
                                    </div>

                                </div>
                            </h4>
                            <button type="button" class="close" (click)="closeModalFn()" aria-label="Close"
                                data-dismiss="modal">&times;</button>
                        </div>
                        <div class="modal-body" *ngIf="menuLoaded">
                            <ng-container *ngIf="selectedSearchType == 'invoice'">
                                <ng-container *hasAccess="{'key' : 'F0002012'}">
                                    <app-invoice-search></app-invoice-search>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="selectedSearchType == 'credit-note'">
                                <ng-container *hasAccess="{'key' : 'F000301'}">
                                    <app-credit-note-search></app-credit-note-search>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="selectedSearchType == 'create_credit_note'">
                                <ng-container *hasAccess="{'key' : 'F000301'}">
                                    <app-invoice-credit-note-search></app-invoice-credit-note-search>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="selectedSearchType == 'adm_acm'">
                                <ng-container *hasAccess="{'key' : 'F0002062'}">
                                    <app-adm-acm-search></app-adm-acm-search>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="selectedSearchType == 'payment'">
                                <ng-container *hasAccess="{'key' : 'F0004022'}">
                                    <app-payment-search></app-payment-search>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="selectedSearchType == 'receipt'">
                                <ng-container *hasAccess="{'key' : 'F0004012'}">
                                    <app-receipt-search></app-receipt-search>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="selectedSearchType == 'client'">
                                <ng-container *hasAccess="{'key' : 'F0001012'}">
                                    <app-client-search></app-client-search>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="selectedSearchType == 'airline'">
                                <ng-container *hasAccess="{'key' : 'F0001022'}">
                                    <app-airline-search></app-airline-search>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="selectedSearchType == 'supplier'">
                                <ng-container *hasAccess="{'key' : 'F0001032'}">
                                    <app-supplier-search></app-supplier-search>
                                </ng-container>
                            </ng-container>
                            <!-- <ng-container *ngIf="selectedSearchType == 'employee'">
                                <ng-container *hasAccess="{'key' : 'F0001042'}">
                                    <app-employee-search></app-employee-search>
                                </ng-container>
                            </ng-container> -->
                            <ng-container *ngIf="selectedSearchType == 'general'">
                                <ng-container *hasAccess="{'key' : 'F0001052'}">
                                    <app-general-search></app-general-search>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="selectedSearchType == 'account'">
                                <ng-container>
                                    <app-account-search></app-account-search>
                                </ng-container>
                            </ng-container>

                            <ng-container *ngIf="selectedSearchType == 'jv'">
                                <ng-container *hasAccess="{'key' : 'F0002072'}">
                                    <app-jv-search></app-jv-search>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="selectedSearchType == 'ledger'">
                                <ng-container *hasAccess="{'key' : 'F000501'}">
                                    <app-ledger-search></app-ledger-search>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="selectedSearchType == 'outstanding'">
                                <ng-container>
                                    <app-outstanding-search></app-outstanding-search>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="selectedSearchType == 'daybook'">
                                <ng-container *hasAccess="{'key' : 'F000503'}">
                                    <app-day-book-search></app-day-book-search>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="selectedSearchType == 'gstreport'">
                                <ng-container *hasAccess="{'key' : 'F000505'}">
                                    <app-gst-report-search></app-gst-report-search>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="selectedSearchType == 'tcsreport'">
                                <ng-container *hasAccess="{'key' : 'F000504'}">
                                    <app-tcs-report-search></app-tcs-report-search>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="selectedSearchType == 'tdsreport'">
                                <ng-container *hasAccess="{'key' : 'F000504'}">
                                    <app-tds-report-search></app-tds-report-search>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="selectedSearchType == 'reconciliation'">
                                <ng-container *hasAccess="{'key' : 'F0059'}">
                                    <app-reconciliation-search></app-reconciliation-search>
                                </ng-container>
                            </ng-container>

                            <ng-container *ngIf="selectedSearchType == 'flown-report'">
                                <ng-container>
                                    <app-flown-report-search></app-flown-report-search>
                                </ng-container>
                            </ng-container>

                            <ng-container *ngIf="selectedSearchType == 'psr-report'">
                                <ng-container>
                                    <app-psr-report-search></app-psr-report-search>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="selectedSearchType == 'plb-report'">
                                <ng-container>
                                    <app-airline-plb-report-search></app-airline-plb-report-search>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="selectedSearchType == 'email-status'">
                                <ng-container>
                                    <app-email-status-search></app-email-status-search>
                                </ng-container>
                            </ng-container>

                            <ng-container *ngIf="selectedSearchType == 'unpaid-report'">
                                <ng-container>
                                    <app-unpaid-report-search></app-unpaid-report-search>
                                </ng-container>
                            </ng-container>

                            <ng-container *ngIf="selectedSearchType == 'role'">
                                <ng-container>
                                    <app-role-search></app-role-search>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="selectedSearchType == 'banking'">
                                <ng-container *hasAccess="{'key' : 'F000405'}">
                                    <app-banking-search></app-banking-search>
                                </ng-container>
                            </ng-container>

                            <ng-container *ngIf="selectedSearchType == 'trial-balance'">
                                <ng-container>
                                <app-trial-balance-search></app-trial-balance-search>  
                                </ng-container>
                            </ng-container>

                            <ng-container *ngIf="selectedSearchType == 'vertical-pnl'">
                                <ng-container>
                                <app-vertical-pnl-search></app-vertical-pnl-search>  
                                </ng-container>
                            </ng-container>

                            <ng-container *ngIf="selectedSearchType == 'profit-loss'">
                                <ng-container>
                                <app-profit-loss-search></app-profit-loss-search>  
                                </ng-container>
                            </ng-container>

                            <ng-container *ngIf="selectedSearchType == 'balance-sheet'">
                                <ng-container>
                                <app-balance-sheet-search></app-balance-sheet-search>  
                                </ng-container>
                            </ng-container>

                            <ng-container *ngIf="selectedSearchType == 'missing-transactions'">
                                <ng-container *hasAccess="{'key' : 'F000405'}">
                                    <app-missing-transactions-search></app-missing-transactions-search>
                                </ng-container>
                            </ng-container>

                            <ng-container *ngIf="selectedSearchType == 'aging-report' ">
                                <ng-container >
                                    <app-aging-report-search></app-aging-report-search>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="selectedSearchType == 'supplier-wise-ticket'">
                                <ng-container >
                                    <app-ticket-statement></app-ticket-statement>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="selectedSearchType == 'bill-wise-profit-report'">
                                <ng-container>
                                    <app-bill-wise-search></app-bill-wise-search>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="selectedSearchType == 'e-invoicing'">
                                <ng-container>
                                    <app-e-invoice-search></app-e-invoice-search>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="selectedSearchType == 'e-inv-list'">
                                <ng-container>
                                    <app-e-inv-list-search></app-e-inv-list-search>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="selectedSearchType == 'b2c-adjustment'">
                                <ng-container>
                                    <app-b2c-adjustment-filter></app-b2c-adjustment-filter>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="selectedSearchType == 'mis-report'">
                                <ng-container>
                                    <app-mis-report-search></app-mis-report-search>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="selectedSearchType == 'sales-report'">
                                <ng-container>
                                    <app-sales-report-search></app-sales-report-search>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="selectedSearchType == 'client-sales-report'">
                                <ng-container>
                                    <app-client-sales-report-search></app-client-sales-report-search>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="selectedSearchType == 'history-ledger'">
                                <ng-container>
                                    <app-history-ledger-search></app-history-ledger-search>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="passwordModal" data-backdrop="false" role="dialog"
                aria-labelledby="passwordModalLabel" aria-hidden="true">
                <div class="modal-dialog pwdModal" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title text-primary text-bold pull-left d-flex">

                                <div>Reset Password</div>
                            </h5>
                            <button type="button" class="close pull-right" aria-label="Close" data-dismiss="modal">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <ng-container>
                                <app-reset-password></app-reset-password>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>

            <button type="button" hidden data-toggle="modal" #logoutBtn data-target="#logoutModal"></button>
            <div id="logoutModal" class="modal fade" role="dialog" data-backdrop="false">
                <div class="modal-dialog">
                    <div class="modal-content" #modalContent>
                        <div class="modal-header">
                            <h4 class="modal-title">Logout Confirmation</h4>
                            <button type="button" class="close" (click)="closeLogoutModalFn()"
                                data-dismiss="modal">&times;</button>
                        </div>
                        <div class="modal-body">
                            <p> Do you want to Logout?</p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn-style mr-3 bg-purple" tabIndex="1" attr.tabIndex="1"
                                data-dismiss="modal" (click)="logout()"> Yes</button>
                            <button type="button" class="btn-style" tabIndex="2" attr.tabIndex="2"
                                data-dismiss="modal">No</button>
                        </div>
                    </div>
                </div>
            </div>

            <button type="button" hidden data-toggle="modal" #switchBtn data-target="#switchModal"></button>
            <div id="switchModal" class="modal fade switch-modal" role="dialog" data-backdrop="false">
                <div class="modal-dialog">
                    <div class="modal-content" #modalContent>
                    <div class="modal-header">
                        <h1>Switch Company and Branch</h1>
                        <button type="button" class="close"  data-dismiss="modal">&times;</button>
                    </div>
                    <div class="modal-body">
                        <div style="margin-bottom: 20px;" class="dropdown-down">
                            <!-- Company Dropdown -->
                            <select id="company-select" [(ngModel)]="selectedCompany" (change)="onCompanyChange($event.target.value)" class="dropdown">
                                <option value="" selected hidden >COMPANY</option>
                                <option  *ngFor="let company of companys" [value]="company.tenant_id">
                                    {{ company.company_name }}
                                </option>
                            </select>
                        </div>
                        <div class="dropdown-down">
                            <!-- Branch Dropdown -->
                            <select id="branch-select" [(ngModel)]="selectedBranch" (change)="onBranchSelect($event.target.value)" class="dropdown">
                                <option value="" selected hidden >BRANCH</option>
                                <option *ngFor="let branch of branches" [value]="branch.branch_name">
                                    {{ branch.branch_name || "HQ" }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button (click)="openconfirmModal()" class="custom-switch-button">Switch Branch</button>
                    </div>
                </div>
             </div>
            </div>

            <button type="button" hidden data-toggle="modal" #confirmBtn data-target="#confirmModal"></button>
            <div id="confirmModal" class="modal fade switch-modal confirm-modal" role="dialog" data-backdrop="false">
                <div class="modal-dialog">
                    <div class="modal-content" #modalContent>
                <div class="modal-header">
                  <h2>Confirm Branch Switch</h2>
                </div>
                <div class="modal-body">
                  <p>You will be logged in to company- <b>{{selectedCompanyname}}</b> of branch- <b>{{selectedBranchname || 'HQ'}}</b>. Are you sure you want to switch to this branch?</p>
                </div>
                <div class="modal-footer modal-actions">
                  <button (click)="onCancel()" class="no-button">No</button>
                  <button (click)="onConfirm()" class="yes-button">Yes</button>
                </div>
                </div>
                </div>
              </div>


            <button type="button" hidden #closeModal class="close" data-dismiss="modal">&times;</button>
            <button type="button" hidden class="close"  data-toggle="modal" #searchModal
                data-target="#exampleModal">&times;</button>


        </div>
    </div>


</header>