<div class="ledger-container pb-20">
  <!-- <div class="tab-nav d-flex align-item-center justify-content-between">
    <ul class="list-inline list-unstyled p-0 m-0">
      <li class="active page-title   text-uppercase"> Client</li>
    </ul>
    <ul class="page-nav d-flex align-item-center list-inline list-unstyled p-0 m-0">
      <li [routerLink]="['/']" class="text-grey text-capitalize cursor  hover-text-primary transition"> Dashboard
      </li>
      <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
      <li class="text-grey text-capitalize cursor text-primary transition"> View Client</li>
    </ul>
  </div> -->
  <div class="row tab-nav pl-0 pr-0  align-item-center">
    <!-- <div class="col-sm-4 pl-0">
        <ul class="list-inline list-unstyled p-0 m-0">
            <li class="active page-title text-uppercase cursor"> View Client</li>
        </ul>
    </div> -->
    <div class="col-sm-4 ">
      <ul class="page-nav d-flex align-item-center list-inline list-unstyled p-0 m-0">
          <li [routerLink]="['/']"
              class="text-grey text-capitalize cursor  hover-text-primary transition"> UTILITY</li>
          <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
          <li [routerLink]="['/']"
              class="text-grey text-capitalize cursor  hover-text-primary transition"> Cost Center</li>
          <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
          <li class="text-grey text-capitalize cursor text-primary transition"> View Cost Center</li>
      </ul>
  </div>
    <div class="col-sm-4 text-center">
        <span class="rec-date d-block text-center">
            <div class="noWrap justify-content-around">
              <ng-container >
                <div class="cname text-bold"><span class="text-bold">Displaying all records</span>
                   
                    <button title="Custom Search" class="btn   save-as text-bold bg-whiteSmoke " 
    type="button" (click)="openAdvanceSearchModal()" >
    <img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/green-edit.svg" alt="" width="15px">
</button>
                </div>
            </ng-container>								
            </div>
        </span>
    </div>
    <div class="col-sm-4  d-flex align-item-center justify-content-end">
      <div class="page-search-box input-group  d-flex align-item-center"
      style="height: fit-content; text-align: left;">
      <input type="text" class="form-control" [(ngModel)]="onSearch" oninput="this.value = this.value.toUpperCase()"
        (ngModelChange)="this.onSearchFilter.next($event)" placeholder="search" style=" text-align: left" />
      <!-- <div class="save-button">
              <button title="Search Receipts..."
                  class="btn mr-1  save-as text-bold bg-whiteSmoke text-green dropdown-toggle" type="button"
                  (click)="openModal()">
                  <img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/purple-magnifying-glass.svg" alt="">

              </button>

          </div> -->
         <!-- <li _ngcontent-qvt-c8="" class="excel" (click)="
         exportClientAsXLSX(
         )" ><i _ngcontent-qvt-c8="" aria-hidden="true" class="fa fa-file-excel-o fa-excel"></i></li> -->
        
    </div>
    </div>
</div>

  <!-- <div class="heading-box" style="margin-bottom: 10px;">
    <div class="heading">View Client</div>
    <span class="hr-line"></span>
  </div>
  <div class="row mb-1 justify-content-end mr-1 local-search">
    <div id="custom-search-input">
      <div class="input-group col-md-12" style="height: fit-content">
        <input type="text" class="form-control" [(ngModel)]="onSearch" oninput="this.value = this.value.toUpperCase()"
          (ngModelChange)="this.onSearchFilter.next($event)" placeholder="search"
          style="margin: 2px; text-align: left" />
      </div>
    </div>
  </div> -->
  <div class="table-container" (window:resize)="onResize($event)">
    <div class="table " style="    margin-top: -5px;">
      
     

      <nz-table #clientData [nzData]="viewCostCenter"
                [nzLoading]="loader"
                [nzFrontPagination]="false"
            >

            <thead>
              <tr #tableHeader tabIndex="0">
                <th style="max-width: 270px;" class="text-left">Name</th>
                <th style="max-width: 70px;" class="text-right" style="max-width: 106px;">Action</th>
              </tr>
            </thead>

            <ng-container *ngIf="clientData && clientData.data && clientData.data.length>0 else noResult">
              <tbody 
                [ngStyle]="{height: innerHeight}" 
                class="main-panel" 
                infiniteScroll 
                (window:keydown)="onKeyDown($event)"
                [fromRoot]="fromRoot" 
                [infiniteScrollDistance]="1" 
                [infiniteScrollUpDistance]="1"
                [infiniteScrollThrottle]="200" 
                [infiniteScrollContainer]="selector" 
                (scrolled)="onScrollDown($event)"
                (scrolledUp)="onScrollUp($event)">
                <ng-container *ngFor="let item of clientData['data'] ; let i = index">
                  <tr  class="field-table-input custom-table-row" 
                  [attr.tabIndex]="i+1" 
                  id="rowIndex_{{i+1}}"
                  (keyup.enter)="open_client(item.id, item.name)">
                  <td style="max-width: 270px;" class="text-left text-grey refer font-500" (click)="moveToIndex(i)">
                  
                    <div class="d-flex flex-column">
                      <span class="font-500">{{item?.name}}</span>
                    </div>
                  </td>
                      

                  <td style="max-width: 70px;" class="text-right   ">
                    <div class="d-flex align-item-center justify-content-end">
                      <button title="Edit" class=" custom-table-btn bg-white border-0 p-0 text-bold mr-2 hoverableShadow transition cursor" type="button"
                        (click)="open_client(item.id, item.name)">
                        <img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/dark-edit-btn.svg" alt="" width="20px">
                      </button>
                    </div>
                    
                  </td>
                  </tr>
                </ng-container>
              </tbody>
            </ng-container>

            <ng-template #noResult>
              <div *ngIf="!loader" class="w-100 text-center" style="padding: 20px;">
                  <nz-empty nzNotFoundContent="No result Found"></nz-empty>
              </div>
          </ng-template>

          </nz-table>
    </div>
  </div>
</div>