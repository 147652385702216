import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CostCenterComponent } from './cost-center.component';
import { UpdateCostCenterComponent } from './update-cost-center/update-cost-center.component';
import { CreateCostCenterComponent } from './create-cost-center/create-cost-center.component';
import { ViewCostCenterComponent } from './view-cost-center/view-cost-center.component';
import { AuthGuardService } from 'src/app/shared/auth-guard.service';


const routes: Routes = [

	{
		path: '', component: CostCenterComponent, 
		children: [

			{ path: 'create-cost-center', component: CreateCostCenterComponent, canActivate:[AuthGuardService] },
			{ path: 'update-cost-center/:id', component: UpdateCostCenterComponent, canActivate:[AuthGuardService] },
			{ path: 'view-cost-center', component: ViewCostCenterComponent, canActivate:[AuthGuardService], },

			// {
			// 	path: '',
			// 	redirectTo: 'create-client',
			// 	pathMatch: 'full'
			// }
		]
	},

];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class CostCenterRoutingModule { }
