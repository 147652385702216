import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { environment } from 'src/environments/environment';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';

import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import { CookiesService } from 'src/app/shared/cookies.service';

@Component({
	selector: 'app-create-cost-center',
	templateUrl: './create-cost-center.component.html',
	styleUrls: ['./create-cost-center.component.scss']
})
export class CreateCostCenterComponent implements OnInit {

	submitted = false;
	baseUrl = environment.okountUrl;
	costCenterForm: FormGroup;
	groupList: any;
	showMessage: boolean = false;
	alertType = '';
	message: string = ``;
	countryArr: any = [];
	stateArr: any = [];
	cityArr: any = [];
	salesPersonArr: any;
	keyword = 'name';
	createdClientId = "";
	createdClientCode = "";
	newClient : boolean = true
	isTenantIndian: boolean = false;
	isTesoyBoult: boolean  = false;
	parentClients: any;
	isHQBranch: boolean = false;
	 remainingAmount = 0
    amountType = 'credit'
	vatAlternetname: string = 'VAT'

	@ViewChild('salesPersonAC',{static : true}) salesPersonAC;
	@ViewChild('confirmModal',{static:true}) confirmModal : ElementRef
	@ViewChild('closeModal',{static:true}) closeModal : ElementRef
	@ViewChild("openSuccessModal", { static: true }) openSuccessModal: ElementRef;
	@ViewChild('confirmUpdateModal',{static:true}) confirmUpdateModal : ElementRef;
    @ViewChild('closeUpdateModal',{static:true}) closeUpdateModal : ElementRef;
	branches = []
	constructor(public commonService: CommonServiceService, private fb: FormBuilder, private spinner: NgxSpinnerService, private router: Router, private cookieService : CookiesService) {
	}


	async ngOnInit() {
		this.spinner.show();
		setTimeout(() => {
			this.spinner.hide();
		}, 1000);
		this.costCenterForm = this.fb.group({
			name: ['', [Validators.required]]
		})
		
	}
	
	openConfirmModal() :  void{
		this.confirmModal.nativeElement.click()
		this.commonService.selectedInput.next(21)
	}
	closeConfirmModal() :  void{
		this.closeModal.nativeElement.click()
	}
	focusSalesPerson(e): void {
		e.stopPropagation();
		this.salesPersonAC.focus();
	}
	


	

	reset() {
		this.costCenterForm.reset();
	}

	submit() {

		this.submitted = true;
		this.closeConfirmModal()
		if (this.costCenterForm.valid) {
			let formValues = this.costCenterForm.value
			
			// console.log('form is working')
			console.log('form is working')
			// if
			this.commonService.postRequest(`${this.baseUrl}/costCenter`, this.costCenterForm.value).subscribe(res => {
				// this.updateOpeningBalanceAdjustAcc()
				this.showMessage = false
				this.createdClientId = res[0].id ? res[0].id : ""
				// this.createdClientCode = res[0].account_code ? res[0].account_code : ""
				this.reset()
				this.newClient = false
				this.alertType = 'success-box';
				this.message = 'Cost Center saved successfully '
				this.openSuccessModal.nativeElement.click()
			}, error => {
				this.showMessage = true
				this.alertType = 'error-box';
				this.createdClientId = ""
				this.message = 'Failed to create Client. Please try Again.'
				if(error.error && error.error.message=='duplicate key value violates unique constraint \"client_name_idx\"') this.message = 'CLIENT ALREADY EXIST'
			})
		}
		else {
			this.showMessage = true
			this.alertType = 'warning-box';
			this.message = 'Please fill mandatory fields.'
			return false;
		}
	}
	hideError() {
		this.showMessage = false
	}


	closeSuccesModal(){
		this.reset()
		this.newClient = true
		this.showMessage = false;
		this.alertType = 'success-box';
		this.message = ``
		this.openSuccessModal.nativeElement.click()
	}

	onChangeClient(event) {
		let value = event.target.value;
		if(value == 'child') {
			this.costCenterForm.controls.parent_account_code.setValidators(Validators.required)
			this.costCenterForm.updateValueAndValidity();
			this.commonService.getOkount(`client/parents`).subscribe(res => {
				if(res && res['data']) {
					this.parentClients = res['data']
				}
			}, err => {

			})
		}else {
			this.costCenterForm.controls.parent_account_code.setValue('')
			this.costCenterForm.controls.parent_account_code.setErrors(null)
			this.costCenterForm.controls.parent_account_code.setValidators(null)
			this.costCenterForm.updateValueAndValidity();
		}
	}

	viewClient(client_id) {
		this.closeSuccesModal()
        this.router.navigate([`/utilities/cost-center/update-cost-center/${client_id}`])
	}

	openUpdateConfirmModal(){
		this.confirmUpdateModal.nativeElement.click()
	}
	 updateOpeningBalancecombine(){
        this.closeUpdateConfirmModal()
         this.submit()
    }
	closeUpdateConfirmModal(){
		this.closeUpdateModal.nativeElement.click()
	}
	// updateOpeningBalanceAdjustAcc() {
    //     let req_body = {
    //         balance: this.remainingAmount,
    //         balance_type: this.amountType,
    //         account_code: 'OB0000165'
    //     }
    //     this.commonService.putRequest(`${environment.okountUrl}/client/updateOpeningBalanceAdjust`, req_body).subscribe(res => {
           
    //         console.log(res)
    //     }, err => {
    //     })

    // }
	 
	 
}
