
 <div *ngIf="showMessage" class="status-modal-box  transition delay scale-1" [ngClass]="{'show-modal-box': showMessage, 'hide-modal-box': !showMessage}">
  <div class="status-card transition w-100per position-fixed top-10 bg-white-color modal-box-card d-flex flex-y-center w-100per justify-space-between p-10-20 bdr-radius-10 mb-10">
    <div class="d-flex flex-y-center">
      <img
        src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/{{alertType=='error-box' ? 'red-circle-error.svg' :
          alertType=='success-box' ? 'green-sucess.svg' : 
          alertType=='warning-box' ? 'red-warning.svg' : 
          null }}"
        width="20px"
        height="20px"
        alt="alert-logo"
        class="mr-20"
      />
      <div class="ml-10">
        <h4 class="m-0 text-size-18 text-bold"
        [ngClass]="{'text-red': alertType=='error-box', 'text-orange-color': alertType=='warning-box', 'text-green' :  alertType=='success-box' }">
          {{alertType === "error-box"
          ? "Error"
          : alertType === "warning-box"
          ? "Warning"
          : alertType === "success-box"
          ? "Success"
          : null}}
        </h4>
        <small className="text-dark-grey-color text-size-12 mt-5 mb-0">
         {{message}}
        </small>
      </div>
    </div>
    <img
      src="{{commonService.aws_asset_url}}/_assets/icons/red-close.svg"
      alt="close-btn"
      (click)="hideError()"
      className=" close-btn"
    />
  </div>
</div>

<div class="ledger-container  ">
  <div class="row tab-nav pl-0 pr-0">
    <!-- <div class="col-sm-4 pl-0">
        <ul class="list-inline list-unstyled p-0 m-0">
            <li class="active page-title text-uppercase cursor"> View Client</li>
        </ul>
    </div> -->
    <div class="col-sm-12 ">
      <ul class="page-nav d-flex align-item-center list-inline list-unstyled p-0 m-0">
          <li  
              class="text-grey text-capitalize cursor  hover-text-primary transition"> Master</li>
          <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
          <li  
              class="text-grey text-capitalize cursor  hover-text-primary transition"> Cost Center</li>
          <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
          <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
          <li class="text-grey text-capitalize cursor text-primary transition"> Edit Cost Center</li>
      </ul>
  </div>
</div>



    <form [formGroup]="costCenterForm" #form="ngForm">
    <div style="display: flex; flex-flow: row;">
      <div class="custom-form form-container mt-4" style=" width:100%; max-width: 600px; margin: 0 15px;">
      

      <div class="text-input-group d-flex justify-content-between">
        <div class="text-input-field  mr-2">
          <input class="input-field" oninput="this.value = this.value.toUpperCase()" name="name" tabIndex="2"
            formControlName="name" type="text" [ngClass]="{'error-field': costCenterForm.controls.name.invalid && costCenterForm.controls.name.dirty  || (!costCenterForm.controls.name.valid && submitted),
            'success-field' : costCenterForm.controls.name.valid && costCenterForm.controls.name.dirty}" />
          <label
            [ngClass]="{'error-label': costCenterForm.controls.name.invalid && costCenterForm.controls.name.dirty || (!costCenterForm.controls.name.valid && submitted)}">Account
            <span class="text-danger">*</span>
            <ng-container
              *ngIf="costCenterForm.controls.name.invalid && costCenterForm.controls.name.dirty || (!costCenterForm.controls.name.valid && submitted)">
              <i class="fa fa-info-circle ml-2" title="Account Required" aria-hidden="true"></i>
            </ng-container>
          </label>
  
        </div>
      </div>
      
    <hr>
    <!-- new changes -->
    
    <!-- new changes -->
  </div>
      <div class="btn-field bottom-btns-container full-width-btn-container d-flex align-item-center  justify-content-end ml-0 mr-0">        
        <!-- <button type="button" class="btn-style bg-green mr-3" tabIndex="20"
          >save</button> -->
          <button type="button" class="btn-style bg-green mr-3" tabIndex="22">Reset</button>
          <button type="button" class="btn-style bg-cyan mr-3" tabIndex="23"
          *hasAccess="{'key' : 'F0001013'}"
          (click)="openUpdateConfirmModal()">Modify</button>
        
        <button type="button" class="btn-style bg-red mr-3" (click)="openDeleteConfirmModal()"
        *hasAccess="{'key' : 'F0001014'}"
          tabIndex="24">Delete</button>
        <!-- <button type="button" class="btn-style bg-purple" tabIndex="23"
          [routerLink]="['/master/setting/client', clientData.name,clientData.account_code]">Configuration</button> -->

        <!-- <button type="button" class="btn-style bg-purple" tabIndex="23"
          (click)="openConfiguration(clientData.name, clientData.account_code)">Configuration</button> -->
        <button type="button" class="btn-style bg-purple-iris mr-3" tabIndex="25"
        (click)="openConfiguration(clientData.name, clientData.account_code)">Configuration</button>
        <button type="button" class="btn-style bg-indigo mr-3" tabIndex="26" id="receipt_activity_log" (click)="openActivity()">Activity</button> 
        <!-- <button type="button" class="btn-style bg-water-blue mr-3" tabIndex="25" id="modal_btn" (click)="openCustomerPortalModal()">Customer Portal</button>  -->
        <button type="button" class="btn-style bg-cyan" tabIndex="27" id="modal_btn" (click)="openAddEditContactModal()">Add/Edit Contacts</button> 
      </div>
      <br>
    </div>
    </form>
</div>
<button type="button" hidden #confirmUpdateModal data-toggle="modal" data-target="#updateconfirmationModal"></button>
<div id="updateconfirmationModal" class="modal fade" role="dialog" data-backdrop="false">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Confirmation</h4>
        <button type="button" #closeUpdateModal class="close" data-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        Update {{costCenterForm?.value?.name}} ?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn-style mr-3" tabIndex="28" attr.tabIndex="28"
        data-dismiss="modal">Close</button>
        <button type="button" class="btn-style bg-green" (click)="updateClient()" tabIndex="29"
          attr.tabIndex="29">Yes</button>
      </div>
    </div>
  </div>
</div>

<button type="button" hidden #confirmDeleteModal data-toggle="modal" data-target="#deleteConfirmationModal"></button>

<div id="deleteConfirmationModal" class="modal fade" role="dialog" data-backdrop="false">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Confirmation</h4>
        <button type="button" #closeDeleteModal class="close" data-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        <p>Are you sure to delete ‎this entry ?</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn-style" tabIndex="30" attr.tabIndex="30" (click)="closeDeleteConfirmModal()"
        data-dismiss="modal">No</button>
        <button type="button" class="btn-style bg-red" tabIndex="31" attr.tabIndex="31" (click)="deleteClient()"
          data-dismiss="modal">Yes</button>
      </div>
    </div>
  </div>
</div>
