import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormArray ,FormGroup,FormBuilder,Validators, AbstractControl} from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { environment } from 'src/environments/environment';
import { InvoiceServicesService } from '../../flight/services/invoice-services.service';
import { MiscellaneousInvoiceService } from '../services/miscellaneous-invoice.service';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { TabService } from 'src/app/shared/services/tab.service';
import { CookiesService } from 'src/app/shared/cookies.service';
import * as FileSaver from 'file-saver'; 


@Component({
  selector: 'app-ms-invoice',
  templateUrl: './ms-invoice.component.html',
  styleUrls: ['../miscellaneous-invoice.component.scss'],
  providers: [MiscellaneousInvoiceService]
})
export class MsInvoiceComponent implements OnInit {
  @Input() data;

  @ViewChild("subLedgerAC", { static: true }) subLedgerAC;
  @ViewChild("hotelAc", { static: true }) hotelAc;
  @ViewChild("supplierAc", { static: true }) supplierAc;
  @ViewChild("supplier2Ac", { static: true }) supplier2Ac;
  @ViewChild("clientAC", { static: true }) clientAC;
  @ViewChild("generalLedgerAc", { static: true }) generalLedgerAc;
  @ViewChild("employeeAC", { static: true }) employeeAC;
  @ViewChild("corporateEmployeeAc", { static: true }) corporateEmployeeAc
  @ViewChild("narrationArea", { static: true }) narrationArea;
	@ViewChild('generateEInvoiceModal', {static: true}) generateEInvoiceModal: ElementRef 
  @ViewChild('generateAdvanceModal', {static: true}) generateAdvanceModal: ElementRef


  
  // bookingSupplierArr: any;
  bookingClientArr: any = [];
  bookingSupplierArr: any = [];
  bookingGenerlaAccountArr: any = [];
  hotelsArr: any = [];
  isHsnError : boolean = false;
  isPointOfSupplyError : boolean = false;
  isSuccess: boolean = false;
	showMessage: boolean = false;
	alertType = ''
	message: string = ``;
  checkEInvoice: boolean = false;
	isTesoyBoult: boolean  = false;
  invoiceMode = 'save'
  loggedInuser: any;
  keyword="name";
	generalAccountArr: any;
  generalFilteredData: any
  initialValue = '';
  employeeList: any;
  invoice_number: any;
  uuid: unknown;
  invoice_no: any = '';
  invoiceData: any;
  payablesArr: any = [];
  selectedClient: any;
  selectedSupplier: any;
  selectedHotel: any;
  showTravellerIndex;
  maximizedSection: boolean;
  @ViewChildren('openModal') openModal: QueryList<ElementRef>;
  @ViewChild("openSuccessModal", { static: true }) openSuccessModal;
  staticInputCount = 18
  totalInputInTravellers = 37
  newInvoice = true;
  invoice_config: any;
  companyAddress: any;
  currencyCode : string = "INR";
	nativeLanguageCode = '';
  showPrintOptions: boolean = false
  attachmentFile: File | null = null;
  discount_add: string = 'false'
  isGstNumberExist: boolean = false;
  IncomeSetOff: string = 'true'
  countryArr: any = [];
  groupList: any = [];
  dropdownSettings = {}
  menuVisible: boolean = false;
  CalculateTcs: boolean = false;

  @ViewChild('confirmDeleteModal', { static: true }) confirmDeleteModal: ElementRef;
  @ViewChild('closeDeleteModal', { static: true }) closeDeleteModal: ElementRef;
  @ViewChild('confirmUpdateModal', { static: true }) confirmUpdateModal: ElementRef
	@ViewChild('closeUpdateModal', { static: true }) closeUpdateModal: ElementRef
  @ViewChild('closeSendEmailModal') closebutton;
	@ViewChild('openEmailModal') openEmailModal;
  @ViewChild("openMasterCreationModal", { static: true }) masterCreationModal : ElementRef;

  queryParams: any;
  invoiceType: any;
  transactionType: any;
  reInitialise: any;
  navigationSubscription: any;
	shortcuts: ShortcutInput[] = [];
  currentOpenedModal = ''
  sendEmailForm:FormGroup
  minDate; 
	maxDate;
  hasAccessUpdateCode: any;
  hasAccessDeleteCode: any;
  copyELementTitle = 'Click to Copy'
  currentMaster: any;
	currentMasterName ='';
	currentFormControlName;
  selectedFormControl;
  isTenantIndian: boolean = false;
  clientConfiguration: any
  clientServiceCharge;
  isAadesh:boolean = false
	showRefundableCheckbox = false;
  isP2PEnabled = false;
  financialYearDiff = false;
  round_off_5=false
  salesCurrencyPrint: string = "false"
  corporate_employee: string= "false"
  supplier_trans: string= "false"
  isGSTInclusive: string ='false'
  vatAlternetname: string = 'VAT'
  employeeArr: any
  salesPersonArr: any
  referedByArr: any=[]
  billOfSupplyActive = false;
  
  constructor(
    private formBuilder: FormBuilder,
    private invoiceService : InvoiceServicesService,
    public miscellaneousInvoiceService : MiscellaneousInvoiceService,
    public commonService: CommonServiceService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private router: Router,
    public tabService: TabService,
    private cookiesService : CookiesService

  ) { 
		this.route.paramMap.subscribe(params => {
			this.invoice_no = params['params'].invoiceNumber
			this.invoiceType = params['params'].invoiceType
			this.transactionType = params['params'].transactionType
			this.invoiceMode = params['params'].mode ? params['params'].mode : 'save'

      this.sendEmailForm = this.formBuilder.group({
        toEmails: ["",Validators.required],
        bccEmails: [''],
        ccEmails:[''],
        subject:[''],
        body:['']
      })
		});
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
			// If it is a NavigationEnd event re-initalise the component
			if (e instanceof NavigationEnd) {
				this.initialiseInvites();
			}
		});
  }

  initialiseInvites() {
		if (this.reInitialise) {
			this.ngOnInit()
		}
	}

  ngOnDestroy() {
		if(this.navigationSubscription){
			this.navigationSubscription.unsubscribe();
		}
	}
  
  async ngOnInit(){
    let userData = JSON.parse(this.cookiesService.getCookie('SYSTACC_USER_LOGIN_DATA'))
		if(userData && userData.tenant_id && userData.tenant_id == environment.aadeshSystaccId) {
			this.isAadesh = true;
		}
       if(this.data) {
        this.invoice_no = this.data.invoice,
        this.invoiceMode = this.data.mode
        this.checkWhetherEInvGenerated(this.invoice_no)
       }
  
    this.isTenantIndian = this.commonService.checkTenantIndian()
    //  this.getRoundOffConfig()
    this.getPayableAccounts()
    // this.getBookingClient()
    this.getGeneralAccounts()
    this.getCompanyData()
    let data = {
      invoice_type : this.invoiceType,
      transaction_method : this.transactionType
    }
    this.miscellaneousInvoiceService.initialiseFormData(data,{})
    this.miscellaneousInvoiceService.initialiseTraveller()

    let empConfiguration = this.commonService.employeeConfiguration;
    if(!empConfiguration || empConfiguration.length == 0){
      let loggedInUser = JSON.parse(localStorage.okountUserInfo);
      empConfiguration = await this.commonService.getConfiguration(loggedInUser.accountcode)
    }

    let globalSettingConfig = this.commonService.globalSettingConfig;
		if(!globalSettingConfig || globalSettingConfig.length == 0) {
			globalSettingConfig = await this.commonService.getGlobalSettings();
		}
    let transaction_config = globalSettingConfig.filter(el=>el.group=='TRANSACTION')
		if(transaction_config && transaction_config.length>0){
			console.log("transaction_config[0].json_doc",transaction_config[0].json_doc["value"])
			let currency_config = transaction_config[0].json_doc["value"].filter(el=>el.key=='multi_currency_sale')
			if(currency_config && currency_config.length){
				this.salesCurrencyPrint = currency_config[0].value
			}

      let set_off_config = transaction_config[0].json_doc["value"].filter(el=>el.key=='income_set_off')
			if(set_off_config && set_off_config.length){
				this.IncomeSetOff = set_off_config[0].value
			}
    
        let discount_config = transaction_config[0].json_doc.value.filter(el=>el.key=='DISCOUNT')
        if(discount_config.length){
          this.discount_add = discount_config[0].value
        }
      
      let inv_config = transaction_config[0].json_doc["value"].filter(el=>el.key=='corporate_employee')
      if(inv_config.length){
				this.corporate_employee = inv_config[0].value
			}
      let payable_config = transaction_config[0].json_doc["value"].filter(el=>el.key=='PAYABLE TRANSACTION')
			if(payable_config && payable_config.length){
				this.supplier_trans = payable_config[0].value
			}
      let gst_config = transaction_config[0].json_doc["value"].filter(el=>el.key=='GST_Inclusive_Service_Charge')
      if(gst_config && gst_config.length){
          this.isGSTInclusive = gst_config[0].value
      }
		}
    if(!this.isTenantIndian){
			let vat_value = globalSettingConfig.filter(el=>el.group=='VAT')
		if(vat_value.length){
			let vat_config = vat_value[0].json_doc.value.filter(el=>el.key=='vat_rate')
			let vat_detail,vat_name
			if(vat_config.length){
				 vat_detail = vat_config[0].value
			}
			if(vat_detail && vat_detail.length ){
				vat_name = vat_detail.filter(el=>el.key=='effective_name')
			}
			if(vat_name && vat_name.length){
				this.vatAlternetname = vat_name[0].value
			}
			console.log("vatAlternetname",this.vatAlternetname)

		}
		}

    this.invoiceDateValidation();

    if(this.invoiceMode == 'save' && this.invoice_no != undefined){
      this.getInvoiceData(this.invoice_no)
    }

     if(this.invoiceMode == 'save'){
      let loggedInUser = JSON.parse(localStorage.okountUserInfo);
      this.loggedInuser = loggedInUser
      this.miscellaneousInvoiceService.invoiceForm.controls.employee_name.setValue(
        loggedInUser.name
      );
      this.miscellaneousInvoiceService.invoiceForm.controls.employee.setValue(
        loggedInUser.accountcode
      );
      
     } else {
       if (this.invoiceMode == 'view' && this.invoice_no != undefined) {
         this.getMiscellineousInvoiceData(this.invoice_no)
       }
     }
        this.getAccountingCompany()
    
    this.miscellaneousInvoiceService.invoiceForm.controls.general_ledger_code.setValidators([Validators.required])
    this.miscellaneousInvoiceService.invoiceForm.controls.general_ledger_code_name.setValidators([Validators.required])

    this.ledgerSearch(this.miscellaneousInvoiceService.invoiceForm.value.subledger_code ? this.miscellaneousInvoiceService.invoiceForm.value.subledger_code :'',true)
    if (this.invoiceMode != 'view') {
      if(this.isAadesh==true){
        this.invoice_config = this.miscellaneousInvoiceService.invoice_config[this.invoiceType] ? this.miscellaneousInvoiceService.invoice_config[this.invoiceType] : null
      }else{
        this.invoice_config = this.miscellaneousInvoiceService.updated_invoice_config[this.invoiceType] ? this.miscellaneousInvoiceService.updated_invoice_config[this.invoiceType] : null
      }
      if(this.invoice_config){
        this.generalSearch(this.invoice_config.general_ledger,true,true,true)
      }
			var url = window.location.href.slice(window.location.href.indexOf('?') + 1);
			this.getuuid(url)
      let queryParams = this.route.queryParams['value']
      if(queryParams){
        this.queryParams = queryParams
      }
      if(this.queryParams && this.queryParams['flight_invoice']){
        this.getFlightInvoiceData(this.queryParams['flight_invoice'])
      }
		}

    this.showRefundableCheckbox = this.commonService.checkCreditNoteNonRefundableOrNot()
    this.isP2PEnabled = this.commonService.isP2PEnabled();
    
    this.reInitialise = true
    this.handleInvoiceForChange(this.invoiceType)
    setTimeout(() => {
      this.commonService.selectedInput.next(4)
    }, 100);
   
    // this.salesPersonSearch()
    if(this.invoiceType=='visa'){
      this.getCountries()
    }
    // this.costCenterSearch()
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'location_id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 2,
      allowSearchFilter: true
    };

  }
  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }

  forceReload() {
		this.ngOnInit();
	}

  async initDateValidation (){
		try {
      this.minDate = moment().subtract(15, 'days').format('YYYY-MM-DD')
      this.maxDate = moment().format('YYYY-MM-DD')
      
			let config = this.commonService.getConfigurationByKey(this.invoiceType)
      
			if(config){
				let dateConfig = config['value'].filter(data => data.key == `minimum_${this.invoiceMode == 'view' ? 'update' : 'creation'}_date`)
				let daysConfig = config['value'].filter(data => data.key == `minimum_${this.invoiceMode == 'view' ? 'update' : 'creation'}_days`)
				let dateWithMinDate = moment(dateConfig ? dateConfig[0].value : '')
        let dateWithMinDays = dateConfig && daysConfig[0].value ? moment().subtract(Number(daysConfig[0].value), 'days') : dateWithMinDate
				if(dateWithMinDays.isBefore(dateWithMinDate)){
					this.minDate = dateWithMinDate.format('YYYY-MM-DD')
				}else{
					this.minDate = dateWithMinDays.format('YYYY-MM-DD')
				}
			}
		} catch (error) {
			
		}
    this.miscellaneousInvoiceService.invoiceForm.controls.invoice_date.setValidators([Validators.required,this.validateDate()])
		this.miscellaneousInvoiceService.invoiceForm.controls.invoice_date.updateValueAndValidity()
  }

  validateDate(){
		return (control: AbstractControl) => {
			let valid = true
			if(this){
				if (moment(control.value).isBefore(this.minDate) || moment(control.value).isAfter(this.maxDate)) {
					if(this.invoiceMode == 'view' && this.invoiceData && this.invoiceData.invoice_date && moment(control.value).isSame(moment(this.invoiceData.invoice_date))){
            valid = true
          }else{
            valid = false
          }
				}
			}
			return !valid ? {invoice_date: {value: control.value}} : null;
		};
	}

  ngAfterViewInit(){

		this.shortcuts.push(			
			{
				key: "alt + s",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Save mis Entry",
				description: "Save mis Entry",
				
				command: (e)=>{
				console.log(e)	
			if(document.getElementById("save_mis_invoice_btn")){

				document.getElementById("save_mis_invoice_btn").click()
				setTimeout(() => {
				document.getElementsByTagName('body')[0].click()
				}, 100);
			}
					},
					preventDefault: true
			},
			{
				key: "alt + e",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Update Mis Entry",
				description: "Update Mis Entry",
				
				command: (e)=>{
				console.log(e)	
			if(document.getElementById("update_mis_invoice_btn")){

				document.getElementById("update_mis_invoice_btn").click()
				setTimeout(() => {
				document.getElementsByTagName('body')[0].click()
				}, 100);
			}
					},
					preventDefault: true
			},
			{
				key: "alt + d",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Delete Mis Entry",
				description: "Delete Mis Entry",
				
				command: (e)=>{
				console.log(e)	
			if(document.getElementById("delete_mis_invoice_btn")){

				document.getElementById("delete_mis_invoice_btn").click()
				setTimeout(() => {
				document.getElementsByTagName('body')[0].click()
				}, 100);
			}
					},
					preventDefault: true
			},
      {
				key: "alt + j",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Open Journal",
				description: "Open Journal",
				
				command: (e)=>{
				console.log(e)	
			if(document.getElementById("journal_ms_invoice_btn")){
				document.getElementById("journal_ms_invoice_btn").click()
				setTimeout(() => {
				document.getElementsByTagName('body')[0].click()
				}, 100);
			}
					},
					preventDefault: true
			},
      {
				key: "alt + plus",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Add New Row",
				description: "Add New Row",
				
				command: (e)=>{
        let updatedCount = this.miscellaneousInvoiceService.invoiceForm.value.number_of_pax+1
        this.miscellaneousInvoiceService.invoiceForm.controls.number_of_pax.setValue(updatedCount)
        this.miscellaneousInvoiceService.travellerCountUpdate(updatedCount)
        setTimeout(() => {
          document.getElementsByTagName('body')[0].click()
          }, 100);
					},
					preventDefault: true
			},
      {
				key: "alt + -",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Remove Last Row",
				description: "Remove Last Row",
				
				command: (e)=>{
          if(this.miscellaneousInvoiceService.invoiceForm.value.number_of_pax>0){
           let updatedCount = this.miscellaneousInvoiceService.invoiceForm.value.number_of_pax-1
           this.miscellaneousInvoiceService.invoiceForm.controls.number_of_pax.setValue(updatedCount)
            this.miscellaneousInvoiceService.removeTraveller(updatedCount)
          }
          setTimeout(() => {
            document.getElementsByTagName('body')[0].click()
            }, 100);
					},
					preventDefault: true
			},
      {
				key: "alt + y",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Clicked On Yes Button",
				description: "Clicked On Yes Button",
				
				command: (e)=>{
				if(this.currentOpenedModal=='update'){
					this.updateProduct()
					setTimeout(() => {
						document.getElementsByTagName('body')[0].click()
						}, 100);
				}else if(this.currentOpenedModal=='delete'){
					this.deleteInvoice(this.invoice_no)
					setTimeout(() => {
						document.getElementsByTagName('body')[0].click()
						}, 100);
				}
				this.currentOpenedModal = ''
					},
					preventDefault: true
			},
			{
				key: "alt + n",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Clicked On No Button",
				description: "Clicked On No Button",
				
				command: (e)=>{
				if(this.currentOpenedModal=='update'){
					this.closeUpdateConfirmModal()
					setTimeout(() => {
						document.getElementsByTagName('body')[0].click()
						}, 100);
				}else if(this.currentOpenedModal=='delete'){
					this.closeDeleteConfirmModal()
					setTimeout(() => {
						document.getElementsByTagName('body')[0].click()
						}, 100);
				}
				this.currentOpenedModal = ''
					},
					preventDefault: true
			},
      {
				key: ["alt + 1","alt + 2","alt + 3","alt + 4","alt + 5","alt + 6","alt + 7","alt + 8","alt + 9"],
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Edit  Traveller",
				description: "Edit  Traveller",
				
				command: (e)=>{
				   if(document.getElementById(`edit_traveller_${Number(e.event.key)-1}_btn`)){
					document.getElementById(`edit_traveller_${Number(e.event.key)-1}_btn`).click()
				   }
           setTimeout(() => {
						document.getElementsByTagName('body')[0].click()
						}, 100);
					},
					preventDefault: true
					
			},
      {
				key: "f10",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Send mail",
				description: "Send mail",
				command: (e)=>{
				console.log(e)	
				if(document.getElementById('mis_invoice_send_mail_btn') && this.invoiceMode=='view'){
					document.getElementById('mis_invoice_send_mail_btn').click()
					setTimeout(() => {
					document.getElementsByTagName('body')[0].click()
					}, 100);
				}
			},
					preventDefault: true
			},
      
	  )
	}
  

  getFlightInvoiceData(searchId: any, type: any = 'flight') {
		try {
			let url = `invoice?invoiceNumber=${searchId}&type=${type}&parseData=ms-invoice`
			this.invoiceService.getMethod(url).subscribe(async (res) => {
				let invoiceData = res['data'][0];
        let resp = await this.miscellaneousInvoiceService.bindInvoiceData(invoiceData,this.invoiceMode)
        let invoiceType = invoiceData.products[0].invoice_type;
          this.staticInputCount = this.calculateStaticCount(invoiceType);

          this.clientSearch(invoiceData.products[0].client_code,false,false)
          this.supplierSearch(invoiceData.products[0].supplier_code,false,false)
          this.generalSearch(invoiceData.products[0].general_ledger_code,true,true,false)
			},
				err => {
					this.spinner.hide()
					console.log(err)
				})
		} catch (error) {
			console.log('Error occured in getInvoice due to : ', error)
		}
	}
  getCompanyData() {
    return new Promise((resolve, reject) => {
			this.invoiceService.getMethod(`setting/address`).subscribe(
				res => {
					this.companyAddress = res
          resolve(res)
				},
				err => {
					reject({});
				}
			);
		});
  }

  async getuuid(url) {
		if (!url.includes('invoice_number') && this.invoiceMode != 'view') {
			this.uuid = await this.getInvoice()
			this.miscellaneousInvoiceService.invoiceForm.controls.invoice_number.setValue(this.uuid)
			window.history.replaceState({}, document.title, `/${window.location.hash}?invoice_number=${this.uuid}`);
		} else {
			if (url.split('=')[1] != undefined) {
				let invNo = url.split('=')[1]
				this.miscellaneousInvoiceService.invoiceForm.controls.invoice_number.setValue(invNo)
			}
		}
	}

  getInvoice() {
		return new Promise((resolve, reject) => {
			this.invoiceService.getMethod(`invoice/getuuid`).subscribe(
				res => {
					let invoiceNo;
					try {
						invoiceNo = res["uuid"]
						resolve(invoiceNo);
					} catch (error) {
						invoiceNo = res
						resolve(invoiceNo);
					}
				},
				err => {
					reject("");
				}
			);
		});
	}

  getMiscellineousInvoiceData(searchId: any) {
		try {
			let url = `invoice/miscellaneous/${searchId}`
      this.spinner.show()
			this.invoiceService.getMethod(url).subscribe(async (res) => {
				let invoiceData = res['data'][0];
        
        if(invoiceData && invoiceData.accounting_company) {
          if(invoiceData['accounting_company'].currency_code) {
            this.currencyCode = invoiceData['accounting_company'].currency_code;
          }
          if(invoiceData['accounting_company'].native_language_code && invoiceData['accounting_company'].currency_code == 'AED') {
            this.nativeLanguageCode = invoiceData['accounting_company'].native_language_code;
          }
      }

      if(invoiceData.tcs_rate) this.CalculateTcs=true
        
        if(invoiceData.product_type == 'gst'){
          this.spinner.hide()
          this.router.navigate([`/transactions/ms/gst-invoice/view/${searchId}`])
        }else{
          this.invoiceData = res['data'][0];
          this.invoiceType = this.invoiceData.products[0].invoice_type
          if(this.invoiceType=='visa'){
            this.getCountries()
          }
          // this.handleInvoiceForChange(this.invoiceData.products[0].invoice_type)
          let resp = await this.miscellaneousInvoiceService.bindInvoiceData(invoiceData,this.invoiceMode)
          // this.initDateValidation()

          this.staticInputCount = this.calculateStaticCount(this.invoiceType);

          this.clientSearch(this.invoiceData.products[0].client_code,false,false);
          
          try {
            if(this.invoiceMode == 'view' && this.invoiceData.bill_of_supply == false && !this.invoiceData.bos_reference) {
              let clientConfiguration:any = await this.commonService.getClientConfiguration(this.invoiceData.products[0].client_code);
              
              if(clientConfiguration && clientConfiguration.length > 0) {
                let bos = clientConfiguration.find(item => item.key == 'enable_bill_of_supply')
                if(bos && bos.value == 'TRUE') {
                  this.billOfSupplyActive = true;
                }
              }
            } else {
              this.billOfSupplyActive = false
            }
          }catch(error) {
            console.log("error", error);
          }
         
          this.supplierSearch(this.invoiceData.products[0].supplier_code,false,false)
          this.generalSearch(this.invoiceData.products[0].general_ledger_code,true,false,false)
          // this.ledgerSearch()
          if(this.invoiceData.products[0].hotel){
            this.hotelSearch(this.invoiceData.products[0].hotel, false);
          }
          this.spinner.hide()
        }

        if(this.invoiceMode == 'view'){
          if(this.invoiceType == 'hotel') {
            if(this.miscellaneousInvoiceService.invoiceForm.value.transaction_method == 'sales') {
              this.hasAccessUpdateCode = 'F0002022'
              this.hasAccessDeleteCode = 'F0002023'
            }else {
              this.hasAccessUpdateCode = 'F0003022'
              this.hasAccessDeleteCode = 'F0003023'
            }
          }else if(this.invoiceType == 'insurance'){
            if(this.miscellaneousInvoiceService.invoiceForm.value.transaction_method == 'sales') {
              this.hasAccessUpdateCode = 'F0002032'
              this.hasAccessDeleteCode = 'F0002033'
            }else {
              this.hasAccessUpdateCode = 'F0003032'
              this.hasAccessDeleteCode = 'F0003033'
            }
          }else {
            if(this.miscellaneousInvoiceService.invoiceForm.value.transaction_method == 'sales') {
              this.hasAccessUpdateCode = 'F0002042'
              this.hasAccessDeleteCode = 'F0002043'
            }else {
              this.hasAccessUpdateCode = 'F0003042'
              this.hasAccessDeleteCode = 'F0003043'
            }
          }
        }			

        
			},
				err => {
					this.spinner.hide()
					console.log(err)
				})
		} catch (error) {
      this.spinner.hide()
			console.log('Error occured in getInvoice due to : ', error)
		}
	}
  // getBookingClient() {
	// 	try {
	// 		this.invoiceService.searchAccount(`client`).subscribe(data => {
	// 			this.bookingClientArr = data
	// 		}, err => {
	// 			throw new Error(JSON.stringify(err));
	// 		})
	// 	} catch (error) {
	// 		console.log('Error while getting Booking Client : ', error)

	// 	}
	// }
  getPayableAccounts() {
		try {
			this.invoiceService.searchAccount(`util/getsupplierairline`).subscribe(data => {
				this.payablesArr = data
			}, err => {
				throw new Error(JSON.stringify(err));
			})
		} catch (error) {
			console.log('Error while getting Booking Suppliers : ', error)

		}
	}

  // getRoundOffConfig(){
  //   try {
	// 		this.invoiceService.getMethod(`global_settings/rail_invoice`).subscribe((res: any) => {
	// 			this.round_off_5 = res
	// 		}, err => {
	// 			throw new Error(JSON.stringify(err));
	// 		})
	// 	} catch (error) {
	// 		console.log('Error while getting round off config : ', error)

	// 	}
  // }

	getGeneralAccounts() {
		try {
			this.invoiceService.getMethod(`general/bank-od-cc`).subscribe(data => {
				this.generalAccountArr = data
				this.generalFilteredData = data;
			}, err => {
				this.generalAccountArr = []
				this.generalFilteredData = []
				throw new Error(JSON.stringify(err));
			})
		} catch (error) {
			console.log('Error while getting Booking Suppliers : ', error)
		}
	}

  selectGeneralAccount(event, currentIndex){
		if(event && event.nzValue) {
			let value = event.nzValue;
			if(value.name) {
				this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[currentIndex].controls.ledger_card_name.setValue(value.name)
			}
			if(value.account_code) {
				this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[currentIndex].controls.ledger_card_no.setValue(value.account_code)
			}
		}
	}

  	onChangeSearch(value) {
    		// this.bookingSupplierArr =  this.bookingSupplierArrBase.map(item => (item.account_code.includes(value) || item.name.includes(value)) );
    		
    	}
    
    	async selectAffiliateEvent(data){
    	}
    
    	async selectEvent(data) {
    	}
    
    	onFocused(e) {
    		
    	}

  hideError() {
    this.showMessage = false
  }

  closeMaxModule() {
    this.commonService.minimizeModule(true);
  }

  async selectAccount(value, formControlName, index, getMaster) {
    if(value && value.account_code) {
      try {
        if(formControlName == 'ledger_card_no') {
          this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[index].controls.ledger_card_no.setValue(value.account_code ? value.account_code : '')
          this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[index].controls.ledger_card_name.setValue(value.name ? value.name : '')
          return;
        }
        if(formControlName == 'payable_code') {
          this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[index].controls.payable_code.setValue(value.account_code ? value.account_code : '')
          this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[index].controls.payable_account_name.setValue(value.name ? value.name : '')
        
          let payableAccountCode = this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[index].controls.payable_code.value
          if(payableAccountCode && getMaster) this.getMasterConfiguration(payableAccountCode, index, 'payable')
          return;
        }
        this.miscellaneousInvoiceService.invoiceForm.controls[formControlName].setValue(
          value && value.account_code ? value.account_code : ''
        );
        this.miscellaneousInvoiceService.invoiceForm.controls[`${formControlName}_name`].setValue(
          value && value.name ? value.name : ''
        );
        if(formControlName == 'client_code'){
          if(this.invoiceMode != 'view') {
            this.clientConfiguration = await this.commonService.getClientConfiguration(value.account_code)
            this.calculateServiceChargeClientWise()
          }
          this.selectedClient = value
          if(value && value.account_code && getMaster){
            this.getMasterConfiguration(value.account_code ? value.account_code : '', null, 'client')
            this.miscellaneousInvoiceService.calculateFromApi(0)
          }
          this.calcuateAllSC()
        }

        if(formControlName == 'hotel'){
          this.selectedHotel = value;
        }
        if(formControlName == 'supplier_code'){
          this.selectedSupplier = value
          this.calcuateAllSC()
        }
      } catch (error) {}
    }
    else {
      
      if(value && value.name && value.name.toLowerCase() == 'create new' && this.currentMasterName ) {
        this.openModalMasterCreation(formControlName)
      }
    }
  }
  

  clientSearch(value,withStatus,getMaster) {
    try {
      this.currentMasterName = value;
      let searchType = this.miscellaneousInvoiceService.invoiceForm.controls.client_account_type.value;
      if (searchType == null) {
        this.miscellaneousInvoiceService.invoiceForm.controls.client_account_type.setValue(
          "client"
        );
        searchType = this.miscellaneousInvoiceService.invoiceForm.controls.client_account_type.value;
      }
      if (value.length > 2 && searchType != null && searchType != "") {
        let status = ''
        if(withStatus){
          status = '&status=active'
        }
        this.invoiceService
          .searchAccount(
            `${searchType}/autocomplete?name_like=${value.toUpperCase()}${status}`
          )
          .subscribe(
            (res) => {
              this.bookingClientArr = res;
              if(!this.bookingClientArr || this.bookingClientArr.length == 0) {
                this.bookingClientArr.push({name: 'Create New'})
              }
            
              if(this.bookingClientArr[0]  && (!withStatus && !getMaster)){
                if(this.invoiceMode == 'view'){
                  this.selectAccount(this.bookingClientArr.find(el=>el.account_code==value),'client_code', null, getMaster)
                }else{
                  this.selectAccount(this.bookingClientArr[0],'client_code', null, getMaster)
                }
              }
            },
            (error) => {}
          );
      }else {
        this.bookingClientArr = [];
      }
    } catch (error) {
      console.error(error)
    }
  }

  generalSearch(value,isSelect,withStatus,getMaster) {
    try {
     
      this.currentMasterName = value;

      if (value.length > 2) {
        let status = '';
        if(withStatus){
          status = '&status=active'
        }
        this.invoiceService
          .searchAccount(
            `general/autocomplete?name_like=${value.toUpperCase()}${status}`
          )
          .subscribe(
            (res) => {
              this.bookingGenerlaAccountArr = res;
              if(!this.bookingGenerlaAccountArr || this.bookingGenerlaAccountArr.length == 0) {
                this.bookingGenerlaAccountArr.push({name: 'Create New'})
              }
              if(this.bookingGenerlaAccountArr[0] && isSelect){
                this.selectAccount(this.bookingGenerlaAccountArr[0],'general_ledger_code', null, getMaster)
              }
            },
            (error) => {}
          );
      } else {
        this.bookingGenerlaAccountArr = [];
      }
    } catch (error) {
      console.error(error)
    }
  }

  supplierSearch(value,withStatus, getMaster) {
    try {
      let searchType = this.miscellaneousInvoiceService.invoiceForm.controls.supplier_account_type.value;
      if (searchType == null) {
        this.miscellaneousInvoiceService.invoiceForm.controls.supplier_account_type.setValue(
          "supplier"
        );
        searchType = this.miscellaneousInvoiceService.invoiceForm.controls.supplier_account_type.value;
      }
      if (value.length > 2 && searchType != null && searchType != "") {
        this.currentMasterName = value;
        let status = '';
        if(withStatus){
          status = '&status=active'
        }
        this.invoiceService
          .searchAccount(
            `${searchType}/autocomplete?name_like=${value.toUpperCase()}${status}`
          )
          .subscribe(
            (res) => {
              this.bookingSupplierArr = res;
              if(!this.bookingSupplierArr || this.bookingSupplierArr.length == 0) {
                this.bookingSupplierArr.push({name: 'Create New'})
              }
              if(this.bookingSupplierArr[0] && this.invoiceMode == 'view' && ( !getMaster)){
                if(this.invoiceMode == 'view'){
                  this.selectAccount(this.bookingSupplierArr.find(el=>el.account_code==value),'supplier_code', null, getMaster)
                }else{
                this.selectAccount(this.bookingSupplierArr[0],'supplier_code', null, getMaster)
                }
              }
            },
            (error) => {}
          );
      }else {
        this.bookingSupplierArr = []
      }
    } catch (error) {}
  }

  ledgerSearch(value,getMaster) {
    try {
      let searchType = this.miscellaneousInvoiceService.invoiceForm.controls
        .subledger_type.value;
      if (searchType == null) {
        this.miscellaneousInvoiceService.invoiceForm.controls.subledger_type.setValue(
          "client"
        );
        searchType = this.miscellaneousInvoiceService.invoiceForm.controls
          .subledger_type.value;
      }
      if (value.length > 2 && searchType != null && searchType != "") {
        let status = '';
        if(this.invoiceMode != 'view'){
          status = '&status=active'
        }
        this.invoiceService
          .searchAccount(
            `${searchType}/autocomplete?name_like=${value.toUpperCase()}${status}`
          )
          .subscribe(
            (res) => {
              this.miscellaneousInvoiceService["ledgerList"] = res;
              if (value == "CC001A" || value == "CC001B") {
                this.selectAccount(res[0], "subledger_code",null, getMaster);
                this.miscellaneousInvoiceService.invoiceForm.controls.subledger_code_name.setValue(
                  res[0].name
                );
              }
              if(this.invoiceMode == 'view') {
                this.selectAccount(res[0], "subledger_code", null, getMaster);
              }
            },
            (error) => {}
          );
      }
    } catch (error) {}
  }

  hotelSearch(value, getMaster) {
    try {

      this.currentMasterName = value;

      if (value.length > 2) {
        let status = '';
        if(this.invoiceMode != 'view'){
          status = '&status=active'
        }
        this.invoiceService
          .searchAccount(
            `hotel/autocomplete?name_like=${value.toUpperCase()}${status}`
          )
          .subscribe(
            (res) => {
              this.hotelsArr = res;
              if(!this.hotelsArr || this.hotelsArr.length == 0) {
                this.hotelsArr.push({name: 'Create New'})
              }

              if(this.hotelsArr[0] && this.invoiceMode == 'view' && (!getMaster)){
                this.selectAccount(this.hotelsArr[0],'hotel', null, getMaster)
              }
            },
            (error) => {}
          );
      }
    } catch (error) {}
  }

  employeeSearch(value) {
    try {
      if (value.length > 2) {
        this.invoiceService
          .searchAccount(
            `employee/autocomplete?name_like=${value.toUpperCase()}`
          )
          .subscribe(
            (res) => {
              this.employeeList = res;
            },
            (error) => {}
          );
      }
    } catch (error) {}
  }

  salesPersonSearch(event) {
    try {
        this.invoiceService
          .searchAccount(
            `user/salesPerson?names_like=${event.target.value||''}`
          )
          .subscribe(
            (res) => {
              this.salesPersonArr = res;
            },
            (error) => {}
          );
    } catch (error) {}
  }
  referedBySearch(event) {
    try {
        this.invoiceService
          .searchAccount(
            `employee/autocomplete?name_like=${event.target.value||''}`
          )
          .subscribe(
            (res) => {
              this.referedByArr = res;
            },
            (error) => {}
          );
    } catch (error) {}
  }

  selectSalesPerson(nzEvent, formControlName) {
    let nzValue = nzEvent.nzValue;
    let value = nzValue;
      try {
        if(formControlName == 'sales_person_name') {
          this.miscellaneousInvoiceService.invoiceForm.controls.sales_person_name.setValue(value.t_user_name)
          this.miscellaneousInvoiceService.invoiceForm.controls.sales_person_code.setValue(value.user_code)
  return;
        }
        
      } catch (error) {}
  }

  selectReferedBy(nzEvent, formControlName) {
    let nzValue = nzEvent.nzValue;
    let value = nzValue;
      try {
        if(formControlName == 'refered_by_name') {
          this.miscellaneousInvoiceService.invoiceForm.controls.refered_by_name.setValue(value.name)
          this.miscellaneousInvoiceService.invoiceForm.controls.refered_by_code.setValue(value.account_code)
  return;
        }
        
      } catch (error) {}
  }

  selectCostCenter(nzEvent, formControlName) {
    let nzValue = nzEvent.nzValue;
    let value = nzValue;
      try {
        if(formControlName == 'invoice_cost_center') {
          this.miscellaneousInvoiceService.invoiceForm.controls['invoice_cost_center'].setValue(value.name)
          return;
        }
        
      } catch (error) {}
  }

  // selectSalesPerson(event,currentIndex) {
  //   const selectedOption = (event.target as HTMLSelectElement).value;
  //   const value = JSON.parse(selectedOption);
	// 		if(value.t_user_name) {
  //       this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[currentIndex].controls.sales_person_name.setValue(value.t_user_name)
	// 		}
	// 		if(value.user_code) {
	// 			this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[currentIndex].controls.sales_person_code.setValue(value.user_code)
	// 		}
		
  // }
  // onFocused(e) {}


  async saveProductInvoice() {
    if(this.commonService.gstFiledDate) {
			if(moment(this.commonService.gstFiledDate).isSameOrAfter(moment(this.miscellaneousInvoiceService.invoiceForm.controls.invoice_date.value).format('YYYY-MM-DD'))) {
				this.showMessage = true
				this.spinner.hide()
				this.alertType = "error-box"
				this.message = 'Not Allowed to create the invoice.'
				return false;
			}
		}

    if(this.miscellaneousInvoiceService.invoiceForm.value.bill_of_supply === true) {
      let checkBosApplicableOrNot = this.checkBosApplicableOrNot(this.miscellaneousInvoiceService.travellerForm.value)
      if(!checkBosApplicableOrNot || this.miscellaneousInvoiceService.invoiceForm.value.is_p2p === true) {
          this.showMessage = true
          this.spinner.hide()
          this.alertType = "error-box"
          this.message = 'Bill of supply Not Applicable.'
          return false;
      }
    }
    
    
    !this.financialYearDiff && await this.initDateValidation();
    if(this.isGstNumberExist){
    await this.checkHsn(this.miscellaneousInvoiceService.travellerForm.value)
      if(this.isHsnError==true){ 
        window.scroll(0,0)
        this.showMessage = true;
        this.isSuccess = false
        this.invoice_number = ''
        this.alertType = 'error-box';
        this.message = `Enter Hsn Code for all Service Charges`
        this.spinner.hide()
        return false
      }
				const res = await this.invoiceService.getMethod(`client/autocomplete?name_like=${this.selectedClient.account_code.toUpperCase()}&status=active&client_type=all`).toPromise()
      // const res = await this.invoiceService.getMethod(`client?client_code=${this.selectedClient.account_code.toUpperCase()}&status=active`).toPromise()
          console.log("res",res)
          if(!res[0]["state_id"] || res[0]["state_id"] ==0 || !res[0]["state_name"]) {
            window.scroll(0,0)
            this.showMessage = true;
            this.isSuccess = false
            this.invoice_number = ''
            this.alertType = 'error-box';
            this.message = `Update Client With State Code`
            this.spinner.hide()
            return false
          }
          }
      
    
    if (this.invoiceMode == "view") {
      this.updateProduct();
    } else {
      const invalid = [];
      const controls = this.miscellaneousInvoiceService.invoiceForm.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          invalid.push(name);
        }
      }
      if(invalid.length > 0) {
        window.scroll(0,0)
        this.showMessage = true;
        this.isSuccess = false
        this.invoice_number = ''
        this.alertType = 'error-box';
        this.message = `Invalid field ${invalid[0]} ${invalid.length > 1 ? `+${invalid.length-1}` : ''}`
        this.spinner.hide()
        return false
      }
      this.spinner.show()

      let product_json_doc = this.miscellaneousInvoiceService.createMsProduct(
        this.miscellaneousInvoiceService.invoiceForm.value,this.miscellaneousInvoiceService.travellerForm.value
      );
      if(this.invoiceMode != 'view' && this.miscellaneousInvoiceService.invoiceForm.value.bill_of_supply === true) { 
        let billOfSupplyRequest = this.miscellaneousInvoiceService.createMsProductForBillSupply(
          this.miscellaneousInvoiceService.invoiceForm.value,this.miscellaneousInvoiceService.travellerForm.value
        );
        product_json_doc['billOfSupplyRequest'] = billOfSupplyRequest;
      } 

      this.miscellaneousInvoiceService.postMethod(`invoice/miscellaneous`,product_json_doc).subscribe(async (data) => {
      // this.miscellaneousInvoiceService.postMethod(``,product_json_doc).subscribe(async (data) => {
        window.scroll(0, 0)
        // this.showMessage = true;
        this.isSuccess = true
        this.alertType = 'success-box';
        this.message = `Invoice Saved with invoice no. : `
        this.invoice_number = data['invoice_number']
        
        let defaultObj = {
          transaction_method : this.miscellaneousInvoiceService.invoiceForm.controls.transaction_method.value
        }

        this.miscellaneousInvoiceService.initialiseFormData(defaultObj,{})
        let loggedInUser = JSON.parse(localStorage.okountUserInfo);
        this.loggedInuser = loggedInUser
        this.miscellaneousInvoiceService.invoiceForm.controls.employee_name.setValue(
          loggedInUser.name
        );
        this.miscellaneousInvoiceService.invoiceForm.controls.employee.setValue(
          loggedInUser.accountcode
        );
        this.miscellaneousInvoiceService.travellerList.controls = [];
        this.miscellaneousInvoiceService.addTraveller({})
        let obj = {
          invoice_no: 'New Invoice',
        };
        this.miscellaneousInvoiceService.fareList = []
        this.miscellaneousInvoiceService.fareList.push(obj);
        this.miscellaneousInvoiceService.calculateFromApi(0)
        this.uuid = await this.getInvoice()

        this.miscellaneousInvoiceService.invoiceForm.controls.invoice_number.setValue(this.uuid)
        let location =window.location.hash.split("?")[0].replace('#/','')

        this.router.navigateByUrl(`${location}?invoice_number=${this.uuid}`)
        // window.history.replaceState({}, document.title, `/${location}?invoice_number=${this.uuid}`);
        this.spinner.hide()
        this.newInvoice = false;
        this.openSuccessModal.nativeElement.click()
        setTimeout(() => {
          this.commonService.selectedInput.next(1)
        }, 100);
      },err=> {
        window.scroll(0, 0)
        this.showMessage = true;
        this.isSuccess = false
        this.invoice_number = ''
        this.alertType = 'error-box';
        this.message = err['error']['message']
        this.spinner.hide()
      })
      
    }
  }

  async updateProduct() {
   
    !this.financialYearDiff && this.initDateValidation();
    const invalid = [];
      const controls = this.miscellaneousInvoiceService.invoiceForm.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          invalid.push(name);
        }
      }
      if(invalid.length > 0) {
        window.scroll(0,0)
        this.showMessage = true;
        this.isSuccess = false
        this.invoice_number = ''
        this.alertType = 'error-box';
        this.message = `Invalid field ${invalid[0]} ${invalid.length > 1 ? `+${invalid.length-1}` : ''}`
        this.spinner.hide()
        this.closeUpdateConfirmModal()
        return false
    }
    this.closeUpdateConfirmModal()
    this.spinner.show()
    if(this.isGstNumberExist){
    this.checkHsn(this.miscellaneousInvoiceService.travellerForm.value)
      if(this.isHsnError==true){ 
        window.scroll(0,0)
        this.showMessage = true;
        this.isSuccess = false
        this.invoice_number = ''
        this.alertType = 'error-box';
        this.message = `Enter Hsn Code for all Service Charges`
        this.spinner.hide()
        return false
      }
      const res = await this.invoiceService.getMethod(`client/autocomplete?name_like=${this.selectedClient.account_code.toUpperCase()}&status=active&client_type=all`).toPromise()

      // const res = await this.invoiceService.getMethod(`client?client_code=${this.selectedClient.account_code.toUpperCase()}&status=active`).toPromise()

          console.log("res",res)
          if(!res[0]["state_id"] || res[0]["state_id"] ==0 || !res[0]["state_name"]) {
            window.scroll(0,0)
            this.showMessage = true;
            this.isSuccess = false
            this.invoice_number = ''
            this.alertType = 'error-box';
            this.message = `Update Client With State Code`
            this.spinner.hide()
            return false
          }
          }   

      const invoiceFormValue = JSON.parse(JSON.stringify(this.miscellaneousInvoiceService.invoiceForm.value));
      let product_json_doc = this.miscellaneousInvoiceService.createMsProduct(
          invoiceFormValue, this.miscellaneousInvoiceService.travellerForm.value
      );
      
      if(!this.invoiceData.bos_reference && this.miscellaneousInvoiceService.invoiceForm.value.bill_of_supply === true) { 
          let billOfSupplyRequest = JSON.parse(JSON.stringify(product_json_doc))
          let originalRequest = this.miscellaneousInvoiceService.createMsProductForBillSupply(
            this.miscellaneousInvoiceService.invoiceForm.value,this.miscellaneousInvoiceService.travellerForm.value
          );

          let localJsonDocProduct = JSON.parse(JSON.stringify(originalRequest))

          product_json_doc = localJsonDocProduct;
          product_json_doc['billOfSupplyRequest'] = billOfSupplyRequest;
          product_json_doc['billOfSupplyRequest']['bill_of_supply'] = true;
      } 

      this.miscellaneousInvoiceService.putMethod(`invoice/miscellaneous/${this.invoice_no}`,product_json_doc).subscribe(async (data) => {
        window.scroll(0, 0)
        this.showMessage = true;
        this.isSuccess = true
        this.alertType = 'success-box';
        this.message = `Invoice Updated`

        if(product_json_doc['billOfSupplyRequest'] && product_json_doc['billOfSupplyRequest']['bill_of_supply'] === true) {
          this.forceReload();
          return;
        } 

        if(product_json_doc.status == 'draft'||product_json_doc.status == 'Draft'){
          this.showMessage = true;
          this.isSuccess = true
          this.alertType = 'success-box';
          this.invoice_number = data['invoice_number']
          this.message = `Invoice Saved with invoice no. : ${this.invoice_number}`
        this.miscellaneousInvoiceService.initialiseFormData({},{})

        let loggedInUser = JSON.parse(localStorage.okountUserInfo);
        this.loggedInuser = loggedInUser
        this.miscellaneousInvoiceService.invoiceForm.controls.employee_name.setValue(
          loggedInUser.name
        );
        this.miscellaneousInvoiceService.invoiceForm.controls.employee.setValue(
          loggedInUser.accountcode
        );
        this.miscellaneousInvoiceService.travellerList.controls = [];
        this.miscellaneousInvoiceService.addTraveller({})
        let obj = {
          invoice_no: 'New Invoice',
        };
        this.miscellaneousInvoiceService.fareList = []
        this.miscellaneousInvoiceService.fareList.push(obj);
        this.miscellaneousInvoiceService.calculateFromApi(0)
        this.uuid = await this.getInvoice()
        this.miscellaneousInvoiceService.invoiceForm.controls.invoice_number.setValue(this.uuid)
        window.history.replaceState({}, document.title, `/${window.location.hash}?invoice_number=${this.uuid}`);
        this.newInvoice = false;
        this.openSuccessModal.nativeElement.click()
          // setTimeout(()=>{
          //   window.top.close()
          // },5000)
        }
        this.spinner.hide()
      },err=> {
        window.scroll(0, 0)
        this.showMessage = true;
        this.isSuccess = false
        this.invoice_number = ''
        this.alertType = 'error-box';
        this.message = err['error']['message']
        this.spinner.hide()
      })
      
  }
  calcuateAllSC(){
    let controls = this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls
      for (let i = 0; i < controls.length; i++) {
        this.calcuateSC(i)
      }
  }

  calcuateSC(i){
    const invoiceData = this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[i].value
    let gstRate = this.getGstRate(i)
    let client_igst = 0
    let client_igst_2 = 0
    let client_igst_3 = 0
    let client_sgst = 0
    let client_sgst_2 = 0
    let client_sgst_3 = 0
    let client_cgst = 0
    let client_cgst_2 = 0
    let client_cgst_3 = 0
    let clientTotalAmount = 0
    let clientTotalAmount_2 = 0
    let clientTotalAmount_3 = 0
    let client_vat_amount = 0
    let client_igst_on_raf = 0
    let client_cgst_on_raf = 0
    let client_sgst_on_raf = 0
    let client_vat_on_raf = 0
    
    let clientRafAmount =  Number(invoiceData.client_raf_charges ? invoiceData.client_raf_charges : 0);
   
    if(invoiceData.client_gst_type== 'S' || (!this.isTenantIndian && invoiceData.client_vat_type== 'S')){
      clientTotalAmount = invoiceData.client_sc1_type == 'T' ? Number(invoiceData.client_sc1_amount) : 0
    }

    if(invoiceData.client_gst_type== 'B' || (!this.isTenantIndian && invoiceData.client_vat_type== 'B')){
      clientTotalAmount = Number(!isNaN(Number(invoiceData.client_basic)) ? Number(invoiceData.client_basic) : 0)+Number(invoiceData.client_sc2_type == 'B'?invoiceData.client_sc2_amount:0)+Number(invoiceData.client_sc3_type == 'B'?invoiceData.client_sc3_amount:0)
    }
   
    if(invoiceData.client_gst_type== 'T' || (!this.isTenantIndian && invoiceData.client_vat_type== 'T')){
      clientTotalAmount = Number(!isNaN(Number(invoiceData.client_taxes)) ? Number(invoiceData.client_taxes) : 0)+Number(invoiceData.client_sc2_type == ' OT'?invoiceData.client_sc2_amount:0)+Number(invoiceData.client_sc3_type == '0T'?invoiceData.client_sc3_amount:0)
    }

    // if (invoiceData.client_sc2_taxable == 'T') {
    if(invoiceData.client_gst_type_2== 'T'){
      // clientTotalAmount_2 = this.calculateTotal("client",i)
      clientTotalAmount_2 = Number(!isNaN(Number(invoiceData.client_taxes)) ? Number(invoiceData.client_taxes) : 0)+Number(invoiceData.client_sc2_type == 'OT'?invoiceData.client_sc2_amount:0)+Number(invoiceData.client_sc3_type == 'OT'?invoiceData.client_sc3_amount:0)

    }
    if(invoiceData.client_gst_type_2== 'B'){
      clientTotalAmount_2 = Number(!isNaN(Number(invoiceData.client_basic)) ? Number(invoiceData.client_basic) : 0)+Number(invoiceData.client_sc2_type == 'B'?invoiceData.client_sc2_amount:0)+Number(invoiceData.client_sc3_type == 'B'?invoiceData.client_sc3_amount:0)
    }
    if(invoiceData.client_gst_type_2== 'S'){
      // clientTotalAmount_2 = invoiceData.client_sc2_type == 'T' ? Number(invoiceData.client_sc2_amount) : 0
      clientTotalAmount_2 = invoiceData.client_sc1_type == 'T' ? Number(invoiceData.client_sc1_amount) : 0
    }
    // }
    // if (invoiceData.client_sc3_taxable == 'T') {
      if (invoiceData.client_gst_type_3 == 'T') {
        // clientTotalAmount_3 = this.calculateTotal("client",i)
        clientTotalAmount_3 = Number(!isNaN(Number(invoiceData.client_taxes)) ? Number(invoiceData.client_taxes) : 0)+Number(invoiceData.client_sc2_type == 'OT'?invoiceData.client_sc2_amount:0)+Number(invoiceData.client_sc3_type == 'OT'?invoiceData.client_sc3_amount:0)
      }
      if (invoiceData.client_gst_type_3 == 'B') {
        clientTotalAmount_3 = Number(!isNaN(Number(invoiceData.client_basic)) ? Number(invoiceData.client_basic) : 0)+Number(invoiceData.client_sc2_type == 'B'?invoiceData.client_sc2_amount:0)+Number(invoiceData.client_sc3_type == 'B'?invoiceData.client_sc3_amount:0)
      }
      if (invoiceData.client_gst_type_3 == 'S') {
        // clientTotalAmount_3 = invoiceData.client_sc3_type == 'T' ? Number(invoiceData.client_sc3_amount) : 0
        clientTotalAmount_3 = invoiceData.client_sc1_type == 'T' ? Number(invoiceData.client_sc1_amount) : 0
      }
    // }
    if(this.isTenantIndian){
    if(gstRate.client_igst_applicable){
      client_igst = Number((clientTotalAmount * gstRate.client_gst_rate/100).toFixed(2))
      client_igst_2 = Number((clientTotalAmount_2 * gstRate.client_gst_rate_2/100).toFixed(2))
      client_igst_3 = Number((clientTotalAmount_3 * gstRate.client_gst_rate_3/100).toFixed(2))
      client_igst_on_raf = Number((clientRafAmount * 18/100).toFixed(2))

      if(this.miscellaneousInvoiceService.invoiceForm.controls.is_p2p.value == true) {
        let basicAmount = Number(invoiceData.client_basic);
        let p2pRate;
        if(basicAmount <= 7500) {
          p2pRate = 12;
        } else if(basicAmount>7500) {
          p2pRate = 18
        }
        this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[i].controls.client_gst_type_2.setValue('B')
        this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[i].controls.client_gst_conf_2.setValue(p2pRate)
        client_igst_2 = Number(client_igst_2) + Number((basicAmount * p2pRate/100))
        client_igst_2 = Number(client_igst_2.toFixed(2))
      }
    }else{
      let rate = gstRate.client_gst_rate/2
      let rate_2 = gstRate.client_gst_rate_2/2
      let rate_3 = gstRate.client_gst_rate_3/2
      client_cgst = Number((clientTotalAmount * rate/100).toFixed(2))
      client_sgst = Number((clientTotalAmount * rate/100).toFixed(2))
      client_cgst_2 = Number((clientTotalAmount_2 * rate_2/100).toFixed(2))
      client_sgst_2 = Number((clientTotalAmount_2 * rate_2/100).toFixed(2))
      client_cgst_3 = Number((clientTotalAmount_3 * rate_3/100).toFixed(2))
      client_sgst_3 = Number((clientTotalAmount_3 * rate_3/100).toFixed(2))
      client_cgst_on_raf = Number((clientRafAmount * 9/100).toFixed(2))
      client_sgst_on_raf = Number((clientRafAmount * 9/100).toFixed(2))

      if(this.miscellaneousInvoiceService.invoiceForm.controls.is_p2p.value == true) {
        let basicAmount = Number(invoiceData.client_basic);
        let p2pRate;
        if(basicAmount <= 7500) {
          p2pRate = 12;
        } else if(basicAmount > 7500) {
          p2pRate = 18;
        }
        this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[i].controls.client_gst_type_2.setValue('B')
        this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[i].controls.client_gst_conf_2.setValue(p2pRate)
        client_cgst_2 = Number(client_cgst_2) + Number((basicAmount * (p2pRate/2)/100))
        client_sgst_2 = Number(client_sgst_2) + Number((basicAmount * (p2pRate/2)/100))
        client_cgst_2 = Number(client_cgst_2.toFixed(2))
        client_sgst_2 = Number(client_sgst_2.toFixed(2))
      }

    }
    }else{
      client_vat_amount = Number((clientTotalAmount * gstRate.client_vat_rate/100).toFixed(2))
      client_vat_on_raf = Number((clientRafAmount * gstRate.client_vat_rate/100).toFixed(2))
    }
    
    let supplier_igst = 0
    let supplier_igst_2 = 0
    let supplier_igst_3 = 0
    let supplier_sgst = 0
    let supplier_sgst_2 = 0
    let supplier_sgst_3 = 0
    let supplier_cgst = 0
    let supplier_cgst_2 = 0
    let supplier_cgst_3 = 0
    let supplierTotalAmount = 0
    let supplierTotalAmount_2 = 0
    let supplierTotalAmount_3 = 0
    let supplier_vat_amount = 0
    let supplier_igst_on_raf = 0
    let supplier_cgst_on_raf = 0
    let supplier_sgst_on_raf = 0
    let supplier_vat_on_raf = 0

    let supplierRafAmount =  Number(invoiceData.supplier_raf_charges ? invoiceData.supplier_raf_charges : 0);

    if(invoiceData.supplier_gst_type== 'S' || (!this.isTenantIndian && invoiceData.supplier_vat_type== 'S')){
      supplierTotalAmount = invoiceData.supplier_sc1_type == 'T' ? Number(invoiceData.supplier_sc1_amount) : 0
    }

    if(invoiceData.supplier_gst_type== 'T' || (!this.isTenantIndian && invoiceData.supplier_vat_type== 'T')){
     // supplierTotalAmount = this.calculateTotal("supplier",i)
     supplierTotalAmount =!isNaN(Number(invoiceData.supplier_taxes)) ? Number(invoiceData.supplier_taxes) : 0
    }
    if(invoiceData.supplier_gst_type== 'B' || (!this.isTenantIndian && invoiceData.supplier_vat_type== 'B')){
      supplierTotalAmount = !isNaN(Number(invoiceData.supplier_basic)) ? Number(invoiceData.supplier_basic) : 0
    }
   
    // if (invoiceData.supplier_sc2_taxable == 'T') {
      if (invoiceData.supplier_gst_type_2 == 'T') {
        //supplierTotalAmount_2 = this.calculateTotal("supplier",i)
        supplierTotalAmount_2 = !isNaN(Number(invoiceData.supplier_taxes)) ? Number(invoiceData.supplier_taxes) : 0
      }
      if (invoiceData.supplier_gst_type_2 == 'B') {
        supplierTotalAmount_2 = !isNaN(Number(invoiceData.supplier_basic)) ? Number(invoiceData.supplier_basic) : 0
      }
      if (invoiceData.supplier_gst_type_2 == 'S') {
        //supplierTotalAmount_2 = invoiceData.supplier_sc2_type == 'T' ? Number(invoiceData.supplier_sc2_amount) : 0
        supplierTotalAmount_2 = invoiceData.supplier_sc1_type == 'T' ? Number(invoiceData.supplier_sc1_amount) : 0
      }
    // }
    // if (invoiceData.supplier_sc3_taxable == 'T') {
      if (invoiceData.supplier_gst_type_3 == 'T') {
        // supplierTotalAmount_3 = this.calculateTotal("supplier",i)
        supplierTotalAmount_3 = !isNaN(Number(invoiceData.supplier_taxes)) ? Number(invoiceData.supplier_taxes) : 0
      }
      if (invoiceData.supplier_gst_type_3 == 'B') {
        supplierTotalAmount_3 = !isNaN(Number(invoiceData.supplier_basic)) ? Number(invoiceData.supplier_basic) : 0
      }
      if (invoiceData.supplier_gst_type_3 == 'S') {
        //supplierTotalAmount_3 = invoiceData.supplier_sc3_type == 'T' ? Number(invoiceData.supplier_sc3_amount) : 0
        supplierTotalAmount_3 = invoiceData.supplier_sc1_type == 'T' ? Number(invoiceData.supplier_sc1_amount) : 0
      }
    // }
    if(this.isTenantIndian){
    if(gstRate.supplier_igst_applicable){
      supplier_igst = Number((supplierTotalAmount * gstRate.supplier_gst_rate/100).toFixed(2))
      supplier_igst_2 = Number((supplierTotalAmount_2 * gstRate.supplier_gst_rate_2/100).toFixed(2))
      supplier_igst_3 = Number((supplierTotalAmount_3 * gstRate.supplier_gst_rate_3/100).toFixed(2))
      supplier_igst_on_raf = Number((supplierRafAmount * 18/100).toFixed(2))
    }else{
      let rate = gstRate.supplier_gst_rate/2
      let rate_2 = gstRate.supplier_gst_rate_2/2
      let rate_3 = gstRate.supplier_gst_rate_3/2
      supplier_cgst = Number((supplierTotalAmount * rate/100).toFixed(2))
      supplier_sgst = Number((supplierTotalAmount * rate/100).toFixed(2))
      supplier_cgst_2 = Number((supplierTotalAmount_2 * rate_2/100).toFixed(2))
      supplier_sgst_2 = Number((supplierTotalAmount_2 * rate_2/100).toFixed(2))
      supplier_cgst_3 = Number((supplierTotalAmount_3 * rate_3/100).toFixed(2))
      supplier_sgst_3 = Number((supplierTotalAmount_3 * rate_3/100).toFixed(2))
      supplier_cgst_on_raf = Number((supplierRafAmount * 9/100).toFixed(2))
      supplier_sgst_on_raf = Number((supplierRafAmount * 9/100).toFixed(2))
    }
  }else{
    supplier_vat_amount = Number((supplierTotalAmount * gstRate.supplier_vat_rate/100).toFixed(2))
    supplier_vat_on_raf = Number((supplierRafAmount * gstRate.supplier_vat_rate/100).toFixed(2))

  }
  
    // TODO : Fix gst calculation
    this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[i].controls.client_cgst.setValue(client_cgst)
    this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[i].controls.client_sgst.setValue(client_sgst)
    this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[i].controls.client_igst.setValue(client_igst)
    this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[i].controls.client_cgst_2.setValue(client_cgst_2)
    this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[i].controls.client_sgst_2.setValue(client_sgst_2)
    this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[i].controls.client_igst_2.setValue(client_igst_2)
    this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[i].controls.client_cgst_3.setValue(client_cgst_3)
    this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[i].controls.client_sgst_3.setValue(client_sgst_3)
    this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[i].controls.client_igst_3.setValue(client_igst_3)
    this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[i].controls.client_igst_on_raf.setValue(client_igst_on_raf)
    this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[i].controls.client_cgst_on_raf.setValue(client_cgst_on_raf)
    this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[i].controls.client_sgst_on_raf.setValue(client_sgst_on_raf)
    this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[i].controls.client_vat_on_raf.setValue(client_vat_on_raf)
    this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[i].controls.client_vat_amount.setValue(client_vat_amount)
    
    

    this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[i].controls.supplier_cgst.setValue(supplier_cgst)
    this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[i].controls.supplier_sgst.setValue(supplier_sgst)
    this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[i].controls.supplier_igst.setValue(supplier_igst)
    this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[i].controls.supplier_cgst_2.setValue(supplier_cgst_2)
    this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[i].controls.supplier_sgst_2.setValue(supplier_sgst_2)
    this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[i].controls.supplier_igst_2.setValue(supplier_igst_2)
    this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[i].controls.supplier_cgst_3.setValue(supplier_cgst_3)
    this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[i].controls.supplier_sgst_3.setValue(supplier_sgst_3)
    this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[i].controls.supplier_igst_3.setValue(supplier_igst_3)
    this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[i].controls.supplier_igst_on_raf.setValue(supplier_igst_on_raf)
    this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[i].controls.supplier_cgst_on_raf.setValue(supplier_cgst_on_raf)
    this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[i].controls.supplier_sgst_on_raf.setValue(supplier_sgst_on_raf)
    this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[i].controls.supplier_vat_on_raf.setValue(supplier_vat_on_raf)
    this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[i].controls.supplier_vat_amount.setValue(supplier_vat_amount)
    return true
  }

  calculateTotal(calculation_key,i){
    const invoiceData = this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[i].value
    
    let nonAccount = invoiceData[`${calculation_key}_non_account`]
    let basic = invoiceData[`${calculation_key}_basic`]
    let taxes = invoiceData[`${calculation_key}_taxes`]
    let commission_amount = invoiceData[`${calculation_key}_commission_amount`]
    let discount_amount = invoiceData[`${calculation_key}_discount_amount`]
    let tds = invoiceData[`${calculation_key}_tds`]
    let sc1_type = invoiceData[`${calculation_key}_sc1_type`]
    let sc1_amount = invoiceData[`${calculation_key}_sc1_amount`]
    let sc2_type = invoiceData[`${calculation_key}_sc2_type`]
    let sc2_amount = invoiceData[`${calculation_key}_sc2_amount`]
     let sc2_taxable = invoiceData[`${calculation_key}_sc2_taxable`]
    let sc3_type = invoiceData[`${calculation_key}_sc3_type`]
    let sc3_amount = invoiceData[`${calculation_key}_sc3_amount`]
     let sc3_taxable = invoiceData[`${calculation_key}_sc3_taxable`]

    let total = Number(nonAccount) + Number(basic) + Number(taxes) - Number(commission_amount)- Number(discount_amount) + Number(tds)
    if(sc1_type == 'T'){
      total += Number(sc1_amount)
    }
    // if(sc2_taxable == 'T'){
      total += Number(sc2_amount)
    // }
    // if(sc3_taxable == 'T'){
      total += Number(sc3_amount)
    // }
    return !isNaN(Number(total)) ? Number(total) : 0
  }


  getGstRate(i) {
    let formValue = this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[i].value
    let client_gst_rate = formValue.client_gst_conf ? formValue.client_gst_conf : 0
    let client_gst_rate_2 = formValue.client_gst_conf_2 ? formValue.client_gst_conf_2 : 0
    let client_gst_rate_3 = formValue.client_gst_conf_3 ? formValue.client_gst_conf_3 : 0
    let supplier_gst_rate = formValue.supplier_gst_conf ? formValue.supplier_gst_conf : 0
    let supplier_gst_rate_2 = formValue.supplier_gst_conf_2 ? formValue.supplier_gst_conf_2 : 0
    let supplier_gst_rate_3 = formValue.supplier_gst_conf_3 ? formValue.supplier_gst_conf_3 : 0
    let supplier_igst_applicable = this.selectedSupplier &&  this.selectedSupplier.country_id==101? this.checkIsIgstApplicable(this.selectedSupplier.gst_number,this.selectedSupplier.state_id) : false
    let client_igst_applicable = this.selectedClient && this.selectedClient.country_id==101  ? this.checkIsIgstApplicable(this.selectedClient.gst_number,this.selectedClient.state_id) : false
    let client_vat_rate = formValue.client_vat_conf ? formValue.client_vat_conf : 0
    let supplier_vat_rate = formValue.supplier_vat_conf ? formValue.supplier_vat_conf : 0

    let isP2P = this.miscellaneousInvoiceService.invoiceForm.value.is_p2p;

    if(isP2P && this.selectedHotel) {
      if(this.selectedHotel.gst_number && this.selectedHotel.state_id) {
        let p2pIgstApplicable = this.selectedHotel && this.selectedHotel.country_id==101?this.checkIsIgstApplicable(this.selectedHotel.gst_number,this.selectedHotel.state_id):false
        client_igst_applicable = p2pIgstApplicable;
      }
    }

    return {
      client_gst_rate,
      client_gst_rate_2,
      client_gst_rate_3,
      supplier_gst_rate,
      supplier_gst_rate_2,
      supplier_gst_rate_3,
      supplier_igst_applicable,
      client_igst_applicable,
      client_vat_rate,
      supplier_vat_rate
    }
  }

  checkIsIgstApplicable(otherGstNumber, otherState) {
    let accountingCompanyGstNumber = this.companyAddress && this.companyAddress.gst_number ? this.companyAddress.gst_number : ""
    let accountingCompanyState = this.companyAddress && this.companyAddress.state_name ? this.companyAddress.state_name : ''
    let accountingCompanyStateId = this.companyAddress && this.companyAddress.state_id ? this.companyAddress.state_id : ''
    if (otherGstNumber && otherGstNumber.length > 0 && otherGstNumber != 'null') {
      //if other's company code is of same as of accounting company then non igst applicable 
      if (accountingCompanyGstNumber.substring(0, 2) == otherGstNumber.substring(0, 2)) {
        return false;
      } else {
        return true;
      }
    } else if ((otherState && otherState.length && otherState.length > 0 && otherState != 'null')) {
      //if other's company state is of same sate then non igst applicable
      if (accountingCompanyState.toUpperCase() == otherState.toUpperCase()) {
        return false;
      } else {
        return true;
      }
      //if other code/state both is undefined then consider it non igst applicable 
  }else if (typeof otherState=='number' && otherState!=0) {
    //if other's company state is of same sate then non igst applicable
    if (accountingCompanyStateId == otherState) {
        return false;
      } else {
        return true;
      }
      //if other code/state both is undefined then consider it non igst applicable 
    } else {
      return false;
    }
  }

  async calculateCommission(base,tax,commType,commFor,commRate,tdsType,tdsRate,roundOffType = "NORMAL_ROUND_OFF",i){
    let res = await this.miscellaneousInvoiceService.calculateCommission(base,tax,commType,commFor,commRate,tdsType,tdsRate,roundOffType = "NORMAL_ROUND_OFF",i)
    return res
  }

  async  calculateNetAmount(i){
    var invoiceData = this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[i].value
  if(invoiceData.client_sc2_taxable=="T") this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[i].controls.client_gst_conf_2.setValue(0)
  if(invoiceData.client_sc3_taxable=="T") this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[i].controls.client_gst_conf_3.setValue(0)
  if(invoiceData.supplier_sc2_taxable=="T") this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[i].controls.supplier_gst_conf_2.setValue(0)
  if(invoiceData.supplier_sc3_taxable=="T") this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[i].controls.supplier_gst_conf_3.setValue(0)
    let rcm_applicable = this.miscellaneousInvoiceService.invoiceForm.value.rcm_applicable
  let reverse_tds = this.miscellaneousInvoiceService.invoiceForm.value.reverse_tds

    let  supplierCommRes = await this.calculateCommission(invoiceData.supplier_basic, invoiceData.supplier_taxes, invoiceData.supplier_commission_type,'SUPPLIER',invoiceData.supplier_commission_value,invoiceData.supplier_tds_type,invoiceData.supplier_tds_conf,'NORMAL_ROUND_OFF',i)
    let  clientCommRes = await this.calculateCommission(invoiceData.client_basic, invoiceData.client_taxes, invoiceData.client_commission_type,'CLIENT',invoiceData.client_commission_value,invoiceData.client_tds_type,invoiceData.client_tds_conf,'NORMAL_ROUND_OFF',i)
    invoiceData = this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[i].value
    let scRes = await this.calcuateSC(i);
    invoiceData = this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[i].value
    let client_total = 0
    let client_net_amount = 0
    let supplier_total = 0
    let supplier_net_amount = 0
    let client_sc_amount = Number(invoiceData.client_sc1_amount)+Number(invoiceData.client_sc2_amount)+Number(invoiceData.client_sc3_amount)

    let supplier_sc_amount = Number(invoiceData.supplier_sc1_amount)+Number(invoiceData.supplier_sc2_amount)+Number(invoiceData.supplier_sc3_amount)
    
    if(invoiceData.pay_type != undefined && invoiceData.pay_type.trim && invoiceData.pay_type != 'AGENT_CC' ){
      client_total += Number(invoiceData.client_basic) + Number(invoiceData.client_taxes )
    }
    
    client_net_amount = client_total - 
                    Number(invoiceData.client_commission_amount)-Number(invoiceData.client_discount_amount)+Number(client_sc_amount) +
                    Number(invoiceData.client_travel_insurance ? invoiceData.client_travel_insurance : 0) +
                    Number(invoiceData.client_meal_charges ? invoiceData.client_meal_charges : 0) +
                    Number(invoiceData.client_toll_charges ? invoiceData.client_toll_charges : 0) +
                    Number(invoiceData.client_parking_charges ? invoiceData.client_parking_charges : 0) +
                    Number(invoiceData.client_permit_charges ? invoiceData.client_permit_charges : 0) +
                    Number(invoiceData.invoice_type !='non_account' && invoiceData.client_tds ? invoiceData.client_tds : 0) + 
                    Number(invoiceData.client_igst) + Number(invoiceData.client_cgst) + Number(invoiceData.client_sgst)+
                    Number(invoiceData.client_igst_2) + Number(invoiceData.client_cgst_2) + Number(invoiceData.client_sgst_2)+
                    Number(invoiceData.client_igst_3) + Number(invoiceData.client_cgst_3) + Number(invoiceData.client_sgst_3) +
                    Number(invoiceData.client_vat_amount) - Number(invoiceData.client_xxl_charges) - Number(invoiceData.client_raf_charges) - Number(invoiceData.client_vat_on_raf) -
                    Number(invoiceData.client_cgst_on_raf) - Number(invoiceData.client_sgst_on_raf) - Number(invoiceData.client_igst_on_raf);
    
    if(invoiceData.pay_type != undefined && invoiceData.pay_type.trim && invoiceData.pay_type.trim().length == 0 ){
      supplier_total += Number(invoiceData.supplier_basic) + Number(invoiceData.supplier_taxes )
      let purchase_currency_rate = Number(this.miscellaneousInvoiceService.invoiceForm.controls.purchase_currency_rate.value);
      supplier_total = Number(supplier_total)/Number(purchase_currency_rate)
      supplier_net_amount = supplier_total - 
                            Number(invoiceData.supplier_commission_amount) - Number(invoiceData.supplier_discount_amount) + 
                            Number(invoiceData.supplier_travel_insurance ? invoiceData.supplier_travel_insurance : 0) +
                            Number(invoiceData.supplier_meal_charges ? invoiceData.supplier_meal_charges : 0) + 
                            Number(invoiceData.supplier_toll_charges ? invoiceData.supplier_toll_charges : 0) + 
                            Number(invoiceData.supplier_parking_charges ? invoiceData.supplier_parking_charges : 0) + 
                            Number(invoiceData.supplier_permit_charges ? invoiceData.supplier_permit_charges : 0) + 
                            Number(invoiceData.invoice_type !='non_account' && invoiceData.supplier_tds  ? (reverse_tds==true?  Number(invoiceData.supplier_tds)*-1:invoiceData.supplier_tds) : 0) + Number(supplier_sc_amount) + 
                            ( rcm_applicable==true?0:
                            Number(invoiceData.supplier_igst) + Number(invoiceData.supplier_cgst) + Number(invoiceData.supplier_sgst)+
                            Number(invoiceData.supplier_igst_2) + Number(invoiceData.supplier_cgst_2) + Number(invoiceData.supplier_sgst_2)+
                            Number(invoiceData.supplier_igst_3) + Number(invoiceData.supplier_cgst_3) + Number(invoiceData.supplier_sgst_3)
                            - Number(invoiceData.supplier_cgst_on_raf) - Number(invoiceData.supplier_sgst_on_raf) - Number(invoiceData.supplier_igst_on_raf) )+ 
                            Number(invoiceData.supplier_vat_amount) - Number(invoiceData.supplier_xxl_charges) - Number(invoiceData.supplier_raf_charges) - Number(invoiceData.supplier_vat_on_raf) 
    }
    this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[i].controls.supplier_net_amount.setValue(Number(supplier_net_amount).toFixed(2))
    this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[i].controls.client_net_amount.setValue(Number(client_net_amount).toFixed(2)) 
  }

  async handlePayTypeChange(i){
    let calcLocalRes = await this.calculateNetAmount(i)
    let calcApiRes = await this.miscellaneousInvoiceService.calculateFromApi(0)
  }

  replicateClientAmount(i: any, fromFCN,toFCN) {
    let travellerList = this.miscellaneousInvoiceService.travellerForm.controls.travellerList
    let account_type = this.miscellaneousInvoiceService.invoiceForm.value.invoice_type

    if(account_type == 'rail' && this.invoiceMode != 'view') {
      if(fromFCN == 'client_basic' || fromFCN == 'client_taxes') {
        let basicAmount = this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[i].controls.client_basic.value;
        let taxAmount = this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[i].controls.client_taxes.value;
        
        let basePlusTax = Number(basicAmount ? basicAmount : 0) + Number(taxAmount ? taxAmount : 0);
        this.calculateServiceChargeForRail(i, basePlusTax)
      }
    }

    if(
      account_type != 'non_account' && 
      fromFCN && 
      toFCN && 
      travellerList.controls[i] && 
      travellerList.controls[i].controls && 
      travellerList.controls[i].controls[fromFCN] &&
      travellerList.controls[i].controls[toFCN]
      ){
        let purchase_currency = this.miscellaneousInvoiceService.invoiceForm.controls.purchase_currency.value;
        let purchase_currency_rate = Number(this.miscellaneousInvoiceService.invoiceForm.controls.purchase_currency_rate.value);
        
        if((fromFCN == 'client_basic' || fromFCN == 'client_taxes' || fromFCN == 'supplier_basic' || fromFCN == 'supplier_taxes') && !this.isTenantIndian && purchase_currency && purchase_currency_rate) {
          if(fromFCN == 'client_basic' || fromFCN == 'client_taxes') {
            travellerList.controls[i].controls[toFCN].setValue((travellerList.controls[i].controls[fromFCN].value * purchase_currency_rate).toFixed(2));
          } else {
            travellerList.controls[i].controls[toFCN].setValue((travellerList.controls[i].controls[fromFCN].value / purchase_currency_rate).toFixed(2));
          }
        } else {
          travellerList.controls[i].controls[toFCN].setValue(travellerList.controls[i].controls[fromFCN].value)

        }
      this.calculateNetAmount(i)
    }
  }

  moveToPreviousTraveller(i) {
		this.showTravellerIndex = i - 1
	}

  moveToNextTraveller(i) {
		this.showTravellerIndex = i + 1
    let nextStep = 1
    if(this.miscellaneousInvoiceService.invoiceForm.value.invoice_type != 'hotel'){
      nextStep = 4
    }
		let index = this.showTravellerIndex*this.totalInputInTravellers+this.staticInputCount+nextStep
		this.commonService.selectedInput.next(index)
    if(i == this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls.length){
      this.returnToInvoice()
    }
	}

  returnToInvoice(){
    if(this.isGstNumberExist){
    this.checkHsn(this.miscellaneousInvoiceService.travellerForm.value)
    if(this.isHsnError==true){ 
      window.scroll(0,0)
      this.showMessage = true;
      this.isSuccess = false
      this.invoice_number = ''
      this.alertType = 'error-box';
      this.message = `Enter Hsn Code for all Service Charges`
      this.spinner.hide()
      return false
    }
  }
		this.minimizeSection()
		// this.calculateFareFromApi(0)
		// this.saveTravellers()
	}

  openReplicateModal(e: Event,index) {
		e.stopPropagation();
		if (this.openModal["_results"][0]) {
			this.openModal["_results"][0].nativeElement.click()
			this.commonService.selectedInput.next(this.miscellaneousInvoiceService.lastIndexOfTraveller+7)
		}
	}

  replicateFormData(index) {
		let tempFormValue = Object.assign({}, this.miscellaneousInvoiceService.travellerForm.value.travellerList[index])
		let travellerListLength = this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls.length
		for (let i = 0; i < travellerListLength; i++) {
      if(i >= index){
        const travellerList = this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[i];
        this.setTravellerData(travellerList, tempFormValue)
      }
		}
		// this.saveTravellers();
		if (this.showTravellerIndex < travellerListLength - 1) {
			this.moveToNextTraveller(index)
		}
	}

  setTravellerData(travellerList, tempFormValue) {
		let keys = Object.keys(tempFormValue)
		for (let i = 0; i < keys.length; i++) {
			const element = keys[i];
			if (element != 'room_name' && element != 'reference_number' && element != 'lead_pax_name' && element != 'pay_type' && element != 'ledger_card_no') {
				travellerList.controls[element].setValue(tempFormValue[element])
			}
		}
	}

  focusClientAc() {
    if (this.clientAC && this.clientAC.focus) {
      this.clientAC.focus()
    }
  }
  focusGeneralLedgerAc() {
    if (this.generalLedgerAc && this.generalLedgerAc.focus) {
      this.generalLedgerAc.focus()

    }
  }
  focusNarrationArea() {
    if (this.narrationArea != undefined) {
      this.narrationArea.focus();
    }
  }
  focusSupplierAc() {
    if (this.supplierAc != undefined) {
      this.supplierAc.focus();
    }
  }
  focusSupplier2Ac() {
    if (this.supplier2Ac != undefined) {
      this.supplier2Ac.focus();
    }
  }
  focusSubledgerAc() {
    if (this.subLedgerAC != undefined) {
      this.subLedgerAC.focus();
    }
  }

  focusHotelAc() {
    if (this.hotelAc != undefined) {
      this.hotelAc.focus();
    }
  }

  focusEmployeeAc() {
    if (this.employeeAC != undefined) {
      this.employeeAC.focus();
    }
  }

  get DynamicFormControls() {
		const travellerList = <FormArray>this.miscellaneousInvoiceService.travellerForm.get("travellerList");
		return travellerList;
	}

  maximizeSection(index,nextStep =1,focusRequired = true) {
		this.maximizedSection = true
		this.showTravellerIndex = index
    if(focusRequired){
      this.commonService.selectedInput.next(this.showTravellerIndex*this.totalInputInTravellers+this.staticInputCount+nextStep)
    }
	}

  minimizeSection() {
		this.maximizedSection = false
		this.showTravellerIndex = null
    this.miscellaneousInvoiceService.calculateFromApi(0)
    this.setNarration()
    setTimeout(() => {
      this.commonService.selectedInput.next(this.miscellaneousInvoiceService.lastIndexOfTraveller+1)
    }, 100);
	}

    setNarration(){
      let invoiceDate = this.miscellaneousInvoiceService.invoiceForm.controls.service_date.value
      let services = this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls;
      
      let hotel_name = '';
      if(this.miscellaneousInvoiceService.invoiceForm.controls.hotel_name.value.hasOwnProperty('name')) {
          hotel_name = this.miscellaneousInvoiceService.invoiceForm.controls.hotel_name.value.name;
      } else {
          hotel_name = this.miscellaneousInvoiceService.invoiceForm.controls.hotel_name.value;
      }
      
      let invoiceData = this.miscellaneousInvoiceService.invoiceForm.value      

      let travellerListLength = services.length;
      let firstService = travellerListLength && services[0].controls;
      
      var narrLine1= ''
      console.log("firstService.lead_pax_name.value",firstService.lead_pax_name.value)
      if (travellerListLength > 1) {
        narrLine1 = firstService.lead_pax_name.value && firstService.lead_pax_name.value.account_name?firstService.lead_pax_name.value.account_name:firstService.lead_pax_name.value + ' + ' + (travellerListLength - 1);
      } else {
        if(firstService.lead_pax_name && firstService.lead_pax_name.value)
          narrLine1 = firstService.lead_pax_name.value && firstService.lead_pax_name.value.account_name?firstService.lead_pax_name.value.account_name:firstService.lead_pax_name.value;
      }
      
      var narrLine2 = ''
      var narrArr2 = []
      if(this.invoiceType == 'hotel'){
        let i;
        for(i=0; i<2; i++){
          let serviceAtIndex = services[i] && services[i].controls ;
          if(serviceAtIndex && serviceAtIndex.room_name && serviceAtIndex.room_name.value){
            narrArr2.push(serviceAtIndex.room_name.value.toUpperCase())
          }
        }
      } else if(this.invoiceType == 'rail') {
          narrArr2.push(`${invoiceData.train_no} | ${invoiceData.train_name} | PNR: ${invoiceData.pnr}`)
      } else if(this.invoiceType == 'bus') {
        narrArr2.push(`${invoiceData.boarding_point} - ${invoiceData.arrival_point} | PNR: ${invoiceData.pnr}`)
      } else if (this.invoiceType == 'car') {
        narrArr2.push(`${invoiceData.boarding_point} - ${invoiceData.arrival_point}`)
      } else {
        let i;
        for(i=0; i<2; i++){
          let serviceAtIndex = services[i] && services[i].controls ;
          if(serviceAtIndex && serviceAtIndex.reference_number && serviceAtIndex.reference_number.value){
            narrArr2.push(serviceAtIndex.reference_number.value.toUpperCase())
          }
        }
      }


      narrLine2 = narrArr2.join(' + ');
      if(this.invoiceType == 'hotel'){
        if(hotel_name) {
          narrLine2 = `${hotel_name.toUpperCase()} | ${narrLine2}`
        }
      }else {
        if(travellerListLength>2){
          narrLine2 += ` + MORE `
        }
      }

      var narrLine3 = ''
      if(this.invoiceType == 'hotel'){
        narrLine3 = `CHECK IN DATE: ${moment(firstService['service_started'].value, 'YYYY-MM-DD').format('DD-MM-YYYY')} CHECK OUT DATE:${moment(firstService['service_ended'].value, 'YYYY-MM-DD').format('DD-MM-YYYY')} `
      }else if(this.invoiceType=='visa') {
        narrLine3 = `VISA DATE : ${moment(invoiceDate, 'YYYY-MM-DD').format('DD-MM-YYYY')} `
      } else if(this.invoiceType == 'insurance') {
        narrLine3 = `SERVICE DATE : ${moment(invoiceDate, 'YYYY-MM-DD').format('DD-MM-YYYY')} `
      } else {
        narrLine3 = `FROM ${invoiceData.departure_station} TO ${invoiceData.arrival_station}| DATE: ${moment(invoiceData.departure_date).format('DD-MM-YYYY')}`
      } 
      
      var narrLine4 = `ISSUED OFFLINE : ${this.miscellaneousInvoiceService.invoiceForm.controls.employee_name.value.toUpperCase()}`
      this.miscellaneousInvoiceService.invoiceForm.controls.narration_1.setValue(narrLine1)
      this.miscellaneousInvoiceService.invoiceForm.controls.narration_2.setValue(narrLine2)
      this.miscellaneousInvoiceService.invoiceForm.controls.narration_3.setValue(narrLine3)
      this.miscellaneousInvoiceService.invoiceForm.controls.narration_4.setValue(narrLine4)
  }

  viewInvoice(invoice_no){
    this.closeSuccesModal()
    this.router.navigate([`/transactions/ms/ms-invoice/view/${invoice_no}`])
  }
  openVoucher(invoice_number) {
		let url = `${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/transactions/ms-invoice-view;searchId=${invoice_number};type=miscellaneous`;
		window.open(url, "_blank");
	}

  openActivity(invoice_no) {
		let pathurl=`${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/activity/activity-summary/miscellaneous/${invoice_no}`

		if(window.location.protocol != 'file:'){
			window.open(pathurl,'_blank')
		}else{
			var customeEventHandler = new CustomEvent("handle-window-open", {
				"detail": {
					hashPath : pathurl
				}
			  });
			window.dispatchEvent(customeEventHandler)
		}
	}

  viewJournal(invoice_no) {
    let url = `${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/transactions/view-invoice-journal/${invoice_no}`;
		window.open(url, "_blank");
	}

  async handleInvoiceForChange(value){
    this.staticInputCount = this.calculateStaticCount(value);

    for (const traveller of this.miscellaneousInvoiceService.travellerList.controls) {
      traveller['controls'].client_tds_type.setValue(value == 'non_account' ? 'B' : 'C') 
      let res = await this.calculateNetAmount(this.miscellaneousInvoiceService.travellerList.controls.indexOf(traveller))
    }
    this.miscellaneousInvoiceService.calculateFromApi(0)
    if(this.isAadesh==true){
    this.invoice_config = this.miscellaneousInvoiceService.invoice_config[value] ? this.miscellaneousInvoiceService.invoice_config[value] : ""
    }else{
      this.invoice_config = this.miscellaneousInvoiceService.updated_invoice_config[this.invoiceType] ? this.miscellaneousInvoiceService.updated_invoice_config[this.invoiceType] : null
    }
    if(this.invoice_config && this.invoice_config.general_ledger){
      this.generalSearch(this.invoice_config.general_ledger,true,true,false)
    }else{
      this.selectAccount(null,'general_ledger_code',null, false)
    }
  }

  handleMaximizeSection(i){
    let staticCount =  this.calculateStaticCount(this.miscellaneousInvoiceService.invoiceForm.value.invoice_type);

    if(this.staticInputCount == staticCount){
      this.maximizeSection(i,2)
    }
  }

  closeSuccesModal(){
    this.showMessage = false;
    this.isSuccess = false
    this.alertType = 'success-box';
    this.message = ``
    this.newInvoice = true
    this.openSuccessModal.nativeElement.click()
  }
  resetSelection(formControlName) {
    this.miscellaneousInvoiceService.invoiceForm.controls[formControlName].setValue('')
    this.miscellaneousInvoiceService.invoiceForm.controls[`${formControlName}_name`].setValue('')
    if(formControlName == 'client_code'){
      this.bookingClientArr = []
    }
    if(formControlName == 'supplier_code'){
      this.bookingSupplierArr = []
    }
  }

  setServiceDate(e){
    if(e.target.value && moment(e.target.value).isValid()){
      this.miscellaneousInvoiceService.invoiceForm.controls.service_date.setValue(e.target.value)

    }
  }

  resetCurrentOpenedModal(){
    this.currentOpenedModal = ''
  }
  openDeleteConfirmModal() {
    if(this.commonService.gstFiledDate) {
			if(moment(this.commonService.gstFiledDate).isSameOrAfter(moment(this.invoiceData.invoice_date).format('YYYY-MM-DD')) || moment(this.commonService.gstFiledDate).isSameOrAfter(moment(this.miscellaneousInvoiceService.invoiceForm.controls.invoice_date.value).format('YYYY-MM-DD'))) {
				this.showMessage = true
				this.spinner.hide()
				this.alertType = "error-box"
				this.message = 'Not Allowed to delete the invoice.'
				return false;
			}
		}
    this.closeOpenedModals('delete')
    this.currentOpenedModal = 'delete'
		this.confirmDeleteModal.nativeElement.click()
	}
	closeDeleteConfirmModal() {
    this.currentOpenedModal = ''
		this.closeDeleteModal.nativeElement.click()
	}

  openUpdateConfirmModal() {
    if(this.commonService.gstFiledDate) {
      if(moment(this.commonService.gstFiledDate).isSameOrAfter(moment(this.invoiceData.invoice_date).format('YYYY-MM-DD')) || moment(this.commonService.gstFiledDate).isSameOrAfter(moment(this.miscellaneousInvoiceService.invoiceForm.controls.invoice_date.value).format('YYYY-MM-DD'))) {
        this.showMessage = true
				this.spinner.hide()
				this.alertType = "error-box"
				this.message = 'Not Allowed to update the invoice.'
				return false;
			}
		}

    if(this.miscellaneousInvoiceService.invoiceForm.value.bill_of_supply === true) {
      let checkBosApplicableOrNot = this.checkBosApplicableOrNot(this.miscellaneousInvoiceService.travellerForm.value)
      if(!checkBosApplicableOrNot || this.miscellaneousInvoiceService.invoiceForm.value.is_p2p === true) {
        this.showMessage = true
        this.spinner.hide()
        this.alertType = "error-box"
        this.message = 'Bill of supply Not Applicable.'
        return false;
      }
    }
    this.closeOpenedModals('update')
		this.currentOpenedModal = 'update'
		this.confirmUpdateModal.nativeElement.click()
	}
	closeUpdateConfirmModal() {
		this.currentOpenedModal = ''
		this.closeUpdateModal.nativeElement.click()
	}

  closeOpenedModals(modalName){
		if(modalName!='delete') this.closeDeleteConfirmModal()
		if(modalName!='update') this.closeUpdateConfirmModal()
	}

  deleteInvoice(invoice_no) {
		this.closeDeleteConfirmModal()
		this.commonService.deleteRequest(`${environment.okountUrl}/invoice/miscellaneous?invoice_no=${invoice_no}`).subscribe(res => {
			this.showMessage = true
			this.alertType = 'success-box';
			this.message = `Invoice Deleted`
		}, err => {
			this.showMessage = true
			this.alertType = 'error-box';
			this.message = `Failed to delete invoice.`
		})
	}

  openSendEmailModal(){
		//define email.
    let primary_email = ''
    let secondary_email = ''
    let subject = `INVOICE NO. ${this.invoice_no}`
    let body = `Dear Travel Partner,\n\nPlease find Attached Invoice ${this.invoice_no}`
    body+=`\n\nPlease do not hesitate to contact us if you have any query or concern about attached document.`
    body+=`\n\nIt's been a pleasure to serve you.\n\nBest Regards\n\n${this.invoiceData.accounting_company?.name}\nAccounts Team`
    if(this.invoiceData.accounting_company && this.invoiceData.accounting_company.telephone){
      body += `\nContact Us on ${this.invoiceData.accounting_company.telephone}`
  }
    if(this.invoiceData && this.invoiceData.client){
      primary_email = this.invoiceData.client.hasOwnProperty('primary_email')?this.invoiceData.client.primary_email:''
      secondary_email = this.invoiceData.client.hasOwnProperty('secondary_email')?this.invoiceData.client.secondary_email:''
    }
    this.sendEmailForm.controls.toEmails.setValue(primary_email)
    this.sendEmailForm.controls.bccEmails.setValue("")
    this.sendEmailForm.controls.ccEmails.setValue(secondary_email?.split(',').join('\n'))
    this.sendEmailForm.controls.subject.setValue(subject)
		this.sendEmailForm.controls.body.setValue(body)
    this.openEmailModal.nativeElement.click();
	}

	callTosendEmail(){
  	
		let toEmails = this.sendEmailForm.value.toEmails
		let bccEmails = this.sendEmailForm.value.bccEmails?.split(",")
		let ccEmails = this.sendEmailForm.value.ccEmails?.split(",")
    let subject = this.sendEmailForm.value.subject
    let body = this.sendEmailForm.value.body
    let clientName = this.invoiceData && this.invoiceData.client && this.invoiceData.client.name?this.invoiceData.client.name:''
		if(toEmails){
      
				toEmails = toEmails.split(",")
				window.scrollTo(0, 0)
				let emails = {
				  to_addresses:toEmails,
				  cc_addresses:ccEmails,
				  bcc_addresses:bccEmails
				}
				let pageUrl = `transactions/ms-invoice-view;searchId=${this.invoice_no};type=miscellaneous`
        const formData = new FormData();
        formData.append('email',JSON.stringify(emails))
        formData.append('name',clientName)
        formData.append('page_url',pageUrl)
        formData.append('subject',subject)
        formData.append('email_body',body)
        formData.append('document_num',this.invoice_no)
        if(this.attachmentFile){
          formData.append('attachmentFile',this.attachmentFile)
        }
        let data = formData
				this.commonService.fileUpload(`${environment.okountUrl}/send_mail?transaction_type=miscellaneous_invoice`,data).subscribe((res : any)=> {
				  this.showMessage = true;
				  this.alertType = 'success-box'
				  this.message = res.msg
				  this.closebutton.nativeElement.click();
				},err => {
				  this.showMessage = true
				  this.alertType = "error-box"
				  this.message = err.msg
				  this.closebutton.nativeElement.click();
				  
				})
			  }
	
	  }


  closeWindow() {
		window.close();
	}

  copytext(val: string) {
		const selBox = document.createElement('textarea');
		selBox.style.position = 'fixed';
		selBox.style.left = '0';
		selBox.style.top = '0';
		selBox.style.opacity = '0';
		selBox.value = val;
		document.body.appendChild(selBox);
		selBox.focus();
		selBox.select();
		document.execCommand('copy');
		document.body.removeChild(selBox);
		this.copyELementTitle = 'Copied'
	}

  prepareTravellers(value){
    this.miscellaneousInvoiceService.travellerCountUpdate(value)
    if(this.miscellaneousInvoiceService.invoiceForm.value.invoice_type !='hotel'){
      setTimeout(() => {
        this.commonService.selectedInput.next(this.staticInputCount+4)
      }, 100);
    }
  }

  openModalMasterCreation(formControlName) {
    if(formControlName == 'client_code') {
      let selectedType = this.miscellaneousInvoiceService.invoiceForm.controls.client_account_type.value;
      this.miscellaneousInvoiceService.invoiceForm.controls.client_code_name.setValue(this.currentMasterName)
      this.currentMaster = selectedType;
      this.currentFormControlName = formControlName
    }else if(formControlName == 'general_ledger_code') {
      this.currentMaster = 'general'
      this.currentFormControlName = formControlName
      this.miscellaneousInvoiceService.invoiceForm.controls.general_ledger_code_name.setValue(this.currentMasterName)
    }else if(formControlName == 'supplier_code') {
      this.currentMaster = 'supplier'
      this.currentFormControlName = formControlName
      this.miscellaneousInvoiceService.invoiceForm.controls.supplier_code_name.setValue(this.currentMasterName)
    }
    else if(formControlName == 'hotel') {
      this.currentMaster = 'hotel'
      this.currentFormControlName = formControlName
      this.miscellaneousInvoiceService.invoiceForm.controls.hotel_name.setValue(this.currentMasterName)
    }

    if(this.currentMaster) {
      this.masterCreationModal.nativeElement.click()
    }
  }

  setFormValues(data) {
    let name = 'name'
    if(this.currentMaster == 'general' || this.currentMaster == 'airline') {
      name = 'account_name';
    }

    this.showMessage = true;
    this.alertType = 'success-box';
    this.message = `${this.currentMaster} created successfully with account code. : ${data.account_code}`
    
    let formControl =  this.currentFormControlName 
    if(formControl) {
      this.miscellaneousInvoiceService.invoiceForm.controls[formControl].setValue(data.account_code ?  data.account_code : '')
      this.miscellaneousInvoiceService.invoiceForm.controls[`${formControl}_name`].setValue(data[name] ?  data[name] : '')
    }
  }

  clickToPrintOptions() {
		this.showPrintOptions = !this.showPrintOptions;
	}

  checkWhetherEInvGeneratedOrNot(invoice) {
		this.commonService.getRequest(`${environment.okountUrl}/e_invoicing/check-invoice?invoiceNumber=${invoice}`).subscribe((res : any)=>{
			if(res && res['message'] && res['message'] == 'not_generated') {
				this.generateEInvoiceModal.nativeElement.click()
				return false;
			} 

			this.getPdfOfEInvoice(invoice)
		},err=>{
			console.log(err)
		})
	}

  closeGenerateEInvoiceModal(){
		this.generateEInvoiceModal.nativeElement.click()
	}

  generateEInvoice(invoice) {
		this.spinner.show()
		this.commonService.postRequest(`${environment.okountUrl}/e_invoicing/miscellaneous?invoiceNumber=${invoice}`, null).subscribe((res : any)=> {
			this.showMessage = true;
			this.alertType = 'success-box'
			this.getPdfOfEInvoice(invoice);
		},err => {
			this.showMessage = true
			this.spinner.hide()
			this.alertType = "error-box"
			this.message = err.error && err.error.message ? err.error.message : ''
		})

	}

  getPdfOfEInvoice(invoice) {
		this.spinner.show()
		this.commonService.downloadPDF(`${environment.okountUrl}/e_invoicing/print/miscellaneous?invoiceNumber=${invoice}&preview=false`, null).subscribe((fileBlob:any) =>{
			let fileName = `E-invoice-${invoice}.pdf` 			
			let blob = new Blob([fileBlob], { 
				type: 'application/pdf'
			});
			this.spinner.hide()
			FileSaver.saveAs(blob, fileName);
	   }, err => {
		    this.spinner.hide()
	   })
	}
  calculateServiceChargeForRail(i, netAmount) {
		let serviceChargeAmount = 0;

    if(netAmount <= 1000) {
      serviceChargeAmount = this.getClientConfigurationAmount("general_train_tkt_0001_to_1000")
    } else if(netAmount > 1000  &&  netAmount <= 2000) {
      serviceChargeAmount = this.getClientConfigurationAmount("general_train_tkt_1000_to_2000")
    } else if(netAmount > 2000 && netAmount <= 3500) {
      serviceChargeAmount = this.getClientConfigurationAmount("general_train_tkt_2000_to_3500")
    } else if(netAmount > 3500 ) {
      serviceChargeAmount = this.getClientConfigurationAmount("general_train_tkt_above_3500")
    }
    if((Number(this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[i].controls.client_sc1_amount.value) > 0) === false) {
      this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[i].controls.client_sc1_amount.setValue(Number(serviceChargeAmount ? serviceChargeAmount : 0));
    }  
  }

  calculateServiceChargeClientWise() {
		let currentClassType = this.miscellaneousInvoiceService.invoiceForm.controls.type.value;
		let serviceChargeAmount = 0;
    if(this.miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel') {
      if(currentClassType == 'domestic') {
        serviceChargeAmount = this.getClientConfigurationAmount("hotel_domestic")
      } else if(currentClassType == 'international') {
        serviceChargeAmount = this.getClientConfigurationAmount("hotel_international")
      } 
    }
		
    if(this.miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'visa') {
      if(currentClassType == 'normal') {
        serviceChargeAmount = this.getClientConfigurationAmount("normal_visa")
      } else if(currentClassType  == 'outstation') {
        serviceChargeAmount = this.getClientConfigurationAmount("outstation_visa")
      }
    }

		let numberSC = Number(serviceChargeAmount ? serviceChargeAmount : 0);
		this.clientServiceCharge = numberSC;

		let travellers = this.miscellaneousInvoiceService.travellerForm.get("travellerList");
		if(travellers && travellers.controls.length>0) {
			for(let item of travellers.controls) {
          if(this.miscellaneousInvoiceService.invoiceForm.value.invoice_type == 'hotel' && item.value.service_started && item.value.service_ended) {
            let dateDifferenceInDays = moment(item.value.service_ended).diff(moment(item.value.service_started), 'days');
            let totalSectorAmount = Number(this.clientServiceCharge) * dateDifferenceInDays;
            if((Number(item.controls.client_sc1_amount.value) > 0) === false) {
              item.controls.client_sc1_amount.setValue(totalSectorAmount);
            }
          }
				}
		}
		
		return numberSC;
	}

	getClientConfigurationAmount(key) {
		let value = 0;
		if(key && this.clientConfiguration && this.clientConfiguration.length>0){
			let selectedItem = this.clientConfiguration.find(item => item.key == key)
			if(selectedItem && selectedItem.value) {
				value = Number(selectedItem.value);
			}
		}
		return value;
	}
  getMasterConfiguration(payableAccountCode, travellerIndex, accountFor){
    this.commonService.getRequest(`${environment.okountUrl}/master_settings?account_code=${payableAccountCode}`).subscribe((res: any) => {
     
      if(res && res.length > 0){
        
        let data = res[0];
        if(accountFor == 'payable') {
          let tds = data && data.json_doc && data.json_doc.value && data.json_doc.value.find(item => item.key == 'tds')
          let gst = data && data.json_doc && data.json_doc.value && data.json_doc.value.find(item => item.key == 'gst')
          if(gst){
            let gstRate = gst.value.find(item => item.key == 'rate')
            if(gstRate && gstRate.value) {
              this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[travellerIndex].controls.supplier_gst_conf.setValue(gstRate.value)
            }
          } else {
            this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[travellerIndex].controls.supplier_gst_conf.setValue(0)
          }
          if(tds){
            let tdsRate = tds.value.find(item => item.key == 'rate')
            if(tdsRate && tdsRate.value) {
              this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[travellerIndex].controls.supplier_tds_conf.setValue(tdsRate.value)
            }
          } else {
            this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[travellerIndex].controls.supplier_tds_conf.setValue(0)
          }

        this.calculateNetAmount(travellerIndex);
        
        }else if(accountFor == 'client' && this.isTenantIndian) {
          // let tcs = data && data.json_doc && data.json_doc.value && data.json_doc.value.find(item => item.key == 'tcs')
          if(this.invoiceMode != 'view') {
            let billOfSupplyObj = data && data.json_doc && data.json_doc.value && data.json_doc.value.find(item => item.key == 'enable_bill_of_supply')
            if(billOfSupplyObj){
              let value = billOfSupplyObj.value;
              if(value == 'TRUE') {
                this.billOfSupplyActive = true;
              }
            }
          } else {
            this.billOfSupplyActive = false
          }

          
          // if(tcs){
          //   let tcsRate = tcs.value.find(item => item.key == 'rate')
          //   if(tcsRate && tcsRate.value) {
          //     this.miscellaneousInvoiceService.invoiceForm.controls.tcs_rate.setValue(tcsRate.value)
              
          //   }
          // } else {
            this.miscellaneousInvoiceService.invoiceForm.controls.tcs_rate.setValue(0)
          // }

        }
      } 
    },err=> {
  })
}

getAccountingCompany() {
  try {
    this.invoiceService.getMethod('util/accounting-company').subscribe(async (res) => {
      if(res) {
        if(res['currency_code']) {
          this.currencyCode = res['currency_code'];
        }
        if(res['native_language_code'] && res['currency_code'] == 'AED') {
          this.nativeLanguageCode = res['native_language_code'];
        }
        if(this.invoiceMode != 'view') {
          this.miscellaneousInvoiceService.invoiceForm.controls.purchase_currency_rate.setValue(1);
          this.miscellaneousInvoiceService.invoiceForm.controls.purchase_currency.setValue(this.currencyCode ? this.currencyCode : '');
        }
        if(res['gst_number']){
          this.isGstNumberExist = true
        }
      }
    },
    err => {
      this.spinner.hide()
    })
  } catch (error) {
    console.log('Error occured in getInvoice due to : ', error)
  }
}

changeSalesCurrencyAmount(event) {
  let value =  this.miscellaneousInvoiceService.invoiceForm.controls.sales_currency_rate.value;
  let rate =  Number(value ? value : 0);
  if(rate) {
    let client_net_amount =  this.miscellaneousInvoiceService.totalFareModel[0].client_net_amount;
    let grandTotal = Number(client_net_amount)
    this.miscellaneousInvoiceService.invoiceForm.controls.sales_currency_amount.setValue((grandTotal * rate).toFixed(2));
  } else {
    this.miscellaneousInvoiceService.invoiceForm.controls.sales_currency_amount.setValue('');
  }
 }

 calculateStaticCount(invoiceType) {
    let staticInput = 16;
      if(invoiceType == 'hotel') {
        staticInput = 16
      } else if(invoiceType == "insurance") {
        staticInput = 15
      } else if(invoiceType == "visa") {
        staticInput = 16
      } else if(invoiceType == "rail") {
        staticInput = 23
      } else if(invoiceType == "car") {
        staticInput = 24
      } else if(invoiceType == "bus") {
        staticInput = 23
      }
      if(!this.isTenantIndian) {
        staticInput = staticInput + 2;
      }
      return staticInput;  
  }

  async filterGeneralAccounts(event, currentIndex) {
		if(!this.generalAccountArr || this.generalAccountArr == undefined || this.generalAccountArr == null) {
			await this.getGeneralAccounts()
		}
		let value = event.target.value;
		if(value) {
			let accounts = this.generalAccountArr.filter(account => (account.name.includes(value) || account.account_code.includes(value)))
			this.generalFilteredData = accounts;
		} else {
			this.generalFilteredData = this.generalAccountArr;
			let traveller = this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[currentIndex]
			if(traveller) {
				traveller.controls['ledger_card_no'].setValue('')
			}
		}
	}

  checkWhetherEInvGenerated(invoice) {
		this.commonService.getRequest(`${environment.okountUrl}/e_invoicing/check-invoice?invoiceNumber=${invoice}`).subscribe((res : any)=>{
			if(res && res['message'] && res['message'] == 'not_generated') {
				this.checkEInvoice= false;
			} else{
				this.checkEInvoice= true
			}
			if(this.cookiesService.getCookie('SYSTACC_USER_LOGIN_DATA')) {
				let userData = JSON.parse(this.cookiesService.getCookie('SYSTACC_USER_LOGIN_DATA'))
        if(userData.tenant_id && environment.BHUPESH_TENANT.split(',').includes(typeof userData.tenant_id=='number'?userData.tenant_id.toString():userData.tenant_id)){
          this.isTesoyBoult = true
			}
		}
		},err=>{
			console.log(err)
			return false
		})
	}
  voucher(invoice_number) {
    this.spinner.show()
		this.commonService.downloadPDF(`${environment.okountUrl}/invoice/miscellaneous/voucher/${invoice_number}?preview=false`, null).subscribe((fileBlob:any) =>{
			let fileName = `VOUCHER-${invoice_number}.pdf` 			
			let blob = new Blob([fileBlob], { 
				type: 'application/pdf'
			});
			this.spinner.hide()
			FileSaver.saveAs(blob, fileName);
	   }, err => {
		    this.spinner.hide()
	   })

  }
  newModel(){
    this.generateAdvanceModal.nativeElement.click()

  }

  createCreditNote(invoice_no) {
		var url=`${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/transactions/ms/ms-invoice/save/purchase/hotel/${invoice_no}`;
		window.open(url,'_blank')
		// this.router.navigate(['/transactions/view-invoice-journal', invoice_no])
	}

  getInvoiceData(searchId: any) {
		try {
			let url = `invoice/miscellaneous/${searchId}`
      this.spinner.show()
			this.invoiceService.getMethod(url).subscribe(async (res) => {
				let invoiceData = res['data'][0];
        let obj = {
					invoice_no: invoiceData.invoice_number,
				}
        this.miscellaneousInvoiceService.fareList.push(obj);
        if(invoiceData.tcs_rate) this.CalculateTcs=true
        
        if(invoiceData && invoiceData.accounting_company) {
          if(invoiceData['accounting_company'].currency_code) {
            this.currencyCode = invoiceData['accounting_company'].currency_code;
          }
          if(invoiceData['accounting_company'].native_language_code && invoiceData['accounting_company'].currency_code == 'AED') {
            this.nativeLanguageCode = invoiceData['accounting_company'].native_language_code;
          }
      }
        
        if(invoiceData.product_type == 'gst'){
          this.spinner.hide()
          this.router.navigate([`/transactions/ms/gst-invoice/view/${searchId}`])
        }else{
          this.invoiceData = res['data'][0];
          this.invoiceType = this.invoiceData.products[0].invoice_type
          // this.handleInvoiceForChange(this.invoiceData.products[0].invoice_type)
          let resp = await this.miscellaneousInvoiceService.bindInvoiceData(invoiceData,this.invoiceMode)
          // this.initDateValidation()
          for (const traveller of this.miscellaneousInvoiceService.travellerList.controls) {
            let resp = await this.calculateNetAmount(this.miscellaneousInvoiceService.travellerList.controls.indexOf(traveller))
          }
          setTimeout(() => {
            let calcResp = this.miscellaneousInvoiceService.calculateFromApi(0)
          
          }, 100);

          this.staticInputCount = this.calculateStaticCount(this.invoiceType);

          this.clientSearch(this.invoiceData.products[0].client_code,false,false)
          this.supplierSearch(this.invoiceData.products[0].supplier_code,false,false)
          this.generalSearch(this.invoiceData.products[0].general_ledger_code,true,false,false)

          if(this.invoiceData.products[0].hotel){
            this.hotelSearch(this.invoiceData.products[0].hotel, false);
          }
          // this.ledgerSearch()
          this.spinner.hide()
        }

        if(this.invoiceMode == 'view'){
          if(this.invoiceType == 'hotel') {
            if(this.miscellaneousInvoiceService.invoiceForm.value.transaction_method == 'sales') {
              this.hasAccessUpdateCode = 'F0002022'
              this.hasAccessDeleteCode = 'F0002023'
            }else {
              this.hasAccessUpdateCode = 'F0003022'
              this.hasAccessDeleteCode = 'F0003023'
            }
          }else if(this.invoiceType == 'insurance'){
            if(this.miscellaneousInvoiceService.invoiceForm.value.transaction_method == 'sales') {
              this.hasAccessUpdateCode = 'F0002032'
              this.hasAccessDeleteCode = 'F0002033'
            }else {
              this.hasAccessUpdateCode = 'F0003032'
              this.hasAccessDeleteCode = 'F0003033'
            }
          }else {
            if(this.miscellaneousInvoiceService.invoiceForm.value.transaction_method == 'sales') {
              this.hasAccessUpdateCode = 'F0002042'
              this.hasAccessDeleteCode = 'F0002043'
            }else {
              this.hasAccessUpdateCode = 'F0003042'
              this.hasAccessDeleteCode = 'F0003043'
            }
          }
        }
			},
				err => {
					this.spinner.hide()
					console.log(err)
				})
		} catch (error) {
      this.spinner.hide()
			console.log('Error occured in getInvoice due to : ', error)
		}
	}
  handleAttachment(event: any) {
		this.attachmentFile = event.target.files[0];
	}

  invoiceDateValidation() {
    let financeYearResponse = this.commonService.checkFinancialYearValidation();
		this.financialYearDiff = financeYearResponse.isFinancialYearDiff;
		this.minDate = financeYearResponse.minDate;
		this.maxDate = financeYearResponse.maxDate;

    this.miscellaneousInvoiceService.invoiceForm.controls.invoice_date.setValue(this.maxDate);
    this.miscellaneousInvoiceService.invoiceForm.controls.invoice_date.setValidators([Validators.required, this.validateDate()]);
    this.miscellaneousInvoiceService.invoiceForm.controls.invoice_date.updateValueAndValidity()
   
  }    

  openBill(invoice){
		this.spinner.show()
		this.commonService.downloadPDF(`${environment.okountUrl}/invoice/miscellaneous/download/${invoice}?currency_bill=true&preview=false`, null).subscribe((fileBlob:any) =>{
			let fileName = `CURRENCY-BILL-${invoice}.pdf` 			
			let blob = new Blob([fileBlob], { 
				type: 'application/pdf'
			});
			this.spinner.hide()
			FileSaver.saveAs(blob, fileName);
	   }, err => {
		    this.spinner.hide()
	   })
	}
  
  selectEmployee(event, currentIndex){
		if(event && event.nzValue) {
			let value = event.nzValue;
			if(value.account_name) {
        this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[currentIndex].controls.lead_pax_name.setValue(value.account_name)
			}
			if(value.account_code) {
				this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[currentIndex].controls.e_code.setValue(value.account_code)
			}
      if(value.cost_center) {
				this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[currentIndex].controls.cost_center.setValue(value.cost_center)
			}
		}
	}

  focusCorporateEmployeeAc(index,nextStep,focusRequired) {
    if (this.corporateEmployeeAc && this.corporateEmployeeAc.focus) {
      this.corporateEmployeeAc.focus()
    }
  }

  async getCorporateEmployee(event, currentIndex) {
		let val;
		if(event) {
			val = event.target.value;
		}
		if(event && currentIndex != null) {
			if(!val || val == null || val == undefined) {
				const traveller = this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[currentIndex]
				traveller.controls['lead_pax_name'].setValue('')
				//traveller.controls['employee'].setValue('')
				traveller.controls['e_code'].setValue('')
				traveller.controls['cost_center'].setValue('')
				
				// setTimeout(() => {
				// 	this.calculateFareFromApi(0)
				// }, 100);
			}
		};
    let client_code = this.miscellaneousInvoiceService.invoiceForm.controls.client_code.value
		try {
			let url = `util/getCorporateEmployee?&client=${client_code}`
			if(val && val.length > 2){
				url = `util/getCorporateEmployee?name=${val}&client=${client_code}`
			}
			this.invoiceService.getMethod(url).subscribe(data => {
				this.employeeArr = data["data"]
				// this.bookingSupplierArrBase = data;
			}, err => {
				throw new Error(JSON.stringify(err));
			})
		} catch (error) {
			console.log('Error while getting Booking Suppliers : ', error)

		} 
	}

  openBosReference() {
    if(this.invoiceData && this.invoiceData.bos_reference) {
      let url = `${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/transactions/ms-invoice-view;searchId=${this.invoiceData.bos_reference};type=miscellaneous`;
      window.open(url, "_blank");
    }
  }

  checkBosApplicableOrNot(product_json_doc) {
    
    if(product_json_doc.travellerList.length>0) {
      for(let item of product_json_doc.travellerList) {
        let gst_amount = Number(item.client_igst) + Number(item.client_cgst) + Number(item.client_sgst);
        let secondGstAmount = Number(item.client_igst_2) + Number(item.client_cgst_2) + Number(item.client_sgst_2);
        let thirdGstAmount = Number(item.client_igst_3) + Number(item.client_cgst_3) + Number(item.client_sgst_3);
        if(((item.client_gst_type == 'S' && Number(gst_amount) > 0) == false) || secondGstAmount>0 || thirdGstAmount>0) {
          return false;
        }
      }
      return true;
    }
  }

  checkHsn(invoice_product) {
    this.isPointOfSupplyError = false
    this.isHsnError = false
    if (invoice_product && invoice_product.travellerList) {
      for (let item of invoice_product.travellerList) {
        if (item.client_cgst != 0 || item.client_sgst != 0 || item.client_igst != 0) {
          if (!item.hsn) {
            this.isHsnError = true
          }
        }
          if (item.client_cgst_2 != 0 || item.client_sgst_2 != 0 || item.client_igst_2 != 0 || (item.client_sc2_taxable=='T' && item.client_sc2_amount != 0)) {
            if (!item.hsn_2) {
              this.isHsnError = true
            }
          }
          if (item.client_cgst_3 != 0 || item.client_sgst_3 != 0 || item.client_igst_3 != 0 || (item.client_sc3_taxable=='T' && item.client_sc3_amount != 0)) {
            if (!item.hsn_3) {
              this.isHsnError = true
            }
          }
      }
     
      
    }
  }

  getCountries() {
		this.commonService.getOkount(`location/country`).subscribe(res => {
			this.countryArr = res
		})
	}
  costCenterSearch(event) {
    try {
        this.invoiceService
          .searchAccount(
            `costCenter?names_like=${event.target.value||''}`
          )
          .subscribe(
            (res) => {
              this.groupList = res['data'];
            },
            (error) => {}
          );
    } catch (error) {}
  }

  validateSalesPerson(inputValue: string): void {
    const isValid = this.salesPersonArr.some(item => item.t_user_name === inputValue);
    if (!isValid) {
      this.miscellaneousInvoiceService.invoiceForm.controls.sales_person_name.setValue(''); // Clear the invalid input
      this.miscellaneousInvoiceService.invoiceForm.controls.sales_person_code.setValue('')
      
    }
  }
  validateReferedby(inputValue: string): void {
    const isValid = this.referedByArr.some(item => item.name === inputValue);
    if (!isValid) {
      this.miscellaneousInvoiceService.invoiceForm.controls.refered_by_name.setValue(''); // Clear the invalid input
      this.miscellaneousInvoiceService.invoiceForm.controls.refered_by_code.setValue('')
      
    }
  }

  validateCostCenter(inputValue: string): void {
    const isValid = this.groupList.some(item => item.name === inputValue);
    if (!isValid) {
      this.miscellaneousInvoiceService.invoiceForm.controls.invoice_cost_center.setValue(''); // Clear the invalid input
      
    }
  }
  toggleMenu() {
		this.menuVisible = !this.menuVisible;
	  }

    getTotalDays(){
      let start_date = this.miscellaneousInvoiceService.invoiceForm.controls.departure_date.value
      let end_date = this.miscellaneousInvoiceService.invoiceForm.controls.arrival_date.value
      if(start_date && end_date){
        let days = moment(end_date).diff(moment(start_date), 'days');
        this.miscellaneousInvoiceService.invoiceForm.controls.total_days.setValue(days)
      }else{
        this.miscellaneousInvoiceService.invoiceForm.controls.total_days.setValue(0)

      }

    }

    changeAmount(formControlName,i){
      if(this.isGSTInclusive=='true'){
      const invoiceData = this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[i].value
      let gstRate = this.getGstRate(i)
      let gst_rate=18
      if(formControlName=='supplier_sc1_amount'){
        gst_rate = Number(gstRate.supplier_gst_rate)
      }
      if(formControlName=='client_sc1_amount'){
        gst_rate = Number(gstRate.client_gst_rate)
      }
      const amount = Number(invoiceData[formControlName]) || 0; // Default to 0 if undefined or invalid
  const gstExclusiveAmount = (amount * 100) / (gst_rate + 100);
      this.miscellaneousInvoiceService.travellerForm.controls.travellerList.controls[i].controls[formControlName].setValue(Number(gstExclusiveAmount.toFixed(2)))
    }
      this.calculateNetAmount(i)
    }

    selectMode(useConsolidateMode: boolean) {
      console.log('event', useConsolidateMode)
      if(useConsolidateMode==true){
        this.miscellaneousInvoiceService.invoiceForm.controls.tcs_rate.setValue(5)
        this.miscellaneousInvoiceService.invoiceForm.controls.tcs_rate_above_7.setValue(20)
        this.miscellaneousInvoiceService.calculateFromApi(0)
      }
      this.CalculateTcs=useConsolidateMode 
      if(useConsolidateMode==false){
        this.miscellaneousInvoiceService.invoiceForm.controls.tcs_rate.setValue(0)
        this.miscellaneousInvoiceService.invoiceForm.controls.tcs_rate_above_7.setValue(0)
        this.miscellaneousInvoiceService.invoiceForm.controls.tcs_amount.setValue(0)
      }

    }

}
