import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { FormGroup, Validators, FormBuilder, NgForm } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment'
import { FormCanDeactivate } from 'src/app/shared/form-can-deactivate/form-can-deactivate';
import { CookiesService } from 'src/app/shared/cookies.service';


@Component({
	selector: 'app-update-client',
	templateUrl: './update-client.component.html',
	styleUrls: ['./update-client.component.scss']
})
export class UpdateClientComponent extends FormCanDeactivate implements OnInit {
	@Input() data;
	@ViewChild('form')
 	form: NgForm;
	clientId: any;
	submitted = false;
	baseUrl = environment.okountUrl;
	keyword = 'name';
	clientForm: FormGroup;
	clientContactForm : FormGroup;
	selectedClient : any;
	groupList: any;
	allClientGroupName: any
	alertType = '';
	message: string = ``;
	countryArr: any = [];
	stateArr: any = [];
	cityArr: any = [];
	showMessage: boolean = false;
	clientData: any;
	clientContacts: any = [];
	salesPersonArr: any = [];
	showCpclient: boolean = false;
	passwordType = 'password'
	isAlreadyRegForCp = false;
	isTenantIndian: boolean = false;
	parentClients: any;
	isTesoyBoult: boolean  = false;
	branches: any = []
	isHQBranch: boolean = false;
	vatAlternetname: string = 'VAT'

	@ViewChild('salesPersonAC',{static : true}) salesPersonAC;
	@ViewChild('confirmModal',{static:true}) confirmModal : ElementRef
	@ViewChild('closeModal',{static:true}) closeModal : ElementRef
	@ViewChild('confirmDeleteModal',{static:true}) confirmDeleteModal : ElementRef
	@ViewChild('closeDeleteModal',{static:true}) closeDeleteModal : ElementRef
	@ViewChild('confirmUpdateModal',{static:true}) confirmUpdateModal : ElementRef
	@ViewChild('closeUpdateModal',{static:true}) closeUpdateModal : ElementRef
	@ViewChild('openContactDetail',{static:true}) openContactDetail : ElementRef
	@ViewChild('closeContactDetail',{static:true}) closeContactDetail : ElementRef
	@ViewChild('addEditContact', {static: true}) addEditContact : ElementRef
	@ViewChild('closeEditContact',{static:true}) closeEditContact : ElementRef
	@ViewChild('addCustomerPortalModal',{static:true}) addCustomerPortalModal : ElementRef
	@ViewChild('closeCustomerPortalModal', {static: true}) closeCustomerPortalModal: ElementRef

	constructor(
		public commonService: CommonServiceService,
		private route: ActivatedRoute,
		private router: Router,
		private fb: FormBuilder,
		private spinner: NgxSpinnerService,
		private cookieService : CookiesService
	) {
		super()
		this.route.paramMap.subscribe(params => {
			this.clientId = params['params'].id
		});
		this.clientForm = this.fb.group({
			parent_id: ['31', Validators.required],
			address_1: [''],
			address_2: [''],
			address_3: [''],
			city_id: [''],
			city_name: [''],
			state_id: [''],
			state_name: [''],
			country_id: ['101'],
			country: [''],
			gst_number: [''],
			gst_expiry_date: [''],
			vat_number: [''],
			telephone: [''],
			mobile_no: [''],
			primary_email: [''],
			secondary_email: [''],
			pan_number: [''],
			is_memo_account:[false],
			is_branch: [false],
			balance: [''],
			remarks: [''],
			name: ['', [Validators.required]],
			account_legal_name: [''],
			contact_person: [''],
			days: [''],
			status: [''],
			balance_type: [''],
			sales_person: [''],
			sales_person_id: [''],
			sales_person_name: [''],
			sub_ledger: [''],
			client_group_id : [''],
			enableCPCheck: [false],
			user_name : [''],
			password: [''],
			currency_rate:[''],
			local_balance: [''],
			currency:[''],
			parent_account_code:[''],
			client_type:[''],
			branch:{}
		})

		this.clientContactForm = this.fb.group({
			account_code: ['',  Validators.required],
			type: ['', Validators.required],
			mobile: ['', Validators.required],
			email: ['', Validators.required],
			description: [''],
			selectedClient: [''],
			contact_person: ['', Validators.required],
			address: ['']
		})

	}

	async ngOnInit() {
		
		this.isTenantIndian = this.commonService.checkTenantIndian()
		if(this.data) {
			this.clientId = this.data.client_id
		}
		if (this.clientId != undefined) {
			this.getClient()
		}
		this.isHQBranch = this.commonService.checkHQBranch()
		this.isTesoyBoult  = this.commonService.checkTesoyBoult()
		if(this.isHQBranch==true){
			this.getAllBranches()
			
			// this.branchForm = this.fb.group({
			// 	formObj
			// })
		}
		if(!this.isTenantIndian){
			let globalSettingConfig = this.commonService.globalSettingConfig;
		if(!globalSettingConfig || globalSettingConfig.length == 0) {
			globalSettingConfig = await this.commonService.getGlobalSettings();
		}
			let inv_config = globalSettingConfig.filter(el=>el.group=='VAT')
		if(inv_config.length){
			let vat_config = inv_config[0].json_doc.value.filter(el=>el.key=='vat_rate')
			let vat_detail,vat_name
			if(vat_config.length){
				 vat_detail = vat_config[0].value
			}
			if(vat_detail && vat_detail.length ){
				vat_name = vat_detail.filter(el=>el.key=='effective_name')
			}
			if(vat_name && vat_name.length){
				this.vatAlternetname = vat_name[0].value
			}
		}
		}
	}

	focusSalesPerson(e): void {
		e.stopPropagation();
		this.salesPersonAC.focus();
	}

	getClient() {
		this.commonService.getOkount(`client?client_id=${this.clientId}`).subscribe(async (res) => {

			if (res[0] != undefined) {
				this.clientData = res[0]
				if(this.clientData && this.clientData.client_type=='child'){
					this.getParentClient();
				}
			}

			if(this.clientData) {
				this.getClientContacts()
			}
			await this.getGroups()
			await this.getAllGroups()
			await this.getCountries()
			await this.getStates(this.clientData.country_id)
			await this.getCities(this.clientData.state_id)
			await this.getCityName(this.clientData.city_id)
			await this.getSalesPerson(this.clientData.sales_person ? this.clientData.sales_person : '')
			await this.setClientData()
		})
	}
	setClientData() {
		if (this.clientForm != undefined && this.clientData != undefined) {
			this.clientForm.controls.parent_id.setValue(this.clientData.parent_id)
			this.clientForm.controls.address_1.setValue(this.clientData.address_1)
			this.clientForm.controls.city_id.setValue(this.clientData.city_id)
			this.clientForm.controls.city_name.setValue(this.clientData.city_name)
			this.clientForm.controls.state_id.setValue(this.clientData.state_id)
			this.clientForm.controls.client_group_id.setValue(this.clientData.client_group_id !==null ? this.clientData.client_group_id : '' )
			this.clientForm.controls.state_name.setValue(this.clientData.state_name)
			this.clientForm.controls.country_id.setValue(this.clientData.country_id)
			this.clientForm.controls.country.setValue(this.clientData.country)
			this.clientForm.controls.gst_number.setValue(this.clientData.gst_number)
			if (this.clientData.gst_expiry_date != null && this.clientData.gst_expiry_date != '') {
				this.clientForm.controls.gst_expiry_date.setValue(moment(this.clientData.gst_expiry_date).format('YYYY-MM-DD'))
			}
			this.clientForm.controls.telephone.setValue(this.clientData.telephone)
			this.clientForm.controls.mobile_no.setValue(this.clientData.mobile_no)
			this.clientForm.controls.primary_email.setValue(this.clientData.primary_email)
			this.clientForm.controls.secondary_email.setValue(this.clientData.secondary_email)
			this.clientForm.controls.pan_number.setValue(this.clientData.pan_number)
			this.clientForm.controls.is_memo_account.setValue(this.clientData.is_memo_account)
			this.clientForm.controls.is_branch.setValue(this.clientData.is_branch)
			this.clientForm.controls.balance.setValue(this.clientData.balance)
			this.clientForm.controls.remarks.setValue(this.clientData.remarks)
			this.clientForm.controls.name.setValue(this.clientData.name)
			this.clientForm.controls.account_legal_name.setValue(this.clientData.account_legal_name)
			this.clientForm.controls.contact_person.setValue(this.clientData.contact_person)
			this.clientForm.controls.days.setValue(this.clientData.days)
			this.clientForm.controls.status.setValue(this.clientData.status)
			this.clientForm.controls.balance_type.setValue(this.clientData.balance_type)
			this.clientForm.controls.sales_person_id.setValue(this.clientData.sales_person_id)
			this.clientForm.controls.sales_person.setValue(this.clientData.sales_person)
			this.clientForm.controls.sales_person_name.setValue(this.clientData.sales_person_name)
			this.clientForm.controls.sub_ledger.setValue(this.clientData.sub_ledger)
			this.clientForm.controls.vat_number.setValue(this.clientData.vat_number)
			this.clientForm.controls.user_name.setValue(this.clientData.user_name ? this.clientData.user_name : '')
			this.clientForm.controls.currency.setValue(this.clientData.currency)
			this.clientForm.controls.currency_rate.setValue(this.clientData.currency_rate)
			this.clientForm.controls.local_balance.setValue(this.clientData.local_balance)
			this.clientForm.controls.parent_account_code.setValue(this.clientData.parent_account_code)
			this.clientForm.controls.client_type.setValue(this.clientData.client_type)
			this.clientForm.controls.enableCPCheck.setValue(this.clientData.is_cp_enable ? this.clientData.is_cp_enable : false)
			this.clientForm.controls.is_branch.setValue(this.clientData.is_branch?this.clientData.is_branch:false)
			if(this.clientData.is_cp_enable) {
				this.isAlreadyRegForCp = true;
			}else {
				this.isAlreadyRegForCp = false
			}
		}
	}

	getCountries() {
		try {
			this.commonService.getOkount(`location/country`).subscribe(res => {
				this.countryArr = res
			})
		} catch (error) {
			
		}
	}

	getStates(country_id) {
		try {
			let selectedCountry = this.countryArr.filter(option => {
				return option.location_id == Number(country_id);
			})
			if (selectedCountry.length > 0) {
				this.clientForm.controls.country.setValue(selectedCountry[0].name)
			} else {
				this.clientForm.controls.country.setValue('India')
			}
			console.log(selectedCountry)
			this.commonService.getOkount(`location/states?country_id=${country_id}`).subscribe(res => {
				this.stateArr = res
			})
		} catch (error) {
			
		}
	}
	getCities(state_id) {
		try {
			let selectedState = this.stateArr.filter(option => {
				return option.location_id == Number(state_id);
			})
			if (selectedState.length > 0) {
				this.clientForm.controls.state_name.setValue(selectedState[0].name)
			}
			this.commonService.getOkount(`location/city?state_id=${state_id}`).subscribe(res => {
				this.cityArr = res
			})
		} catch (error) {
			
		}
	}
	getCityName(city_id) {
		try {
			let selectedCity = this.cityArr.filter(option => {
				return option.location_id == Number(city_id);
			})
			if (selectedCity.length > 0) {
				this.clientForm.controls.city_name.setValue(selectedCity[0].name)
			}
		} catch (error) {
			
		}
	}

	openUpdateConfirmModal(){
		this.confirmUpdateModal.nativeElement.click()
		this.commonService.selectedInput.next(24)
	}

	closeUpdateConfirmModal(){
		this.closeUpdateModal.nativeElement.click()
	}


	updateClient() {

		this.submitted = true;
		this.closeUpdateConfirmModal()
		if (this.clientForm.valid) {
			let formValues = this.clientForm.value
			console.log(formValues)
			if (formValues.sales_person_name?.employee_id != undefined) {
                formValues['sales_person'] = formValues.sales_person_name.account_code
                formValues['sales_person_id'] = formValues.sales_person_name.employee_id
                formValues['sales_person_name'] = formValues.sales_person_name.name
            }
			this.commonService.putRequest(`${this.baseUrl}/client?client_id=${this.clientId}`, this.clientForm.value).subscribe(res => {
				this.showMessage = true
				this.alertType = 'success-box';
				this.message = 'Client updated successfully.'
			}, error => {
				this.showMessage = true
				this.alertType = 'error-box';
				this.message = 'Failed to update Client. Please try Again.'
			})
		}
		else {
			this.showMessage = true
			this.alertType = 'warning-box';
			this.message = 'Please fill mandatory fields.'
			return false;
		}
	}

	getGroups() {
		this.commonService.getOkount('v3account/getallsuperparents').subscribe(res => {
			this.groupList = res
			console.log('Group list : ', this.groupList)
		}, err => {

		})
	}
	getAllGroups(){
		this.commonService.getOkount('v3account/getAllClientGroupName').subscribe(res => {
			this.allClientGroupName = res
		}, err => {

		})
	}
	hideError() {                   
		this.showMessage = false
	}

	getSalesPerson(value) {
		if (value.length > 2) {
			this.commonService.getOkount(`employee/autocomplete?name_like=${value.toUpperCase()}`).subscribe(res => {
				this.salesPersonArr = res
			}, err => {
			})
		}
	}
	selectSalesPerson(value){
		try {
			this.clientForm.controls['sales_person'].setValue(value.account_code)
			this.clientForm.controls['sales_person_id'].setValue(value.employee_id)
			this.clientForm.controls['sales_person_name'].setValue(value.name)
		} catch (error) {
	
		}
	}
	closeWindow() {
		window.close();
	}

	openDeleteConfirmModal(){
		this.confirmDeleteModal.nativeElement.click()
		this.commonService.selectedInput.next(26)
	}

	closeDeleteConfirmModal(){
		this.closeDeleteModal.nativeElement.click()
	}


	deleteClient(){
		// this.submitted = true;
		let clienTid = this.clientData.account_code
		this.closeDeleteConfirmModal()
		if(clienTid != ''){
			this.commonService.deleteRequest(`${this.baseUrl}/client?account_code=${clienTid}`).subscribe((res: any) => {
				// this.clientForm.controls.account_code.setValue('')
				this.clientForm.reset()
				this.showMessage = true
				this.alertType = 'success-box';
				this.message = `Client Deleted.`
				this.spinner.hide();
			},
				err => {
					this.showMessage = true
					this.alertType = 'error-box';
					this.message = err.error && err.error.message?err.error.message:`Failed to delete client.`
					this.spinner.hide();
				})
		} else {
			this.clientForm.reset()
		}
	}

	openConfiguration(client, account_code) {
		client=client.replace("("," ").replace(")"," ").replace("/"," ")
		let path=`${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/master/setting/client/${client}/${account_code}`
		window.open(path,'_blank')
	}
	openActivity(){
		let path=`${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/activity/activity-summary/client/${this.clientData.account_code}`;
		if(window.location.protocol != 'file:'){
			window.open(path,'_blank')
		}else{
			var customeEventHandler = new CustomEvent("handle-window-open", {
				"detail": {
					hashPath : path
				}
			  });
			window.dispatchEvent(customeEventHandler)
		}
	}

	openAddEditContactModal() {
		this.addEditContact.nativeElement.click()
	}

	saveContact() {
		let account_code = this.clientData.account_code
		this.clientContactForm.controls.account_code.setValue(account_code)
		this.commonService.postRequest(`${this.baseUrl}/client/contacts`, this.clientContactForm.value).subscribe(res => {
			this.showMessage = true
			this.alertType = 'success-box';
			this.message = 'Address added successfully.'
			this.getClientContacts()
			this.closeContactDetail.nativeElement.click()
		}, error => {
			this.showMessage = true
			this.alertType = 'error-box';
			this.message = 'Failed to add address for the Client. Please try Again.'
		})
	}

	updateContact() {
		this.commonService.putRequest(`${this.baseUrl}/client/contacts?contact_id=${this.selectedClient.id}`, this.clientContactForm.value).subscribe(res => {
			this.showMessage = true
			this.alertType = 'success-box';
			this.message = 'Address updated successfully.'
			this.getClientContacts()
			this.closeContactDetail.nativeElement.click()
		}, error => {
			this.showMessage = true
			this.alertType = 'error-box';
			this.message = 'Failed to update address for the Client. Please try Again.'
		})	
	}


	getClientContacts() {
		let account_code = this.clientData.account_code
		this.commonService.getOkount(`client/contacts?account_code=${account_code}`).subscribe((res) => {
			if (res != undefined) {
				this.clientContacts = res['data']
				console.log("clientContacts", this.clientContacts)
			}
		})
	}

	submitAddEditContact() {
		    let account_code = this.clientData.account_code
			let value = this.clientContactForm.controls.selectedClient.value
			this.closeEditContact.nativeElement.click()
			if(value == '' || value == null || value == undefined ) {
				this.clientContactForm.controls.account_code.setValue(account_code)
				this.clientContactForm.controls.type.setValue("")
				this.clientContactForm.controls.mobile.setValue("")
				this.clientContactForm.controls.email.setValue("")
				this.clientContactForm.controls.description.setValue("")
				this.clientContactForm.controls.contact_person.setValue("")
				this.clientContactForm.controls.address.setValue("")
				this.openContactDetail.nativeElement.click()
			} else {
				let client = this.clientContacts.find(acc => acc.id == value)
				this.selectedClient = client
				this.clientContactForm.controls.account_code.setValue(client.account_code)
				this.clientContactForm.controls.type.setValue(client.type)
				this.clientContactForm.controls.mobile.setValue(client.mobile)
				this.clientContactForm.controls.email.setValue(client.email)
				this.clientContactForm.controls.description.setValue(client.description)
				this.clientContactForm.controls.contact_person.setValue(client.contact_person)
				this.clientContactForm.controls.address.setValue(client.address)
				this.openContactDetail.nativeElement.click()
			}
	}

	openCustomerPortalModal() {
		let clientEmail = this.clientForm.controls.primary_email.value;
		this.clientForm.controls.user_name.setValue(clientEmail);
		this.passwordType = 'password'
		this.addCustomerPortalModal.nativeElement.click();
	}

	sendCsutomerPortalInvite() {
		let data = {
			user_name : this.clientForm.controls.user_name.value,
			password: this.clientForm.controls.password.value,
			enableCP: this.clientForm.controls.enableCPCheck.value
		}
		this.commonService.postRequest(`${this.baseUrl}/customer_portal?client_id=${this.clientId}`, data).subscribe(res => {
			this.showMessage = true
			this.alertType = 'success-box';
			if(this.clientForm.controls.enableCPCheck.value) {
				this.showCpclient = true;
				this.isAlreadyRegForCp = true;
				this.message = 'Email sent successfully.'
			}else{
				this.showCpclient = false;
				this.isAlreadyRegForCp = false;
				this.message = 'Client Access Removed for access portal'
			}
			this.closeCustomerPortalModal.nativeElement.click();
		}, error => {
			this.showMessage = true
			this.alertType = 'error-box';
			this.message = 'Failed to send email. Please try Again.'
		})
	}

	openCPData() {
		let clientEmail = this.clientForm.controls.primary_email.value;
		this.clientForm.controls.user_name.setValue(clientEmail);
		if(this.clientForm.controls.enableCPCheck.value && !this.isAlreadyRegForCp) {
			this.showCpclient = true;
		}
		if(this.clientForm.controls.enableCPCheck.value == false) {
			this.showCpclient = false
		}
		if(this.isAlreadyRegForCp && this.clientForm.controls.enableCPCheck.value == false) {
			this.sendCsutomerPortalInvite()
		}
	}

	changePasswordType() {
		if(this.passwordType == 'password') {
			this.passwordType = 'text'
		} else {
			this.passwordType = 'password'
		}
	}

	onCancelCustomerPortal() {
		this.clientForm.controls.enableCPCheck.setValue(false);
		this.clientForm.controls.user_name.setValue('');
		this.clientForm.controls.password.setValue('');
		this.showCpclient = false;
	}

	disableCustomerPortal() {
		this.showCpclient = true
	}

	changeCurrentAmount(event) {
		let currency = this.clientForm.controls.currency.value;
		let currency_rate = this.clientForm.controls.currency_rate.value;
		if(currency && currency_rate) {
			let local_balance = this.clientForm.controls.local_balance.value
			this.clientForm.controls.balance.setValue((Number(local_balance) / currency_rate).toFixed(2));
		} else {
			this.clientForm.controls.balance.setValue('')
		}
	}

	currencyChange(value){
		this.clientForm.controls.currency_rate.setValue('');
	
	}

	getParentClient() {
		
			this.clientForm.controls.parent_account_code.setValidators(Validators.required)
			this.clientForm.updateValueAndValidity();
			this.commonService.getOkount(`client/parents`).subscribe(res => {
				if(res && res['data']) {
					this.parentClients = res['data']
				}
			}, err => {

			})
		
	}
	onChangeClient(event) {
		let value = event.target.value;
		if(value == 'child') {
			this.clientForm.controls.parent_account_code.setValidators(Validators.required)
			this.clientForm.updateValueAndValidity();
			this.commonService.getOkount(`client/parents`).subscribe(res => {
				if(res && res['data']) {
					this.parentClients = res['data']
				}
			}, err => {

			})
		}else {
			this.clientForm.controls.parent_account_code.setValue('')
			this.clientForm.controls.parent_account_code.setErrors(null)
			this.clientForm.controls.parent_account_code.setValidators(null)
			this.clientForm.updateValueAndValidity();
		}
	}

	getAllBranches() {
        this.commonService.getOkount(`branch`).subscribe(res=> {
          if(res && res['data']) {
            this.branches = res['data'];
			let formObj = this.branches.reduce((acc,curr)=>{
				if(curr.branch_type=='BRANCH'){
					acc[curr.branch_name]=false
				}
				return acc
			},{})
			console.log("formObj",formObj)
			this.clientForm.controls.branch.setValue(formObj)
          }
        },err=> {
    
        })
      }

	  handleValueChange(e,branchName){
		console.log("branchName",this.clientForm.controls.branch)
		this.clientForm.controls.branch.value[branchName]=e.currentTarget.checked
	  }
}

	
