
 <div *ngIf="showMessage" class="status-modal-box  transition delay scale-1" [ngClass]="{'show-modal-box': showMessage, 'hide-modal-box': !showMessage}">
  <div class="status-card transition w-100per position-fixed top-10 bg-white-color modal-box-card d-flex flex-y-center w-100per justify-space-between p-10-20 bdr-radius-10 mb-10">
    <div class="d-flex flex-y-center">
      <img
        src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/{{alertType=='error-box' ? 'red-circle-error.svg' :
          alertType=='success-box' ? 'green-sucess.svg' : 
          alertType=='warning-box' ? 'red-warning.svg' : 
          null }}"
        width="20px"
        height="20px"
        alt="alert-logo"
        class="mr-20"
      />
      <div class="ml-10">
        <h4 class="m-0 text-size-18 text-bold"
        [ngClass]="{'text-red': alertType=='error-box', 'text-orange-color': alertType=='warning-box', 'text-green' :  alertType=='success-box' }">
          {{alertType === "error-box"
          ? "Error"
          : alertType === "warning-box"
          ? "Warning"
          : alertType === "success-box"
          ? "Success"
          : null}}
        </h4>
        <small className="text-dark-grey-color text-size-12 mt-5 mb-0">
         {{message}}
        </small>
      </div>
    </div>
    <img
      src="{{commonService.aws_asset_url}}/_assets/icons/red-close.svg"
      alt="close-btn"
      (click)="hideError()"
      className=" close-btn"
    />
  </div>
</div>

<div class="ledger-container  ">
  <div class="row tab-nav pl-0 pr-0">
    <!-- <div class="col-sm-4 pl-0">
        <ul class="list-inline list-unstyled p-0 m-0">
            <li class="active page-title text-uppercase cursor"> View Client</li>
        </ul>
    </div> -->
    <div class="col-sm-12 ">
      <ul class="page-nav d-flex align-item-center list-inline list-unstyled p-0 m-0">
          <li  
              class="text-grey text-capitalize cursor  hover-text-primary transition"> Master</li>
          <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
          <li  
              class="text-grey text-capitalize cursor  hover-text-primary transition"> Client</li>
          <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
          <li  
              class="text-grey text-capitalize cursor  hover-text-primary transition"> View Client</li>
          <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
          <li class="text-grey text-capitalize cursor text-primary transition"> Edit Client</li>
      </ul>
  </div>
</div>



    <form [formGroup]="clientForm" #form="ngForm">
    <div style="display: flex; flex-flow: row;">
      <div class="custom-form form-container mt-4" style=" width:100%; max-width: 600px; margin: 0 15px;">
      <div class="select-field text-input-field">
        <select id="selectscroll" class="input-field" tabIndex="1" formControlName="parent_id" [ngClass]="{'error-field': (!clientForm.controls.parent_id.valid && submitted),
        'success-field' : clientForm.controls.parent_id.valid && clientForm.controls.parent_id.dirty}">
          <option selected disabled>SELECT</option>
          <ng-container *ngIf="groupList != undefined">
            <option *ngFor="let item of groupList" value="{{item.id}}">{{item.name}}</option>
          </ng-container>
        </select>
        <label
          [ngClass]="{'error-label': clientForm.controls.parent_id.invalid && clientForm.controls.parent_id.dirty || (!clientForm.controls.parent_id.valid && submitted)}">Group
          <ng-container
            *ngIf="clientForm.controls.parent_id.invalid && clientForm.controls.parent_id.dirty || (!clientForm.controls.parent_id.valid && submitted)">
            <i class="fa fa-info-circle ml-2" title="Group Required" aria-hidden="true"></i>
          </ng-container>
        </label>
      </div>

      <div class="text-input-group d-flex justify-content-between">
        <div class="text-input-field  mr-2">
          <input class="input-field" oninput="this.value = this.value.toUpperCase()" name="name" tabIndex="2"
            formControlName="name" type="text" [ngClass]="{'error-field': clientForm.controls.name.invalid && clientForm.controls.name.dirty  || (!clientForm.controls.name.valid && submitted),
            'success-field' : clientForm.controls.name.valid && clientForm.controls.name.dirty}" />
          <label
            [ngClass]="{'error-label': clientForm.controls.name.invalid && clientForm.controls.name.dirty || (!clientForm.controls.name.valid && submitted)}">Account
            <span class="text-danger">*</span>
            <ng-container
              *ngIf="clientForm.controls.name.invalid && clientForm.controls.name.dirty || (!clientForm.controls.name.valid && submitted)">
              <i class="fa fa-info-circle ml-2" title="Account Required" aria-hidden="true"></i>
            </ng-container>
          </label>
  
        </div>
        <div class="text-input-field">
          <input class="input-field" oninput="this.value = this.value.toUpperCase()" name="contact_person" tabIndex="2"
            formControlName="contact_person" type="text" [ngClass]="{'error-field': clientForm.controls.contact_person.invalid && clientForm.controls.contact_person.dirty  || (!clientForm.controls.contact_person.valid && submitted),
              'success-field' : clientForm.controls.contact_person.valid && clientForm.controls.contact_person.dirty}" />
          <label
            [ngClass]="{'error-label': clientForm.controls.contact_person.invalid && clientForm.controls.contact_person.dirty || (!clientForm.controls.contact_person.valid && submitted)}">Contact Person
            <span class="text-danger">*</span>
            <ng-container
              *ngIf="clientForm.controls.contact_person.invalid && clientForm.controls.contact_person.dirty || (!clientForm.controls.contact_person.valid && submitted)">
              <i class="fa fa-info-circle ml-2" title="Contact Person Required" aria-hidden="true"></i>
            </ng-container>
          </label>
  
        </div>
      </div>
      <div class="text-input-group d-flex justify-content-between">
        <div class="text-input-field mr-2">
          <input class="input-field" oninput="this.value = this.value.toUpperCase()" name="account_legal_name" 
            formControlName="account_legal_name" type="text"/>
          <label>Client Legal Name</label>
        </div>
        <div style="width: 150px;" class="text-input-field mr-2" *ngIf="clientData?.account_code">
          <input class="input-field" [value]="clientData?.account_code" type="text" readonly />
        
          <label>Account Code</label>
        </div>
      </div>

      <div class="text-input-field">
        <input class="input-field" oninput="this.value = this.value.toUpperCase()" name="address_1" tabIndex="3"
          formControlName="address_1" type="text" [ngClass]="{'error-field': clientForm.controls.address_1.invalid && clientForm.controls.address_1.dirty || (!clientForm.controls.address_1.valid && submitted),
          'success-field' : clientForm.controls.address_1.valid && clientForm.controls.address_1.dirty }" />
        <label
          [ngClass]="{'error-label': clientForm.controls.address_1.invalid && clientForm.controls.address_1.dirty || (!clientForm.controls.address_1.valid && submitted)}">Address

          <ng-container
            *ngIf="clientForm.controls.address_1.invalid && clientForm.controls.address_1.dirty || (!clientForm.controls.address_1.valid && submitted)">
            <i class="fa fa-info-circle ml-2" title="Required Address" aria-hidden="true"></i>
          </ng-container>
        </label>

      </div>
      <div class="text-input-group d-flex justify-content-between">
        <div class="text-input-field mr-3">
          <select class="input-field" oninput="this.value = this.value.toUpperCase()" tabIndex="4"
            formControlName="country_id" (change)="getStates($event.target.value)">
            <option *ngFor="let country of countryArr" value="{{country.location_id}}">{{country.name}}</option>
          </select>
          <label>Country</label>
        </div>
        <div class="text-input-field mr-3">
          <select class="input-field" oninput="this.value = this.value.toUpperCase()" tabIndex="5"
            formControlName="state_id" (change)="getCities($event.target.value)">
            <option *ngFor="let state of stateArr" value="{{state.location_id}}">{{state.name}}</option>
          </select>
          <label>State</label>
        </div>
        <div class="text-input-field">
          <select class="input-field" oninput="this.value = this.value.toUpperCase()" tabIndex="6"
            formControlName="city_id" (change)="getCityName($event.target.value)">
            <option *ngFor="let city of cityArr" value="{{city.location_id}}">{{city.name}}</option>
          </select>
          <label>City</label>
        </div>
      </div>

      <div class="text-input-group d-flex justify-content-between">

        <div class="text-input-field mr-3" *ngIf="isTenantIndian">
          <input class="input-field" oninput="this.value = this.value.toUpperCase()" name="gst_number" tabIndex="7"
            formControlName="gst_number" type="text" maxlength="15"
            pattern="^([0][1-9]|[1-2][0-9]|[3][0-7])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$"
            [ngClass]="{'error-field': clientForm.controls.gst_number.invalid && clientForm.controls.gst_number.dirty || (!clientForm.controls.gst_number.valid && submitted),
          'success-field' : clientForm.controls.gst_number.valid && clientForm.controls.gst_number.dirty}" />
          <label
            [ngClass]="{'error-label': clientForm.controls.gst_number.invalid && clientForm.controls.gst_number.dirty} || (!clientForm.controls.gst_number.valid && submitted)">
            GST NUMBER
            <ng-container
              *ngIf="clientForm.controls.gst_number.invalid && clientForm.controls.gst_number.dirty || (!clientForm.controls.gst_number.valid && submitted)">
              <i class="fa fa-info-circle ml-2" title="Invalid GST Number ( ex: 12ABCDE1234F1Z3)"
                aria-hidden="true"></i>
            </ng-container>
          </label>
        </div>

        <div class="text-input-field" *ngIf="isTenantIndian">
          <input class="input-field" oninput="this.value = this.value.toUpperCase()" name="gst_expiry_date" tabIndex="8"
            formControlName="gst_expiry_date" type="date"
            [ngClass]="{'error-field': clientForm.controls.gst_expiry_date.invalid && clientForm.controls.gst_expiry_date.dirty  || (!clientForm.controls.gst_expiry_date.valid && submitted),
            'success-field' : clientForm.controls.gst_expiry_date.valid && clientForm.controls.gst_expiry_date.dirty}" />
          <label
            [ngClass]="{'error-label': clientForm.controls.gst_expiry_date.invalid && clientForm.controls.gst_expiry_date.dirty}  || (!clientForm.controls.gst_expiry_date.valid && submitted)">Expiry
            Date
            <ng-container
              *ngIf="clientForm.controls.gst_expiry_date.invalid && clientForm.controls.gst_expiry_date.dirty  || (!clientForm.controls.gst_expiry_date.valid && submitted)">
              <i class="fa fa-info-circle ml-2" title="required expiry date" aria-hidden="true"></i>
            </ng-container>
          </label>
        </div>

        <div class="text-input-field" *ngIf="!isTenantIndian">
          <input class="input-field" oninput="this.value = this.value.toUpperCase()" name="vat_number" tabIndex="7"
            formControlName="vat_number" type="text" maxlength="20"
            [ngClass]="{'error-field': clientForm.controls.vat_number.invalid && clientForm.controls.vat_number.dirty || (!clientForm.controls.vat_number.valid && submitted),
            'success-field' : clientForm.controls.vat_number.valid && clientForm.controls.vat_number.dirty}" />
          <label
            [ngClass]="{'error-label': clientForm.controls.vat_number.invalid && clientForm.controls.vat_number.dirty} || (!clientForm.controls.vat_number.valid && submitted)">
            {{vatAlternetname}} NUMBER
          </label>
        </div>

      </div>
      <div class="text-input-group d-flex justify-content-between">
        <div class="text-input-field mr-3">
          <input class="input-field" oninput="this.value = this.value.toUpperCase()" name="telephone" tabIndex="9"
            formControlName="telephone" type="text" pattern="([0-9]+)" [ngClass]="{'error-field': clientForm.controls.telephone.invalid && clientForm.controls.telephone.dirty ,
        'success-field' : clientForm.controls.telephone.valid && clientForm.controls.telephone.dirty}" />
          <label
            [ngClass]="{'error-label': clientForm.controls.telephone.invalid && clientForm.controls.telephone.dirty}">Telephone
            <ng-container *ngIf="clientForm.controls.telephone.invalid && clientForm.controls.telephone.dirty">
              <i class="fa fa-info-circle ml-2" title="Invalid Telephone Number" aria-hidden="true"></i>
            </ng-container>
          </label>
        </div>
        <div class="text-input-field">
          <input class="input-field" oninput="this.value = this.value.toUpperCase()" name="mobile_no" minlength="10"
            maxlength="15" tabIndex="10" formControlName="mobile_no" type="text" pattern="([0-9]+)" [ngClass]="{'error-field': clientForm.controls.mobile_no.invalid && clientForm.controls.mobile_no.dirty  || (!clientForm.controls.mobile_no.valid && submitted),
        'success-field' : clientForm.controls.mobile_no.valid && clientForm.controls.mobile_no.dirty}" />
          <label
            [ngClass]="{'error-label': clientForm.controls.mobile_no.invalid && clientForm.controls.mobile_no.dirty  || (!clientForm.controls.mobile_no.valid && submitted)}">Mobile
            No.<span class="text-danger">*</span>
            <ng-container
              *ngIf="clientForm.controls.mobile_no.invalid && clientForm.controls.mobile_no.dirty  || (!clientForm.controls.mobile_no.valid && submitted)">
              <i class="fa fa-info-circle ml-2" title="Invalid Mobile Number" aria-hidden="true"></i>
            </ng-container>
          </label>
        </div>
      </div>
      <div class="text-input-group d-flex justify-content-between">
        <div class="text-input-field mr-3">
          <input class="input-field" oninput="this.value = this.value.toUpperCase()" name="primary_email" tabIndex="11"
            formControlName="primary_email" type="text" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,100})+$"
            [ngClass]="{'error-field': clientForm.controls.primary_email.invalid && clientForm.controls.primary_email.dirty  || (!clientForm.controls.primary_email.valid && submitted),
        'success-field' : clientForm.controls.primary_email.valid && clientForm.controls.primary_email.dirty}" />
          <label
            [ngClass]="{'error-label': clientForm.controls.primary_email.invalid && clientForm.controls.primary_email.dirty  || (!clientForm.controls.primary_email.valid && submitted)}">Email
            #1<span class="text-danger">*</span><ng-container
              *ngIf="clientForm.controls.primary_email.invalid && clientForm.controls.primary_email.dirty  || (!clientForm.controls.primary_email.valid && submitted)">
              <i class="fa fa-info-circle ml-2" title="Invalid Email" aria-hidden="true"></i>
            </ng-container>
          </label>

        </div>

        <div class="text-input-field">
          <input class="input-field" oninput="this.value = this.value.toUpperCase()" name="secondary_email"
            tabIndex="12" formControlName="secondary_email" type="text"
            [ngClass]="{'error-field': clientForm.controls.secondary_email.invalid && clientForm.controls.secondary_email.dirty,
        'success-field' : clientForm.controls.secondary_email.valid && clientForm.controls.secondary_email.dirty}" />
          <label
            [ngClass]="{'error-label': clientForm.controls.secondary_email.invalid && clientForm.controls.secondary_email.dirty}">Email
            #2 <ng-container
              *ngIf="clientForm.controls.secondary_email.invalid && clientForm.controls.secondary_email.dirty">
              <i class="fa fa-info-circle ml-2" title="Invalid Email" aria-hidden="true"></i>
            </ng-container>
          </label>
        </div>
      </div>
      <div class="text-input-group d-flex justify-content-between">
        <div class="text-input-field mr-3">
          <input class="input-field" style="    width: 185px;" oninput="this.value = this.value.toUpperCase()"
            name="pan_number" tabIndex="13" formControlName="pan_number" type="text" maxlength="10"
            pattern="([a-zA-Z]{5})(\d{4})([a-zA-Z]{1})$" [ngClass]="{'error-field': clientForm.controls.pan_number.invalid && clientForm.controls.pan_number.dirty,
        'success-field' : clientForm.controls.pan_number.valid && clientForm.controls.pan_number.dirty}" />
          <label
            [ngClass]="{'error-label': clientForm.controls.pan_number.invalid && clientForm.controls.pan_number.dirty}">PAN
            NO. <ng-container *ngIf="clientForm.controls.pan_number.invalid && clientForm.controls.pan_number.dirty">
              <i class="fa fa-info-circle ml-2" title="Invalid pan number" aria-hidden="true"></i>
            </ng-container>
          </label>
        </div>
        <div class="select-field text-input-field mr-3">
          <select id="selectscroll" class="input-field" tabIndex="1" formControlName="client_group_id" [ngClass]="{'error-field': (!clientForm.controls.client_group_name),
          'success-field' : clientForm.controls.client_group_id}">
            <option value=""  >NONE</option>
              <option *ngFor="let item of allClientGroupName let i=index;" value="{{item.id}}">{{item.client_group_name}}</option>
          </select>
          <label
            [ngClass]="{'error-label': clientForm.controls.parent_id.invalid && clientForm.controls.parent_id.dirty || (!clientForm.controls.parent_id.valid && submitted)}">Client Group
            <ng-container
              *ngIf="clientForm.controls.parent_id.invalid && clientForm.controls.parent_id.dirty || (!clientForm.controls.parent_id.valid && submitted)">
              <i class="fa fa-info-circle ml-2" title="Group Required" aria-hidden="true"></i>
            </ng-container>
          </label>
        </div>
        <div class="text-input-group d-flex justify-content-between">
          <div class="text-input-field" style="    width: 185px;">
            <label class="ngauto-label" for="affname"
              [ngClass]="{'error-label': clientForm.controls.sales_person_name.invalid && clientForm.controls.sales_person_name.dirty || (!clientForm.controls.sales_person_name.valid && submitted)}">Sales
              Person
              <ng-container
                *ngIf="clientForm.controls.sales_person_name.invalid && clientForm.controls.sales_person_name.dirty || (!clientForm.controls.sales_person_name.valid && submitted)">
                <i class="fa fa-info-circle ml-2" title="Sales Person Required" aria-hidden="true"></i>
              </ng-container>
            </label>
            <div class="ng-autocomplete w-100" (focus)="focusSalesPerson($event)" tabIndex="14">
              <ng-autocomplete #salesPersonAC
                [ngClass]="{'error-ng-field': clientForm.controls.sales_person_name.invalid && clientForm.controls.sales_person_name.dirty || (!clientForm.controls.sales_person_name.valid && submitted),
            'success-ng-field' : clientForm.controls.sales_person_name.valid && clientForm.controls.sales_person_name.dirty }"
                [data]="salesPersonArr" [searchKeyword]="keyword" (inputChanged)='getSalesPerson($event)'
                [itemTemplate]="itemTemplate" (selected)="selectSalesPerson($event)" formControlName="sales_person_name"
                [notFoundTemplate]="notFoundTemplate">
              </ng-autocomplete>
              <ng-template #itemTemplate let-item>
                <a innerHTML='<span class="colr-1">{{item.name}}
                                  ({{item.account_code}})</span><br />
                              <span class="colr-2">{{item.primary_email}}</span><br />'>
                </a>
              </ng-template>
              <ng-template #notFoundTemplate let-notFound>
                <div [innerHTML]="notFound"></div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>

      <ng-container *ngIf="!isTenantIndian">
      <div class="text-input-group d-flex justify-content-between">
        
        <div class="select-field text-input-field mr-3" >
          <select id="selectscroll" class="input-field" tabIndex="1" formControlName="currency" disabled (change)="currencyChange($event.target.value)"  >
            <option value="INR">INR</option>
            <option value="CAD">CAD</option>
              <option value="USD">USD</option>
              <option value="AED">AED</option>
              <option value="EURO">EURO</option>
          </select>
          <label>CURRENCY</label>
        </div>
        <div class="text-input-field mr-3">
          <input class="input-field" style="    width: 185px;" oninput="this.value = this.value.toUpperCase()" readonly
            name="currency" tabIndex="13" formControlName="currency_rate" type="text" maxlength="10"
            [ngClass]="{'error-field': clientForm.controls.currency_rate.invalid && clientForm.controls.currency_rate.dirty,
        'success-field' : clientForm.controls.currency_rate.valid && clientForm.controls.currency_rate.dirty}" />
          <label
            [ngClass]="{'error-label': clientForm.controls.currency_rate.invalid && clientForm.controls.currency_rate.dirty}">CURRENCY RATE<ng-container *ngIf="clientForm.controls.currency_rate.invalid && clientForm.controls.currency_rate.dirty">
              <i class="fa fa-info-circle ml-2" title="Invalid pan number" aria-hidden="true"></i>
            </ng-container>
          </label>
        </div>
        <div class="text-input-group d-flex justify-content-between">
          <div class="text-input-field" style="    width: 185px;">
            <input class="input-field" oninput="this.value = this.value.toUpperCase()" name="local_balance" tabIndex="13" readonly
          formControlName="local_balance"  type="text" [ngClass]="{'error-field': clientForm.controls.local_balance.invalid && clientForm.controls.local_balance.dirty,
        'success-field' : clientForm.controls.local_balance.valid && clientForm.controls.local_balance.dirty}" (input)="changeCurrentAmount($event)" />
        <label
          [ngClass]="{'error-label': clientForm.controls.local_balance.invalid && clientForm.controls.local_balance.dirty}">AMOUNT
          <ng-container *ngIf="clientForm.controls.local_balance.invalid && clientForm.controls.local_balance.dirty">
            <i class="fa fa-info-circle ml-2" title="Invalid amount" aria-hidden="true"></i>
          </ng-container>
        </label>
          </div>
        </div>
      </div>
    </ng-container>



      <div class="text-input-group d-flex">
        <div class="text-input-field mr-2 w-100">
          <!-- <label>Remarks</label> -->
          <input class="input-field w-100" oninput="this.value = this.value.toUpperCase()" name="balance" tabIndex="15" [readonly]="!isTenantIndian"
            formControlName="balance" disabled  type="text" [ngClass]="{'error-field': clientForm.controls.balance.invalid && (clientForm.controls.balance.dirty || submitted),
        'success-field' : clientForm.controls.balance.valid && (clientForm.controls.balance.dirty || submitted)}"
        style="cursor: not-allowed;" />
          <label
            [ngClass]="{'error-label': clientForm.controls.balance.invalid && (clientForm.controls.balance.dirty || submitted)}">Opening
            Balance
            <ng-container *ngIf="clientForm.controls.balance.invalid && (clientForm.controls.balance.dirty || submitted)">
              <i class="fa fa-info-circle ml-2" title="Invalid opening balance" aria-hidden="true"></i>
            </ng-container>
          </label>
        </div>
        <div class="select-field text-input-field mr-2">
          <select disabled style="cursor: not-allowed;" class="input-field" tabIndex="16" formControlName="sub_ledger">
            <option value="CC001A">CASH</option>
            <option value="CC001B">CREDIT</option>
          </select>
          <label> Sub Ledger </label>
        </div>
        <div class="select-field text-input-field mr-2" style="width: 100px;">
          <select disabled style="cursor: not-allowed;" class="input-field" style="padding: 2px;" tabIndex="17" formControlName="balance_type">
            <option value="debit" selected>DR</option>
            <option value="credit">CR</option>
          </select>
        </div>
        <div class="select-field text-input-field w-100">
          <select class="input-field" tabIndex="18" formControlName="status">
            <option value="active">ACTIVE</option>
            <option value="inactive">INACTIVE</option>
          </select>
          <label>Status</label>
        </div>
      </div>
      <ng-container *ngIf="isTesoyBoult">
      <div class="text-input-group d-flex justify-content-between">
        <div class="text-input-field">
            <label class="ngauto-label" for="client_type">
              Client Type
            </label>
  
            
              <select class="input-field"  tabIndex="18"
                formControlName="client_type" (change)="onChangeClient($event)">
                <option value="">Select</option>
                <option value="master">Master</option>
                <option value="child">Child</option>
              </select>
          
        </div>
        
        <div class="text-input-field mr-3"  *ngIf="clientForm.controls.client_type.value == 'child'">
          <select class="input-field" tabIndex="20" formControlName="parent_account_code" [ngClass]="{'error-field': (!clientForm.controls.parent_account_code.valid && submitted),
          'success-field' : clientForm.controls.parent_account_code.valid && clientForm.controls.parent_account_code.dirty}">
          <option value="">SELECT</option>
          <ng-container *ngIf="parentClients != undefined">
              <option *ngFor="let item of parentClients" value="{{item.account_code}}">{{item.name}}({{item.account_code}})</option>
            </ng-container>
          </select>
          <label
          [ngClass]="{'error-label': clientForm.controls.parent_id.invalid && clientForm.controls.parent_id.dirty || (!clientForm.controls.parent_id.valid && submitted)}">Group
          <ng-container
            *ngIf="clientForm.controls.parent_id.invalid && clientForm.controls.parent_id.dirty || (!clientForm.controls.parent_id.valid && submitted)">
            <i class="fa fa-info-circle ml-2" title="Group Required" aria-hidden="true"></i>
          </ng-container>
        </label>
        </div>
      
      </div>
    </ng-container>

      <div class="text-input-group">
        <div class="text-input-field mr-2">
          <textarea tabIndex="21" class="input-field" formControlName="remarks"
            oninput="this.value = this.value.toUpperCase()" rows="2"></textarea>
          <label>Remarks</label>
        </div>
      </div>

      <div class="form-check">
        <input class="radio-ui mr-2" type="checkbox" formControlName="is_memo_account" id="isMemo">
        <label cclass="radio-label" style="transform: none;font-size: 12px;margin-bottom: 0;" for="isMemo">
          Is Memo Account
        </label>
      </div>
  
      <!-- Checkbox for is_branch -->
      <div class="form-check">
        <input class="radio-ui mr-2" type="checkbox" formControlName="is_branch" id="isBranch">
        <label class="radio-label" style="transform: none;font-size: 12px;margin-bottom: 0;" for="isBranch">
          Is Branch
        </label>
      </div>

      <div class="select-field text-input-field" *ngIf="isHQBranch">
        <label for="selectedBranch" style="font-size: 13px; font-weight: 500!important;">Branch</label>
        <ng-container *ngFor="let item of branches">
          <div *ngIf="item?.branch_type=='BRANCH'" class="input-fiel" style="font-size: 13px; font-weight: 500!important;">
            <input class="radio-ui mr-2"  type="checkbox"  (change)="handleValueChange($event,item?.branch_name)"  />
            <label class="radio-label"   style="transform: none;font-size: 12px;margin-bottom: 0;" >{{item?.branch_name}}</label>
          </div>
    </ng-container>
      </div>


      <hr>
      <div class="input-fiel Customerradio">
        <input class="radio-ui" id="enable_cp" type="checkbox" name="enableCPCheck" formControlName="enableCPCheck" (change)="openCPData()"/>
        <label class="radio-label" for="enable_cp">Enable Customer Portal</label>
      </div>
      <ng-container *ngIf="showCpclient && !isAlreadyRegForCp">
      <div class="text-input-group d-flex justify-content-between">
        <div class="text-input-field mr-3">
          <input class="input-field" oninput="this.value = this.value.toUpperCase()" name="user_name"
            formControlName="user_name" type="text"  />
          <label for="user_name">User Name</label>
        </div>
        <div class="text-input-field text-input-field-password">
          <input class="input-field" name="password"
            formControlName="password" type="{{passwordType}}" placeholder="password"/>
            <label for="password">Password</label>
            <img (click)="changePasswordType()" class="eyeViewIcon" src="{{passwordType == 'password' ? commonService.aws_asset_url+'/_assets/images/icons/view.svg' : commonService.aws_asset_url+'/_assets/images/icons/eye-hide.svg'}}" />
        </div>
      </div>
      <div style="margin-top: 20px;" class="d-flex align-item-center justify-content-end ">
        <button type="button" class="btn-style bg-purple mr-3" (click)="sendCsutomerPortalInvite()">Save</button>
        <button type="button" class="btn-style bg-green" (click)="onCancelCustomerPortal()">Cancel</button>
      </div>
    </ng-container>
    <div class="d-flex align-item-center justify-content-end " *ngIf="isAlreadyRegForCp == true">
      <button type="button" class="btn-style bg-green" (click)="openCustomerPortalModal()">Reset CP</button>
    </div>
    </div>
    <hr>
    <!-- new changes -->
    <div class='contactCard contact-address ml-5' *ngIf="clientContacts && clientContacts.length > 0">
          <h3>Contacts:</h3>
         <div class="cardCn bg-purple" *ngFor="let item of clientContacts">
            <p><span class="text-black"> {{item.contact_person}}</span><span *ngIf="item.type" class="designation">, {{item.type}}</span></p>
            <p>Email: <span class="text-primary">{{item.email}}</span> </p>
            <p>M: <span class="text-black">{{item.mobile}}</span> </p>
            <p *ngIf="item.address">Address: <span class="text-black  address">{{item.address}}</span></p>
            <p *ngIf="item.description">Description: <span class="text-black">{{item.description}}</span> </p>
        </div>
    </div>  
    <!-- new changes -->
  </div>
      <div class="btn-field bottom-btns-container full-width-btn-container d-flex align-item-center  justify-content-end ml-0 mr-0">        
        <!-- <button type="button" class="btn-style bg-green mr-3" tabIndex="20"
          >save</button> -->
          <button type="button" class="btn-style bg-green mr-3" tabIndex="22">Reset</button>
          <button type="button" class="btn-style bg-cyan mr-3" tabIndex="23"
          *hasAccess="{'key' : 'F0001013'}"
          (click)="openUpdateConfirmModal()">Modify</button>
        
        <button type="button" class="btn-style bg-red mr-3" (click)="openDeleteConfirmModal()"
        *hasAccess="{'key' : 'F0001014'}"
          tabIndex="24">Delete</button>
        <!-- <button type="button" class="btn-style bg-purple" tabIndex="23"
          [routerLink]="['/master/setting/client', clientData.name,clientData.account_code]">Configuration</button> -->

        <!-- <button type="button" class="btn-style bg-purple" tabIndex="23"
          (click)="openConfiguration(clientData.name, clientData.account_code)">Configuration</button> -->
        <button type="button" class="btn-style bg-purple-iris mr-3" tabIndex="25"
        (click)="openConfiguration(clientData.name, clientData.account_code)">Configuration</button>
        <button type="button" class="btn-style bg-indigo mr-3" tabIndex="26" id="receipt_activity_log" (click)="openActivity()">Activity</button> 
        <!-- <button type="button" class="btn-style bg-water-blue mr-3" tabIndex="25" id="modal_btn" (click)="openCustomerPortalModal()">Customer Portal</button>  -->
        <button type="button" class="btn-style bg-cyan" tabIndex="27" id="modal_btn" (click)="openAddEditContactModal()">Add/Edit Contacts</button> 
      </div>
      <br>
    </form>
</div>
<button type="button" hidden #confirmUpdateModal data-toggle="modal" data-target="#updateconfirmationModal"></button>
<div id="updateconfirmationModal" class="modal fade" role="dialog" data-backdrop="false">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Confirmation</h4>
        <button type="button" #closeUpdateModal class="close" data-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        Update {{clientForm?.value?.name}} ?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn-style mr-3" tabIndex="28" attr.tabIndex="28"
        data-dismiss="modal">Close</button>
        <button type="button" class="btn-style bg-green" (click)="updateClient()" tabIndex="29"
          attr.tabIndex="29">Yes</button>
      </div>
    </div>
  </div>
</div>

<button type="button" hidden #confirmDeleteModal data-toggle="modal" data-target="#deleteConfirmationModal"></button>

<div id="deleteConfirmationModal" class="modal fade" role="dialog" data-backdrop="false">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Confirmation</h4>
        <button type="button" #closeDeleteModal class="close" data-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        <p>Are you sure to delete ‎this entry ?</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn-style" tabIndex="30" attr.tabIndex="30" (click)="closeDeleteConfirmModal()"
        data-dismiss="modal">No</button>
        <button type="button" class="btn-style bg-red" tabIndex="31" attr.tabIndex="31" (click)="deleteClient()"
          data-dismiss="modal">Yes</button>
      </div>
    </div>
  </div>
</div>


<button type="button" hidden #openContactDetail data-toggle="modal" data-target="#contactDetailModal"></button>
  <div
    class="modal"
    id="contactDetailModal"
    aria-labelledby="contactDetailModal"
    data-backdrop="false"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content" style="margin-top:100px;">
        <div class="modal-header">
          <div class="d-flex justify-content-center">
            <h4 class="modal-title">{{clientForm.controls.name.value}}'s Additional Contacts</h4>
          </div>
          <button
            type=" button"
            class="close"
            #closeContactDetail
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form [formGroup]="clientContactForm" >
            <div>

              <div class="form-group text-input-field ">
                <input
                  class="input-field ng-pristine ng-valid ng-touched text-size-12"
                  id="contact_person"
                  style="max-height: 45px;"
                  formControlName="contact_person"
                  required />
                <label for="contact_person" class="mb-0">Contact Person</label>
              </div>

              <div class="form-group text-input-field">
                <select
                  class="input-field ng-pristine ng-valid ng-touched text-size-12"
                  id="type"
                  formControlName="type"
                  required
                >
                <option value="">select</option>
                <option value="owner">Owner</option>
                <option value="accountant">Accountant</option>
                <option value="sales">Sales</option>
                <option value="counter_staff">Counter staff</option>
                <option value="other">Other</option>
              </select>
                <label for="type" class="mb-0">Contact Type</label>
              </div>

              <div class="form-group text-input-field">
                <textarea
                  class="input-field ng-pristine ng-valid ng-touched text-size-12"
                  rows="3"
                  id="address"
                  formControlName="address"
                ></textarea>
                <label for="address" class="mb-0">Address</label>
              </div>
  
              <div class="form-group text-input-field ">
                <input
                  class="input-field ng-pristine ng-valid ng-touched text-size-12"
                  id="mobile"
                  style="max-height: 45px;"
                  formControlName="mobile" 
                  required/>
                <label for="mobile" class="mb-0">Mobile No.</label>
              </div>
  
              <div class="form-group text-input-field ">
                <input
                  class="input-field ng-pristine ng-valid ng-touched text-size-12"
                  id="email"
                  style="max-height: 45px;"
                  formControlName="email"  required/>
                <label for="email" class="mb-0">Email</label>
              </div>
             
              <div class="form-group text-input-field">
               <textarea
                 class="input-field ng-pristine ng-valid ng-touched text-size-12"
                 rows="3"
                 id="description"
                 formControlName="description"
               ></textarea>
               <label for="description" class="mb-0">Description</label>
             </div>
            </div>
            
            <div class="mt-3 d-flex justify-content-center align-item-center">
              <button type="button" class="btn-style mr-2" data-dismiss="modal">
                Cancel
              </button>
              <button
                type="button"
                class="btn-style bg-green"
                [disabled]="!clientContactForm.valid"
                (click)="clientContactForm.controls.selectedClient.value == '' ? saveContact() : updateContact()">
                Save Address
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>


  <button type="button" hidden #addEditContact data-toggle="modal" data-target="#addEditContactModal"></button>
  <div
    class="modal"
    id="addEditContactModal"
    aria-labelledby="addEditContactModal"
    data-backdrop="false"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content" style="margin-top:100px;max-width: 300px;">
        <div class="modal-header">
          <div class="d-flex justify-content-center">
            <h4 class="modal-title">{{clientForm.controls.name.value}}'s Add/Edit Contact</h4>
          </div>
          <button
            type=" button"
            class="close"
            #closeEditContact
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form [formGroup]="clientContactForm" >
            <div class="form-group text-input-field">
              <select class="input-field ng-pristine ng-valid ng-touched text-size-12" formControlName="selectedClient" >
                <option value="" class="text-red">Add new contact</option>
                <option *ngFor="let item of clientContacts" value="{{item.id}}">{{item.contact_person}}</option>
              </select>
            </div>

            <div class="mt-3 d-flex justify-content-center align-item-center">
              <button type="button" class="btn-style mr-2" data-dismiss="modal">
                Cancel
              </button>
              <button
                type="button"
                class="btn-style bg-green"
                (click)="submitAddEditContact()">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>



  <button type="button" hidden #addCustomerPortalModal data-toggle="modal" data-target="#addCustomerPortalForm"></button>
  <div class="modal addCustomerPortalForm" id="addCustomerPortalForm" aria-labelledby="addCustomerPortalForm" data-backdrop="false">
    <div class="modal-dialog" role="document">
      <div class="modal-content" style="margin-top:124px;max-width: 300px;">
        <div class="modal-header">
          <div class="d-flex justify-content-center">
            <h4 class="modal-title">Customer Portal</h4>
          </div>
          <button
            type=" button"
            class="close"
            #closeCustomerPortalModal
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form [formGroup]="clientForm" >
            <div class="form-group text-input-field ">
              <label for="user_name" class="mb-0">Email Id</label>
              <input
                class="input-field ng-pristine ng-valid ng-touched text-size-12"
                id="user_name"
                style="max-height: 45px;"
                formControlName="user_name" />
            </div>
           
            <div class="form-group text-input-field ">
              <label for="password" class="mb-0">Password</label>
              <input
                class="input-field ng-pristine ng-valid ng-touched text-size-12"
                id="password"
                type="{{passwordType}}"
                style="max-height: 45px;"
                formControlName="password" />
              <img (click)="changePasswordType()" class="eyeViewIcon" src="{{passwordType == 'password' ? commonService.aws_asset_url+'/_assets/images/icons/view.svg' : commonService.aws_asset_url+'/_assets/images/icons/eye-hide.svg'}}" />
            </div>

            <div class="mt-4 d-flex justify-content-center align-item-center">
              <button type="button" class="btn-style bg-green mr-2" data-dismiss="modal">
                Cancel
              </button>
              <button
                type="button"
                class="btn-style bg-purple"
                (click)="sendCsutomerPortalInvite()">
                Send Request
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
