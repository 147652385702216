import { Component, OnInit, ViewChild, ElementRef, ViewChildren, QueryList, Input } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormArray, Validators, NgForm, AbstractControl, FormGroup } from '@angular/forms';
import { CommonServiceService } from 'src/app/shared/common-service.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
// import { invoiceService } from '../services/invoice.service';
import * as moment from 'moment-timezone';
import { FormCanDeactivate } from 'src/app/shared/form-can-deactivate/form-can-deactivate';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { InvoiceServicesService } from '../../invoice/flight/services/invoice-services.service';
import { CookiesService } from 'src/app/shared/cookies.service';


@Component({
  selector: 'app-add-entry-purchase-invoice',
  templateUrl: './add-entry-purchase-invoice.component.html',
  styleUrls: ['./add-entry-purchase-invoice.component.scss'],
	// providers: [invoiceService]
})
export class AddEntryPurchaseInvoiceComponent extends FormCanDeactivate  implements OnInit {
	@Input() data
	@ViewChild('form')
 	form: NgForm;
	invoiceData: any;
	serviceArray: FormArray
	purchaseInvoiceForm: any;
	selectedDate;
	invoice_no: any;
	keyword = "name"
	sendEmailForm: FormGroup;
	menuVisible: boolean = false;
	showMessage: boolean = false;
	alertType = '';
	message: string = ``;
	isAadesh: boolean = false;
	accountList:any;
	subledgeraccountList:any
	ledgerList: any;
	attachmentFile: File | null = null;
	@ViewChild("subLedgerAC", { static: true }) subLedgerAC;
	@ViewChild("accountAc", { static: true }) accountAc;
	@ViewChildren('subLedgerAc') subLedgerAc: QueryList<ElementRef>;
	lastInputIndex = 8;
	@ViewChild('confirmModal',{static:true}) confirmModal : ElementRef
	@ViewChild('closeModal',{static:true}) closeModal : ElementRef

	@ViewChild('confirmDeleteModal',{static:true}) confirmDeleteModal : ElementRef
	@ViewChild('closeDeleteModal',{static:true}) closeDeleteModal : ElementRef
	@ViewChild('deleteConfirmBtn',{static:true}) deleteConfirmBtn : ElementRef

	@ViewChild('confirmUpdateModal',{static:true}) confirmUpdateModal : ElementRef
	@ViewChild('closeUpdateModal',{static:true}) closeUpdateModal : ElementRef

	@ViewChild('updateConfirmBtn',{static:true}) updateConfirmBtn : ElementRef
	@ViewChild('updateButton',{static:true}) updateButton : ElementRef
	@ViewChild('openEmailModal') openEmailModal;
	@ViewChild('closeSendEmailModal') closebutton;
	@ViewChild("openSuccessModal", { static: true }) openSuccessModal;

	@ViewChild("closeMasterCreationModal", { static: true }) closeMasterCreationModal : ElementRef;
	@ViewChild("openMasterCreationModal", { static: true }) masterCreationModal : ElementRef;
	shortcuts: ShortcutInput[] = [];
	currentOpenedModal = ''
	minDate;
  	maxDate;
	invoiceMode = 'save';
	newInvoice: boolean = true;
	copyELementTitle = 'Click to Copy'
	transactionType: any;
	currentMasterInputName = ''
	showCreateButton = false;
	currentMaster=''
	currentGeneralIndex;
	constructor(
		public commonService: CommonServiceService,
		private spinner: NgxSpinnerService,
		private fb: FormBuilder,
		private route: ActivatedRoute,
		private httpClient: HttpClient,
		private router: Router,
		private invoiceService : InvoiceServicesService,
		private cookiesService: CookiesService

	) {
		super()
		this.purchaseInvoiceForm = this.fb.group({
			invoice_date: [moment().format('YYYY-MM-DD'),Validators.required],
			supp_ref_number: [''],
			invoice_number: [''],
			account_type: ['supplier'],
			account_code: ['', Validators.required],
			account_code_name: ['', Validators.required],
			sub_total: [''],
			gst_total: [''],
			tds_total: [''],
			total: [''],
			narration_1: [''],
			narration_2: [''],
			narration_3: [''],
			narration_4: [''],
			narration_5: [''],
			transaction_method:[''],
			employee_code: [''],
			employee_code_name: [''],
			subledger_type: ["client"],
			subledger_code: [""],
			subledger_code_name: [""],
			crn_against:[''],
			serviceArray: this.fb.array([]),
			rcm_applicable: [false],
			// reverse_tds: [false]

		});
		this.sendEmailForm = this.fb.group({
			toEmails: ["",Validators.required],
			bccEmails: [''],
			ccEmails:[''],
			subject:[''],
			body:['']
		})
		this.serviceArray = this.purchaseInvoiceForm.get("serviceArray") as FormArray;
		this.route.paramMap.subscribe(params => {
			this.invoice_no = params['params'].invoice
			this.invoiceMode = params['params'].mode ? params['params'].mode : 'save'
			this.transactionType = params['params'].transaction_type
		});

	}

	async ngOnInit() {
		/** spinner starts on init */
		this.spinner.show();

		setTimeout(() => {
			/** spinner ends after 5 seconds */
			this.spinner.hide();
		}, 1000);

		if (this.data) {
			this.invoice_no = this.data.invoice
			this.invoiceMode = this.data.mode;
			this.transactionType = this.data.transaction_type
		}

		if(!this.transactionType && this.invoice_no !=undefined){
			this.invoiceMode ='view'
		}else{
			this.invoiceMode ='save'
		}
		console.log('mode', this.invoiceMode);
		let empConfiguration = this.commonService.employeeConfiguration;
		if (!empConfiguration || empConfiguration.length == 0) {
			let loggedInUser = JSON.parse(localStorage.okountUserInfo);
			empConfiguration = await this.commonService.getConfiguration(loggedInUser.accountcode)
		}

		if(this.invoiceMode == 'save' && this.invoice_no != undefined){
			this.getData()
		  }else if (this.invoiceMode == 'view' && this.invoice_no != undefined) {
			this.getInvoiceData()
		} else {
			this.addInvoiceRow()
		}

		this.commonService.selectedInput.next(0)

		let loggedInUser = JSON.parse(localStorage.okountUserInfo);
		if (this.invoiceMode != 'view') {
			this.purchaseInvoiceForm.controls.employee_code_name.setValue(
				loggedInUser.name ? loggedInUser.name.toUpperCase() : ''
			);
			this.purchaseInvoiceForm.controls.employee_code.setValue(
				loggedInUser.accountcode
			);
			this.purchaseInvoiceForm.controls.transaction_method.setValue(
				this.transactionType
			);
		}
		let globalSettingConfig = this.commonService.globalSettingConfig;
		if (!globalSettingConfig || globalSettingConfig.length == 0) {
			globalSettingConfig = await this.commonService.getGlobalSettings();
		}
		this.minDate = moment().subtract(15, 'days').format('YYYY-MM-DD')
		let config = this.commonService.getConfigurationByKey('flight_invoice')
		if (config) {
			let dateConfig = config['value'].filter(data => data.key == 'minimum_update_date')
			let daysConfig = config['value'].filter(data => data.key == 'minimum_update_days')
			let dateWithMinDate = moment(dateConfig ? dateConfig[0].value : '')
			let dateWithMinDays = dateConfig && daysConfig[0].value ? moment().subtract(Number(daysConfig[0].value), 'days') : dateWithMinDate
			if (dateWithMinDays.isBefore(dateWithMinDate)) {
				this.minDate = dateWithMinDate.format('YYYY-MM-DD')
			} else {
				this.minDate = dateWithMinDays.format('YYYY-MM-DD')
			}
		}
		this.ledgerSearch(this.purchaseInvoiceForm.value.subledger_code ? this.purchaseInvoiceForm.value.subledger_code :'')

		this.maxDate = moment().format('YYYY-MM-DD')
		this.purchaseInvoiceForm.controls.invoice_date.setValidators([Validators.required, this.validateDate()])
		this.purchaseInvoiceForm.controls.invoice_date.updateValueAndValidity()
		let userData = JSON.parse(this.cookiesService.getCookie('SYSTACC_USER_LOGIN_DATA'))
		if(userData && userData.tenant_id && userData.tenant_id == environment.aadeshSystaccId) {
			this.isAadesh = true;
		}
	}


	validateDate(){
		return (control: AbstractControl) => {
			let valid = true
			if(this){
				if (moment(control.value).isBefore(this.minDate) || moment(control.value).isAfter(this.maxDate)) {
					if(this.invoiceData && this.invoiceData.length > 0 && this.invoiceData[0].invoice_date && moment(control.value,'YYYY-MM-DD').isSame(moment(this.invoiceData[0].invoice_date,'YYYY-MM-DD'))){
						valid = true
					}else{
						valid = false
					}
				}
			}
			return !valid ? {invoice_date: {value: control.value}} : null;
		};
	}

	ngAfterViewInit(){

		this.shortcuts.push(			
			{
				key: "alt + e",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Update invoice Entry",
				description: "Update invoice Entry",
				
				command: (e)=>{
				console.log(e)	
			if(document.getElementById("modify_invoice_btn")){

				document.getElementById("modify_invoice_btn").click()
				setTimeout(() => {
				document.getElementsByTagName('body')[0].click()
				}, 100);
			}
					},
					preventDefault: true
			},
			{
				key: "alt + d",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Delete invoice Entry",
				description: "Delete invoice Entry",
				
				command: (e)=>{
				console.log(e)	
			if(document.getElementById("delete_invoice_btn")){

				document.getElementById("delete_invoice_btn").click()
				setTimeout(() => {
				document.getElementsByTagName('body')[0].click()
				}, 100);
			}
					},
					preventDefault: true
			},
			{
				key: "alt + l",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Activity Log Of invoice Entry",
				description: "Activity Log Of invoice Entry",
				
				command: (e)=>{
				console.log(e)	
			if(document.getElementById("invoice_activity_log_btn")){

				document.getElementById("invoice_activity_log_btn").click()
				setTimeout(() => {
				document.getElementsByTagName('body')[0].click()
				}, 100);
			}
					},
					preventDefault: true
			},
			{
				key: "alt + plus",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Add New invoice Row",
				description: "Add New invoice Row",
				
				command: (e)=>{
				console.log(e)	
					this.addInvoiceRow()
					setTimeout(() => {
						document.getElementsByTagName('body')[0].click()
						}, 100);
					},
					preventDefault: true
			},
			{
				key: "alt + -",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Delete Last invoice Row",
				description: "Delete Last invoice Row",
				
				command: (e)=>{
				console.log(e)	
				if(this.serviceArray.length>0){
					this.removeInvoiceRow(this.serviceArray.length-1)
					setTimeout(() => {
					document.getElementsByTagName('body')[0].click()
					}, 100);
				}
						},
						preventDefault: true
			},
			{
				key: "alt + y",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Clicked On Yes Button",
				description: "Clicked On Yes Button",
				
				command: (e)=>{
				if(this.currentOpenedModal=='update'){
					this.updateSubmit(this.purchaseInvoiceForm.value)
					setTimeout(() => {
						document.getElementsByTagName('body')[0].click()
						}, 100);
				}else if(this.currentOpenedModal=='delete'){
					this.deleteInvoice(this.invoice_no)
					setTimeout(() => {
						document.getElementsByTagName('body')[0].click()
						}, 100);
				}
				this.currentOpenedModal = ''
					},
					preventDefault: true
			},
			{
				key: "alt + n",
				allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
				label: "Clicked On No Button",
				description: "Clicked On No Button",
				
				command: (e)=>{
				if(this.currentOpenedModal=='update'){
					this.closeUpdateConfirmModal()
					setTimeout(() => {
						document.getElementsByTagName('body')[0].click()
						}, 100);
				}else if(this.currentOpenedModal=='delete'){
					this.closeDeleteConfirmModal()
					setTimeout(() => {
						document.getElementsByTagName('body')[0].click()
						}, 100);
				}
				this.currentOpenedModal = ''
					},
					preventDefault: true
			},
			
	  )
	}

	openConfirmModal(lastIndex,addIndex) :  void{
		this.hideError()
		this.confirmModal.nativeElement.click()
		this.moveToTab(lastIndex,addIndex)
	}
	closeConfirmModal() :  void{
		console.log('Modal Closed')
		if (this.closeModal) {
			this.closeModal.nativeElement.click()
		}
	}

	focusClientAc() {
		if (this.accountAc && this.accountAc.focus) {
		  this.accountAc.focus()
		}
	}

	movetoIndex(index){
		this.lastInputIndex = ((index+1)*8)
		setTimeout(() => {
			this.commonService.selectedInput.next(this.lastInputIndex+1)
		}, 100);
	}
	moveToTab(lastInputIndex,addIndex){
		this.commonService.selectedInput.next(lastInputIndex+addIndex)
	}

	addInvoiceRow() {
		this.closeConfirmModal()
		this.serviceArray.push(this.buildInvoiceEntries());
	}

	removeInvoiceRow(index) {
		this.serviceArray.removeAt(index);
		this.movetoIndex(this.serviceArray.length - 1)
	}

	getAccounts(event) {
		let value = event.target.value;
		let searchType = this.purchaseInvoiceForm.controls.account_type.value;
		if (searchType == null) {
			this.purchaseInvoiceForm.controls.account_type.setValue("supplier");
			searchType = this.purchaseInvoiceForm.controls.account_type.value;
		}

		this.currentMasterInputName = value;
		this.currentMaster = 'supplier'
			this.getMethod(`${searchType}/autocomplete?name_like=${value.toUpperCase()}&status=active`).subscribe(res => {
				this.accountList = res

				if(!this.accountList || this.accountList.length == 0) {
					this.showCreateButton = true;
				}else {
					this.showCreateButton = false;
				}
			}, err => {
		})
	}

	generalSearch(event) {
		let value = event.target.value;
		this.currentMasterInputName = value;
		this.currentMaster = 'general'
		try {
		  if (value.length > 2) {
			this.getMethod(
				`general/autocomplete?name_like=${value.toUpperCase()}&status=active`
			  )
			  .subscribe(
				(res) => {
				  this.ledgerList = res;
				 	if(!this.ledgerList || this.ledgerList.length == 0) {
						this.showCreateButton = true;
					} else {
						this.showCreateButton = false;
					}
				},
				(error) => {}
			  );
		  }
		} catch (error) {
		  console.error(error)
		}
	  }
	

  getMethod(url){
		const headers = new HttpHeaders({
			'Content-Type': 'application/json'
		});
		return this.httpClient.get(`${environment.okountUrl}/${url}`, { headers: headers })
			.pipe(map(res => {
				return res;
			}),
			catchError((err) => {
				return throwError(err);
			}))
	}

	get DynamicFormControls() {
		return <FormArray>this.purchaseInvoiceForm.get("serviceArray");
	}

	dateSelection(event) {
		let invoiceFormGroupControl = this.purchaseInvoiceForm.controls.serviceArray.controls
		this.selectedDate = moment(event).format('YYYY-MM-DD')
		for (let i = 0; i < invoiceFormGroupControl.length; i++) {
			const invoiceControl = invoiceFormGroupControl[i];
			invoiceControl.controls.invoice_date.setValue(this.selectedDate)
		}
	}

	searchAccount(value, i) {
		try {
			let searchType = this.purchaseInvoiceForm.controls.serviceArray.controls[i].value.account_type
			if (value.length > 2 && searchType != null && searchType != '') {
				this.commonService.getRequest(`${environment.okountUrl}/${searchType}/autocomplete?name_like=${value.toUpperCase()}&status=active`).subscribe(res => {
					this.accountList[i] = res
				});
			}
		} catch (error) {
			console.log('Error occured while account autoo complete search : ',error)
		}
	}
	selectAccount(nzEvent, formControlName, index) {
		let data = nzEvent && nzEvent.nzValue?nzEvent.nzValue:nzEvent
		console.log("formControlName",formControlName)
		console.log("data",data)

		if(this.currentMaster == 'general') {
			this.currentGeneralIndex = index;
		}
		if(nzEvent.nzValue == "") {
			this.masterCreationModal.nativeElement.click()
		} else {
			if(formControlName == 'account_code') {
				this.purchaseInvoiceForm.controls.account_code.setValue(data.account_code ? data.account_code : '')
				this.purchaseInvoiceForm.controls.account_code_name.setValue(data.name ? data.name : '')
			}else if(formControlName == 'subledger_code') {
				this.purchaseInvoiceForm.controls.subledger_code.setValue(data.account_code ? data.account_code : '')
				this.purchaseInvoiceForm.controls.subledger_code_name.setValue(data.name ? data.name : '')
			}else {
				this.purchaseInvoiceForm.controls.serviceArray.controls[index].controls[formControlName].setValue(data.account_code ? data.account_code : '')
				this.purchaseInvoiceForm.controls.serviceArray.controls[index].controls[`${formControlName}_name`].setValue(data.name ? data.name : '')
			}
			
		}
		
	}
	searchLedger(value, i) {
		try {
			let searchType = this.purchaseInvoiceForm.controls.serviceArray.controls[i].value.sub_ledger_type
			if (value.length > 2 && searchType != null && searchType != '') {
				this.commonService.getRequest(`${environment.okountUrl}/${searchType}/autocomplete?name_like=${value.toUpperCase()}&status=active`).subscribe(res => {
					this.ledgerList[i] = res
					console.log(this.ledgerList)
				});
			}
		} catch (error) {

		}
	}
	selectLedger(data, formControlName, index) {
		this.purchaseInvoiceForm.controls.serviceArray.controls[index].controls[formControlName].setValue(data.account_code)
	}

	calculateAmount(index, formControlName) {
		let formControls = this.purchaseInvoiceForm.controls.serviceArray.controls[index].controls;
		let rcm_applicable = this.purchaseInvoiceForm.controls.rcm_applicable.value
    	// let reverse_tds = this.purchaseInvoiceForm.controls.reverse_tds.value
		if(formControls.quantity.value && formControls.rate.value) {
			let amountOfRow = Number(formControls.rate.value ? formControls.rate.value : 0) * Number(formControls.quantity.value ? formControls.quantity.value : 1)
			this.purchaseInvoiceForm.controls.serviceArray.controls[index].controls['amount'].setValue(amountOfRow.toFixed(2));
		}

		let totalGst = this.getTotalGst()
		let totalTds = this.getTotalTds()
		let subtotal = this.calculateSubTotal()
		this.purchaseInvoiceForm.controls.gst_total.setValue(Number(totalGst).toFixed(2))
		this.purchaseInvoiceForm.controls.tds_total.setValue(Number(totalTds).toFixed(2))
		this.purchaseInvoiceForm.controls.sub_total.setValue(Number(subtotal).toFixed(2))
		let total_amount = (rcm_applicable ? 0 : (totalGst ? Number(totalGst) : 0)) - (totalTds ?  Number(totalTds): 0) + (subtotal ? Number(subtotal) : 0)
		this.purchaseInvoiceForm.controls.total.setValue(Number(total_amount).toFixed(2));
	}
	

	calculateSubTotal() {
		let amount = 0
		if (this.purchaseInvoiceForm.controls.serviceArray.controls.length > 0) {
			let controls = this.purchaseInvoiceForm.controls.serviceArray.controls
			for (let index = 0; index < controls.length; index++) {
				const element = controls[index];
				let totalAmountRowWise = Number(element.value.amount);
				if(totalAmountRowWise) {
					amount += totalAmountRowWise;
				}
			}
		}
		let fixedAmount = amount.toFixed(2)
		return fixedAmount;
	}

	getTotalGst() {
		let amount = 0
		if (this.purchaseInvoiceForm.controls.serviceArray.controls.length > 0) {
			let controls = this.purchaseInvoiceForm.controls.serviceArray.controls
			for (let index = 0; index < controls.length; index++) {
				const element = controls[index];
				let totalAmountRowWise = Number(element.value.rate) * Number(element.value.quantity);
				let gstRateOfRow = Number(element.value.gst_rate ? element.value.gst_rate : null) 
				if(gstRateOfRow) {
					amount += (gstRateOfRow * totalAmountRowWise)/100
				}
			}
		}
		let fixedAmount = amount.toFixed(2)
		return fixedAmount;
	}

	getTotalTds() {
		let amount = 0
		if (this.purchaseInvoiceForm.controls.serviceArray.controls.length > 0) {
			let controls = this.purchaseInvoiceForm.controls.serviceArray.controls
			for (let index = 0; index < controls.length; index++) {
				const element = controls[index];
				let totalAmountRowWise = Number(element.value.rate) * Number(element.value.quantity);
				let tdsRateOfRow = Number(element.value.tds_rate ? element.value.tds_rate : null) 
				if(tdsRateOfRow) {
					amount += (tdsRateOfRow * totalAmountRowWise)/100
				}
			}
		}
		let fixedAmount = amount.toFixed(2)
		return fixedAmount;
	}


	buildInvoiceEntries() {
		let currentUser = JSON.parse(localStorage.getItem('okountUserInfo'))
		let date = moment().format('YYYY-MM-DD')
		if (this.selectedDate != undefined) {
			date = this.selectedDate
		}
		return this.fb.group({
			"id": [""],
			"description": [""],
			"general_ledger_code": ["", Validators.required],
			"general_ledger_code_name": ["", Validators.required],
			"quantity": [""],
			"rate": [""],
			"gst_rate": [""],
			"tds_rate": [""],
			"amount": [""],
			"last_updated_by_id": [currentUser.id], // FIX ME
			"last_updated_by_name": [currentUser.name], // FIX ME
			"last_updated_by_code": [currentUser.accountcode] // FIX ME
			
		});
	}

	getInvoiceData() {
		this.spinner.show();
		this.commonService.getOkount(`invoice/purchase/${this.invoice_no}`).subscribe(async (res) => {
			if (res && res['data']) {
				this.invoiceData = res['data'][0]
				if(this.invoiceData) {
					this.transactionType = this.invoiceData.transaction_method

					let product = this.invoiceData.products[0];
					this.purchaseInvoiceForm.controls.account_type.setValue(this.invoiceData.account_type);
					this.purchaseInvoiceForm.controls.account_code.setValue(this.invoiceData.affiliate_code);
					this.purchaseInvoiceForm.controls.account_code_name.setValue(this.invoiceData.affiliate_name);

					this.purchaseInvoiceForm.controls.invoice_date.setValue(moment(this.invoiceData.invoice_date).format('YYYY-MM-DD'));
					this.purchaseInvoiceForm.controls.invoice_number.setValue(this.invoiceData.invoice_number);
					this.purchaseInvoiceForm.controls.supp_ref_number.setValue(this.invoiceData.supp_ref_number);

					this.purchaseInvoiceForm.controls.tds_total.setValue(this.invoiceData.tds_total);
					this.purchaseInvoiceForm.controls.gst_total.setValue(this.invoiceData.gst_total);
					this.purchaseInvoiceForm.controls.sub_total.setValue(this.invoiceData.sub_total);
					this.purchaseInvoiceForm.controls.total.setValue(this.invoiceData.total);

					this.purchaseInvoiceForm.controls.narration_1.setValue(this.invoiceData.narration_1);
					this.purchaseInvoiceForm.controls.narration_2.setValue(this.invoiceData.narration_2);
					this.purchaseInvoiceForm.controls.narration_3.setValue(this.invoiceData.narration_3);
					this.purchaseInvoiceForm.controls.narration_4.setValue(this.invoiceData.narration_4);
					this.purchaseInvoiceForm.controls.narration_5.setValue(this.invoiceData.narration_5);
					this.purchaseInvoiceForm.controls.subledger_type.setValue(this.invoiceData.subledger_type)
					this.purchaseInvoiceForm.controls.subledger_code.setValue(this.invoiceData.subledger_code)
					this.purchaseInvoiceForm.controls.subledger_code_name.setValue(this.invoiceData.subledger_code_name)
					this.purchaseInvoiceForm.controls.crn_against.setValue(this.invoiceData.crn_against)
					this.purchaseInvoiceForm.controls.rcm_applicable.setValue(this.invoiceData.rcm_applicable)
					this.purchaseInvoiceForm.controls.transaction_method.setValue(this.invoiceData.transaction_method||'purchase')
					this.purchaseInvoiceForm.controls.employee_code.setValue(product.employee_code ? product.employee_code : '');
					this.purchaseInvoiceForm.controls.employee_code_name.setValue(product.employee_code_name ? product.employee_code_name : '');
				}
				
				for (const key in this.invoiceData.products) {
					if (this.invoiceData.products.hasOwnProperty(key)) {
						const element = this.invoiceData.products[key];
						this.addInvoiceRow()
						await this.setinvoiceData(element, key)
						this.lastInputIndex = ((this.invoiceData.products.length)*8)
					}
				}
			}
		})
		this.spinner.hide();
	}

	setinvoiceData(data, index) {
		if (this.purchaseInvoiceForm != undefined && data != undefined) {
			console.log('data',data)
			this.purchaseInvoiceForm.controls.serviceArray.controls[index].controls.general_ledger_code.setValue(data.general_ledger_code)
			this.purchaseInvoiceForm.controls.serviceArray.controls[index].controls.general_ledger_code_name.setValue(data.general_ledger_code_name)
			try {
				//this.purchaseInvoiceForm.controls.invoice_date.setValue(moment(data.invoice_date).format('YYYY-MM-DD'))
				//this.purchaseInvoiceForm.controls.serviceArray.controls[index].controls.invoice_date.setValue(moment(data.invoice_date).format('YYYY-MM-DD'))
			} catch (error) {
			}

			this.purchaseInvoiceForm.controls.serviceArray.controls[index].controls.description.setValue(data.description)
			
			this.purchaseInvoiceForm.controls.serviceArray.controls[index].controls.quantity.setValue(data.quantity)
			this.purchaseInvoiceForm.controls.serviceArray.controls[index].controls.rate.setValue(data.rate)
			this.purchaseInvoiceForm.controls.serviceArray.controls[index].controls.tds_rate.setValue(data.tds_rate)
			this.purchaseInvoiceForm.controls.serviceArray.controls[index].controls.gst_rate.setValue(data.gst_rate)
			this.purchaseInvoiceForm.controls.serviceArray.controls[index].controls.amount.setValue(data.client_net_amount)
			
			this.purchaseInvoiceForm.controls.serviceArray.controls[index].controls.last_updated_by_id.setValue(data.last_updated_by_id)
			this.purchaseInvoiceForm.controls.serviceArray.controls[index].controls.last_updated_by_name.setValue(data.last_updated_by_name)
			this.purchaseInvoiceForm.controls.serviceArray.controls[index].controls.last_updated_by_code.setValue(data.last_updated_by_code)

		}
	}

	async createSubmit(value) {
		const invalid = [];
		const controls = this.purchaseInvoiceForm.controls;
		for (const name in controls) {
			if (controls[name].invalid && name != "serviceArray") {
				invalid.push(name);
			}
		}
		if(invalid.length > 0) {
			window.scroll(0,0)
			this.showMessage = true;
			this.alertType = 'error-box';
			this.message = `Invalid field ${invalid[0]} ${invalid.length > 1 ? `+${invalid.length-1}` : ''}`
			this.spinner.hide()
			this.closeUpdateConfirmModal()
			return false
		}
		this.closeUpdateConfirmModal()
		this.hideError()
		
		let date = value.invoice_date
		if(this.purchaseInvoiceForm.invalid){
			this.showMessage = true
			this.alertType = 'error-box';
			this.message = 'Invalid Data, please validate data and try again.'
			return false
		}
		for (let i = 0; i < value.serviceArray.length; i++) {
			value.serviceArray[i].invoice_date = date;
		}
		
		// await this.dateSelection(date)
		this.commonService.selectedInput.next(this.lastInputIndex+1)
		this.spinner.show()
		this.commonService.postRequest(`${environment.okountUrl}/invoice/purchase`, value).subscribe(async (res) => {
			this.invoice_no = res['invoice_number'];
			this.showMessage = false
			this.alertType = 'success-box';
			this.message = `Invoice Saved with invoice no `
			this.newInvoice = false;
			this.openSuccessModal.nativeElement.click()
			this.purchaseInvoiceForm.reset()
			let loggedInUser = JSON.parse(localStorage.okountUserInfo);
			this.purchaseInvoiceForm.controls.employee_code_name.setValue(
				loggedInUser.name ? loggedInUser.name.toUpperCase() : ''
			);
			this.purchaseInvoiceForm.controls.employee_code.setValue(
			    loggedInUser.accountcode
			);
			this.purchaseInvoiceForm.controls.invoice_date.setValue(moment().format('YYYY-MM-DD'));
			this.serviceArray = this.purchaseInvoiceForm.get("serviceArray") as FormArray;		
			this.serviceArray.controls = []
			this.addInvoiceRow()
			this.spinner.hide();
		}, err => {
			this.showMessage = true
			this.alertType = 'error-box';
			this.message = 'Failed to create invoice.'
			if( err['error']['message']){
				this.message = err['error']['message']
			  }
			this.spinner.hide();
		})
	}

	async updateSubmit(value) {
		const invalid = [];
		const controls = this.purchaseInvoiceForm.controls;
		for (const name in controls) {
			if (controls[name].invalid && name != "serviceArray") {
				invalid.push(name);
			}
		}
		if(invalid.length > 0) {
			window.scroll(0,0)
			this.showMessage = true;
			this.alertType = 'error-box';
			this.message = `Invalid field ${invalid[0]} ${invalid.length > 1 ? `+${invalid.length-1}` : ''}`
			this.spinner.hide()
			this.closeUpdateConfirmModal()
			return false
		}
		this.closeUpdateConfirmModal()
		this.hideError()
		
		let date = value.invoice_date
		if(this.purchaseInvoiceForm.invalid){
			this.showMessage = true
			this.alertType = 'error-box';
			this.message = 'Invalid Data, please validate data and try again.'
			return false
		}
		for (let i = 0; i < value.serviceArray.length; i++) {
			value.serviceArray[i].invoice_date = date;
		}
		
		this.commonService.selectedInput.next(this.lastInputIndex+1)
		this.spinner.show()
		this.commonService.putRequest(`${environment.okountUrl}/invoice/purchase/${this.invoice_no}`, value).subscribe(async (res) => {
			let invoice_no = res['invoice_number'];
			this.showMessage = true
			res['old_transaction_no'] = invoice_no
			this.alertType = 'success-box';
			this.message = `invoice Updated successfully.`
			this.spinner.hide();
		}, err => {
			this.showMessage = true
			this.alertType = 'error-box';
			this.message = 'Failed to update invoice.'
			if( err['error']['message']){
				this.message = err['error']['message']
			  }
			this.spinner.hide();
		})
	}

	reset() {
		this.purchaseInvoiceForm.reset()
		this.serviceArray.controls = []
		this.addInvoiceRow()
	}

	openActivity(){
		let path=`${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/activity/activity-summary/invoice/${this.invoice_no}`;
		if(window.location.protocol != 'file:'){
			window.open(path,'_blank')
		}else{
			var customeEventHandler = new CustomEvent("handle-window-open", {
				"detail": {
					hashPath : path
				}
			  });
			window.dispatchEvent(customeEventHandler)
		}
	}


	viewJournal(invoice_no) {
		let url = `${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/transactions/view-invoice-journal/${invoice_no}`;
		window.open(url, "_blank");
	}

	viewInvoice(invoice_no){
		this.closeSuccesModal()
		this.router.navigateByUrl(`transactions/pur/purchase/view/${invoice_no}`)
	  }

	closeWindow() {
		window.close();
	}

	deleteInvoice(invoiceNumber){
		this.closeDeleteConfirmModal()
		this.hideError()
		this.commonService.selectedInput.next(this.lastInputIndex+1)
		this.commonService.deleteRequest(`${environment.okountUrl}/invoice/purchase/${invoiceNumber}`).subscribe(res=>{
			this.showMessage = true
			this.alertType = 'success-box';
			this.message = `Invoice Deleted`
		},err=>{
			debugger
			this.showMessage = true
			this.alertType = 'error-box';
			this.message = `Failed to delete Invoice.`
		})
	}

	hideError(){
		this.showMessage = false
	}
	resetCurrentModalType(){
		this.currentOpenedModal = ''
	}
	openDeleteConfirmModal(){
		if(this.commonService.gstFiledDate) {
			if(moment(this.commonService.gstFiledDate).isSameOrAfter(moment(this.invoiceData[0].invoice_date).format('YYYY-MM-DD')) || moment(this.commonService.gstFiledDate).isSameOrAfter(moment(this.purchaseInvoiceForm.controls.invoice_date.value).format('YYYY-MM-DD'))) {
				this.showMessage = true
				this.spinner.hide()
				this.alertType = "error-box"
				this.message = 'Not Allowed to delete the invoice.'
				return false;
			}
		}
		
		this.closeOpenedModals('delete')
		this.currentOpenedModal = 'delete'
		this.confirmDeleteModal.nativeElement.click()
		this.commonService.selectedInput.next(this.lastInputIndex+5)
	}
	closeDeleteConfirmModal(){
		this.currentOpenedModal = ''
		this.closeDeleteModal.nativeElement.click()
	}
	
	focusDeleteConfirm(){
		this.deleteConfirmBtn.nativeElement.focus()
	}
	openUpdateConfirmModal(){

		if(this.commonService.gstFiledDate) {
			if(moment(this.commonService.gstFiledDate).isSameOrAfter(moment(this.invoiceData[0].invoice_date).format('YYYY-MM-DD')) || moment(this.commonService.gstFiledDate).isSameOrAfter(moment(this.purchaseInvoiceForm.controls.invoice_date.value).format('YYYY-MM-DD'))) {
				this.showMessage = true
				this.spinner.hide()
				this.alertType = "error-box"
				this.message = 'Not Allowed to update the invoice.'
				return false;
			}
		}
		
		this.closeOpenedModals('update')
		this.currentOpenedModal = 'update'
		this.confirmUpdateModal.nativeElement.click()
		this.commonService.selectedInput.next(this.lastInputIndex+7)
	}
	closeUpdateConfirmModal(){
		this.currentOpenedModal = ''
		this.closeUpdateModal.nativeElement.click()
	}

	closeOpenedModals(modalName){
		if(modalName!='delete') this.closeDeleteConfirmModal()
		if(modalName!='update') this.closeUpdateConfirmModal()
	}
	focusUpdateConfirm(){
		this.updateConfirmBtn.nativeElement.focus()
	}

	openInvoiceActivity(invoice_no) {
		let path=`${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/activity/activity-summary/invoice/${invoice_no}`
		window.open(path,'_blank')
	}

	openInvoicePrint(invoice_no) {
		let path=`${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/transactions/invoice/print/${invoice_no}`
		window.open(path,'_blank')
	}

	openSendEmailModal(){
		let primary_email = ''
		let secondary_email = ''
		let accounting_company = this.invoiceData && this.invoiceData.length>0 && this.invoiceData[0].accounting_company ? this.invoiceData[0].accounting_company : null;
		let subject = `Invoice. ${this.invoice_no}`
		let body = `Dear Travel Partner,\n\nPlease find Attached Invoice ${this.invoice_no}`
		body+=`\n\nPlease do not hesitate to contact us if you have any query or concern about attached document.\n\nIt's been a pleasure to serve you.\n\nBest Regards\n\n${accounting_company?.name}\nAccounts Team`
		if(accounting_company && accounting_company.telephone){
			body += `\nContact Us on ${accounting_company.telephone}`
		}
		if(this.invoiceData && this.invoiceData.length>0 && this.invoiceData[0].client){
			primary_email = this.invoiceData[0].client.hasOwnProperty('primary_email')?this.invoiceData[0].client.primary_email:''
			secondary_email = this.invoiceData[0].client.hasOwnProperty('secondary_email')?this.invoiceData[0].client.secondary_email:''
		}
		this.sendEmailForm.controls.toEmails.setValue(primary_email)
		this.sendEmailForm.controls.bccEmails.setValue("")
		this.sendEmailForm.controls.ccEmails.setValue(secondary_email?.split(',').join('\n'))
		this.sendEmailForm.controls.subject.setValue(subject)
		this.sendEmailForm.controls.body.setValue(body)
		this.openEmailModal.nativeElement.click();
	}

	callTosendEmail(){
		let toEmails = this.sendEmailForm.value.toEmails
		let bccEmails = this.sendEmailForm.value.bccEmails?.split(",")
		let ccEmails = this.sendEmailForm.value.ccEmails?.split(",")
		let subject = this.sendEmailForm.value.subject
        let body = this.sendEmailForm.value.body
		if(toEmails){
			toEmails = toEmails.split(",")
			window.scrollTo(0, 0)
			let emails = {
				to_addresses:toEmails,
				cc_addresses:ccEmails,
				bcc_addresses:bccEmails
			}
			let pageUrl = `transactions/invoice/print/${this.invoice_no}`
			const formData = new FormData();
			formData.append('email',JSON.stringify(emails))
			formData.append('page_url',pageUrl)
			formData.append('subject',subject)
			formData.append('email_body',body)
			formData.append('document_num',this.invoice_no)
			if(this.attachmentFile){
				formData.append('attachmentFile',this.attachmentFile)
			}
			let data = formData
			this.commonService.fileUpload(`${environment.okountUrl}/send_mail?transaction_type=invoice`,data).subscribe((res : any)=> {
				this.showMessage = true;
				this.alertType = 'success-box'
				this.message = res.msg
				this.closebutton.nativeElement.click();
			},err => {
				this.showMessage = true
				this.alertType = "error-box"
				this.message = err.msg
				this.closebutton.nativeElement.click();
			 })
			}
	  }


	closeSuccesModal(){
		this.showMessage = false;
		this.alertType = '';
		this.message = ``
		this.newInvoice = true
		this.openSuccessModal.nativeElement.click()
	}

	ledgerSearch(event) {
		let value = event && event.target ?event.target.value:event
		try {
		  let searchType = this.purchaseInvoiceForm.controls.subledger_type.value;
		  if (searchType == null) {
			this.purchaseInvoiceForm.controls.subledger_type.setValue(
			  "client"
			);
			searchType = this.purchaseInvoiceForm.controls
			  .subledger_type.value;
		  }
		  if (value.length > 2 && searchType != null && searchType != "") {
			let status = '';
			if(this.invoiceMode != 'view'){
			  status = '&status=active'
			}
			this.invoiceService
			  .searchAccount(
				`${searchType}/autocomplete?name_like=${value.toUpperCase()}${status}`
			  )
			  .subscribe(
				(res) => {
					this.subledgeraccountList= res;
				  if (value == "CC001A" || value == "CC001B") {
					this.selectAccount(res[0], "subledger_code",null);
					this.purchaseInvoiceForm.invoiceForm.controls.subledger_code_name.setValue(
					  res[0].name
					);
				  }
				  if(this.invoiceMode == 'view') {
					this.selectAccount(res[0], "subledger_code", null);
				  }
				},
				(error) => {}
			  );
		  }
		} catch (error) {}
	  }

	  onFocused(e) {
    		
	  }

	copytext(val: string) {
		const selBox = document.createElement('textarea');
		selBox.style.position = 'fixed';
		selBox.style.left = '0';
		selBox.style.top = '0';
		selBox.style.opacity = '0';
		selBox.value = val;
		document.body.appendChild(selBox);
		selBox.focus();
		selBox.select();
		document.execCommand('copy');
		document.body.removeChild(selBox);
		this.copyELementTitle = 'Copied'
	}

	closeMasterModal() {
		this.closeMasterCreationModal.nativeElement.click()
	}

	setFormValues(data) {
		this.showMessage = true;
		this.alertType = 'success-box';
		this.message = `${this.currentMaster} created successfully with account code. : ${data.account_code}`
		
		if(this.currentMaster == 'general') {
			this.purchaseInvoiceForm.controls.serviceArray.controls[this.currentGeneralIndex].controls.general_ledger_code.setValue(data.account_code ? data.account_code : '')
			this.purchaseInvoiceForm.controls.serviceArray.controls[this.currentGeneralIndex].controls.general_ledger_code_name.setValue(data.name ? data.name : data.account_name ? data.account_name : '')
		
		} else {
			this.purchaseInvoiceForm.controls.account_code.setValue(data.account_code);
			this.purchaseInvoiceForm.controls.account_code_name.setValue(data.name);
		}
		
	}
	handleAttachment(event: any) {
		this.attachmentFile = event.target.files[0];
	  }
	  createCreditNote(invoice_no) {
		var url=`${environment.name == 'v3-stage' || environment.name == 'v3-prod' ? '/v3/' : "/"}#/transactions/pur/purchase/save/refund/${invoice_no}`;
		window.open(url,'_blank')
		// this.router.navigate(['/transactions/view-invoice-journal', invoice_no])
	} 

	getData() {
		this.spinner.show();
		this.commonService.getOkount(`invoice/purchase/${this.invoice_no}`).subscribe(async (res) => {
			if (res && res['data']) {
				this.invoiceData = res['data'][0]
				if(this.invoiceData) {

					let product = this.invoiceData.products[0];
					this.purchaseInvoiceForm.controls.account_type.setValue(this.invoiceData.account_type);
					this.purchaseInvoiceForm.controls.account_code.setValue(this.invoiceData.affiliate_code);
					this.purchaseInvoiceForm.controls.account_code_name.setValue(this.invoiceData.affiliate_name);

					this.purchaseInvoiceForm.controls.invoice_date.setValue(moment().format('YYYY-MM-DD'));
					this.purchaseInvoiceForm.controls.invoice_number.setValue('');
					this.purchaseInvoiceForm.controls.crn_against.setValue(this.invoiceData.invoice_number)
					
					this.purchaseInvoiceForm.controls.supp_ref_number.setValue(this.invoiceData.supp_ref_number);

					this.purchaseInvoiceForm.controls.tds_total.setValue(this.invoiceData.tds_total);
					this.purchaseInvoiceForm.controls.gst_total.setValue(this.invoiceData.gst_total);
					this.purchaseInvoiceForm.controls.sub_total.setValue(this.invoiceData.sub_total);
					this.purchaseInvoiceForm.controls.total.setValue(this.invoiceData.total);

					this.purchaseInvoiceForm.controls.narration_1.setValue(this.invoiceData.narration_1);
					this.purchaseInvoiceForm.controls.narration_2.setValue(this.invoiceData.narration_2);
					this.purchaseInvoiceForm.controls.narration_3.setValue(this.invoiceData.narration_3);
					this.purchaseInvoiceForm.controls.narration_4.setValue(this.invoiceData.narration_4);
					this.purchaseInvoiceForm.controls.narration_5.setValue(this.invoiceData.narration_5);
					this.purchaseInvoiceForm.controls.subledger_type.setValue(this.invoiceData.subledger_type)
					this.purchaseInvoiceForm.controls.subledger_code.setValue(this.invoiceData.subledger_code)
					this.purchaseInvoiceForm.controls.subledger_code_name.setValue(this.invoiceData.subledger_code_name)
					this.purchaseInvoiceForm.controls.transaction_method.setValue('refund')
					this.purchaseInvoiceForm.controls.employee_code.setValue(product.employee_code ? product.employee_code : '');
					this.purchaseInvoiceForm.controls.employee_code_name.setValue(product.employee_code_name ? product.employee_code_name : '');
				}
				
				for (const key in this.invoiceData.products) {
					if (this.invoiceData.products.hasOwnProperty(key)) {
						const element = this.invoiceData.products[key];
						this.addInvoiceRow()
						await this.setinvoiceData(element, key)
						this.lastInputIndex = ((this.invoiceData.products.length)*8)
					}
				}
			}
		})
		this.spinner.hide();
	}

	focusSubledgerAc() {
		if (this.subLedgerAC != undefined) {
		  this.subLedgerAC.focus();
		}
	  }

	  toggleMenu() {
		this.menuVisible = !this.menuVisible;
		}
}
