<ng-keyboard-shortcuts [shortcuts]="shortcuts"></ng-keyboard-shortcuts>

<form class="sector-section" [formGroup]="sectorService.travellerForm" *ngIf="sectorService.travellerList != undefined">
	<div class="traveller-outer-container">
		<div class="traveller-section">

			<div class="traveller-col">
				<table  class="table table-hovered table-bordered">
					<thead>
						<tr>
							<th style="width: 30px;">#</th>
							<th style="width: 64px;">Type</th>
							<th style="width: 64px;">Title</th>
							<th *ngIf="corporate_employee=='false'" class="text-left" style="width: 300px;">Name<span class="text-danger">*</span></th>
							<th *ngIf="corporate_employee=='true'" class="text-left" style="width: 300px;">Name<span class="text-danger">*</span></th>
							<th *ngIf="corporate_employee=='true'" class="text-left"style="width: 120px;">E-code<span class="text-danger"></span></th>
							<th style="width: 120px;" *ngIf="invoiceType == 'reissue'">Old Ticket No.</th>
							<th class="text-left" style="width: 180px;">Ticket No.<span class="text-danger">*</span></th>
							<th class="text-left" *ngIf="sectorService.sectorForm.controls.txtSector2.value && sectorService.sectorForm.controls.txtSector2.value.length &&  sectorService.sectorForm.controls.txtSector2.value.length>0" style="width: 180px;">CNJ Ticket No.<span class="text-danger"></span></th>
							<th style="width: 120px;">Pay Type</th>
							<th style="width: 215px;">Card</th>
							<th style="width: 260px;">Payable</th>
							<th *ngIf="supplier_trans=='true'" class="text-left" style="width: 130px;">Payable Trans. No.<span class="text-danger">*</span></th>
							<th *ngIf="supplier_trans=='true'" class="text-center" style="width: 86px;">Payable Date<span class="text-danger">*</span></th>

							<!-- <th>
								<div class="d-flex align-item-center text-bold"> Base <input type="checkbox"
										class="ml-1" (change)="setFare($event,'txtBaseFare')" /></div>
							</th>
							<th>
								<div class="d-flex align-item-center text-bold"> YQ <input type="checkbox" class="ml-1"
										(change)="setFare($event,'txtYQ')" /></div>
							</th>
							<th>
								<div class="d-flex align-item-center text-bold"> YR <input type="checkbox" class="ml-1"
										(change)="setFare($event,'txtYR')" /></div>
							</th>
							<th>
								<div class="d-flex align-item-center text-bold"> K3 <input type="checkbox" class="ml-1"
										(change)="setFare($event,'txtK3')" /></div>
							</th>
							<th>
								<div class="d-flex align-item-center text-bold"> OC <input type="checkbox" class="ml-1"
										(change)="setFare($event,'txtOC')" /></div>
							</th>
							<th>
								<div class="d-flex align-item-center text-bold"> OT <input type="checkbox" class="ml-1"
										(change)="setFare($event,'txtOT')" /></div>
							</th>
							<th>Addons</th> -->
							<th style="width: 120px;">
								<div class="d-flex align-item-center justify-content-end text-bold"> Total <input type="checkbox"
										class="ml-1" (change)="setAmount($event)" /></div>
							</th>
							<th style="width: 100px;">CNJ</th>
							<th style="width: 100px;">Action</th>
						</tr>
					</thead>
					<tbody formArrayName="travellerList">
						<ng-container *ngFor="let travellers of DynamicFormControls.controls; let i = index">
							<ng-container [formGroupName]="i">
								<tr class=" {{travellers.value.travellerStatus}}_table_row">
									<td style="width: 30px;">{{i+1}}</td>
									<td><select
											tabIndex="{{i*(travellers.value.totalIndexCount)+1+(sectorService.lastSectorTwoIndex)}}"
											attr.tabIndex="{{i*(travellers.value.totalIndexCount)+1+(sectorService.lastSectorTwoIndex)}}"
											(focus)="maximizeSection(i)" formControlName="selType"
											[ngClass]="{'validateError': (travellers.controls.selType.invalid && travellers.controls.selType.touched) || 
											(!travellers.controls.selType.valid && sectorService.submitted)}"
											class="select-input {{travellers.value.travellerStatus}}_input">
											<option value="ADT">Adult</option>
											<option value="CHD">Child</option>
											<option value="INF">Infant</option>
										</select>
									</td>
									<td><select
											tabIndex="{{i*(travellers.value.totalIndexCount)+2+(sectorService.lastSectorTwoIndex)}}"
											attr.tabIndex="{{i*(travellers.value.totalIndexCount)+2+(sectorService.lastSectorTwoIndex)}}"
											formControlName="selTitle"
											[ngClass]="{'validateError': (travellers.controls.selTitle.invalid && travellers.controls.selTitle.touched) || 
											(!travellers.controls.selTitle.valid && sectorService.submitted)}"
											class="select-input {{travellers.value.travellerStatus}}_input">
											<option value="">Select </option>
											<option value="MR">Mr</option>
											<option value="MS">Ms</option>
											<option value="MRS">Mrs</option>
											<option value="MISS">Miss</option>
											<option value="MSTR">Mstr</option>
											<option value="CHD">CHD</option>
											<option value="INF">INF</option>
										</select>
										<span
											*ngIf=" travellers.value &&  travellers.value.selTitle &&(travellers.value.selTitle.length > 5 || travellers.value.selTitle.length == 0)">Invalid</span>
									</td>

									<td *ngIf="corporate_employee=='false'"><input
											tabIndex="{{i*(travellers.value.totalIndexCount)+3+(sectorService.lastSectorTwoIndex)}}"
											attr.tabIndex="{{i*(travellers.value.totalIndexCount)+3+(sectorService.lastSectorTwoIndex)}}"
											oninput="this.value = this.value.toUpperCase()"
											formControlName="txtPassengerName" maxlength="70"
											class="traveller-input-field {{travellers.value.travellerStatus}}_input"
											type="text"  (change)="sectorService.setNarration()"
											[ngClass]="{'validateError': (travellers.controls.txtPassengerName.invalid && travellers.controls.txtPassengerName.touched) || 
											(!travellers.controls.txtPassengerName.valid && sectorService.submitted)}"
											title="{{(travellers.controls.txtPassengerName.invalid && travellers.controls.txtPassengerName.touched) || 
												(!travellers.controls.txtPassengerName.valid && sectorService.submitted) ? 'Invalid Traveller Name' : ''}}"
											/>
									</td>

									<td *ngIf="corporate_employee=='true'">
										<div class="autocomplete-container">
											<div class="input-container" tabIndex="{{i*(travellers.value.totalIndexCount)+3+(sectorService.lastSectorTwoIndex)}}"
											attr.tabIndex="{{i*(travellers.value.totalIndexCount)+3+(sectorService.lastSectorTwoIndex)}}"
											(focus)="focusEmployeeAc(i)" >
											<input #employeeAc
											type="text"
											name="txtEmployeeName"
											formControlName="txtEmployeeName"
										 (input)="getCoporateEmployee($event, i)"
										 [nzAutocomplete]="employeeAutoComplete"
										 maxlength="70" 
										class="traveller-input-field {{travellers.value.travellerStatus}}_input"
										[ngClass]="{'validateError': (travellers.controls.txtEmployeeName.invalid && travellers.controls.txtEmployeeName.touched) || 
										(!travellers.controls.txtEmployeeName.valid && sectorService.submitted)}"
										title="{{(travellers.controls.txtEmployeeName.invalid && travellers.controls.txtEmployeeName.touched) || 
											(!travellers.controls.txtEmployeeName.valid && sectorService.submitted) ? 'Invalid Traveller Name' : ''}}"
										/>
									</div>
									<nz-autocomplete #employeeAutoComplete [nzBackfill]="true" (selectionChange)="selectEmployee($event, i)" >
										<nz-auto-option class="colr-1" *ngFor="let item of employeeArr" [nzValue]="item" [nzLabel]="item.account_name" (change)="sectorService.setNarration()" >
											<a innerHTML='<span class="colr-1">{{item?.account_name}}
													({{item?.account_code}})</span>'>
											</a>                           
										</nz-auto-option>                      
								</nz-autocomplete>
									</div>
								</td>

								<td *ngIf="corporate_employee=='true'"><input
									oninput="this.value = this.value.toUpperCase()"
									formControlName="txtEmployeeCode" maxlength="70" disabled
									class="traveller-input-field {{travellers.value.travellerStatus}}_input"
									type="text" [ngClass]="{'validateError': (travellers.controls.txtEmployeeCode.invalid && travellers.controls.txtEmployeeCode.touched) || 
									(!travellers.controls.txtEmployeeCode.valid && sectorService.submitted)}"
									/>
							</td>

									<td *ngIf="invoiceType == 'reissue'"><input
											tabIndex="{{i*(travellers.value.totalIndexCount)+4+(sectorService.lastSectorTwoIndex)}}"
											attr.tabIndex="{{i*(travellers.value.totalIndexCount)+4+(sectorService.lastSectorTwoIndex)}}"
											oninput="this.value = this.value.toUpperCase()"
											formControlName="prev_ticket_no"  maxLength="14"
											class="traveller-input-field {{travellers.value.travellerStatus}}_input"
											type="text" style="width: 115px !important;"
											[ngClass]="{'validateError': (travellers.controls.prev_ticket_no.invalid && travellers.controls.prev_ticket_no.touched) || 
											(!travellers.controls.prev_ticket_no.valid && sectorService.submitted)}"
											title="{{(travellers.controls.prev_ticket_no.invalid && travellers.controls.prev_ticket_no.touched) || 
												(!travellers.controls.prev_ticket_no.valid && sectorService.submitted) ? 'Invalid ticket number' : ''}}"
											 />
									</td>


									<td><input
											tabIndex="{{i*(travellers.value.totalIndexCount)+4+(sectorService.lastSectorTwoIndex)}}"
											attr.tabIndex="{{i*(travellers.value.totalIndexCount)+4+(sectorService.lastSectorTwoIndex)}}"
											oninput="this.value = this.value.toUpperCase()"
											(change)="checkTicketNumber(travellers.value.txtTicketNumber)"
											formControlName="txtTicketNumber" maxLength="14"
											class="traveller-input-field w-100 {{travellers.value.travellerStatus}}_input"
											type="text"   (change)="sectorService.setNarration()"
											[ngClass]="{'validateError': (travellers.controls.txtTicketNumber.invalid && travellers.controls.txtTicketNumber.touched) || 
											(!travellers.controls.txtTicketNumber.valid && sectorService.submitted)}" 
											title="{{(travellers.controls.txtTicketNumber.invalid && travellers.controls.txtTicketNumber.touched) || 
												(!travellers.controls.txtTicketNumber.valid && sectorService.submitted) ? 'Invalid ticket number' : ''}}"
											/>
									</td>

									<td *ngIf="sectorService.sectorForm.controls.txtSector2.value && sectorService.sectorForm.controls.txtSector2.value.length &&  sectorService.sectorForm.controls.txtSector2.value.length>0"><input 
										oninput="this.value = this.value.toUpperCase()"
										(change)="checkTicketNumber(travellers.value.txtTicketNumber2)"
										formControlName="txtTicketNumber2" maxLength="14"
										class="traveller-input-field w-100 {{travellers.value.travellerStatus}}_input"
										type="text"   (change)="sectorService.setNarration()"
										[ngClass]="{'validateError': (travellers.controls.txtTicketNumber2.invalid && travellers.controls.txtTicketNumber2.touched) || 
										(!travellers.controls.txtTicketNumber2.valid && sectorService.submitted)}" 
										title="{{(travellers.controls.txtTicketNumber2.invalid && travellers.controls.txtTicketNumber2.touched) || 
											(!travellers.controls.txtTicketNumber2.valid && sectorService.submitted) ? 'Invalid ticket number' : ''}}"
										/>
								</td>

									<td>
										<select class="select-input {{travellers.value.travellerStatus}}_input"
											tabIndex="{{i*(travellers.value.totalIndexCount)+5+(sectorService.lastSectorTwoIndex)}}"
											attr.tabIndex="{{i*(travellers.value.totalIndexCount)+5+(sectorService.lastSectorTwoIndex)}}"
											[ngClass]="{'validateError': (travellers.controls.type_of_pay.invalid && travellers.controls.type_of_pay.touched) || 
											(!travellers.controls.type_of_pay.valid && sectorService.submitted)}"
											formControlName="type_of_pay" (change)="setBookingSupplier($event,i)">
											<option value="">Select</option>
											<option value="OUR_CC">Our CC</option>
											<option value="AGENT_CC">Agent CC</option>
										</select>
									</td>
									
									<td>
										<div class="autocomplete-container">
											<div class="input-container" tabIndex="{{i*(travellers.value.totalIndexCount)+6+(sectorService.lastSectorTwoIndex)}}"
										    attr.tabIndex="{{i*(travellers.value.totalIndexCount)+6+(sectorService.lastSectorTwoIndex)}}"
										    (focus)="focusGeneralAc(i)">
											<input #ledgerCardAc
												type="text"
												oninput="this.value = this.value.toUpperCase()" 
												name="ledger_card_name"
												[attr.disabled]="travellers.value.type_of_pay != 'OUR_CC' ? true : null"
												formControlName="ledger_card_name"
												(input)="filterGeneralAccounts($event, i)"
												[nzAutocomplete]="ledgerCardNoAutoComplete"
												[ngClass]="{'validateError': (travellers.controls.ledger_card_name.invalid && travellers.controls.ledger_card_name.touched) || 
												(!travellers.controls.ledger_card_name.valid && sectorService.submitted)}" 
												title="{{(travellers.controls.ledger_card_name.invalid && travellers.controls.ledger_card_name.touched) || 
												    (!travellers.controls.ledger_card_name.valid && sectorService.submitted) ? 'Invalid Card' : ''}}"
											/>

											<nz-autocomplete #ledgerCardNoAutoComplete [nzBackfill]="true" (selectionChange)="selectGeneralAccount($event, i)"  >
													<nz-auto-option  class="colr-1" *ngFor="let item of generalFilteredData" [nzValue]="item" [nzLabel]="item.name">
														<a
															innerHTML='<span class="colr-1">{{item?.name}}
																({{item?.account_code}})</span>'>
														</a>                           
													</nz-auto-option>                      
											</nz-autocomplete>
										
										</div>
										</div>

									</td>
 
									<td>
										<div class="autocomplete-container">
											<div class="input-container" tabIndex="{{i*(travellers.value.totalIndexCount)+7+(sectorService.lastSectorTwoIndex)}}"
											attr.tabIndex="{{i*(travellers.value.totalIndexCount)+7+(sectorService.lastSectorTwoIndex)}}"
											(focus)="focusSupplierAc(i)" >
											<input #supplierAc 
												type="text"
												name="txtbookingSupplier"
												formControlName="txtbookingSupplier"
												(input)="getBookingSupplier($event, i)"
												[nzAutocomplete]="supplierAutoComplete"
												[attr.disabled]="(!sectorService.enablePayable && sectorService.invoiceForm.value.invoiceType != 'C' ) ? true : null"
												[ngClass]="{'validateError': (travellers.controls.txtbookingSupplier.invalid && travellers.controls.txtbookingSupplier.touched) || 
												(!travellers.controls.txtbookingSupplier.valid && sectorService.submitted)}" 
												 title="{{(travellers.controls.txtbookingSupplier.invalid && travellers.controls.txtbookingSupplier.touched) || 
												    (!travellers.controls.txtbookingSupplier.valid && sectorService.submitted) ? 'Invalid Supplier' : ''}}"
											/>

											<nz-autocomplete #supplierAutoComplete [nzBackfill]="true" (selectionChange)="selectBookingSupplier($event, i)" >
													<nz-auto-option class="colr-1" *ngFor="let item of bookingSupplierArr" [nzValue]="item" [nzLabel]="item.name" >
														<a innerHTML='<span class="colr-1">{{item?.name}}
																({{item?.account_code}})</span>'>
														</a>                           
													</nz-auto-option>                      
											</nz-autocomplete>
										</div>
										</div>
									</td>

									<td *ngIf="supplier_trans=='true'"><input
										oninput="this.value = this.value.toUpperCase()"
										formControlName="txtSupplierCode" maxLength="14"
										class="traveller-input-field w-100 {{travellers.value.travellerStatus}}_input"
										type="text" [ngClass]="{'validateError': (travellers.controls.txtSupplierCode.invalid && travellers.controls.txtSupplierCode.touched) || 
										(!travellers.controls.txtSupplierCode.valid && sectorService.submitted)}" 
										title="{{(travellers.controls.txtSupplierCode.invalid && travellers.controls.txtSupplierCode.touched) || 
											(!travellers.controls.txtSupplierCode.valid && sectorService.submitted) ? 'Invalid ticket number' : ''}}"
										/>
								</td>
									<td *ngIf="supplier_trans=='true'">
										<input class=" traveller-input-field" type="date"
										formControlName="supplierDate" (change)="sectorService.setNarration()"
										[ngClass]="{'validateError': (travellers.controls.supplierDate.invalid && travellers.controls.supplierDate.touched) || 
										(!travellers.controls.supplierDate.valid && sectorService.submitted)}"/>
									</td>
									<td>
										<input oninput="this.value = this.value.toUpperCase()"
										formControlName="txtAmount" readonly
										class="traveller-input-field traveller-amt-field text-right {{travellers.value.travellerStatus}}_input"
										type="text" />
									</td>
									<td>
									<input
										tabIndex="{{i*(travellers.value.totalIndexCount)+8+(sectorService.lastSectorTwoIndex)}}"
										attr.tabIndex="{{i*(travellers.value.totalIndexCount)+8+(sectorService.lastSectorTwoIndex)}}"
										formControlName="isConjuction" (change)="calculateTotal(i)"
										class="traveller-input-field traveller-amt-field text-right {{travellers.value.travellerStatus}}_input"
										type="checkbox" />
									</td>
									<td class="action-col">
										<span (click)="maximizeSection(i)"
											id="edit_traveller_{{i}}_btn"
											class="d-flex justify-content-center align-item-center">
											<a href="javascript:void(0)" title="Edit" class=" custom-table-btn mr-2 bg-white border-0 p-0 text-bold hoverableShadow transition cursor"><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/dark-edit-btn-fb.svg" alt="" width="20px"></a>
											<a (click)="removeTraveller(i);sectorService.setNarration()" title="Delete" class=" custom-table-btn bg-white border-0 p-0 text-bold hoverableShadow transition cursor" href="javascript:void(0)"><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/dark-delete.svg" alt="" width="20px">
											</a>
										</span>
									</td>
								</tr>

								<ng-container *ngIf="showTravellerIndex == i">
									<tr>
										<td class="traveller-expanded" colspan="11">
											<div class="traveller-ssr">
												<table class="table table-hovered table-bordered">
													<thead>
														<tr>
															<th class="text-left">Sector</th>
															<th class="text-left">Seat Code</th>
															<th class="text-right">Seat Charges</th>
															<th class="text-right">Meal Code</th>
															<th class="text-right">Meal charges</th>
															<th class="text-right">Baggage Code</th>
															<th class="text-right">Baggage Charges</th>
															<th class="text-right">Addnl Baggage</th>
															<th class="text-right">Baggage Charges</th>
															<th class="text-right">Ancillary</th>
															<th class="text-right">Ancillary Charges</th>
														</tr>
													</thead>
													<tbody>
														<ng-container formArrayName="sectorList"
															*ngFor="let sectors of travellers.controls.sectorList.controls; let j = index">

															<tr [formGroupName]="j"
																[ngClass]="{cancelledTicket: sectors.value.isCancelled == true}">
																<!-- i*(travellers.value.totalIndexCount)+(j*10+8+1)+(sectorService.lastSectorTwoIndex)}} -->
																<!-- i = travellerIndex,
																			j = travellerSectorIndex
																			10 = total Sector input field per row
																			8 = traveller field count before TravellerSector
																			1 = current field index of sector -->
																<td>
																<input
																	[ngClass]="{cancelled_input: sectors.value.isCancelled == true}"
																	oninput="this.value = this.value.toUpperCase()"
																	formControlName="sector" readonly
																	class="traveller-inner-input-field "
																	type="text" />
																</td>
																<td>
																<input
																	[ngClass]="{cancelled_input: sectors.value.isCancelled == true}"
																	tabIndex="{{i*(travellers.value.totalIndexCount)+(j*10+8+1)+(sectorService.lastSectorTwoIndex)}}"
																	attr.tabIndex="{{i*(travellers.value.totalIndexCount)+(j*10+8+1)+(sectorService.lastSectorTwoIndex)}}"
																	oninput="this.value = this.value.toUpperCase()"
																	formControlName="txtSeatCode"
																	class="traveller-inner-input-field "
																	[ngClass]="{'validateError': (sectors.controls.txtSeatCode.invalid && sectors.controls.txtSeatCode.touched) || 
																	(!sectors.controls.txtSeatCode.valid && sectorService.submitted)}" 
																	title="{{(sectors.controls.txtSeatCode.invalid && sectors.controls.txtSeatCode.touched) || 
																	(!sectors.controls.txtSeatCode.valid && sectorService.submitted) ? 'Invalid Code' : ''}}"
																	type="text" />
																</td>
																<td>
																	<input
																		[ngClass]="{cancelled_input: sectors.value.isCancelled == true}"
																		tabIndex="{{i*(travellers.value.totalIndexCount)+(j*10+8+2)+(sectorService.lastSectorTwoIndex)}}"
																		attr.tabIndex="{{i*(travellers.value.totalIndexCount)+(j*10+8+2)+(sectorService.lastSectorTwoIndex)}}"
																		oninput="this.value = this.value.toUpperCase()"
																		formControlName="txtSeatCharges" pattern='^\d{0,8}(\.\d{1,2})?$'
																		(input)="calculateTotal(i)"
																		class="traveller-inner-input-field text-right"
																		[ngClass]="{'validateError': (sectors.controls.txtSeatCharges.invalid && sectors.controls.txtSeatCharges.touched) || 
												                        (!sectors.controls.txtSeatCharges.valid && sectorService.submitted)}" 
												                        title="{{(sectors.controls.txtSeatCharges.invalid && sectors.controls.txtSeatCharges.touched) || 
													                    (!sectors.controls.txtSeatCharges.valid && sectorService.submitted) ? 'Invalid charges' : ''}}"
																		type="text" />
																	</td>
																<td>
																	<input
																		[ngClass]="{cancelled_input: sectors.value.isCancelled == true}"
																		tabIndex="{{i*(travellers.value.totalIndexCount)+(j*10+8+3)+(sectorService.lastSectorTwoIndex)}}"
																		attr.tabIndex="{{i*(travellers.value.totalIndexCount)+(j*10+8+3)+(sectorService.lastSectorTwoIndex)}}"
																		oninput="this.value = this.value.toUpperCase()"
																		formControlName="txtMealCode"
																		class="traveller-inner-input-field "
																		[ngClass]="{'validateError': (sectors.controls.txtMealCode.invalid && sectors.controls.txtMealCode.touched) || 
												                        (!sectors.controls.txtMealCode.valid && sectorService.submitted)}" 
												                        title="{{(sectors.controls.txtMealCode.invalid && sectors.controls.txtMealCode.touched) || 
													                    (!sectors.controls.txtMealCode.valid && sectorService.submitted) ? 'Invalid Code' : ''}}"
																		type="text" />
																	</td>
																<td>
																	<input
																		[ngClass]="{cancelled_input: sectors.value.isCancelled == true}"
																		tabIndex="{{i*(travellers.value.totalIndexCount)+(j*10+8+4)+(sectorService.lastSectorTwoIndex)}}"
																		attr.tabIndex="{{i*(travellers.value.totalIndexCount)+(j*10+8+4)+(sectorService.lastSectorTwoIndex)}}"
																		oninput="this.value = this.value.toUpperCase()"
																		formControlName="txtMealCharges"
																		(input)="calculateTotal(i)" pattern='^\d{0,8}(\.\d{1,2})?$'
																		class="traveller-inner-input-field text-right"
																		[ngClass]="{'validateError': (sectors.controls.txtMealCharges.invalid && sectors.controls.txtMealCharges.touched) || 
												                        (!sectors.controls.txtMealCharges.valid && sectorService.submitted)}" 
												                        title="{{(sectors.controls.txtMealCharges.invalid && sectors.controls.txtMealCharges.touched) || 
													                    (!sectors.controls.txtMealCharges.valid && sectorService.submitted) ? 'Invalid charges' : ''}}"
																		type="text" />
																	</td>
																<td>
																	<input
																		[ngClass]="{cancelled_input: sectors.value.isCancelled == true}"
																		tabIndex="{{i*(travellers.value.totalIndexCount)+(j*10+8+5)+(sectorService.lastSectorTwoIndex)}}"
																		attr.tabIndex="{{i*(travellers.value.totalIndexCount)+(j*10+8+5)+(sectorService.lastSectorTwoIndex)}}"
																		oninput="this.value = this.value.toUpperCase()"
																		formControlName="txtBagCode"
																		class="traveller-inner-input-field "
																		[ngClass]="{'validateError': (sectors.controls.txtBagCode.invalid && sectors.controls.txtBagCode.touched) || 
												                        (!sectors.controls.txtBagCode.valid && sectorService.submitted)}" 
												                        title="{{(sectors.controls.txtBagCode.invalid && sectors.controls.txtBagCode.touched) || 
													                    (!sectors.controls.txtBagCode.valid && sectorService.submitted) ? 'Invalid Code' : ''}}"
																		type="text" />
																	</td>
																<td>
																	<input
																		[ngClass]="{cancelled_input: sectors.value.isCancelled == true}"
																		tabIndex="{{i*(travellers.value.totalIndexCount)+(j*10+8+6)+(sectorService.lastSectorTwoIndex)}}"
																		attr.tabIndex="{{i*(travellers.value.totalIndexCount)+(j*10+8+6)+(sectorService.lastSectorTwoIndex)}}"
																		oninput="this.value = this.value.toUpperCase()"
																		formControlName="txtBagCharges"
																		(input)="calculateTotal(i)" pattern='^\d{0,8}(\.\d{1,2})?$'
																		class="traveller-inner-input-field text-right"
																		[ngClass]="{'validateError': (sectors.controls.txtBagCharges.invalid && sectors.controls.txtBagCharges.touched) || 
												                        (!sectors.controls.txtBagCharges.valid && sectorService.submitted)}" 
												                        title="{{(sectors.controls.txtBagCharges.invalid && sectors.controls.txtBagCharges.touched) || 
													                    (!sectors.controls.txtBagCharges.valid && sectorService.submitted) ? 'Invalid charges' : ''}}"
																		type="text" />
																	</td>

																<td>
																	<input
																		[ngClass]="{cancelled_input: sectors.value.isCancelled == true}"
																		tabIndex="{{i*(travellers.value.totalIndexCount)+(j*10+8+7)+(sectorService.lastSectorTwoIndex)}}"
																		attr.tabIndex="{{i*(travellers.value.totalIndexCount)+(j*10+8+7)+(sectorService.lastSectorTwoIndex)}}"
																		oninput="this.value = this.value.toUpperCase()"
																		formControlName="txtAdditionalBaggageCode"
																		class="traveller-inner-input-field "
																		[ngClass]="{'validateError': (sectors.controls.txtAdditionalBaggageCode.invalid && sectors.controls.txtAdditionalBaggageCode.touched) || 
												                        (!sectors.controls.txtAdditionalBaggageCode.valid && sectorService.submitted)}" 
												                        title="{{(sectors.controls.txtAdditionalBaggageCode.invalid && sectors.controls.txtAdditionalBaggageCode.touched) || 
													                    (!sectors.controls.txtAdditionalBaggageCode.valid && sectorService.submitted) ? 'Invalid Code' : ''}}"
																		type="text" />
																	</td>
																<td>
																	<input
																		[ngClass]="{cancelled_input: sectors.value.isCancelled == true}"
																		tabIndex="{{i*(travellers.value.totalIndexCount)+(j*10+8+8)+(sectorService.lastSectorTwoIndex)}}"
																		attr.tabIndex="{{i*(travellers.value.totalIndexCount)+(j*10+8+8)+(sectorService.lastSectorTwoIndex)}}"
																		oninput="this.value = this.value.toUpperCase()"
																		formControlName="txtAdditionalBaggageCharges"
																		(input)="calculateTotal(i)" pattern='^\d{0,8}(\.\d{1,2})?$'
																		class="traveller-inner-input-field text-right"
																		[ngClass]="{'validateError': (sectors.controls.txtAdditionalBaggageCharges.invalid && sectors.controls.txtAdditionalBaggageCharges.touched) || 
												                        (!sectors.controls.txtAdditionalBaggageCharges.valid && sectorService.submitted)}" 
												                        title="{{(sectors.controls.txtAdditionalBaggageCharges.invalid && sectors.controls.txtAdditionalBaggageCharges.touched) || 
													                    (!sectors.controls.txtAdditionalBaggageCharges.valid && sectorService.submitted) ? 'Invalid charges' : ''}}"
																		type="text" />
																	</td>
																<td>
																	<input
																		[ngClass]="{cancelled_input: sectors.value.isCancelled == true}"
																		tabIndex="{{i*(travellers.value.totalIndexCount)+(j*10+8+9)+(sectorService.lastSectorTwoIndex)}}"
																		attr.tabIndex="{{i*(travellers.value.totalIndexCount)+(j*10+8+9)+(sectorService.lastSectorTwoIndex)}}"
																		oninput="this.value = this.value.toUpperCase()"
																		formControlName="txtAncillaryCode"
																		class="traveller-inner-input-field " 
																		[ngClass]="{'validateError': (sectors.controls.txtAncillaryCode.invalid && sectors.controls.txtAncillaryCode.touched) || 
												                        (!sectors.controls.txtAncillaryCode.valid && sectorService.submitted)}" 
												                        title="{{(sectors.controls.txtAncillaryCode.invalid && sectors.controls.txtAncillaryCode.touched) || 
													                    (!sectors.controls.txtAncillaryCode.valid && sectorService.submitted) ? 'Invalid Code' : ''}}"
																		type="text" />
																	</td>
																<td>
																	<input
																		[ngClass]="{cancelled_input: sectors.value.isCancelled == true}"
																		tabIndex="{{i*(travellers.value.totalIndexCount)+(j*10+8+10)+(sectorService.lastSectorTwoIndex)}}"
																		attr.tabIndex="{{i*(travellers.value.totalIndexCount)+(j*10+8+10)+(sectorService.lastSectorTwoIndex)}}"
																		oninput="this.value = this.value.toUpperCase()"
																		formControlName="txtAncillaryCharges"
																		(input)="calculateTotal(i)" pattern='^\d{0,8}(\.\d{1,2})?$'
																		class="traveller-inner-input-field text-right"
																		[ngClass]="{'validateError': (sectors.controls.txtAncillaryCharges.invalid && sectors.controls.txtAncillaryCharges.touched) || 
												                        (!sectors.controls.txtAncillaryCharges.valid && sectorService.submitted)}" 
												                        title="{{(sectors.controls.txtAncillaryCharges.invalid && sectors.controls.txtAncillaryCharges.touched) || 
													                    (!sectors.controls.txtAncillaryCharges.valid && sectorService.submitted) ? 'Invalid charges' : ''}}"
																		type="text" />
																	</td>
															</tr>

														</ng-container>

													</tbody>
												</table>
											</div>
										</td>
									</tr>

									<tr>
										<td colspan="5">
											<table class="table table-bordered table-hovered w-100">
												<!-- tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+9)+(sectorService.lastSectorTwoIndex)}}"
												attr.tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+9)+(sectorService.lastSectorTwoIndex)}}" -->
												<tr>
													<td></td>
													<td class="inner-heads text-center" *ngIf="!isTenantIndian">Amount({{invoice_root_data.purchase_currency}})</td>
													<td class="inner-heads text-center">Amount({{currencyCode}})</td>
												</tr>
												<tr>
													<td class="text-bold" >
														<div class="d-flex align-item-center justify-content-center text-bold"> Base <input 
															
															type="checkbox"
															class="ml-1" (change)="setFare($event,'txtBaseFare')" /></div>
													</td>
													<td *ngIf="!isTenantIndian"><input 
														tabIndex="{{!isTenantIndian && (i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+9)+(sectorService.lastSectorTwoIndex))}}"
														attr.tabIndex="{{!isTenantIndian && (i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+9)+(sectorService.lastSectorTwoIndex))}}"
														type="text" class="traveller-amt-field" formControlName="localBaseFare"
														oninput="this.value = this.value.toUpperCase()" pattern='^\d{0,8}(\.\d{1,2})?$'
														class="traveller-input-field traveller-amt-field text-right {{travellers.value.travellerStatus}}_input"
														(input)="calculateBase(i)" [ngClass]="{'validateError': (travellers.controls.localBaseFare.invalid && travellers.controls.localBaseFare.touched) || 
											            (!travellers.controls.localBaseFare.valid && sectorService.submitted)}" /></td>
													<td ><input 
														tabIndex="{{isTenantIndian && (i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+9)+(sectorService.lastSectorTwoIndex))}}"
														attr.tabIndex="{{isTenantIndian && (i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+9)+(sectorService.lastSectorTwoIndex))}}"
														oninput="this.value = this.value.toUpperCase()"
														pattern='^\d{0,8}(\.\d{1,2})?$'
														formControlName="txtBaseFare" (input)="calculateTotal(i)"
														class="traveller-input-field traveller-amt-field text-right {{travellers.value.travellerStatus}}_input"
														type="text"  [readonly]="!isTenantIndian"
														[ngClass]="{'validateError': (travellers.controls.txtBaseFare.invalid && travellers.controls.txtBaseFare.touched) || 
											            (!travellers.controls.txtBaseFare.valid && sectorService.submitted)}"/>
													</td>
												</tr>
												<tr>
													<td>
														<div class="d-flex align-item-center justify-content-center text-bold"> YQ <input 
															
															type="checkbox" class="ml-1"
															(change)="setFare($event,'txtYQ')" /></div>
													</td>
													<td *ngIf="!isTenantIndian"><input 
														tabIndex="{{!isTenantIndian && (i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+10)+(sectorService.lastSectorTwoIndex))}}"
														attr.tabIndex="{{!isTenantIndian && (i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+10)+(sectorService.lastSectorTwoIndex))}}"
														type="text" formControlName="localYQ" class="traveller-amt-field" 
														oninput="this.value = this.value.toUpperCase()" pattern='^\d{0,8}(\.\d{1,2})?$'
														class="traveller-input-field traveller-amt-field text-right {{travellers.value.travellerStatus}}_input"
														(input)="calculateYQ(i)" [ngClass]="{'validateError': (travellers.controls.localYQ.invalid && travellers.controls.localYQ.touched) || 
											            (!travellers.controls.localYQ.valid && sectorService.submitted)}"/></td>
													<td><input 
														tabIndex="{{isTenantIndian && (i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+10)+(sectorService.lastSectorTwoIndex))}}"
														attr.tabIndex="{{isTenantIndian && (i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+10)+(sectorService.lastSectorTwoIndex))}}"
														oninput="this.value = this.value.toUpperCase()" formControlName="txtYQ"
														(input)="calculateTotal(i)"  [readonly]="!isTenantIndian"
														pattern='^\d{0,8}(\.\d{1,2})?$'
														class="traveller-input-field traveller-amt-field text-right {{travellers.value.travellerStatus}}_input"
														[ngClass]="{'validateError': (travellers.controls.txtYQ.invalid && travellers.controls.txtYQ.touched) || 
											            (!travellers.controls.txtYQ.valid && sectorService.submitted)}"
														type="text" /></td>
												</tr>
												<tr>
													<td>
														<div class="d-flex align-item-center justify-content-center text-bold"> YR <input 
															
															type="checkbox" class="ml-1"
															(change)="setFare($event,'txtYR')" /></div>
													</td>
													<td *ngIf="!isTenantIndian"><input 
														tabIndex="{{!isTenantIndian && (i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+11)+(sectorService.lastSectorTwoIndex))}}"
														attr.tabIndex="{{!isTenantIndian && (i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+11)+(sectorService.lastSectorTwoIndex))}}"
														type="text" formControlName="localYR" class="traveller-amt-field"  
														oninput="this.value = this.value.toUpperCase()" 
														(input)="calculateYR(i)" pattern='^\d{0,8}(\.\d{1,2})?$'
														class="traveller-input-field traveller-amt-field text-right {{travellers.value.travellerStatus}}_input"
														[ngClass]="{'validateError': (travellers.controls.localYR.invalid && travellers.controls.localYR.touched) || 
											            (!travellers.controls.localYR.valid && sectorService.submitted)}" /></td>
													<td><input  
														tabIndex="{{isTenantIndian && (i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+11)+(sectorService.lastSectorTwoIndex))}}"
														attr.tabIndex="{{isTenantIndian && (i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+11)+(sectorService.lastSectorTwoIndex))}}"
														oninput="this.value = this.value.toUpperCase()" formControlName="txtYR"
														(input)="calculateTotal(i)" pattern='^\d{0,8}(\.\d{1,2})?$' [readonly]="!isTenantIndian"
														class="traveller-input-field traveller-amt-field text-right {{travellers.value.travellerStatus}}_input"
														[ngClass]="{'validateError': (travellers.controls.txtYR.invalid && travellers.controls.txtYR.touched) || 
											            (!travellers.controls.txtYR.valid && sectorService.submitted)}"
														type="text" /></td>
												</tr>
												<tr>
													<td>
														<div class="d-flex align-item-center justify-content-center text-bold"> K3 <input 
																 
															 type="checkbox" class="ml-1"
															(change)="setFare($event,'txtK3')" /></div>
													</td>
													<td *ngIf="!isTenantIndian"><input
														tabIndex="{{!isTenantIndian && (i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+12)+(sectorService.lastSectorTwoIndex))}}"
														attr.tabIndex="{{!isTenantIndian && (i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+12)+(sectorService.lastSectorTwoIndex))}}"
														 type="text" formControlName="localK3" class="traveller-amt-field" 
														 oninput="this.value = this.value.toUpperCase()"
														(input)="calculateK3(i)" pattern='^\d{0,8}(\.\d{1,2})?$' 
														class="traveller-input-field traveller-amt-field text-right {{travellers.value.travellerStatus}}_input"
														[ngClass]="{'validateError': (travellers.controls.localK3.invalid && travellers.controls.localK3.touched) || 
											            (!travellers.controls.localK3.valid && sectorService.submitted)}"
														/></td>
													<td><input 
														tabIndex="{{isTenantIndian && (i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+12)+(sectorService.lastSectorTwoIndex))}}"
														attr.tabIndex="{{isTenantIndian && (i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+12)+(sectorService.lastSectorTwoIndex))}}"
														oninput="this.value = this.value.toUpperCase()" formControlName="txtK3"
														(input)="calculateTotal(i)" pattern='^\d{0,8}(\.\d{1,2})?$' [readonly]="!isTenantIndian"
														class="traveller-input-field traveller-amt-field text-right {{travellers.value.travellerStatus}}_input"
														[ngClass]="{'validateError': (travellers.controls.txtK3.invalid && travellers.controls.txtK3.touched) || 
											            (!travellers.controls.txtK3.valid && sectorService.submitted)}"
														type="text" /></td>
												</tr>
												<tr>
													<td>
														<div class="d-flex align-item-center justify-content-center text-bold"> OC <input 
															type="checkbox" class="ml-1"
															(change)="setFare($event,'txtOC')" /></div>
													</td>
													<td *ngIf="!isTenantIndian"><input 
														tabIndex="{{!isTenantIndian && (i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+13)+(sectorService.lastSectorTwoIndex))}}"
														attr.tabIndex="{{!isTenantIndian && (i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+13)+(sectorService.lastSectorTwoIndex))}}"
														type="text" formControlName="localOC" class="traveller-amt-field" 
														oninput="this.value = this.value.toUpperCase()" 
														(input)="calculateOC(i)" pattern='^\d{0,8}(\.\d{1,2})?$'
														class="traveller-input-field traveller-amt-field text-right {{travellers.value.travellerStatus}}_input"
														[ngClass]="{'validateError': (travellers.controls.localOC.invalid && travellers.controls.localOC.touched) || 
											            (!travellers.controls.localOC.valid && sectorService.submitted)}"
														 /></td>
													<td><input  
														tabIndex="{{isTenantIndian && (i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+13)+(sectorService.lastSectorTwoIndex))}}"
														attr.tabIndex="{{isTenantIndian && (i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+13)+(sectorService.lastSectorTwoIndex))}}"
														oninput="this.value = this.value.toUpperCase()" formControlName="txtOC"
														(input)="calculateTotal(i)" pattern='^\d{0,8}(\.\d{1,2})?$' [readonly]="!isTenantIndian"
														class="traveller-input-field traveller-amt-field text-right {{travellers.value.travellerStatus}}_input"
														[ngClass]="{'validateError': (travellers.controls.txtOC.invalid && travellers.controls.txtOC.touched) || 
											            (!travellers.controls.txtOC.valid && sectorService.submitted)}"
														type="text" /></td>
												</tr>
												<tr>
													<td>
														<div class="d-flex align-item-center justify-content-center text-bold"> OT <input 
															type="checkbox" class="ml-1"
															(change)="setFare($event,'txtOT')" /></div>
													</td>
													<td *ngIf="!isTenantIndian"><input 
														tabIndex="{{!isTenantIndian && (i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+14)+(sectorService.lastSectorTwoIndex))}}"
														attr.tabIndex="{{!isTenantIndian && (i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+14)+(sectorService.lastSectorTwoIndex))}}"
														type="text" formControlName="localOT" class="traveller-amt-field" 
														oninput="this.value = this.value.toUpperCase()"
														(input)="calculateOT(i)" pattern='^\d{0,8}(\.\d{1,2})?$'
														class="traveller-input-field traveller-amt-field text-right {{travellers.value.travellerStatus}}_input"
														[ngClass]="{'validateError': (travellers.controls.localOT.invalid && travellers.controls.localOT.touched) || 
											            (!travellers.controls.localOT.valid && sectorService.submitted)}"
														/></td>
													<td><input 
														tabIndex="{{isTenantIndian && (i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+14)+(sectorService.lastSectorTwoIndex))}}"
														attr.tabIndex="{{isTenantIndian && (i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+14)+(sectorService.lastSectorTwoIndex))}}"
														oninput="this.value = this.value.toUpperCase()" formControlName="txtOT"
														(input)="calculateTotal(i)" pattern='^\d{0,8}(\.\d{1,2})?$' [readonly]="!isTenantIndian"
														[ngClass]="{'validateError': (travellers.controls.txtOT.invalid && travellers.controls.txtOT.touched) || 
											            (!travellers.controls.txtOT.valid && sectorService.submitted)}"
														class="traveller-input-field traveller-amt-field text-right {{travellers.value.travellerStatus}}_input"
														type="text" /></td>
												</tr>
												<tr>
													<td class="text-bold">
														ADDONS
													</td>
													<td *ngIf="!isTenantIndian"><input 
														type="text" formControlName="localAddons"  
														class="traveller-input-field traveller-amt-field text-right {{travellers.value.travellerStatus}}_input" readonly/></td>
													<td><input oninput="this.value = this.value.toUpperCase()"
														formControlName="txtAddons" readonly
														class="traveller-input-field traveller-amt-field text-right {{travellers.value.travellerStatus}}_input"
														type="text" /></td>
												</tr>
												<tr>
													<td class="text-bold">
														Total
													</td>
													<td *ngIf="!isTenantIndian"><input 
														type="text" formControlName="localAmount" 
														class="traveller-input-field traveller-amt-field text-right {{travellers.value.travellerStatus}}_input" readonly/></td>
													<td><input oninput="this.value = this.value.toUpperCase()"
														formControlName="txtAmount" readonly
														class="traveller-input-field traveller-amt-field text-right {{travellers.value.travellerStatus}}_input"
														type="text" /></td>
												</tr>
											</table>
										</td>
										<td colspan="6">
											<table class="table table-bordered table-hovered w-100">
												<tr>
													<td  class="inner-heads text-left"  >Name</td>
													<td class="inner-heads" colspan="3">Client Accounting ({{currencyCode}})</td>
													<td class="inner-heads" colspan="3">Supplier Accounting ({{currencyCode}})</td>
												</tr>
												<tr>
													<td> - </td>
													<td>Type</td>
													<td style="width: 80px;">Value</td>
													<td style="width: 80px;">Amount</td>
													<td>Type</td>
													<td style="width: 80px;">Value</td>
													<td style="width: 80px;">Amount</td>
												</tr>
										
												<ng-container>
													<!-- i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+14)+(sectorService.lastSectorTwoIndex -->
													<!-- i = travellerIndex,
														6 = total Sector input field per row
														14 = (previous conjunction field count index of  i.e, 12) + 1 -->
													<tr>
														<td class="text-left">IATA</td>
														<td><select
																tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+15)+(sectorService.lastSectorTwoIndex)}}"
																attr.tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+15)+(sectorService.lastSectorTwoIndex)}}"
																formControlName="selClientIataType"
																(change)="calculateTotal(i)" class="inner-select">
																<option value="RB">RB</option>
																<option value="RR">RR</option>
																<option value="RY">RY</option>
																<option value="RQ">RQ</option>
																<option value="RT">RT</option>
																<option value="VL">VL</option>
															</select></td>
														<td><input
																tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+16)+(sectorService.lastSectorTwoIndex)}}"
																attr.tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+16)+(sectorService.lastSectorTwoIndex)}}"
																oninput="this.value = this.value.toUpperCase()"
																formControlName="selClientIataValue" pattern='^\d{0,8}(\.\d{1,2})?$'
																[ngClass]="{'validateError': (travellers.controls.selClientIataValue.invalid && travellers.controls.selClientIataValue.touched) || 
											                    (!travellers.controls.selClientIataValue.valid && sectorService.submitted)}"
																class="traveller-inner-input-field text-right"
																type="text" (input)="calculateTotal(i)" /></td>
														<td><input oninput="this.value = this.value.toUpperCase()"
																formControlName="selClientIataAmount" readonly
																class="traveller-inner-input-field text-right"
																type="text" /></td>
														<td><select
																tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+17)+(sectorService.lastSectorTwoIndex)}}"
																attr.tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+17)+(sectorService.lastSectorTwoIndex)}}"
																formControlName="selSupplierIataType"
																(change)="calculateTotal(i)" class="inner-select">
																<option value="RB">RB</option>
																<option value="RR">RR</option>
																<option value="RY">RY</option>
																<option value="RQ">RQ</option>
																<option value="RT">RT</option>
																<option value="VL">VL</option>
															</select></td>
														<td><input
																tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+18)+(sectorService.lastSectorTwoIndex)}}"
																attr.tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+18)+(sectorService.lastSectorTwoIndex)}}"
																oninput="this.value = this.value.toUpperCase()"
																formControlName="selSupplierIataValue"
																(input)="calculateTotal(i)" pattern='^\d{0,8}(\.\d{1,2})?$'
																[ngClass]="{'validateError': (travellers.controls.selSupplierIataValue.invalid && travellers.controls.selSupplierIataValue.touched) || 
											                    (!travellers.controls.selSupplierIataValue.valid && sectorService.submitted)}"
																class="traveller-inner-input-field text-right "
																type="text" /></td>
														<td><input oninput="this.value = this.value.toUpperCase()"
																formControlName="selSupplierIataAmount" readonly
																class="traveller-inner-input-field text-right"
																type="text" /></td>
													</tr>
													<tr>
														<td class="text-left">PLB</td>
														<td><select
																tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+19)+(sectorService.lastSectorTwoIndex)}}"
																attr.tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+19)+(sectorService.lastSectorTwoIndex)}}"
																formControlName="selClientPlbType"
																(change)="calculateTotal(i)" class="inner-select">
																<option value="RB">RB</option>
																<option value="RR">RR</option>
																<option value="RY">RY</option>
																<option value="RQ">RQ</option>
																<option value="RT">RT</option>
																<option value="VL">VL</option>
															</select></td>
														<td><input
																tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+20)+(sectorService.lastSectorTwoIndex)}}"
																attr.tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+20)+(sectorService.lastSectorTwoIndex)}}"
																oninput="this.value = this.value.toUpperCase()"
																formControlName="selClientPlbValue" pattern='^\d{0,8}(\.\d{1,2})?$'
																[ngClass]="{'validateError': (travellers.controls.selClientPlbValue.invalid && travellers.controls.selClientPlbValue.touched) || 
											                    (!travellers.controls.selClientPlbValue.valid && sectorService.submitted)}"
																(input)="calculateTotal(i)"
																class="traveller-inner-input-field text-right "
																type="text" /></td>
														<td><input oninput="this.value = this.value.toUpperCase()"
																formControlName="selClientPlbAmount" readonly
																class="traveller-inner-input-field text-right "
																type="text" /></td>
														<td><select
																tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+21)+(sectorService.lastSectorTwoIndex)}}"
																attr.tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+21)+(sectorService.lastSectorTwoIndex)}}"
																formControlName="selSupplierPlbType"
																(change)="calculateTotal(i)" class="inner-select">
																<option value="RB">RB</option>
																<option value="RR">RR</option>
																<option value="RY">RY</option>
																<option value="RQ">RQ</option>
																<option value="RT">RT</option>
																<option value="VL">VL</option>
															</select></td>
														<td><input
																tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+22)+(sectorService.lastSectorTwoIndex)}}"
																attr.tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+22)+(sectorService.lastSectorTwoIndex)}}"
																oninput="this.value = this.value.toUpperCase()"
																formControlName="selSupplierPlbValue" pattern='^\d{0,8}(\.\d{1,2})?$'
																[ngClass]="{'validateError': (travellers.controls.selSupplierPlbValue.invalid && travellers.controls.selSupplierPlbValue.touched) || 
											                    (!travellers.controls.selSupplierPlbValue.valid && sectorService.submitted)}"
																(input)="calculateTotal(i)"
																class="traveller-inner-input-field text-right"
																type="text" /></td>
														<td><input oninput="this.value = this.value.toUpperCase()"
																formControlName="selSupplierPlbAmount" readonly
																class="traveller-inner-input-field text-right"
																type="text" /></td>
													</tr>
													<tr>
														<td class="text-left">CASHBACK</td>
														<td><select
																tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+23)+(sectorService.lastSectorTwoIndex)}}"
																attr.tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+23)+(sectorService.lastSectorTwoIndex)}}"
																formControlName="selClientCbType"
																(change)="calculateTotal(i)" class="inner-select">
																<option value="RB">RB</option>
																<option value="RR">RR</option>
																<option value="RY">RY</option>
																<option value="RQ">RQ</option>
																<option value="RT">RT</option>
																<option value="VL">VL (Segment)</option>
																<option value="VLN">VL (Net)</option>
															</select></td>
														<td><input
																tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+24)+(sectorService.lastSectorTwoIndex)}}"
																attr.tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+24)+(sectorService.lastSectorTwoIndex)}}"
																oninput="this.value = this.value.toUpperCase()"
																formControlName="selClientCbValue"
																[ngClass]="{'validateError': (travellers.controls.selClientCbValue.invalid && travellers.controls.selClientCbValue.touched) || 
											                    (!travellers.controls.selClientCbValue.valid && sectorService.submitted)}"
																(input)="calculateTotal(i)"
																class="traveller-inner-input-field text-right"
																type="text" /></td>
														<td><input oninput="this.value = this.value.toUpperCase()"
																formControlName="selClientCbAmount" readonly
																class="traveller-inner-input-field text-right"
																type="text" /></td>
														<td><select
																tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+25)+(sectorService.lastSectorTwoIndex)}}"
																attr.tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+25)+(sectorService.lastSectorTwoIndex)}}"
																formControlName="selSupplierCbType"
																(change)="calculateTotal(i)" class="inner-select">
																<option value="RB">RB</option>
																<option value="RR">RR</option>
																<option value="RY">RY</option>
																<option value="RQ">RQ</option>
																<option value="RT">RT</option>
																<option value="VL">VL (Segment)</option>
																<option value="VLN">VL (Net)</option>
															</select>
														</td>
														<td><input
																tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+26)+(sectorService.lastSectorTwoIndex)}}"
																attr.tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+26)+(sectorService.lastSectorTwoIndex)}}"
																oninput="this.value = this.value.toUpperCase()"
																formControlName="selSupplierCbValue" 
																[ngClass]="{'validateError': (travellers.controls.selSupplierCbValue.invalid && travellers.controls.selSupplierCbValue.touched) || 
											                    (!travellers.controls.selSupplierCbValue.valid && sectorService.submitted)}"
																(input)="calculateTotal(i)"
																class="traveller-inner-input-field text-right"
																type="text" /></td>
														<td><input oninput="this.value = this.value.toUpperCase()"
																formControlName="selSupplierCbAmount" readonly
																class="traveller-inner-input-field text-right "
																type="text" /></td>
													</tr>

													<tr *ngIf="discount_add=='true'">
														<td class="text-left">DISCOUNT</td>
														<td><select
																formControlName="selClientDsType"
																(change)="calculateTotal(i)" class="inner-select">
																<option value="VL">VL (Segment)</option>
																<option value="VLN">VL (Net)</option>
															</select></td>
														<td><input
																oninput="this.value = this.value.toUpperCase()"
																formControlName="selClientDsValue"
																[ngClass]="{'validateError': (travellers.controls.selClientDsValue.invalid && travellers.controls.selClientDsValue.touched) || 
																(!travellers.controls.selClientDsValue.valid && sectorService.submitted)}"
																(input)="calculateTotal(i)"
																class="traveller-inner-input-field text-right"
																type="text" /></td>
														<td><input oninput="this.value = this.value.toUpperCase()"
																formControlName="selClientDsAmount" readonly
																class="traveller-inner-input-field text-right"
																type="text" /></td>
														<td><select
																formControlName="selSupplierDsType"
																(change)="calculateTotal(i)" class="inner-select">
																<option value="VL">VL (Segment)</option>
																<option value="VLN">VL (Net)</option>
															</select>
														</td>
														<td><input
																oninput="this.value = this.value.toUpperCase()"
																formControlName="selSupplierDsValue" 
																[ngClass]="{'validateError': (travellers.controls.selSupplierDsValue.invalid && travellers.controls.selSupplierDsValue.touched) || 
																(!travellers.controls.selSupplierDsValue.valid && sectorService.submitted)}"
																(input)="calculateTotal(i)"
																class="traveller-inner-input-field text-right"
																type="text" /></td>
														<td><input oninput="this.value = this.value.toUpperCase()"
																formControlName="selSupplierDsAmount" readonly
																class="traveller-inner-input-field text-right "
																type="text" /></td>
													</tr>

												</ng-container>
										
												<tr>
													<td class="text-left">Disc Recall</td>
													<td><select
															
															formControlName="disClientRecallType" class="inner-select">
															<!-- <option value="RB">RB</option> -->
															
														</select></td>
													<td><input
															tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+27)+(sectorService.lastSectorTwoIndex)}}"
															attr.tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+27)+(sectorService.lastSectorTwoIndex)}}"
															oninput="this.value = this.value.toUpperCase()"
															formControlName="disClientRecallValue"
															[ngClass]="{'validateError': (travellers.controls.disClientRecallValue.invalid && travellers.controls.disClientRecallValue.touched) || 
											                (!travellers.controls.disClientRecallValue.valid && sectorService.submitted)}"
															(input)="calculateTotal(i)" pattern='^\d{0,8}(\.\d{1,2})?$'
															class="traveller-inner-input-field text-right "
															type="text" /></td>
													<td><input oninput="this.value = this.value.toUpperCase()"
															formControlName="disClientRecallAmount"
															value="travellers.value.txtClientSC1Value" readonly
															class="traveller-inner-input-field text-right "
															type="text" /></td>
													<td><select
															formControlName="disSupplierRecallType" class="inner-select">
															<!-- <option value="N">N</option> -->
														</select></td>
													<td><input
															tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+28)+(sectorService.lastSectorTwoIndex)}}"
															attr.tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+28)+(sectorService.lastSectorTwoIndex)}}"
															oninput="this.value = this.value.toUpperCase()"
															[ngClass]="{'validateError': (travellers.controls.disSupplierRecallValue.invalid && travellers.controls.disSupplierRecallValue.touched) || 
											                (!travellers.controls.disSupplierRecallValue.valid && sectorService.submitted)}"
															formControlName="disSupplierRecallValue"
															(input)="calculateTotal(i)" pattern='^\d{0,8}(\.\d{1,2})?$'
															class="traveller-inner-input-field text-right "
															type="text" /></td>
													<td><input oninput="this.value = this.value.toUpperCase()"
															formControlName="disSupplierRecallAmount" readonly
															class="traveller-inner-input-field text-right "
															type="text" /></td>
												</tr>

												<tr>
													<td class="text-left">Service Charge 1</td>
													<td><select
															tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+29)+(sectorService.lastSectorTwoIndex)}}"
															attr.tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+29)+(sectorService.lastSectorTwoIndex)}}"
															formControlName="txtClientSC1Type" class="inner-select">
															<!-- <option value="N">N</option> -->
															<option value="N">N</option>
															<option value="T">T</option>
															<option value="B">B</option>
															<option value="Y">Y</option>
														</select></td>
													<td><input
															tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+30)+(sectorService.lastSectorTwoIndex)}}"
															attr.tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+30)+(sectorService.lastSectorTwoIndex)}}"
															oninput="this.value = this.value.toUpperCase()" (input)="calculateTotal(i)"
															(input)="calculateServiceCharge(i, 'txtClientSC1Amount', 'txtClientSC1Value')"
															formControlName="txtClientSC1Value"  pattern='^\d{0,8}(\.\d{1,2})?$'
															[ngClass]="{'validateError': (travellers.controls.txtClientSC1Value.invalid && travellers.controls.txtClientSC1Value.touched) || 
											                (!travellers.controls.txtClientSC1Value.valid && sectorService.submitted)}"
															class="traveller-inner-input-field text-right "
															type="text" /></td>
													<td><input oninput="this.value = this.value.toUpperCase()"
															formControlName="txtClientSC1Amount"
															value="travellers.value.txtClientSC1Value" readonly
															class="traveller-inner-input-field text-right "
															type="text" /></td>
													<td><select
															tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+31)+(sectorService.lastSectorTwoIndex)}}"
															attr.tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+31)+(sectorService.lastSectorTwoIndex)}}"
															formControlName="txtSupplierSC1Type" class="inner-select">
															<option value="N">N</option>
															<option value="T">T</option>
														</select></td>
													<td><input
															tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+32)+(sectorService.lastSectorTwoIndex)}}"
															attr.tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+32)+(sectorService.lastSectorTwoIndex)}}"
															oninput="this.value = this.value.toUpperCase()" (input)="calculateTotal(i)"
															formControlName="txtSupplierSC1Value" pattern='^\d{0,8}(\.\d{1,2})?$'
															[ngClass]="{'validateError': (travellers.controls.txtSupplierSC1Value.invalid && travellers.controls.txtSupplierSC1Value.touched) || 
											                (!travellers.controls.txtSupplierSC1Value.valid && sectorService.submitted)}"
															(input)="calculateServiceCharge(i, 'txtSupplierSC1Amount', 'txtSupplierSC1Value')"
															class="traveller-inner-input-field text-right "
															type="text" /></td>
													<td><input oninput="this.value = this.value.toUpperCase()"
															formControlName="txtSupplierSC1Amount" readonly
															class="traveller-inner-input-field text-right "
															type="text" /></td>
												</tr>
												<tr>
													<td class="text-left">Service Charge 2</td>
													<td>
														<div class="d-flex justify-content-between">
														<select
															tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+33)+(sectorService.lastSectorTwoIndex)}}"
															attr.tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+33)+(sectorService.lastSectorTwoIndex)}}"
															formControlName="txtClientSC2Type" class="inner-select mr-2">
															<option value="BASE">Base</option>
															<option value="YQ">YQ</option>
															<option value="YR">YR</option>
															<option value="K3">K3</option>
															<option value="OC">OC</option>
															<option value="OT">OT</option>
														</select>

														<select
															tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+34)+(sectorService.lastSectorTwoIndex)}}"
															attr.tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+34)+(sectorService.lastSectorTwoIndex)}}"
															formControlName="txtClientSC2Taxable" class="inner-select">
															<option value="N">N</option>
															<option value="T">T</option>
														</select>
													  </div>
													</td>
													
													
													<td><input
															tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+35)+(sectorService.lastSectorTwoIndex)}}"
															attr.tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+35)+(sectorService.lastSectorTwoIndex)}}"
															oninput="this.value = this.value.toUpperCase()"
															formControlName="txtClientSC2Value" (input)="calculateTotal(i)"
															[ngClass]="{'validateError': (travellers.controls.txtClientSC2Value.invalid && travellers.controls.txtClientSC2Value.touched) || 
											                (!travellers.controls.txtClientSC2Value.valid && sectorService.submitted)}"
															(input)="calculateServiceCharge(i, 'txtClientSC2Amount', 'txtClientSC2Value')"
															class="traveller-inner-input-field text-right "
															type="text" /></td>
													<td><input oninput="this.value = this.value.toUpperCase()"
															formControlName="txtClientSC2Amount" readonly
															class="traveller-inner-input-field text-right "
															type="text" /></td>
													<td><select
															tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+36)+(sectorService.lastSectorTwoIndex)}}"
															attr.tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+36)+(sectorService.lastSectorTwoIndex)}}"
															formControlName="txtSupplierSC2Type" class="inner-select">
															<option value="N">N</option>
															<!-- <option value="T">T</option> -->
														</select></td>
													<td><input
															tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+37)+(sectorService.lastSectorTwoIndex)}}"
															attr.tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+37)+(sectorService.lastSectorTwoIndex)}}"
															oninput="this.value = this.value.toUpperCase()"
															formControlName="txtSupplierSC2Value"
															[ngClass]="{'validateError': (travellers.controls.txtSupplierSC2Value.invalid && travellers.controls.txtSupplierSC2Value.touched) || 
											                (!travellers.controls.txtSupplierSC2Value.valid && sectorService.submitted)}"
															(input)="calculateServiceCharge(i, 'txtSupplierSC2Amount', 'txtSupplierSC2Value')"
															class="traveller-inner-input-field text-right"
															type="text" /></td>
													<td><input oninput="this.value = this.value.toUpperCase()"
															formControlName="txtSupplierSC2Amount" readonly
															class="traveller-inner-input-field text-right"
															type="text" /></td>
												</tr>
												<tr>
													<td  class="text-left">Service Charge 3</td>
													<td>
														<!-- <select
															tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+37)+(sectorService.lastSectorTwoIndex)}}"
															attr.tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+37)+(sectorService.lastSectorTwoIndex)}}"
															formControlName="txtClientSC3Type" class="inner-select">
															<option value="BASE">Base</option>
															<option value="YQ">YQ</option>
															<option value="YR">YR</option>
															<option value="K3">K3</option>
															<option value="OC">OC</option>
															<option value="OT">OT</option>
															<option value="N">N</option>
														</select> -->

														<div class="d-flex justify-content-between">
															<select
																tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+38)+(sectorService.lastSectorTwoIndex)}}"
																attr.tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+38)+(sectorService.lastSectorTwoIndex)}}"
																formControlName="txtClientSC3Type" class="inner-select mr-2">
																<option value="BASE">Base</option>
																<option value="YQ">YQ</option>
																<option value="YR">YR</option>
																<option value="K3">K3</option>
																<option value="OC">OC</option>
																<option value="OT">OT</option>
															</select>
	
															<select
																tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+39)+(sectorService.lastSectorTwoIndex)}}"
																attr.tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+39)+(sectorService.lastSectorTwoIndex)}}"
																formControlName="txtClientSC3Taxable" class="inner-select">
																<option value="N">N</option>
																<option value="T">T</option>
															</select>
														</div>

													</td>

														
													<td><input
															tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+40)+(sectorService.lastSectorTwoIndex)}}"
															attr.tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+40)+(sectorService.lastSectorTwoIndex)}}"
															oninput="this.value = this.value.toUpperCase()"
															formControlName="txtClientSC3Value" (input)="calculateTotal(i)"
															[ngClass]="{'validateError': (travellers.controls.txtClientSC3Value.invalid && travellers.controls.txtClientSC3Value.touched) || 
											                (!travellers.controls.txtClientSC3Value.valid && sectorService.submitted)}"
															(input)="calculateServiceCharge(i, 'txtClientSC3Amount', 'txtClientSC3Value')"
															class="traveller-inner-input-field text-right"
															type="text" /></td>
													<td><input oninput="this.value = this.value.toUpperCase()"
															formControlName="txtClientSC3Amount" readonly
															class="traveller-inner-input-field text-right"
															type="text" /></td>
													<td><select
															tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+41)+(sectorService.lastSectorTwoIndex)}}"
															attr.tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+41)+(sectorService.lastSectorTwoIndex)}}"
															formControlName="txtSupplierSC3Type" class="inner-select">
															<option value="N">N</option>
															<!-- <option value="T">T</option> -->
														</select></td>
													<td><input
															tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+42)+(sectorService.lastSectorTwoIndex)}}"
															attr.tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+42)+(sectorService.lastSectorTwoIndex)}}"
															(click)="setTabIndex($event)"
															oninput="this.value = this.value.toUpperCase()"
															formControlName="txtSupplierSC3Value"
															[ngClass]="{'validateError': (travellers.controls.txtSupplierSC3Value.invalid && travellers.controls.txtSupplierSC3Value.touched) || 
											                (!travellers.controls.txtSupplierSC3Value.valid && sectorService.submitted)}"
															(input)="calculateServiceCharge(i, 'txtSupplierSC3Amount', 'txtSupplierSC3Value')"
															class="traveller-inner-input-field text-right"
															type="text" /></td>
													<td><input oninput="this.value = this.value.toUpperCase()"
															formControlName="txtSupplierSC3Amount" readonly
															class="traveller-inner-input-field text-right"
															type="text" /></td>
												</tr>
											</table>
										</td>
									</tr>

									<tr>
										<td colspan="10">
											<div class="traveller-action-col d-flex justify-content-end mt-2 mb-2">
												<button
													tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+43)+(sectorService.lastSectorTwoIndex)}}"
													attr.tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+43)+(sectorService.lastSectorTwoIndex)}}"
													type="button" class="btn-style mr-2" *ngIf="showTravellerIndex > 0"
													(click)="moveToPreviousTraveller(showTravellerIndex)">Previous
													Traveller</button>
												<button type="button"
													tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+43)+(sectorService.lastSectorTwoIndex)}}"
													attr.tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+43)+(sectorService.lastSectorTwoIndex)}}"
													class="btn-style mr-2"
													*ngIf="showTravellerIndex < DynamicFormControls.controls.length - 1"
													(click)="openReplicateModal($event,i)">Next Traveller</button>
												<!-- <ng-container
													*ngIf="showTravellerIndex < DynamicFormControls.controls.length - 1 else submitBtn">
													<button
														tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+48)+(sectorService.lastSectorTwoIndex)}}"
														attr.tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+48)+(sectorService.lastSectorTwoIndex)}}"
														type="button" (click)="openReplicateModal($event,i)"
														class="btn-style mr-2">Save</button>
												</ng-container>
												<ng-template #submitBtn>
													<button type="button"
														tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+48)+(sectorService.lastSectorTwoIndex)}}"
														attr.tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+48)+(sectorService.lastSectorTwoIndex)}}"
														class="btn-style bg-success text-white mr-2" (click)="returnToInvoice()">Save & Return
														to invoice</button>
												</ng-template>
									 -->
												<button type="button" *ngIf="maximizedSection"
													tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+(showTravellerIndex < DynamicFormControls.controls.length - 1 ? 44 : 43))+(sectorService.lastSectorTwoIndex)}}"
													attr.tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+(showTravellerIndex < DynamicFormControls.controls.length - 1 ? 44 : 43))+(sectorService.lastSectorTwoIndex)}}"
													class="btn-style bg-purple" (click)="returnToInvoice()">Calculate Fare</button>
											</div>
										</td>
									</tr>

								</ng-container>
							</ng-container>
						</ng-container>
					</tbody>
				</table>
			</div>


		</div>
	</div>
</form>

<button type="button" hidden #openModal data-toggle="modal" data-target="#replicateConfirm"></button>

<!-- Modal -->
<div id="replicateConfirm" class="modal fade" role="dialog" data-backdrop="false">
	<div class="modal-dialog">

		<!-- Modal content-->
		<div class="modal-content">
			<div class="modal-header">
				<h4 class="modal-title">Confirmation</h4>
				<button type="button" class="close" data-dismiss="modal">&times;</button>
			</div>
			<div class="modal-body">
				<p>Do you want to replicate these values to all the {{selectedTravellerType}}?</p>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn-style" tabIndex="{{sectorService.lastIndexOfTraveller+1}}"
					attr.tabIndex="{{sectorService.lastIndexOfTraveller+1}}"
					(click)="replicateFormData(showTravellerIndex)" data-dismiss="modal">Apply to
					{{selectedTravellerType}}</button>
				<button type="button" class="btn-style" tabIndex="{{sectorService.lastIndexOfTraveller+2}}"
					attr.tabIndex="{{sectorService.lastIndexOfTraveller+2}}" (click)="saveTravellers()"
					(click)="moveToNextTraveller(showTravellerIndex)" data-dismiss="modal">No</button>
			</div>
		</div>

	</div>
</div>