<form [formGroup]="sectorService.travellerForm" *ngIf="sectorService.travellerList != undefined">
	<div class="traveller-outer-container">
		<div class="traveller-section">

			<div class="traveller-col">
				<table  class="table table-hovered table-bordered">
					<thead>
						<tr>
							<th style="width: 30px;">#</th>
							<th style="width: 64px;">Type</th>
							<th style="width: 64px;">Title</th>
							<th *ngIf="corporate_employee=='false'"  style="width: 300px;">Name<span class="text-danger">*</span></th>
							<th *ngIf="corporate_employee=='true'"  style="width: 300px;">Name<span class="text-danger">*</span></th>
							<th *ngIf="corporate_employee=='true'"  style="width: 150px;">E-code<span class="text-danger"></span></th>

							<th style="width: 150px;">Ticket No.</th>
							<th style="width: 100px;">PAY TYPE</th>
							<th style="width: 200px;">CARD</th>
							<th style="width: 250px;">PAYABLE</th>
							<th  *ngIf="supplier_trans=='true'" style="width: 215px;">Payable Trans. N0.</th>
							<th  *ngIf="supplier_trans=='true'" style="width: 86px;">Payable date</th>
							<th style="width: 120px">New Ticket No. <small class="d-block"> (If Any)</small> </th>
							<th style="width: 120px">New PNR <small class="d-block"> (If Any)</small>  </th>
							<!-- <th style="width: 190px;">Payable</th> -->
							<!-- <th class="text-right">Base</th>
							<th class="text-right">YQ</th>
							<th class="text-right">YR</th>
							<th class="text-right">K3</th>
							<th class="text-right">OC</th>
							<th class="text-right">OT</th>
							<th class="text-right">Addons</th> -->
							<th class="text-right" style="width: 120px">Total</th>
							<th style="    width: 67px;"><span><input (click)="selectTraveller($event,'all')"
										*ngIf="sectorService.invoiceMode != 'view'" type="checkbox" checked name=""
										id="">Actions</span></th>
						</tr>
					</thead>
					<tbody formArrayName="travellerList">
						<ng-container *ngFor="let travellers of DynamicFormControls.controls; let i = index">
							<ng-container [formGroupName]="i">
								<tr class="{{travellers.value.travellerStatus}}_table_row"
									[ngClass]="{'d-none' : travellers.value.isCancel == false && maximizedSection}">
									<td style="width: 30px;">{{i+1}}</td>
									<td>
										<ng-container *ngIf="travellers.value.selType == 'ADT'">ADULT</ng-container>
										<ng-container *ngIf="travellers.value.selType == 'CHD'">CHILD</ng-container>
										<ng-container *ngIf="travellers.value.selType == 'INF'">INFANT</ng-container>
									</td>
									<td>
										{{travellers.value.selTitle}}
									</td>
									<td *ngIf="corporate_employee=='false'" >
										{{travellers.value.txtPassengerName}}
									</td>
									<td *ngIf="corporate_employee=='true'" >
										{{travellers.value.txtPassengerName}}
									</td>
									<td *ngIf="corporate_employee=='true'" >
										{{travellers.value.txtEmployeeCode}}
									</td>
									<td>
										<input 
											[ngClass]="{'d-none' : travellers.value.isCancel == false && maximizedSection}"
											formControlName="txtTicketNumber"
											(change)="checkTicketNumber(travellers.value.txtTicketNumber,i)"
											class="{{travellers.value.travellerStatus}}_input traveller-input-field"
											type="text" />
										<!-- {{travellers.value.txtTicketNumber}} -->
									</td>

									<td>
										<select class="select-input {{travellers.value.travellerStatus}}_input"
										    tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+1+10:i*(travellers.value.totalIndexCount)+1+8}}"
										    attr.tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+1+10:i*(travellers.value.totalIndexCount)+1+8}}"
											formControlName="type_of_pay" (change)="setBookingSupplier($event,i)">
											<option value="">Select</option>
											<option value="OUR_CC">Our CC</option>
											<option value="AGENT_CC">Agent CC</option>
										</select>
									</td>
									<!-- <td>
										<select class="select-input {{travellers.value.travellerStatus}}_input"
										tabIndex="{{i*(travellers.value.totalIndexCount)+1+8}}"
										attr.tabIndex="{{i*(travellers.value.totalIndexCount)+1+8}}"
											formControlName="ledger_card_no">
											<option value="">Select</option>
											<ng-container *ngFor="let item of generalAccountArr " >
												<option *ngIf="item.account_code != '' && travellers.value.type_of_pay == 'OUR_CC' "
													value="{{item['account_code']}}">{{item.name}}</option>
											</ng-container>
										</select>
									</td> -->

									<td>
										<div class="autocomplete-container">
											<div class="input-container" tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+1+11:i*(travellers.value.totalIndexCount)+1+9}}"
											attr.tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+1+11:i*(travellers.value.totalIndexCount)+1+9}}">
												<input #ledgerCardAc
												
													(focus)="focusGeneralAc()"
													type="text"
													oninput="this.value = this.value.toUpperCase()" 
													name="ledger_card_name"
													[attr.disabled]="travellers.value.type_of_pay != 'OUR_CC' ? true : null"
													formControlName="ledger_card_name"
													(input)="filterGeneralAccounts($event, i)"
													[nzAutocomplete]="ledgerCardNoAutoComplete"
												/>

												<nz-autocomplete #ledgerCardNoAutoComplete [nzBackfill]="true" (selectionChange)="selectGeneralAccount($event, i)"  >
														<nz-auto-option  class="colr-1" *ngFor="let item of generalFilteredData" [nzValue]="item" [nzLabel]="item.name">
															<a
																innerHTML='<span class="colr-1">{{item?.name}}
																	({{item?.account_code}})</span>'>
															</a>                           
														</nz-auto-option>                      
												</nz-autocomplete>
											</div>
										</div>
									</td>

									<td>
										<div class="autocomplete-container" tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+1+12:i*(travellers.value.totalIndexCount)+1+10}}"
										attr.tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+1+12:i*(travellers.value.totalIndexCount)+1+10}}">
											<div class="input-container">
												<input #supplierAc
													(focus)="focusSupplierAc()" type="text"
													oninput="this.value = this.value.toUpperCase()" 
													name="txtbookingSupplierName"
													[attr.disabled]="(!sectorService.enablePayable && sectorService.invoiceForm.value.invoiceType != 'C') ? true : null"
													formControlName="txtbookingSupplierName"
													(input)="getBookingSupplier($event, i)"
													[nzAutocomplete]="bookingSupplierAc"
												/>

												<nz-autocomplete #bookingSupplierAc [nzBackfill]="true" (selectionChange)="selectBookingSupplier($event, i)"  >
														<nz-auto-option  class="colr-1" *ngFor="let item of bookingSupplierArr" [nzValue]="item" [nzLabel]="item.name">
															<a
																innerHTML='<span class="colr-1">{{item?.name}}
																	({{item?.account_code}})</span>'>
															</a>                           
														</nz-auto-option>                      
												</nz-autocomplete>
											</div>
								       </div>
									</td>

									<td *ngIf="supplier_trans=='true'"><input
										oninput="this.value = this.value.toUpperCase()"
										formControlName="txtSupplierCode" maxLength="14"
										class="traveller-input-field w-100 {{travellers.value.travellerStatus}}_input"
										type="text" [ngClass]="{'validateError': (travellers.controls.txtSupplierCode.invalid && travellers.controls.txtSupplierCode.touched) || 
										(!travellers.controls.txtSupplierCode.valid && sectorService.submitted)}" 
										title="{{(travellers.controls.txtSupplierCode.invalid && travellers.controls.txtSupplierCode.touched) || 
											(!travellers.controls.txtSupplierCode.valid && sectorService.submitted) ? 'Invalid ticket number' : ''}}"
										/>
								</td>
								<td *ngIf="supplier_trans=='true'"><input class=" traveller-input-field" type="date"
										formControlName="supplierDate"
										[ngClass]="{'validateError': (travellers.controls.supplierDate.invalid && travellers.controls.supplierDate.touched) || 
										(!travellers.controls.supplierDate.valid && sectorService.submitted)}"/></td>
								
									<td>
										<input tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+1+13:i*(travellers.value.totalIndexCount)+1+11}}"
											attr.tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+1+13:i*(travellers.value.totalIndexCount)+1+11}}"
											[ngClass]="{'d-none' : travellers.value.isCancel == false && maximizedSection}"
											formControlName="newTicketNo"
											(change)="checkTicketNumber(travellers.value.newTicketNo,i)"
											class="{{travellers.value.travellerStatus}}_input traveller-input-field"
											type="text" />
									</td>
									<td>
										<input tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+2+13:i*(travellers.value.totalIndexCount)+2+11}}"
											attr.tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+2+13:i*(travellers.value.totalIndexCount)+2+11}}"
											[ngClass]="{'d-none' : travellers.value.isCancel == false && maximizedSection}"
											formControlName="newPNRNo"
											class="{{travellers.value.travellerStatus}}_input traveller-input-field"
											type="text" />
									</td>
<!-- 
									<td><input tabIndex="{{i*(travellers.value.totalIndexCount)+3+10}}"
											attr.tabIndex="{{i*(travellers.value.totalIndexCount)+3+10}}"
											[ngClass]="{'d-none' : travellers.value.isCancel == false && maximizedSection}"
											formControlName="txtBaseFare" (input)="calculateTotal(i)"
											class="{{travellers.value.travellerStatus}}_input traveller-input-field traveller-amt-field text-left"
											type="text" />
									</td>
									<td><input tabIndex="{{i*(travellers.value.totalIndexCount)+4+10}}"
											attr.tabIndex="{{i*(travellers.value.totalIndexCount)+4+10}}"
											[ngClass]="{'d-none' : travellers.value.isCancel == false && maximizedSection}"
											formControlName="txtYQ" (input)="calculateTotal(i)"
											class="{{travellers.value.travellerStatus}}_input traveller-input-field traveller-amt-field text-left"
											type="text" />
									</td>
									<td><input tabIndex="{{i*(travellers.value.totalIndexCount)+5+10}}"
											attr.tabIndex="{{i*(travellers.value.totalIndexCount)+5+10}}"
											[ngClass]="{'d-none' : travellers.value.isCancel == false && maximizedSection}"
											formControlName="txtYR" (input)="calculateTotal(i)"
											class="{{travellers.value.travellerStatus}}_input traveller-input-field traveller-amt-field text-left"
											type="text" />
									</td>
									<td><input tabIndex="{{i*(travellers.value.totalIndexCount)+6+10}}"
											attr.tabIndex="{{i*(travellers.value.totalIndexCount)+6+10}}"
											[ngClass]="{'d-none' : travellers.value.isCancel == false && maximizedSection}"
											formControlName="txtK3" (input)="calculateTotal(i)"
											class="{{travellers.value.travellerStatus}}_input traveller-input-field traveller-amt-field text-left"
											type="text" />
									</td>
									<td><input tabIndex="{{i*(travellers.value.totalIndexCount)+7+10}}"
											attr.tabIndex="{{i*(travellers.value.totalIndexCount)+7+10}}"
											[ngClass]="{'d-none' : travellers.value.isCancel == false && maximizedSection}"
											formControlName="txtOC" (input)="calculateTotal(i)"
											class="{{travellers.value.travellerStatus}}_input traveller-input-field traveller-amt-field text-left"
											type="text" /></td>
									<td><input tabIndex="{{i*(travellers.value.totalIndexCount)+8+10}}"
											attr.tabIndex="{{i*(travellers.value.totalIndexCount)+8+10}}"
											[ngClass]="{'d-none' : travellers.value.isCancel == false && maximizedSection}"
											formControlName="txtOT" (input)="calculateTotal(i)"
											class="{{travellers.value.travellerStatus}}_input traveller-input-field traveller-amt-field text-left"
											type="text" /></td>
									<td><input
											[ngClass]="{'d-none' : travellers.value.isCancel == false && maximizedSection}"
											formControlName="txtAddons" readonly
											class="{{travellers.value.travellerStatus}}_input traveller-input-field traveller-amt-field text-left"
											type="text" /></td> -->
									<td><input
											[ngClass]="{'d-none' : travellers.value.isCancel == false && maximizedSection}"
											formControlName="txtAmount" readonly
											class="{{travellers.value.travellerStatus}}_input traveller-input-field traveller-amt-field text-left"
											type="text" /></td>
									<td >
										<span class="action-col row m-0 justify-content-center align-items-center">

											<input type="checkbox" *ngIf="sectorService.invoiceMode != 'view'"
												(click)="selectTraveller($event,i)" formControlName="isCancel"
												name="isCancel_{{i}}" id="isCancel_{{i}}">
											<a class="ml-2" *ngIf="travellers.value.isCancel == true"
												(click)="maximizeSection(i)" href="javascript:void(0)" id="edit_traveller_{{i}}_btn">
												<!-- <button title="Edit"
													class="btn   save-as text-bold bg-whiteSmoke " type="button">
													<img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/green-edit.svg" alt=""
														width="15px">
												</button> -->
												<button title="Edit" class=" custom-table-btn mr-2 bg-white border-0 p-0 text-bold hoverableShadow transition cursor" type="button"
														>
														<img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/dark-edit-btn.svg" alt="" width="20px">
													</button>
												<!-- <i title="Edit"
													class="fas fa-user-edit"></i> -->
											</a>
											<!-- <a (click)="removeTraveller(i)" href="javascript:void(0)"><i title="Delete"
													class="fa fa-trash" aria-hidden="true"></i></a> -->
										</span>
									</td>
								</tr>

								<ng-container *ngIf="showTravellerIndex == i">
									<!-- Invoice Summary if any -->

									<ng-container
										*ngIf="invoiceSummary != undefined && invoiceSummary.data != undefined && invoiceSummary.data.length > 1">
										<tr>
											<td class="traveller-expanded" colspan="16">
												<div class="traveller-ssr">
													<table class="table table-hovered table-bordered">
														<thead>
															<tr>
																<th>Traveller Summary</th>
																<th>Total</th>
																<th>XXL</th>
																<th>RAF</th>
																<th></th>
															</tr>
														</thead>
														<tbody>
															<tr>
																<td></td>
																<td>{{invoiceSummary.total}}</td>
																<td>{{invoiceSummary.XXL}}</td>
																<td>{{invoiceSummary.RAF}}</td>
																<td><a href="javascript:void(0)"
																		(click)="showInvoiceSummary()">View Details</a>
																</td>
															</tr>
														</tbody>
													</table>
												</div>
											</td>
										</tr>
										<!-- Invoice Sumary Detail -->
										<tr *ngIf="showInvoiceSummaryDetail == true">
											<td class="traveller-expanded" colspan="16">
												<div class="traveller-ssr">
													<table class="table table-hovered table-bordered">
														<thead>
															<tr>
																<th>Reference</th>
																<th>Base</th>
																<th>YQ</th>
																<th>YR</th>
																<th>K3</th>
																<th>OC</th>
																<th>OT</th>
																<th>XXL</th>
																<th>RAF</th>
																<th>CLIENT</th>
																<th>SUPPLIER</th>
																<th>Total</th>
															</tr>
														</thead>
														<tbody>
															<ng-container *ngFor="let fare of invoiceSummary.data">
																<tr>
																	<td><a class="hyperLink"
																			(click)="viewPrintScreen(fare.Reference,i)">{{fare?.Reference}}</a>
																	</td>
																	<td>{{fare?.base}}</td>
																	<td>{{fare?.YQ}}</td>
																	<td>{{fare?.YR}}</td>
																	<td>{{fare?.K3}}</td>
																	<td>{{fare?.OC}}</td>
																	<td>{{fare?.OT}}</td>
																	<td>{{fare?.XXL}}</td>
																	<td>{{fare?.RAF}}</td>
																	<td
																		class="text-left field-input letter-spacing-0-1">
																		<span>
																			IATA =
																			{{fare?.commission?.customer?.iata_type}} :
																			{{fare?.commission?.customer?.iata_value}}
																		</span>
																		<hr class="m-0">
																		<span>
																			PLB =
																			{{fare?.commission?.customer?.plb_type}} :
																			{{fare?.commission?.customer?.plb_value}}
																		</span>
																		<hr class="m-0">
																		<span>
																			CB =
																			{{fare?.commission?.customer?.cashback_type}}
																			:
																			{{fare?.commission?.customer?.cashback_value}}
																		</span>
																	</td>
																	<td
																		class="text-left field-input letter-spacing-0-1">
																		<span>
																			IATA =
																			{{fare?.commission?.supplier?.iata_type}} :
																			{{fare?.commission?.supplier?.iata_value}}
																		</span>
																		<hr class="m-0">
																		<span>
																			PLB =
																			{{fare?.commission?.supplier?.plb_type}} :
																			{{fare?.commission?.supplier?.plb_value}}
																		</span>
																		<hr class="m-0">
																		<span>
																			CB =
																			{{fare?.commission?.supplier?.cashback_type}}
																			:
																			{{fare?.commission?.supplier?.cashback_value}}
																		</span>
																	</td>
																	<td>{{fare?.net_received}}</td>
																</tr>
															</ng-container>
														</tbody>
													</table>
												</div>
											</td>
										</tr>
									</ng-container>

									<!-- Sector Wise Addons -->
									<tr>
										<td class="traveller-expanded" colspan="16">
											<div class="traveller-ssr">
												<table class="table table-hovered table-bordered">
													<thead>
														<tr>
															<th>Cancel Request</th>
															<th>Sector</th>
															<th>Seat Code</th>
															<th>Seat Charges</th>
															<th>Meal Code</th>
															<th>Meal charges</th>
															<th>Baggage Code</th>
															<th>Baggage Charges</th>
															<th>Addnl Baggage</th>
															<th>Baggage Charges</th>
															<th>Ancillary</th>
															<th>Ancillary Charges</th>
														</tr>
													</thead>
													<tbody>
														<ng-container formArrayName="sectorList"
															*ngFor="let sectors of travellers.controls.sectorList.controls; let j = index">

															<tr [formGroupName]="j"
																[ngClass]="{cancelledTicket: sectors.value.isCancelled == true}">
																<!--{{i*(travellers.value.totalIndexCount)+(j*6+6+1)+2}} -->
																<!-- i = travellerIndex,
		j = travellerSectorIndex
		6 = total Sector input field per row
		6 = traveller field count before TravellerSector
		1 = current field index of sector , increases with input fields 
		2 = Last index before traveller-->
																<td><input
																		[ngClass]="{'cancelled_input d-none': sectors.value.isCancelled == true}"
																		formControlName="isCancelRequest"
																		class="traveller-inner-input-field "
																		type="checkbox" /></td>
																<td><input
																		[ngClass]="{cancelled_input: sectors.value.isCancelled == true}"
																		oninput="this.value = this.value.toUpperCase()"
																		formControlName="sector" readonly
																		class="traveller-inner-input-field "
																		type="text" /></td>
																<td><input
																		[ngClass]="{cancelled_input: sectors.value.isCancelled == true}"
																		tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+3+13:i*(travellers.value.totalIndexCount)+3+11}}"
											                            attr.tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+3+13:i*(travellers.value.totalIndexCount)+3+11}}"
																		oninput="this.value = this.value.toUpperCase()"
																		formControlName="txtSeatCode"
																		class="traveller-inner-input-field "
																		type="text" /></td>
																<td><input
																		[ngClass]="{cancelled_input: sectors.value.isCancelled == true}"
																		tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+4+13:i*(travellers.value.totalIndexCount)+4+11}}"
											                            attr.tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+4+13:i*(travellers.value.totalIndexCount)+4+11}}"
																		oninput="this.value = this.value.toUpperCase()"
																		formControlName="txtSeatCharges"
																		(input)="calculateTotal(i)"
																		class="traveller-inner-input-field text-right"
																		type="text" /></td>
																<td><input
																		[ngClass]="{cancelled_input: sectors.value.isCancelled == true}"
																		tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+5+13:i*(travellers.value.totalIndexCount)+5+11}}"
											                            attr.tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+5+13:i*(travellers.value.totalIndexCount)+5+11}}"
																		oninput="this.value = this.value.toUpperCase()"
																		formControlName="txtMealCode"
																		class="traveller-inner-input-field "
																		type="text" /></td>
																<td><input
																		[ngClass]="{cancelled_input: sectors.value.isCancelled == true}"
																		tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+6+13:i*(travellers.value.totalIndexCount)+6+11}}"
											                            attr.tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+6+13:i*(travellers.value.totalIndexCount)+6+11}}"
																		oninput="this.value = this.value.toUpperCase()"
																		formControlName="txtMealCharges"
																		(input)="calculateTotal(i)"
																		class="traveller-inner-input-field text-right"
																		type="text" /></td>
																<td><input
																		[ngClass]="{cancelled_input: sectors.value.isCancelled == true}"
																		tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+7+13:i*(travellers.value.totalIndexCount)+7+11}}"
											                            attr.tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+7+13:i*(travellers.value.totalIndexCount)+7+11}}"
																		oninput="this.value = this.value.toUpperCase()"
																		formControlName="txtBagCode"
																		class="traveller-inner-input-field "
																		type="text" /></td>
																<td><input
																		[ngClass]="{cancelled_input: sectors.value.isCancelled == true}"
																		tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+8+13:i*(travellers.value.totalIndexCount)+8+11}}"
											                            attr.tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+8+13:i*(travellers.value.totalIndexCount)+8+11}}"
																		oninput="this.value = this.value.toUpperCase()"
																		formControlName="txtBagCharges"
																		(input)="calculateTotal(i)"
																		class="traveller-inner-input-field text-right"
																		type="text" /></td>
																<td><input
																		[ngClass]="{cancelled_input: sectors.value.isCancelled == true}"
																		tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+9+13:i*(travellers.value.totalIndexCount)+9+11}}"
											                            attr.tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+9+13:i*(travellers.value.totalIndexCount)+9+11}}"
																		oninput="this.value = this.value.toUpperCase()"
																		formControlName="txtAdditionalBaggageCode"
																		class="traveller-inner-input-field "
																		type="text" /></td>
																	<td><input
																		[ngClass]="{cancelled_input: sectors.value.isCancelled == true}"
																		tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+10+13:i*(travellers.value.totalIndexCount)+10+11}}"
											                            attr.tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+10+13:i*(travellers.value.totalIndexCount)+10+11}}"
																		oninput="this.value = this.value.toUpperCase()"
																		formControlName="txtAdditionalBaggageCharges"
																		(input)="calculateTotal(i)"
																		class="traveller-inner-input-field text-right"
																		type="text" /></td>
																	<td><input
																		[ngClass]="{cancelled_input: sectors.value.isCancelled == true}"
																		tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+11+13:i*(travellers.value.totalIndexCount)+11+11}}"
											                            attr.tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+11+13:i*(travellers.value.totalIndexCount)+11+11}}"
																		oninput="this.value = this.value.toUpperCase()"
																		formControlName="txtAncillaryCode"
																		class="traveller-inner-input-field "
																		type="text" /></td>
																	<td><input
																		[ngClass]="{cancelled_input: sectors.value.isCancelled == true}"
																		tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+12+13:i*(travellers.value.totalIndexCount)+12+11}}"
											                            attr.tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+12+13:i*(travellers.value.totalIndexCount)+12+11}}"
																		oninput="this.value = this.value.toUpperCase()"
																		formControlName="txtAncillaryCharges"
																		(input)="calculateTotal(i)"
																		class="traveller-inner-input-field text-right"
																		type="text" /></td>					
															</tr>

														</ng-container>

													</tbody>
												</table>
											</div>
										</td>
									</tr>
									<tr>
										<td colspan="5">
											<table class="table table-bordered table-hovered w-100">
												<tr>
													<td></td>
													<td class="inner-heads text-center" *ngIf="!isTenantIndian">Amount({{invoice_root_data.purchase_currency}})</td>
													<td class="inner-heads text-center">Amount({{currencyCode}})</td>
												</tr>
												<tr>
													<td class="text-bold" >
														<div class="d-flex align-item-center justify-content-center text-bold"> Base <input 
															tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+7)+13:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+7)+11}}"
												            attr.tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+7)+13:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+7)+11}}"
															type="checkbox"
															class="ml-1" (change)="setFare($event,'txtBaseFare')" /></div>
													</td>
													<td *ngIf="!isTenantIndian"><input 
														type="text"  formControlName="localBaseFare"
														oninput="this.value = this.value.toUpperCase()" pattern='^\d{0,8}(\.\d{1,2})?$'
														class="traveller-input-field traveller-amt-field text-right {{travellers.value.travellerStatus}}_input"
														(input)="calculateBase(i)" [ngClass]="{'validateError': (travellers.controls.localBaseFare.invalid && travellers.controls.localBaseFare.touched) || 
											            (!travellers.controls.localBaseFare.valid && sectorService.submitted)}" /></td>
													<td><input
														tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+8)+13:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+8)+11}}"
												        attr.tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+8)+13:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+8)+11}}"
														oninput="this.value = this.value.toUpperCase()"
														formControlName="txtBaseFare" (input)="calculateTotal(i)"
														class="traveller-input-field traveller-amt-field text-right {{travellers.value.travellerStatus}}_input"
														type="text"  [readonly]="!isTenantIndian" /></td>
												</tr>
												<tr>
													<td>
														<div class="d-flex align-item-center justify-content-center text-bold"> YQ <input 
															tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+9)+13:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+9)+11}}"
															attr.tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+9)+13:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+9)+11}}"
															type="checkbox" class="ml-1"
															(change)="setFare($event,'txtYQ')" /></div>
													</td>
													<td *ngIf="!isTenantIndian"><input 
														type="text" formControlName="localYQ" class="traveller-amt-field" 
														oninput="this.value = this.value.toUpperCase()" pattern='^\d{0,8}(\.\d{1,2})?$'
														class="traveller-input-field traveller-amt-field text-right {{travellers.value.travellerStatus}}_input"
														(input)="calculateYQ(i)" [ngClass]="{'validateError': (travellers.controls.localYQ.invalid && travellers.controls.localYQ.touched) || 
											            (!travellers.controls.localYQ.valid && sectorService.submitted)}"/></td>
													<td><input
														tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+10)+13:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+10)+11}}"
														attr.tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+10)+13:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+10)+11}}"
														oninput="this.value = this.value.toUpperCase()" formControlName="txtYQ"
														(input)="calculateTotal(i)" [readonly]="!isTenantIndian" 
														class="traveller-input-field traveller-amt-field text-right {{travellers.value.travellerStatus}}_input"
														type="text" /></td>
												</tr>
												<tr>
													<td>
														<div class="d-flex align-item-center justify-content-center text-bold"> YR <input 
															tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+11)+13:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+11)+11}}"
															attr.tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+11)+13:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+11)+11}}"
															type="checkbox" class="ml-1"
															(change)="setFare($event,'txtYR')" /></div>
													</td>
													<td *ngIf="!isTenantIndian"><input 
														type="text" formControlName="localYR" class="traveller-amt-field"  
														oninput="this.value = this.value.toUpperCase()" 
														(input)="calculateYR(i)" pattern='^\d{0,8}(\.\d{1,2})?$'
														class="traveller-input-field traveller-amt-field text-right {{travellers.value.travellerStatus}}_input"
														[ngClass]="{'validateError': (travellers.controls.localYR.invalid && travellers.controls.localYR.touched) || 
											            (!travellers.controls.localYR.valid && sectorService.submitted)}" /></td>
													<td><input
														tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+12)+13:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+12)+11}}"
												        attr.tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+12)+13:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+12)+11}}"
														oninput="this.value = this.value.toUpperCase()" formControlName="txtYR"
														(input)="calculateTotal(i)" [readonly]="!isTenantIndian" 
														class="traveller-input-field traveller-amt-field text-right {{travellers.value.travellerStatus}}_input"
														type="text" /></td>
												</tr>
												<tr>
													<td>
														<div class="d-flex align-item-center justify-content-center text-bold"> K3 <input 
															 	tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+13)+13:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+13)+11}}"
															    attr.tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+13)+13:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+13)+11}}"
															 type="checkbox" class="ml-1"
															(change)="setFare($event,'txtK3')" /></div>
													</td>
													<td *ngIf="!isTenantIndian"><input
														type="text" formControlName="localK3" class="traveller-amt-field" 
														oninput="this.value = this.value.toUpperCase()"
													   (input)="calculateK3(i)" pattern='^\d{0,8}(\.\d{1,2})?$' 
													   class="traveller-input-field traveller-amt-field text-right {{travellers.value.travellerStatus}}_input"
													   [ngClass]="{'validateError': (travellers.controls.localK3.invalid && travellers.controls.localK3.touched) || 
													   (!travellers.controls.localK3.valid && sectorService.submitted)}"
													   /></td>
													<td><input
														tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+14)+13:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+14)+11}}"
														attr.tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+14)+13:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+14)+11}}"
														oninput="this.value = this.value.toUpperCase()" formControlName="txtK3"
														(input)="calculateTotal(i)" [readonly]="!isTenantIndian" 
														class="traveller-input-field traveller-amt-field text-right {{travellers.value.travellerStatus}}_input"
														type="text" /></td>
												</tr>
												<tr>
													<td>
														<div class="d-flex align-item-center justify-content-center text-bold"> OC <input 
															tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+15)+13:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+15)+11}}"
															attr.tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+15)+13:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+15)+11}}"
															type="checkbox" class="ml-1"
															(change)="setFare($event,'txtOC')" /></div>
													</td>
													<td *ngIf="!isTenantIndian"><input 
														type="text" formControlName="localOC" class="traveller-amt-field" 
														oninput="this.value = this.value.toUpperCase()" 
														(input)="calculateOC(i)" pattern='^\d{0,8}(\.\d{1,2})?$'
														class="traveller-input-field traveller-amt-field text-right {{travellers.value.travellerStatus}}_input"
														[ngClass]="{'validateError': (travellers.controls.localOC.invalid && travellers.controls.localOC.touched) || 
											            (!travellers.controls.localOC.valid && sectorService.submitted)}"
														 /></td>
													<td><input
														tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+16)+13:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+16)+11}}"
														attr.tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+16)+13:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+16)+11}}"
														oninput="this.value = this.value.toUpperCase()" formControlName="txtOC"
														(input)="calculateTotal(i)" [readonly]="!isTenantIndian"
														class="traveller-input-field traveller-amt-field text-right {{travellers.value.travellerStatus}}_input"
														type="text" /></td>
												</tr>
												<tr>
													<td>
														<div class="d-flex align-item-center justify-content-center text-bold"> OT <input 
															tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+17)+13:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+18)+11}}"
															attr.tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+17)+13:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+18)+11}}"
															type="checkbox" class="ml-1"
															(change)="setFare($event,'txtOT')" /></div>
													</td>
													<td *ngIf="!isTenantIndian"><input 
														type="text" formControlName="localOT" class="traveller-amt-field" 
														oninput="this.value = this.value.toUpperCase()"
														(input)="calculateOT(i)" pattern='^\d{0,8}(\.\d{1,2})?$'
														class="traveller-input-field traveller-amt-field text-right {{travellers.value.travellerStatus}}_input"
														[ngClass]="{'validateError': (travellers.controls.localOT.invalid && travellers.controls.localOT.touched) || 
											            (!travellers.controls.localOT.valid && sectorService.submitted)}"
														/></td>
													<td><input
														tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+18)+13:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+18)+11}}"
														attr.tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+18)+13:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+18)+11}}"
														oninput="this.value = this.value.toUpperCase()" formControlName="txtOT"
														(input)="calculateTotal(i)" [readonly]="!isTenantIndian"
														class="traveller-input-field traveller-amt-field text-right {{travellers.value.travellerStatus}}_input"
														type="text" /></td>
												</tr>
												<tr>
													<td class="text-bold">
														ADDONS
													</td>
													<td *ngIf="!isTenantIndian"><input 
														type="text" formControlName="localAddons" class="traveller-amt-field"  
														class="traveller-input-field traveller-amt-field text-right {{travellers.value.travellerStatus}}_input" readonly/></td>
													<td><input oninput="this.value = this.value.toUpperCase()"
														formControlName="txtAddons" readonly
														class="traveller-input-field traveller-amt-field text-right {{travellers.value.travellerStatus}}_input"
														type="text" /></td>
												</tr>
												<tr>
													<td class="text-bold">
														Total
													</td>
													<td *ngIf="!isTenantIndian"><input 
														type="text" formControlName="localAmount" class="traveller-amt-field" 
														class="traveller-input-field traveller-amt-field text-right {{travellers.value.travellerStatus}}_input" readonly/></td>
													<td><input oninput="this.value = this.value.toUpperCase()"
														formControlName="txtAmount" readonly
														class="traveller-input-field traveller-amt-field text-right {{travellers.value.travellerStatus}}_input"
														type="text" /></td>
												</tr>
											</table>
										</td>

										<td colspan="6">
											<table class="table table-bordered table-hovered w-100">
												<tr>
													<td style="width: 300px;" class="inner-heads" rowspan="2">Name</td>
													<td class="inner-heads" colspan="3">Client Accounting ({{currencyCode}})</td>
													<td class="inner-heads" colspan="3">Supplier Accounting ({{currencyCode}})</td>
												</tr>
												<tr>
													<td>Type</td>
													<td style="width: 80px;">Value</td>
													<td style="width: 80px;">Amount</td>
													<td>Type</td>
													<td style="width: 80px;">Value</td>
													<td style="width: 80px;">Amount</td>
												</tr>
												<ng-container>
													<!-- i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+13)+(sectorService.lastSectorTwoIndex -->
													<!-- i = travellerIndex,
		6 = total Sector input field per row
		13 = (previous conjunction field count index of  i.e, 12) + 1 -->
													<tr>
														<td>IATA</td>
														<td><select
																tabIndex="{{isTenantIndian==false? i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+19)+11 : i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+19)+13 }}"
																attr.tabIndex="{{isTenantIndian==false? i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+19)+11 :i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+19)+13}}"
																formControlName="selClientIataType"
																(change)="calculateCommission(i)" class="inner-select">
																<option value="RB">RB</option>
																<option value="RR">RR</option>
																<option value="RY">RY</option>
																<option value="RQ">RQ</option>
																<option value="RT">RT</option>
																<option value="VL">VL</option>
															</select></td>
														<td><input
																tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+20)+11:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+20)+13}}"
																attr.tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+20)+11:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+20)+13}}"
																oninput="this.value = this.value.toUpperCase()"
																formControlName="selClientIataValue"
																class="traveller-inner-input-field text-right"
																type="text" (input)="calculateCommission(i)" /></td>
														<td><input oninput="this.value = this.value.toUpperCase()"
																formControlName="selClientIataAmount" readonly
																class="traveller-inner-input-field text-right"
																type="text" /></td>
														<td><select
																tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+21)+11:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+21)+13}}"
																attr.tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+21)+11:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+21)+13}}"
																formControlName="selSupplierIataType"
																(change)="calculateCommission(i)" class="inner-select">
																<option value="RB">RB</option>
																<option value="RR">RR</option>
																<option value="RY">RY</option>
																<option value="RQ">RQ</option>
																<option value="RT">RT</option>
																<option value="VL">VL</option>
															</select></td>
														<td><input
																tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+22)+11:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+22)+13}}"
																attr.tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+22)+11:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+22)+13}}"
																oninput="this.value = this.value.toUpperCase()"
																formControlName="selSupplierIataValue"
																(input)="calculateCommission(i)"
																class="traveller-inner-input-field text-right "
																type="text" /></td>
														<td><input oninput="this.value = this.value.toUpperCase()"
																formControlName="selSupplierIataAmount" readonly
																class="traveller-inner-input-field text-right"
																type="text" /></td>
													</tr>
													<tr>
														<td>PLB</td>
														<td><select
																tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+23)+11:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+23)+13}}"
																attr.tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+23)+11:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+23)+13}}"
																formControlName="selClientPlbType"
																(change)="calculateCommission(i)" class="inner-select">
																<option value="RB">RB</option>
																<option value="RR">RR</option>
																<option value="RY">RY</option>
																<option value="RQ">RQ</option>
																<option value="RT">RT</option>
																<option value="VL">VL</option>
															</select></td>
														<td><input
																tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+24)+11:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+24)+13}}"
																attr.tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+24)+11:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+24)+13}}"
																oninput="this.value = this.value.toUpperCase()"
																formControlName="selClientPlbValue"
																(input)="calculateCommission(i)"
																class="traveller-inner-input-field text-right "
																type="text" /></td>
														<td><input oninput="this.value = this.value.toUpperCase()"
																formControlName="selClientPlbAmount" readonly
																class="traveller-inner-input-field text-right "
																type="text" /></td>
														<td><select
																tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+25)+11:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+25)+13}}"
																attr.tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+25)+11:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+25)+13}}"
																formControlName="selSupplierPlbType"
																(change)="calculateCommission(i)" class="inner-select">
																<option value="RB">RB</option>
																<option value="RR">RR</option>
																<option value="RY">RY</option>
																<option value="RQ">RQ</option>
																<option value="RT">RT</option>
																<option value="VL">VL</option>
															</select></td>
														<td><input
																tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+26)+11:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+26)+13}}"
																attr.tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+26)+11:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+26)+13}}"
																oninput="this.value = this.value.toUpperCase()"
																formControlName="selSupplierPlbValue"
																(input)="calculateCommission(i)"
																class="traveller-inner-input-field text-right"
																type="text" /></td>
														<td><input oninput="this.value = this.value.toUpperCase()"
																formControlName="selSupplierPlbAmount" readonly
																class="traveller-inner-input-field text-right"
																type="text" /></td>
													</tr>
													<tr>
														<td>CASHBACK</td>
														<td><select
																tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+27)+11:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+27)+13}}"
																attr.tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+27)+11:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+27)+13}}"
																formControlName="selClientCbType"
																(change)="calculateCommission(i)" class="inner-select">
																<option value="RB">RB</option>
																<option value="RR">RR</option>
																<option value="RY">RY</option>
																<option value="RQ">RQ</option>
																<option value="RT">RT</option>
																<option value="VL">VL (Segment)</option>
																<option value="VLN">VL (Net)</option>
															</select></td>
														<td><input
																tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+28)+11:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+28)+13}}"
																attr.tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+28)+11:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+28)+13}}"
																oninput="this.value = this.value.toUpperCase()"s
																formControlName="selClientCbValue"
																(input)="calculateCommission(i)"
																class="traveller-inner-input-field text-right"
																type="text" /></td>
														<td><input oninput="this.value = this.value.toUpperCase()"
																formControlName="selClientCbAmount" readonly
																class="traveller-inner-input-field text-right"
																type="text" /></td>
														<td><select
																tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+29)+11:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+29)+13}}"
																attr.tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+29)+11:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+29)+13}}"
																formControlName="selSupplierCbType"
																(change)="calculateCommission(i)" class="inner-select">
																<option value="RB">RB</option>
																<option value="RR">RR</option>
																<option value="RY">RY</option>
																<option value="RQ">RQ</option>
																<option value="RT">RT</option>
																<option value="VL">VL (Segment)</option>
																<option value="VLN">VL (Net)</option>
															</select></td>
														<td><input
																tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+30)+11:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+30)+13}}"
																attr.tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+30)+11:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+30)+13}}"
																oninput="this.value = this.value.toUpperCase()"
																formControlName="selSupplierCbValue"
																(input)="calculateCommission(i)"
																class="traveller-inner-input-field text-right"
																type="text" /></td>
														<td><input oninput="this.value = this.value.toUpperCase()"
																formControlName="selSupplierCbAmount" readonly
																class="traveller-inner-input-field text-right "
																type="text" /></td>
													</tr>

													<tr *ngIf="discount_add=='true'">
														<td>DISCOUNT</td>
														<td><select
																formControlName="selClientDsType"
																(change)="calculateCommission(i)" class="inner-select">
																<option value="VL">VL (Segment)</option>
																<option value="VLN">VL (Net)</option>
															</select></td>
														<td><input
																oninput="this.value = this.value.toUpperCase()"s
																formControlName="selClientDsValue"
																(input)="calculateCommission(i)"
																class="traveller-inner-input-field text-right"
																type="text" /></td>
														<td><input oninput="this.value = this.value.toUpperCase()"
																formControlName="selClientDsAmount" readonly
																class="traveller-inner-input-field text-right"
																type="text" /></td>
														<td><select
																formControlName="selSupplierDsType"
																(change)="calculateCommission(i)" class="inner-select">
																<option value="VL">VL (Segment)</option>
																<option value="VLN">VL (Net)</option>
															</select></td>
														<td><input
																oninput="this.value = this.value.toUpperCase()"
																formControlName="selSupplierDsValue"
																(input)="calculateCommission(i)"
																class="traveller-inner-input-field text-right"
																type="text" /></td>
														<td><input oninput="this.value = this.value.toUpperCase()"
																formControlName="selSupplierDsAmount" readonly
																class="traveller-inner-input-field text-right "
																type="text" /></td>
													</tr>

													<tr>
														<td>Disc Recall</td>
														<td><select
																
																formControlName="disClientRecallType" class="inner-select">
																<!-- <option value="RB">RB</option> -->
																
															</select></td>
														<td><input
																tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+31)+11:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+31)+13}}"
																attr.tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+31)+11:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+31)+13}}"
																oninput="this.value = this.value.toUpperCase()"
																formControlName="disClientRecallValue"
																(input)="calculateCommission(i)"
																class="traveller-inner-input-field text-right "
																type="text" /></td>
														<td><input oninput="this.value = this.value.toUpperCase()"
																formControlName="disClientRecallAmount"
																value="travellers.value.txtClientSC1Value" readonly
																class="traveller-inner-input-field text-right "
																type="text" /></td>
														<td><select
																formControlName="disSupplierRecallType" class="inner-select">
																<!-- <option value="N">N</option> -->
															</select></td>
														<td><input
																tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+32)+11:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+32)+13}}"
																attr.tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+32)+11:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+32)+13}}"
																oninput="this.value = this.value.toUpperCase()"
																formControlName="disSupplierRecallValue"
																(input)="calculateCommission(i)"
																class="traveller-inner-input-field text-right "
																type="text" /></td>
														<td><input oninput="this.value = this.value.toUpperCase()"
																formControlName="disSupplierRecallAmount" readonly
																class="traveller-inner-input-field text-right "
																type="text" /></td>
													</tr>
												</ng-container>
												<tr>
													<td>Service Charge 1</td>
													<td><select
															tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+33)+11:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+33)+13}}"
															attr.tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+33)+11:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+33)+13}}"
															formControlName="txtClientSC1Type" class="inner-select">
															<option value="N">N</option>
															<option value="T">T</option>
														</select></td>
													<td><input
															tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+34)+11:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+34)+13}}"
															attr.tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+34)+11:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+34)+13}}"
															(input)="calculateServiceCharge(i, 'txtClientSC1Amount', 'txtClientSC1Value')"
															formControlName="txtClientSC1Value"
															class="traveller-inner-input-field text-right "
															type="text" /></td>
													<td><input oninput="this.value = this.value.toUpperCase()"
															formControlName="txtClientSC1Amount"
															value="travellers.value.txtClientSC1Value" readonly
															class="traveller-inner-input-field text-right "
															type="text" /></td>
													<td><select
															tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+35)+11:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+35)+13}}"
															attr.tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+35)+11:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+35)+13}}"
															formControlName="txtSupplierSC1Type" class="inner-select">
															<option value="N">N</option>
															<option value="T">T</option>
														</select></td>
													<td><input
															tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+36)+11:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+36)+13}}"
															attr.tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+36)+11:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+36)+13}}"
															(input)="calculateServiceCharge(i, 'txtSupplierSC1Amount', 'txtSupplierSC1Value')"
															formControlName="txtSupplierSC1Value"
															class="traveller-inner-input-field text-right "
															type="text" /></td>
													<td><input oninput="this.value = this.value.toUpperCase()"
															formControlName="txtSupplierSC1Amount" readonly
															class="traveller-inner-input-field text-right "
															type="text" /></td>
												</tr>
												<tr>
													<td>Service Charge 2</td>
													<td>
														<div class="d-flex justify-content-between">
														<select
															tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+37)+11:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+37)+13}}"
															attr.tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+37)+11:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+37)+13}}"
															formControlName="txtClientSC2Type" class="inner-select mr-2">
															<option value="BASE">Base</option>
															<option value="YQ">YQ</option>
															<option value="YR">YR</option>
															<option value="K3">K3</option>
															<option value="OC">OC</option>
															<option value="OT">OT</option>
															<option value="XXL">XXL</option>
															<option value="RAF">RAF</option>
														</select>

														<select
														tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+34)+(sectorService.lastSectorTwoIndex)}}"
														attr.tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+34)+(sectorService.lastSectorTwoIndex)}}"
														formControlName="txtClientSC2Taxable" class="inner-select">
														<option value="N">N</option>
														<option value="T">T</option>
													</select>
												  </div>
												</td>
												
													<td><input
															tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+38)+11:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+38)+13}}"
															attr.tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+38)+11:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+38)+13}}"
															(input)="calculateServiceCharge(i, 'txtClientSC2Amount', 'txtClientSC2Value')"
															formControlName="txtClientSC2Value"
															class="traveller-inner-input-field text-right "
															type="text" /></td>
													<td><input oninput="this.value = this.value.toUpperCase()"
															formControlName="txtClientSC2Amount" readonly
															class="traveller-inner-input-field text-right "
															type="text" /></td>
													<td><select
															tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+39)+11:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+39)+13}}"
															attr.tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+39)+11:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+39)+13}}"
															formControlName="txtSupplierSC2Type" class="inner-select">
															<option value="N">N</option>
															<!-- <option value="T">T</option> -->
														</select></td>
													<td><input
															tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+40)+11:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+40)+13}}"
															attr.tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+40)+11:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+40)+13}}"
															(input)="calculateServiceCharge(i, 'txtSupplierSC2Amount', 'txtSupplierSC2Value')"
															formControlName="txtSupplierSC2Value"
															class="traveller-inner-input-field text-right"
															type="text" /></td>
													<td><input oninput="this.value = this.value.toUpperCase()"
															formControlName="txtSupplierSC2Amount" readonly
															class="traveller-inner-input-field text-right"
															type="text" /></td>
												</tr>

												<tr>
													<td >Service Charge 3</td>
													<td>
														<!-- <select
															tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+37)+(sectorService.lastSectorTwoIndex)}}"
															attr.tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+37)+(sectorService.lastSectorTwoIndex)}}"
															formControlName="txtClientSC3Type" class="inner-select">
															<option value="BASE">Base</option>
															<option value="YQ">YQ</option>
															<option value="YR">YR</option>
															<option value="K3">K3</option>
															<option value="OC">OC</option>
															<option value="OT">OT</option>
															<option value="N">N</option>
														</select> -->

														<div class="d-flex justify-content-between">
															<select
																tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+38)+(sectorService.lastSectorTwoIndex)}}"
																attr.tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+38)+(sectorService.lastSectorTwoIndex)}}"
																formControlName="txtClientSC3Type" class="inner-select mr-2">
																<option value="BASE">Base</option>
																<option value="YQ">YQ</option>
																<option value="YR">YR</option>
																<option value="K3">K3</option>
																<option value="OC">OC</option>
																<option value="OT">OT</option>
																<option value="XXL">XXL</option>
																<option value="RAF">RAF</option>

															</select>
	
															<select
																tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+39)+(sectorService.lastSectorTwoIndex)}}"
																attr.tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+39)+(sectorService.lastSectorTwoIndex)}}"
																formControlName="txtClientSC3Taxable" class="inner-select">
																<option value="N">N</option>
																<option value="T">T</option>
															</select>
														</div>

													</td>

														
													<td><input
															tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+40)+(sectorService.lastSectorTwoIndex)}}"
															attr.tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+40)+(sectorService.lastSectorTwoIndex)}}"
															oninput="this.value = this.value.toUpperCase()"
															formControlName="txtClientSC3Value"
															[ngClass]="{'validateError': (travellers.controls.txtClientSC3Value.invalid && travellers.controls.txtClientSC3Value.touched) || 
											                (!travellers.controls.txtClientSC3Value.valid && sectorService.submitted)}"
															(input)="calculateServiceCharge(i, 'txtClientSC3Amount', 'txtClientSC3Value')"
															class="traveller-inner-input-field text-right"
															type="text" /></td>
													<td><input oninput="this.value = this.value.toUpperCase()"
															formControlName="txtClientSC3Amount" readonly
															class="traveller-inner-input-field text-right"
															type="text" /></td>
													<td><select
															tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+41)+(sectorService.lastSectorTwoIndex)}}"
															attr.tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+41)+(sectorService.lastSectorTwoIndex)}}"
															formControlName="txtSupplierSC3Type" class="inner-select">
															<option value="N">N</option>
															<!-- <option value="T">T</option> -->
														</select></td>
													<td><input
															tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+42)+(sectorService.lastSectorTwoIndex)}}"
															attr.tabIndex="{{i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*10+42)+(sectorService.lastSectorTwoIndex)}}"
															oninput="this.value = this.value.toUpperCase()"
															formControlName="txtSupplierSC3Value"
															[ngClass]="{'validateError': (travellers.controls.txtSupplierSC3Value.invalid && travellers.controls.txtSupplierSC3Value.touched) || 
											                (!travellers.controls.txtSupplierSC3Value.valid && sectorService.submitted)}"
															(input)="calculateServiceCharge(i, 'txtSupplierSC3Amount', 'txtSupplierSC3Value')"
															class="traveller-inner-input-field text-right"
															type="text" /></td>
													<td><input oninput="this.value = this.value.toUpperCase()"
															formControlName="txtSupplierSC3Amount" readonly
															class="traveller-inner-input-field text-right"
															type="text" /></td>
												</tr>

												<tr>
													<td>XXL</td>
													<td></td>
													<td><input
															tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+41)+11:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+41)+13}}"
															attr.tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+41)+11:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+41)+13}}"
															oninput="this.value = this.value.toUpperCase()"
															formControlName="txtClientXXLAmount" (input)="replicateClientXXLAmount(i)"
															class="traveller-inner-input-field text-right"
															type="text" /></td>
													<td><input oninput="this.value = this.value.toUpperCase()"
															value="{{travellers.value.txtClientXXLAmount}}" readonly
															class="traveller-inner-input-field text-right"
															type="text" /></td>
													<td></td>
													<td><input
															tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+42)+11:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+42)+13}}"
															attr.tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+42)+11:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+42)+13}}"
															oninput="this.value = this.value.toUpperCase()"
															formControlName="txtSupplierXXLAmount"
															class="traveller-inner-input-field text-right"
															type="text" /></td>
													<td><input oninput="this.value = this.value.toUpperCase()"
															value="{{travellers.value.txtSupplierXXLAmount}}" readonly
															class="traveller-inner-input-field text-right"
															type="text" /></td>
												</tr>
												<tr>
													<td>RAF</td>
													<td></td>
													<td><input
															tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+43)+11:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+43)+13}}"
															attr.tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+43)+11:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+43)+13}}"
															oninput="this.value = this.value.toUpperCase()"
															formControlName="txtClientRAFAmount"
															class="traveller-inner-input-field text-right"
															type="text" /></td>
													<td><input oninput="this.value = this.value.toUpperCase()"
															value="{{travellers.value.txtClientRAFAmount}}" readonly
															class="traveller-inner-input-field text-right"
															type="text" /></td>
													<td></td>
													<td><input (click)="setTabIndex($event)"
															tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+44)+11:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+44)+13}}"
															attr.tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+44)+11:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+44)+13}}"
															oninput="this.value = this.value.toUpperCase()"
															formControlName="txtSupplierRAFAmount"
															class="traveller-inner-input-field text-right"
															type="text" /></td>
													<td><input oninput="this.value = this.value.toUpperCase()"
															value="{{travellers.value.txtSupplierRAFAmount}}" readonly
															class="traveller-inner-input-field text-right"
															type="text" /></td>
												</tr>
											</table>
										</td>
									</tr>

									<tr>
										<td colspan="16">
											<div class="traveller-action-col d-flex justify-content-center mt-2 mb-20">
												<button type="button" class="btn-style bg-red mr-3" tabIndex="20" attr.tabIndex="20"
												(click)="closeWindow()">Close</button>
												<button
													tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+45)+11:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+45)+13}}"
													attr.tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+45)+11:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+45)+13}}"
													type="button" class="btn-style  mr-2" *ngIf="showTravellerIndex > 0"
													(click)="moveToPreviousTraveller(showTravellerIndex)">Previous
													Traveller</button>
												<button
													tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+46)+11:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+46)+13}}"
													attr.tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+46)+11:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+46)+13}}"
													type="button" class="btn-style bg-blue mr-2"
													*ngIf="showTravellerIndex < DynamicFormControls.controls.length - 1"
													(click)="openReplicateModal($event,i)">Next Traveller</button>
												<ng-container
													*ngIf="showTravellerIndex < DynamicFormControls.controls.length - 1 else submitBtn">
													<button
														tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+47)+11:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+47)+13}}"
														attr.tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+47)+11:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+47)+13}}"
														type="button" (click)="openReplicateModal($event,i)"
														class="btn-style bg-green mr-2">Save</button>
												</ng-container>
												<ng-template #submitBtn>
													<button
														tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+48)+11:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+48)+13}}"
														attr.tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+48)+11:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+48)+13}}"
														type="button" class="btn-style bg-purple mr-2"
														(click)="returnToInvoice()">Save & Return to
														invoice</button>
												</ng-template>

												<button type="button" *ngIf="maximizedSection"
													tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+49)+11:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+49)+13}}"
													attr.tabIndex="{{isTenantIndian==false?i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+49)+11:i*(travellers.value.totalIndexCount)+((travellers.controls.sectorList.controls.length)*6+49)+13}}"
													class="btn-style bg-orange" (click)="returnToInvoice()">Return to
													Invoice</button>
											</div>
										</td>
									</tr>

								</ng-container>
							</ng-container>
						</ng-container>
					</tbody>
				</table>
				<div class="row" *ngIf="!maximizedSection">
					<div class="col-md-6"><span class="info alert-warning">* Only Selected Travellers will be
							refunded.</span></div>
					<div class="col-md-6">
						<!-- <button type="button" class="btn-style float-right" tabIndex="8" (click)="maximizeSection(0)"
							href="javascript:void(0)"> Edit</button> -->
					</div>

				</div>

			</div>


		</div>
	</div>
</form>


<button type="button" hidden #openModal data-toggle="modal" data-target="#replicateConfirm"></button>

<!-- Modal -->
<div id="replicateConfirm" class="modal fade" role="dialog" data-backdrop="false">
	<div class="modal-dialog">

		<!-- Modal content-->
		<div class="modal-content">
			<div class="modal-header">
				<h4 class="modal-title">Confirmation</h4>
				<button type="button" class="close" data-dismiss="modal">&times;</button>
			</div>
			<div class="modal-body">
				<p>Do you want to replicate these values to all the {{selectedTravellerType}}?</p>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn-style " tabIndex="{{isTenantIndian==false?sectorService.lastIndexOfTraveller+2:sectorService.lastIndexOfTraveller+2}}"
					attr.tabIndex="{{isTenantIndian==false?sectorService.lastIndexOfTraveller+2:sectorService.lastIndexOfTraveller+2}}"
					(click)="moveToNextTraveller(showTravellerIndex)" data-dismiss="modal">No</button>
					<button type="button" class="btn-style bg-orange" tabIndex="{{sectorService.lastIndexOfTraveller+1}}"
						attr.tabIndex="{{sectorService.lastIndexOfTraveller+1}}"
						(click)="replicateFormData(showTravellerIndex)" data-dismiss="modal">Apply to
						{{selectedTravellerType}}</button>
			</div>
		</div>

	</div>
</div>