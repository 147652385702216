
 <div *ngIf="showMessage" class="status-modal-box  transition delay scale-1" [ngClass]="{'show-modal-box': showMessage, 'hide-modal-box': !showMessage}">
  <div class="status-card transition w-100per position-fixed top-10 bg-white-color modal-box-card d-flex flex-y-center w-100per justify-space-between p-10-20 bdr-radius-10 mb-10">
    <div class="d-flex flex-y-center">
      <img
        src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/{{alertType=='error-box' ? 'red-circle-error.svg' :
          alertType=='success-box' ? 'green-sucess.svg' : 
          alertType=='warning-box' ? 'red-warning.svg' : 
          null }}"
        width="20px"
        height="20px"
        alt="alert-logo"
        class="mr-20"
      />
      <div class="ml-10">
        <h4 class="m-0 text-size-18 text-bold"
        [ngClass]="{'text-red': alertType=='error-box', 'text-orange-color': alertType=='warning-box', 'text-green' :  alertType=='success-box' }">
          {{alertType === "error-box"
          ? "Error"
          : alertType === "warning-box"
          ? "Warning"
          : alertType === "success-box"
          ? "Success"
          : null}}
        </h4>
        <small className="text-dark-grey-color text-size-12 mt-5 mb-0">
         {{message}}
        </small>
      </div>
    </div>
    <img
      src="{{commonService.aws_asset_url}}/_assets/icons/red-close.svg"
      alt="close-btn"
      (click)="hideError()"
      className=" close-btn"
    />
  </div>
</div>

<button type="button" hidden  #openSuccessModal data-toggle="modal" data-target="#successModal"></button>

<!-- Modal -->
<div id="successModal" class="modal fade" role="dialog" data-backdrop="false">
    <div class="modal-dialog transaction-creation-modal">
        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <span class="messageFont">
                    <span>
                        {{message}} 
                    </span>
                    <a 
                        *ngIf="createdClientCode != ''"
                         href="javascript:void(0)"
                        (click)="viewClient(createdClientId)">
                            {{createdClientCode}}
                    </a>
                </span>
            <button type="button" class="close" (click)="closeSuccesModal()">&times;</button>
            </div>

            <div class="modal-body">
                <p>Do you want to create new Client or view newly created Client ?</p>
            </div>
          
            <div class="modal-footer justify-content-center" >
                <button tabIndex="1" tabindex="1" type="button" class="btn btn-primary " (click)="viewClient(createdClientId)">
                    View
                </button>
                <button tabIndex="3" tabindex="3" type="button" class="btn btn-primary" (click)="closeSuccesModal()">
                    Create New
                </button>
            </div>
        </div>
    </div>
</div>

<div class="ledger-container  ">
  <div class="row tab-nav pl-0 pr-0">
    <!-- <div class="col-sm-4 pl-0">
        <ul class="list-inline list-unstyled p-0 m-0">
            <li class="active page-title text-uppercase cursor"> View Client</li>
        </ul>
    </div> -->
    <div class="col-sm-4 ">
      <ul class="page-nav d-flex align-item-center list-inline list-unstyled p-0 m-0">
          <li [routerLink]="['/']"
              class="text-grey text-capitalize cursor  hover-text-primary transition"> Utility</li>
          <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
          <li [routerLink]="['/']"
              class="text-grey text-capitalize cursor  hover-text-primary transition"> Cost Center</li>
          <li><img src="{{commonService.aws_asset_url}}/_assets/icons/light-mode/angle-left.svg" alt=""></li>
          <li class="text-grey text-capitalize cursor text-primary transition"> Create Cost Center</li>
      </ul>
  </div>
</div>



    <form [formGroup]="costCenterForm" [ngClass]="{'d-none': !newClient}">
      <div class="custom-form form-container mt-4 pl-15 pr-15" style="    width: 600px;
      margin: 20px 0 ">

    <div class="text-input-group d-flex justify-content-between">
      
      <div class="text-input-field mr-2">
        <input class="input-field" oninput="this.value = this.value.toUpperCase()" name="name" tabIndex="2"
          formControlName="name" type="text" [ngClass]="{'error-field': costCenterForm.controls.name.invalid && costCenterForm.controls.name.dirty  || (!costCenterForm.controls.name.valid && submitted),
            'success-field' : costCenterForm.controls.name.valid && costCenterForm.controls.name.dirty}" />
        <label
          [ngClass]="{'error-label': costCenterForm.controls.name.invalid && costCenterForm.controls.name.dirty || (!costCenterForm.controls.name.valid && submitted)}">Name
          <span class="text-danger">*</span>
          <ng-container
            *ngIf="costCenterForm.controls.name.invalid && costCenterForm.controls.name.dirty || (!costCenterForm.controls.name.valid && submitted)">
            <i class="fa fa-info-circle ml-2" title="Account Required" aria-hidden="true"></i>
          </ng-container>
        </label>

      </div>


    </div>
      

      


        </div>
        <div class="btn-field bottom-btns-container mt-30 d-flex align-item-center justify-content-end  ml-0 mr-0"> 
         
          <div>
            <button type="button" class="btn-style bg-green mr-3" (click)="reset()">Reset</button>     
          <button type="button" class="btn-style  bg-purple" tabIndex="22" #confirmModal (click)="openConfirmModal()"
          data-toggle="modal" data-target="#confirmationModal">Save</button>
          </div>  
      </div>
    </form>

  
</div>

<div class="modal fade" id="confirmationModal" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
  <div class="modal-dialog modal-md" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4>Confirmation</h4>
        <button type="button" class="close" #closeModal data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Create {{costCenterForm?.value?.name}} ?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn-style mr-3" tabIndex="23" data-dismiss="modal">Close</button>
        <button type="button" class="btn-style bg-green" (click)="submit()" tabIndex="24">Yes</button>
      </div>
    </div>
  </div>
</div>

<button type="button" hidden #confirmUpdateModal data-toggle="modal" data-target="#updateconfirmationModal"></button>
<div id="updateconfirmationModal" class="modal fade" role="dialog" data-backdrop="false">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Confirmation</h4>
        <button type="button" #closeUpdateModal class="close" data-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        There is a mismatch of amount {{remainingAmount}} in your opening balance. It will be adjusted in the Opening Balance Adjustment account. Would you like to proceed?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn-style mr-3" 
        data-dismiss="modal">Close</button>
        <button type="button" class="btn-style bg-green" (click)="updateOpeningBalancecombine()" >CREATE</button>
      </div>
    </div>
  </div>
</div>